/* ROLES */
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const ADD_NEW_ROLE_SUCCESS = "ADD_NEW_ROLE_SUCCESS";
export const ADD_NEW_ROLE_FAIL = "ADD_NEW_ROLE_FAIL";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export const GET_ROLE_DETAILS_SUCCESS = "GET_ROLE_DETAILS_SUCCESS";
export const GET_ROLE_DETAILS_FAIL = "GET_ROLE_DETAILS_FAIL";

export const RESET_ROLE_FLAG = "RESET_ROLE_FLAG";