import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_BILLINGS,
  ADD_NEW_BILLING,
  RESEND_INVOICE,
  INVOICE_STATUS_CHANGE
} from "./actionTypes"

import {
  getBillingsSuccess,
  getBillingsFail,

  addNewBillingSuccess,
  addNewBillingFail,

  resendInvoiceSuccess,
  resendInvoiceFail,

  statusChangeInvoiceSuccess,
  statusChangeInvoiceFail

} from "./actions"

//Include Both Helper File with needed methods
import {
  getBillingList,
  addNewBilling,
  resendInvoice,
  statusChangeInvoice
} from "../../helpers/fakebackend_helper"


function* fetchBillings({ payload: data }) {
  try {
    const response = yield call(getBillingList, data)
    yield put(getBillingsSuccess(response))
  } catch (error) {
    yield put(getBillingsFail(error))
  }
}

export function* watchFetchBillings() {
  yield takeEvery(GET_BILLINGS, fetchBillings);
}


function* onAddNewBilling({ payload: data }) {
  try {
      const response = yield call(addNewBilling, data);
      if(response.status === 200){
        yield put(addNewBillingSuccess(response));
      } else {
        yield put(addNewBillingFail(response));
      }
  } catch (error) {
      yield put(addNewBillingFail(error));
  }
}

export function* watchAddNewBilling() {
  yield takeEvery(ADD_NEW_BILLING, onAddNewBilling);
}


//Resend Invoice
function* onResendInvoice({ payload: data }) {
  try {
    const response = yield call(resendInvoice, data);
    yield put(resendInvoiceSuccess({ data, ...response }));
  } catch (error) {
    yield put(resendInvoiceFail(error));
  }
}

export function* watchResendInvoice() {
  yield takeEvery(RESEND_INVOICE, onResendInvoice);
}

//Resend Invoice
function* onStatusChangeInvoice({ payload: data }) {
  try {
    const response = yield call(statusChangeInvoice, data);
    yield put(statusChangeInvoiceSuccess({ data, ...response }));
  } catch (error) {
    yield put(statusChangeInvoiceFail(error));
  }
}

export function* watchStatusChangeInvoice() {
  yield takeEvery(INVOICE_STATUS_CHANGE, onStatusChangeInvoice);
}




function* billingSaga() {
  yield all(
    [
      fork(watchFetchBillings),
      fork(watchAddNewBilling),
      fork(watchResendInvoice),
      fork(watchStatusChangeInvoice),
    ]
  );

}

export default billingSaga
