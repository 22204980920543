import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_TICKETS_LIST,
    GET_TICKET_DETAILS,
    UPDATE_ASSIGN_TICKET,
    GET_TICKET_SIGNATURE_DETAILS,
    GET_TICKET_ASSIGN_DETAILS,
    GET_TLC_TICKET_USER_STATUS,
    GET_TLC_TICKET_ADMIN_STATUS,
    GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS,
    GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS,
    GET_DMV_TICKETS_LIST,
    ADD_NEW_TICKET,
    DELETE_TICKET,
    UPDATE_TICKET,
    
} from "./actionType";
import {
    TicketsApiResponseSuccess,
    TicketsApiResponseError,

    getTicketDetailsSuccess,
    getTicketDetailsFail,

    getSignatureTicketDetailsSuccess,
    getSignatureTicketDetailsFail,

    getTicketAssignDetailsSuccess,
    getTicketAssignDetailsFail,

    getTlcTicketUserStatusSuccess,
    getTlcTicketUserStatusFail,

    getTlcTicketAdminStatusSuccess,
    getTlcTicketAdminStatusFail,

    getTlcTicketUserStatusAssignDocumentsSuccess,
    getTlcTicketUserStatusAssignDocumentsFail,

    getTlcTicketAdminStatusAssignDocumentsSuccess,
    getTlcTicketAdminStatusAssignDocumentsFail,


    updateAssignTicketSuccess,
    updateAssignTicketFail,
    getDmvTicketsSuccess,
    getDmvTicketsFail,
    addTicketSuccess,
    addTicketFail,
    updateTicketSuccess,
    updateTicketFail,
    deleteTicketSuccess,
    deleteTicketFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getTicketsList as getTicketsListApi,
    getTicketsDetails,

    updateAssignTicket,

    getTicketAssignDetails,
    getSignatureTicketDetails,
    getTicketStatusList,
    addNewTicket,
    updateTicket,
    getTicketAssignStatusDocumentsList,
    deleteTicket
}
    from "../../helpers/fakebackend_helper";

function* getTicketsList({ payload: tickiet }) {
    try {
        const response = yield call(getTicketsListApi,tickiet);
        yield put(TicketsApiResponseSuccess(response));
    } catch (error) {
        yield put(TicketsApiResponseError(GET_TICKETS_LIST, error));
    }
}


//TICKET DETAILS
function* onTicketsDetails({ payload: tickiet }) {
    try {
        const response = yield call(getTicketsDetails, tickiet);
        if(response.status === 200){
          yield put(getTicketDetailsSuccess(response));
        } else {
          yield put(getTicketDetailsFail(response));
        }
    } catch (error) {
        yield put(getTicketDetailsFail(error));
    }
  }
  
  export function* watchTicketsDetails() {
    yield takeEvery(GET_TICKET_DETAILS, onTicketsDetails);
  }



  function* getDmvTicketsList({ payload: tickiet }) {
    try {
        const response = yield call(getTicketsListApi,tickiet);
        yield put(getDmvTicketsSuccess(response));
    } catch (error) {
        yield put(getDmvTicketsFail(GET_DMV_TICKETS_LIST, error));
    }
  }

  export function* watchDmvTicketsList() {
    yield takeEvery(GET_DMV_TICKETS_LIST, getDmvTicketsList);
  }

  
  function* getTlcTicketUserStatusList({ payload: ticket }) {
    try {
        const response = yield call(getTicketStatusList,ticket);
        yield put(getTlcTicketUserStatusSuccess(response));
    } catch (error) {
        yield put(getTlcTicketUserStatusFail(GET_TLC_TICKET_USER_STATUS, error));
    }
  }

  export function* watchTlcTicketUserStatusList() {
    yield takeEvery(GET_TLC_TICKET_USER_STATUS, getTlcTicketUserStatusList);
  }
  function* getTlcTicketAdminStatusList({ payload: data }) {
    try {
        const response = yield call(getTicketStatusList,data);
        yield put(getTlcTicketAdminStatusSuccess(response));
    } catch (error) {
        yield put(getTlcTicketAdminStatusFail(GET_TLC_TICKET_ADMIN_STATUS, error));
    }
  }

  export function* watchTlcTicketAdminStatusList() {
    yield takeEvery(GET_TLC_TICKET_ADMIN_STATUS, getTlcTicketAdminStatusList);
  }


  function* getTlcTicketUserStatusAssignDocumentsList({ payload: data }) {
    try {
        const response = yield call(getTicketAssignStatusDocumentsList,data);
        yield put(getTlcTicketUserStatusAssignDocumentsSuccess(response));
    } catch (error) {
        yield put(getTlcTicketUserStatusAssignDocumentsFail(GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS, error));
    }
  }

  export function* watchTlcTicketUserStatusAssignDocumentsList() {
    yield takeEvery(GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS, getTlcTicketUserStatusAssignDocumentsList);
  }
  function* getTlcTicketAdminStatusAssignDocumentsList({ payload: data }) {
    try {
        const response = yield call(getTicketAssignStatusDocumentsList,data);
        yield put(getTlcTicketAdminStatusAssignDocumentsSuccess(response));
    } catch (error) {
        yield put(getTlcTicketAdminStatusAssignDocumentsFail(GET_TLC_TICKET_ADMIN_STATUS, error));
    }
  }

  export function* watchTlcTicketAdminStatusAssignDocumentsList() {
    yield takeEvery(GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS, getTlcTicketAdminStatusAssignDocumentsList);
  }


  //TICKET SIGNATURE DETAILS
    function* onSignatureTicketDetails({ payload: tickiet }) {
        try {
            const response = yield call(getSignatureTicketDetails, tickiet);
            if(response.status === 200){
            yield put(getSignatureTicketDetailsSuccess(response));
            } else {
            yield put(getSignatureTicketDetailsFail(response));
            }
        } catch (error) {
            yield put(getSignatureTicketDetailsFail(error));
        }
    }
  
  export function* watchSignatureTicketDetails() {
    yield takeEvery(GET_TICKET_SIGNATURE_DETAILS, onSignatureTicketDetails);
  }



//ASSIGN TICKET

function* onTicketAssignDetails({ payload: tickiet }) {
    try {
        const response = yield call(getTicketAssignDetails, tickiet);
        if(response.status === 200){
        yield put(getTicketAssignDetailsSuccess(response));
        } else {
        yield put(getTicketAssignDetailsFail(response));
        }
    } catch (error) {
        yield put(getTicketAssignDetailsFail(error));
    }
}

export function* watchTicketAssignDetails() {
yield takeEvery(GET_TICKET_ASSIGN_DETAILS, onTicketAssignDetails);
}


function* onUpdateAssignTicket({ payload: tickiet }) {
    try {
        const response = yield call(updateAssignTicket, tickiet);
        if(response.status === 200){
          yield put(updateAssignTicketSuccess(response));
        } else {
          yield put(updateAssignTicketFail(response));
        }
    } catch (error) {
        yield put(updateAssignTicketFail(error));
    }
  }
  
  export function* watchUpdateAssignTicket() {
    yield takeEvery(UPDATE_ASSIGN_TICKET, onUpdateAssignTicket);
  }










function* onAddNewTicket({ payload: ticket }) {
    try {
        const response = yield call(addNewTicket, ticket);

        yield put(addTicketSuccess(response));
    } catch (error) {
        yield put(addTicketFail(error));
    }
}


function* onUpdateTicket({ payload: ticket }) {
    try {
        const response = yield call(updateTicket, ticket);
        yield put(updateTicketSuccess(response));
    } catch (error) {
        yield put(updateTicketFail(error));
    }
}

function* onDeleteTicket({ payload: ticket }) {
    try {
        const response = yield call(deleteTicket, ticket);

        yield put(deleteTicketSuccess({ ticket, ...response }));
    } catch (error) {
        yield put(deleteTicketFail(error));
    }
}

export function* watchGetTicketsList() {
    yield takeEvery(GET_TICKETS_LIST, getTicketsList);
}

export function* watchAddNewTicket() {
    yield takeEvery(ADD_NEW_TICKET, onAddNewTicket);
}

export function* watchUpdateTicket() {
    yield takeEvery(UPDATE_TICKET, onUpdateTicket);
}

export function* watchDeleteTicket() {
    yield takeEvery(DELETE_TICKET, onDeleteTicket);
}


function* ticketsSaga() {
    yield all(
        [
            fork(watchGetTicketsList),
            fork(watchTicketsDetails),
            fork(watchUpdateAssignTicket),
            fork(watchSignatureTicketDetails),
            
            fork(watchAddNewTicket),
            fork(watchUpdateTicket),
            fork(watchTicketAssignDetails),
            fork(watchDeleteTicket),
            fork(watchTlcTicketUserStatusList),
            fork(watchTlcTicketAdminStatusList),
            fork(watchTlcTicketUserStatusAssignDocumentsList),
            fork(watchTlcTicketAdminStatusAssignDocumentsList),
            fork(watchDmvTicketsList)
        ]
    );
}

export default ticketsSaga;