import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_TRANSACTIONS,
  GET_TRANSACTION_DETAILS,
  GET_PAYMENT_TICKETS,
  GET_PAYMENT_TICKETS_TLC,
  STATUS_CHANGE_TRANSACTION_STATEPAY,
  UPLOAD_STATEPAY_DOCUMENT,
  ASSIGN_MANAGER,
  ASSIGN_USER,
} from "./actionTypes";

import {
  getTransactionsSuccess,
  getTransactionsFail,
  getTransactionDetailsSuccess,
  getTransactionDetailsFail,
  getPaymentTicketsSuccess,
  getPaymentTicketsFail,
  getPaymentTicketsTlcSuccess,
  getPaymentTicketsTlcFail,
  statusChangeStatepaySuccess,
  statusChangeStatepayFail,
  assignManagerSuccess,
  assignManagerFail,
  assignUserSuccess,
  assignUserFail,
  uploadStatepayDocumentSuccess,
  uploadStatepayDocumentFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getTransactionList,
  getPaymentTicketList,
  getTransactionDetails,
  statusChangeStatepay,
  uploadStatepayDocument,
  assignManager,
  assignUser,
} from "../../helpers/fakebackend_helper";

function* fetchTransactions({ payload: transaction }) {
  try {
    const response = yield call(getTransactionList, transaction);
    yield put(getTransactionsSuccess(response));
  } catch (error) {
    yield put(getTransactionsFail(error));
  }
}
export function* watchFetchTransactions() {
  yield takeEvery(GET_TRANSACTIONS, fetchTransactions);
}
function* fetchPaymentTickets({ payload: data }) {
  try {
    const response = yield call(getPaymentTicketList, data);
    yield put(getPaymentTicketsSuccess(response));
  } catch (error) {
    yield put(getPaymentTicketsFail(error));
  }
}
export function* watchFetchPaymentTickets() {
  yield takeEvery(GET_PAYMENT_TICKETS, fetchPaymentTickets);
}
function* fetchPaymentTicketsTLc({ payload: data }) {
  try {
    const response = yield call(getPaymentTicketList, data);
    yield put(getPaymentTicketsTlcSuccess(response));
  } catch (error) {
    yield put(getPaymentTicketsTlcFail(error));
  }
}
export function* watchFetchPaymentTicketsTLc() {
  yield takeEvery(GET_PAYMENT_TICKETS_TLC, fetchPaymentTicketsTLc);
}

function* fetchChangeStatepay({ payload: data }) {
  try {
    const response = yield call(statusChangeStatepay, data);
    console.log(response);
    if (response.status == 400) {
      yield put(statusChangeStatepayFail(response));
    } else {
      yield put(statusChangeStatepaySuccess(response));
    }
  } catch (error) {
    yield put(statusChangeStatepayFail(error));
  }
}
export function* watchFetchChangeStatepay() {
  yield takeEvery(STATUS_CHANGE_TRANSACTION_STATEPAY, fetchChangeStatepay);
}
function* fetchUploadStatepayDocument({ payload: data }) {
  try {
    const response = yield call(uploadStatepayDocument, data);
    console.log(response);
    if (response.status == 400) {
      yield put(uploadStatepayDocumentFail(response));
    } else {
      yield put(uploadStatepayDocumentSuccess(response));
    }
  } catch (error) {
    yield put(uploadStatepayDocumentFail(error));
  }
}
export function* watchFetchUploadStatepayDocument() {
  yield takeEvery(UPLOAD_STATEPAY_DOCUMENT, fetchUploadStatepayDocument);
}

function* onTransactionDetails({ payload: transaction }) {
  try {
    const response = yield call(getTransactionDetails, transaction);
    if (response.status === 200) {
      yield put(getTransactionDetailsSuccess(response));
    } else {
      yield put(getTransactionDetailsFail(response));
    }
  } catch (error) {
    yield put(getTransactionDetailsFail(error));
  }
}

export function* watchTransactionDetails() {
  yield takeEvery(GET_TRANSACTION_DETAILS, onTransactionDetails);
}

function* onAssignManager({ payload: transaction }) {
  try {
    const response = yield call(assignManager, transaction);
    if (response.status === 200) {
      yield put(assignManagerSuccess(response));
    } else {
      yield put(assignManagerFail(response));
    }
  } catch (error) {
    yield put(assignManagerFail(error));
  }
}

export function* watchAssignManager() {
  yield takeEvery(ASSIGN_MANAGER, onAssignManager);
}
function* onAssignUser({ payload: transaction }) {
  try {
    const response = yield call(assignUser, transaction);
    if (response.status === 200) {
      yield put(assignUserSuccess(response));
    } else {
      yield put(assignUserFail(response));
    }
  } catch (error) {
    yield put(assignUserFail(error));
  }
}

export function* watchAssignUser() {
  yield takeEvery(ASSIGN_USER, onAssignUser);
}

function* transactionSaga() {
  yield all([
    fork(watchFetchTransactions),
    fork(watchTransactionDetails),
    fork(watchFetchChangeStatepay),
    fork(watchFetchUploadStatepayDocument),
    fork(watchAssignManager),
    fork(watchAssignUser),
    fork(watchFetchPaymentTicketsTLc),
    fork(watchFetchPaymentTickets),
  ]);
}

export default transactionSaga;
