import React, { useState,useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Card,CardBody,CardHeader,Col,Container,Button,Spinner,Row,Input,Label,FormFeedback,Form} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { 
  addNewVehicle, 
  resetVehicleFlag,
  getStateList,
  getPurchaseTypeList,
  getDriver
} from "../../../store/actions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";


import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const VehicleCreate = () => {
  document.title ="Create Vehicle | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [state,setState] = useState([]);
  const [allType,setAllType] = useState([]);
  const [allDriver,setAllDriver] = useState([]);
  const { isVehicleAdd, isVehicleAddFail, error, success,vehicleState,vehiclePurchaseType,drivers } = useSelector(state => ({
    isVehicleAdd: state.Vehicles.isVehicleAdd,
    isVehicleAddFail: state.Vehicles.isVehicleAddFail,
    error: state.Vehicles.error,
    success: state.Vehicles.success,
    vehicleState:state.Vehicles.vehicleState,
    vehiclePurchaseType:state.Vehicles.vehiclePurchaseType,
    drivers:state.Vehicles.drivers
  }));  

  const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
          vehicle_type:'personal',
          plate_no: '',
          plate_type: '',
          vehicle_state: '',
          vehicle_year:'',
          vehicle_make:'',
          vehicle_color:'',
          vehicle_vin:'',          
          title:'',
          vendor_insurance_expiratione:'',
          vendor_agent_name:'',
          vendor_insurance_note:'',
          registration_number:'',
          registration_expiration:'',
          inspection_expiration_date:'',
          registration_note:'',
          vehicle_purchase_type:'',
          purchase_date:'',
          purchase_price:'',
          warranty_expires_on:'',
          purchase_note:'',          
          driver_id:'',
      },
      validationSchema: Yup.object({
          vehicle_type: Yup.string().required("Please Choose Vehicle Type"),
          plate_no: Yup.string().required("Please Enter Plate No"),
          plate_type: Yup.string().required("Please Enter Plate Type"),
          vehicle_state : Yup.object().required("Please Choose Vehicle State"),
          vehicle_year: Yup.string().required("Please Enter Vehicle Year"),
          vehicle_make: Yup.string().required("Please Enter Vehicle Make"),
          vehicle_color : Yup.string().required("Please Enter Vehicle Color"),
          vehicle_vin : Yup.string().required("Please Enter Vehicle VIN"),          
          title: Yup.string().required("Please Enter Name"),
          //vendor_insurance_expiratione: Yup.object().required("Please Choose Insurance Expiratione"),
          vendor_agent_name: Yup.string().required("Please Enter Agent Name"),
          vendor_insurance_note : Yup.string().required("Please Enter Insurance Note"),
          registration_number : Yup.string().required("Please Enter Registration No"),
          //registration_expiration : Yup.string().required("Please Enter Registration Expiration"),
          //inspection_expiration_date : Yup.object().required("Please Enter Inspection Expiration Date"),
          registration_note: Yup.string().required("Please Enter Registration Note"),
          vehicle_purchase_type : Yup.object().required("Please Enter Vehicle Purchase Type"),
          //purchase_date: Yup.object().required("Please Enter Vehicle Purchase Date"),
          purchase_price : Yup.string().required("Please Enter Vehicle Purchase Price"),
          //warranty_expires_on : Yup.object().required("Please Enter Warranty Expires On"),
          purchase_note : Yup.string().required("Please Enter Purchase Note"),          
          driver_id: Yup.object().required("Please Choose Driver Name"),
      }),
      onSubmit: (values) => {
        console.log(values);
          setIsCreating(true)
          const data = {
            vehicle_type : values.vehicle_type,
            plate_no: values.plate_no,
            plate_type: values.plate_type,
            vehicle_state: (values.vehicle_state)?.value,
            vehicle_year:values.vehicle_year,
            vehicle_make:values.vehicle_make,
            vehicle_color:values.vehicle_color,
            vehicle_vin:values.vehicle_vin,            
            title:values.title,
            vendor_insurance_expiratione:values.vendor_insurance_expiratione,
            vendor_agent_name:values.vendor_agent_name,
            vendor_insurance_note:values.vendor_insurance_note,
            registration_number:values.registration_number,
            registration_expiration:values.registration_expiration,
            inspection_expiration_date:values.inspection_expiration_date,
            registration_note:values.registration_note,
            vehicle_purchase_type: (values.vehicle_purchase_type)?.value,
            purchase_date:values.purchase_date,
            purchase_price:values.purchase_price,
            warranty_expires_on:values.warranty_expires_on,
            purchase_note:values.purchase_note,            
            driver_id:(values.driver_id)?.value
          }
          dispatch(addNewVehicle(data));
      }
  });

  useEffect(() => {
    if(isVehicleAdd || isVehicleAddFail){
        setIsCreating(false)
        validation.resetForm();
        setTimeout(() => {
            dispatch(resetVehicleFlag())
        }, 3000);
    }
  }, [dispatch, isVehicleAdd, isVehicleAddFail, validation]);

  useEffect(() => {
    dispatch(getStateList())
    dispatch(getPurchaseTypeList())
    dispatch(getDriver())
    

  }, []);

  useEffect(() => {
    let states = [];
    if(vehicleState){
      for (let i = 0; i < vehicleState?.length; i++) {
        states[i] = { label: vehicleState[i].name, value: vehicleState[i]._id }
      }
    }
    setState(states)

    let types = [];
    if(vehiclePurchaseType){
      for (let i = 0; i < vehiclePurchaseType?.length; i++) {
        types[i] = { label: vehiclePurchaseType[i].name, value: vehiclePurchaseType[i]._id }
      }
    }
    setAllType(types)

    let driver = [];
    if(drivers){
      for (let i = 0; i < drivers?.length; i++) {
        driver[i] = { label: drivers[i].first_name+' '+drivers[i].last_name, value: drivers[i]._id }
      }
    }
    setAllDriver(driver)
  }, [vehicleState?.length,vehiclePurchaseType?.length,drivers?.lenght]);  

  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title="Create Banner" pageTitle="Banner" pageUrl="/banners" />
        <Row>
          <Col lg={8}>
          <Form onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
          }}>

              <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Banner </h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Name
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Name"
                      type="text"
                      name="title"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title || ""}
                      invalid={
                          validation.touched.title && validation.errors.title ? true : false
                      }
                    />
                    {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                Short Description
                  </Label>
                  <CKEditor
                        editor={ClassicEditor}
                        name="short_description"
                        data={validation.values.short_description || ""}
                        onReady={(editor) => {
                            console.log("Editor is ready to use!", editor);
                        }}
                        invalid={false}
                        />
                        {validation.touched.short_description && validation.errors.short_description ? (
                            <FormFeedback type="invalid">{validation.errors.short_description}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                
              </CardBody>
            </Card>            

              <div className="text-end mb-3">
                <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                    <span className="d-flex align-items-center">
                        {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                        <span className="flex-grow-1">
                           Submit
                        </span>
                    </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button> */}
              </div>
              </Form>
          </Col>
        </Row>
        {isVehicleAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
        {isVehicleAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default VehicleCreate;
