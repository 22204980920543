import {
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  ADD_NEW_VEHICLE,
  ADD_NEW_VEHICLE_SUCCESS,
  ADD_NEW_VEHICLE_FAIL,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  RESET_VEHICLE_FLAG,

  GET_STATE_LIST,
  GET_STATE_LIST_SUCCESS,
  GET_STATE_LIST_FAIL,

  GET_PURCHASE_TYPE_LIST,
  GET_PURCHASE_TYPE_LIST_SUCCESS,
  GET_PURCHASE_TYPE_LIST_FAIL,

  // GET_DRIVERS,
  // GET_DRIVERS_SUCCESS,
  // GET_DRIVERS_FAIL
} from "./actionTypes";

export const getVehicles = data => ({
  type: GET_VEHICLES,
  payload: data,
});

export const getVehiclesSuccess = data => ({
  type: GET_VEHICLES_SUCCESS,
  payload: data,
});

export const getVehiclesFail = error => ({
  type: GET_VEHICLES_FAIL,
  payload: error,
});

export const addNewVehicle = vehicle => ({
  type: ADD_NEW_VEHICLE,
  payload: vehicle,
});

export const addNewVehicleSuccess = vehicle => ({
  type: ADD_NEW_VEHICLE_SUCCESS,
  payload: vehicle,
});

export const addNewVehicleFail = error => ({
  type: ADD_NEW_VEHICLE_FAIL,
  payload: error,
});

export const updateVehicle = vehicle => ({
  type: UPDATE_VEHICLE,
  payload: vehicle,
});

export const updateVehicleSuccess = vehicle => ({
  type: UPDATE_VEHICLE_SUCCESS,
  payload: vehicle,
});

export const updateVehicleFail = error => ({
  type: UPDATE_VEHICLE_FAIL,
  payload: error,
});

export const getVehicleDetails = vehicle => ({
  type: GET_VEHICLE_DETAILS,
  payload: vehicle,
});

export const getVehicleDetailsSuccess = role => ({
  type: GET_VEHICLE_DETAILS_SUCCESS,
  payload: role,
});

export const getVehicleDetailsFail = error => ({
  type: GET_VEHICLE_DETAILS_FAIL,
  payload: error,
});

export const resetVehicleFlag = () => {
  return {
    type: RESET_VEHICLE_FLAG,
  }
}
// Status List
export const getStateList = data => ({
  type: GET_STATE_LIST,
  payload:data
});

export const getStateListSuccess = role => ({
  type: GET_STATE_LIST_SUCCESS,
  payload: role,
});

export const getStateListFail = error => ({
  type: GET_STATE_LIST_FAIL,
  payload: error,
});

// Vehicle Purchase Type List
export const getPurchaseTypeList = () => ({
  type: GET_PURCHASE_TYPE_LIST
});

export const getPurchaseTypeListSuccess = role => ({
  type: GET_PURCHASE_TYPE_LIST_SUCCESS,
  payload: role,
});

export const getPurchaseTypeListFail = error => ({
  type: GET_PURCHASE_TYPE_LIST_FAIL,
  payload: error,
});

// Driver List
// export const getDrivers = data => ({
//   type: GET_DRIVERS,
//   payload: data,
// });

// export const getDriversSuccess = data => ({
//   type: GET_DRIVERS_SUCCESS,
//   payload: data,
// });

// export const getDriversFail = error => ({
//   type: GET_DRIVERS_FAIL,
//   payload: error,
// });