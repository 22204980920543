import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_SITESETTING_DETAILS,
  UPDATE_SITESETTING,
} from "./actionTypes"

import {
  getSitesettingDetailsSuccess,
  getSitesettingDetailsFail,
  updateSitesettingSuccess,
  updateSitesettingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSitesettingDetails,
  updateSitesetting,
} from "../../helpers/fakebackend_helper"




function* onSitesettingDetails({ payload: sitesetting }) {
  try {
      const response = yield call(getSitesettingDetails, sitesetting);
      if(response.status === 200){
        yield put(getSitesettingDetailsSuccess(response));
      } else {
        yield put(getSitesettingDetailsFail(response));
      }
  } catch (error) {
      yield put(getSitesettingDetailsFail(error));
  }
}

export function* watchSitesettingDetails() {
  yield takeEvery(GET_SITESETTING_DETAILS, onSitesettingDetails);
}

function* onSitesettingUpdate({ payload: sitesetting }) {
  try {
      const response = yield call(updateSitesetting, sitesetting);
      if(response.status === 200){
        yield put(updateSitesettingSuccess(response));
      } else {
        yield put(updateSitesettingFail(response));
      }
  } catch (error) {
      yield put(updateSitesettingFail(error));
  }
}

export function* watchUpdateSitesetting() {
  yield takeEvery(UPDATE_SITESETTING, onSitesettingUpdate);
}

function* sitesettingSaga() {
  yield all(
    [
     
      fork(watchSitesettingDetails),
      fork(watchUpdateSitesetting),
    ]
  );

}

export default sitesettingSaga
