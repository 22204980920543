import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_ROLES,
  ADD_NEW_ROLE,
  GET_ROLE_DETAILS,
  UPDATE_ROLE
} from "./actionTypes"

import {
  getRolesSuccess,
  getRolesFail,
  addNewRoleSuccess,
  addNewRoleFail,
  getRoleDetailsSuccess,
  getRoleDetailsFail,
  updateRoleSuccess,
  updateRoleFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRoleList,
  addNewRole,
  getRoleDetails,
  updateRole
} from "../../helpers/fakebackend_helper"

function* fetchRoles({ payload: role }) {
  try {
    const response = yield call(getRoleList, role)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

export function* watchFetchRoles() {
  yield takeEvery(GET_ROLES, fetchRoles);
}

function* onAddNewRole({ payload: role }) {
  try {
      const response = yield call(addNewRole, role);
      if(response.status === 200){
        yield put(addNewRoleSuccess(response));
      } else {
        yield put(addNewRoleFail(response));
      }
  } catch (error) {
      yield put(addNewRoleFail(error));
  }
}

export function* watchAddNewRole() {
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole);
}

function* onRoleDetails({ payload: role }) {
  try {
      const response = yield call(getRoleDetails, role);
      if(response.status === 200){
        yield put(getRoleDetailsSuccess(response));
      } else {
        yield put(getRoleDetailsFail(response));
      }
  } catch (error) {
      yield put(getRoleDetailsFail(error));
  }
}

export function* watchRoleDetails() {
  yield takeEvery(GET_ROLE_DETAILS, onRoleDetails);
}

function* onRoleUpdate({ payload: role }) {
  try {
      const response = yield call(updateRole, role);
      if(response.status === 200){
        yield put(updateRoleSuccess(response));
      } else {
        yield put(updateRoleFail(response));
      }
  } catch (error) {
      yield put(updateRoleFail(error));
  }
}

export function* watchUpdateRole() {
  yield takeEvery(UPDATE_ROLE, onRoleUpdate);
}

function* roleSaga() {
  yield all(
    [
      fork(watchFetchRoles),
      fork(watchAddNewRole),
      fork(watchRoleDetails),
      fork(watchUpdateRole),
    ]
  );

}

export default roleSaga
