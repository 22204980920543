import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_DASHBOARD,
} from "./actionTypes"

import {
  getDashboardSuccess,
  getDashboardFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDashboard,
} from "../../helpers/fakebackend_helper"

function* fetchDashboard({ payload: data }) {
  try {
    const response = yield call(getDashboard, data)
    yield put(getDashboardSuccess(response))
  } catch (error) {
    yield put(getDashboardFail(error))
  }
}

export function* watchFetchDashboard() {
  yield takeEvery(GET_DASHBOARD, fetchDashboard);
}

function* dashboardSaga() {
  yield all(
    [
      fork(watchFetchDashboard),
    ]
  );

}

export default dashboardSaga
