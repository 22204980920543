import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getTicketAssignDetails,
  updateAssignTicket,
  resetTicketFlag,
  getTlcTicketUserStatus,
  getTlcTicketAdminStatus,
  getTlcTicketUserStatusAssignDocuments,
  getTlcTicketAdminStatusAssignDocuments,
  getUsers
} from "../../../store/actions";
import { saveAs } from 'file-saver';
import Moment from "react-moment";
import moment from 'moment-timezone';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64MultipleFile } from "../../../helpers/image_helper";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Tickets from "../../../store/tickets/reducer";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
moment.tz.setDefault("America/New_York");
const AssignTicketManager = () => {
  document.title = "Ticket Assign | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);

  const [isCreating, setIsCreating] = useState(false);
  const [isAssignAdminStatus, setIsAssignAdminStatus] = useState(false);
  const [isManagerShow, setisManagerShow] = useState();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedFilesAdminStatus, setselectedFilesAdminStatus] = useState([]);
  const [fileAdminStatus, setFileAdminStatus] = useState([]);
  const {
    details,
    managerList,
    isAssignTicketUpdate,
    isAssignTicketUpdateFail,
    userStatus,
    adminStatus,
    userStatusAssignDocuments,
    adminStatusAssignDocuments,
    isAdminStatusAssignDocumentsSuccess,
    isUserStatusAssignDocumentsSuccess,
    error,
    success,
  } = useSelector((state) => ({
    details: state.Tickets.assignDetails,
    managerList: state.Users.users,
    isAssignTicketUpdate: state.Tickets.isAssignTicketUpdate,
    isAssignTicketUpdateFail: state.Tickets.isAssignTicketUpdateFail,
    userStatus: state.Tickets.userStatus,
    adminStatus: state.Tickets.adminStatus,
    userStatusAssignDocuments: state.Tickets.userStatusAssignDocuments,
    adminStatusAssignDocuments: state.Tickets.adminStatusAssignDocuments,
    isAdminStatusAssignDocumentsSuccess: state.Tickets.isAdminStatusAssignDocumentsSuccess,
    isUserStatusAssignDocumentsSuccess: state.Tickets.isUserStatusAssignDocumentsSuccess,
    error: state.Tickets.error,
    success: state.Tickets.success,
  }));
  const download = (file_name,original_name="") => {
    saveAs(
      file_name,
      original_name
    );
  };


  const selectedUserStatusOption = {
    value: details && details?.userStatus?.ticket_status?.code,
    label: details && details?.userStatus?.ticket_status?.name,
  };
  const selectedAdminStatusOption = {
    value: details && details?.adminStatus?.ticket_status?.code,
    label: details && details?.adminStatus?.ticket_status?.name,
  };


  useEffect(() => {
    if (isAssignTicketUpdate || isAssignTicketUpdateFail) {
      dispatch(getTlcTicketUserStatusAssignDocuments({ticket_id: id,"status_for":"user"}))
      dispatch(getTlcTicketAdminStatusAssignDocuments({ticket_id: id,"status_for":"admin"}))
      setIsCreating(false);
      setIsAssignAdminStatus(false)
      setTimeout(() => {
        dispatch(resetTicketFlag());
      }, 2000);
    }
  }, [dispatch, isAssignTicketUpdate, isAssignTicketUpdateFail]);



  useEffect(() => {
    dispatch(getTlcTicketAdminStatus({"status_for":"admin","sortKey":"ordering","sortValue":"ASC"}))
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTlcTicketUserStatus({"status_for":"user","sortKey":"ordering","sortValue":"ASC"}))
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTlcTicketAdminStatusAssignDocuments({ticket_id: id,"status_for":"admin"}))
  }, [dispatch,id]);
  useEffect(() => {
    dispatch(getTlcTicketUserStatusAssignDocuments({ticket_id: id,"status_for":"user"}))
  }, [dispatch,id]);


  useEffect(() => {
    dispatch(getTicketAssignDetails({ ticket_id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    setisManagerShow(details && details?.userStatus?.status)
  }, [dispatch, details]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: details && typeof details?.userStatus?.amount==="number" ? details?.userStatus?.amount : "",
      point: details && typeof details?.userStatus?.point  ==="number"? details?.userStatus?.point : "",
      status : selectedUserStatusOption,
    },
    validationSchema: Yup.object({
    }),

    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        ticket_id: details && details?.userStatus?.ticket_id ? details?.userStatus?.ticket_id : "",
        user_id: details && details?.userStatus?.user_details?._id ? details?.userStatus?.user_details?._id : "",
        manager_id: details && details?.userStatus?.manager_details?._id ? details?.userStatus?.manager_details?._id : "",
        status: values.status?.value,
        status_for: "user",
        amount: typeof values.amount ==="number" ? values.amount : '',
        point: typeof values.point ==="number" ? values.point : '',
        file:file

      };
      dispatch(updateAssignTicket(data));
    },
   });
  const validationAdmin = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: details && details?.adminStatus?.amount ? details?.adminStatus?.amount : "",
      status : selectedAdminStatusOption,
    },
    validationSchema: Yup.object({
    }),

    onSubmit: (values) => {
      setIsAssignAdminStatus(true);
      const data = {
        ticket_id: details && details?.userStatus?.ticket_id ? details?.userStatus?.ticket_id : "",
        user_id: details && details?.userStatus?.user_details?._id ? details?.userStatus?.user_details?._id : "",
        manager_id: details && details?.userStatus?.manager_details?._id ? details?.userStatus?.manager_details?._id : "",
        status: values.status?.value,
        status_for: "admin",
        file:fileAdminStatus

      };
      dispatch(updateAssignTicket(data));
    },
   });
  async  function handleAcceptedFiles(files) {
  const   imgArray=[]
    files.map((f) =>{
      Object.assign(f, {
        preview: URL.createObjectURL(f),
        formattedSize: formatBytes(f.size),
      });
      
    },
    
    );
    for (const file of files) {
      const imgver= await  convertToBase64MultipleFile(file);
      imgArray.push(imgver);
    }

    setFile([...file,...imgArray]);
    setselectedFiles([...selectedFiles,...files]);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  async  function handleAcceptedFilesAdminStatus(files) {
    const   imgArray=[]
      files.map((f) =>{
        Object.assign(f, {
          preview: URL.createObjectURL(f),
          formattedSize: formatBytes(f.size),
        });
        
      },
      
      );
      for (const file of files) {
        const imgver= await  convertToBase64MultipleFile(file);
        imgArray.push(imgver);
      }
  
      setFileAdminStatus([...fileAdminStatus,...imgArray]);
      setselectedFilesAdminStatus([...selectedFilesAdminStatus,...files]);
    }

  const handleChange1 = event => {
    setisManagerShow(event.target.value.value)
   if(!['reduce','dismiss','settlement'].includes(event.target.value.value)) {
    validation.setFieldValue("amount","")
    validation.setFieldValue("point","")
    
   }

  };

 
  
  const removeDocuments = (e, i) => {
    
    setselectedFiles((data) =>{
      return data.filter((value, index) => index !== i)
    });
    setFile((data) =>{
      return data.filter((value, index) => index !== i)
    });
   
    
  };
  const removeAdminStatusDocuments = (e, i) => {
    
    setselectedFilesAdminStatus((data) =>{
      return data.filter((value, index) => index !== i)
    });
    setFileAdminStatus((data) =>{
      return data.filter((value, index) => index !== i)
    });
   
  };
  return (
    <div className="page-content">
      {details &&  (( Array.isArray(details?.userStatus)  && details?.userStatus.length>0) || Object.keys(details?.userStatus).length >0) &&
      <Container fluid={true}>
         <BreadCrumb title="Assign Tlc Ticket" pageTitle="Tickets" pageUrl="/transactions/payment-tickets-tlc" />
        <Row>
          <Col lg={6}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Summons Number : {details && details?.userStatus?.ticket_id  ? details?.userStatus?.ticket_id : ""}</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                  <Col xxl={12} md={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                       User Status
                      </Label>
                      <Select
                        type="text"
                        name="status"
                        onChange={(selectedOption) => {
                          let event = {
                            target: { name: "status", value: selectedOption },
                          };
                        
                          validation.handleChange(event);
                          handleChange1(event)
                     
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          userStatus &&
                          userStatus.map((item, index) => ({
                            label: item.name,
                            value: item.code,
                          }))
                        }
                        value={validation.values.status || ""}
                      />
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                    </Col>
                    {isManagerShow == 'reduce' || isManagerShow == 'dismiss' || isManagerShow == 'settlement'?
                      <>
                      <Col xxl={6} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                           Amount
                          </Label>
                          <Input
                            type="number"
                           
                            name="amount"
                            className="form-control"
                            placeholder="Enter Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={typeof validation.values.amount ==="number"  ? validation.values.amount:"" }
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                          validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={6} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                           Point
                          </Label>
                          <Input
                            type="number"
                            name="point"
                            className="form-control"
                            placeholder="Enter Point"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={typeof validation.values.point ==="number"  ? validation.values.point:"" }
                            invalid={
                              validation.touched.point &&
                              validation.errors.point
                                ? true
                                : false
                            }
                          />
                          {validation.touched.point &&
                          validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.point}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col></>
                      :''
                    }
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Assign Documents</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <p className="text-muted">Add Assign Documents.</p>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                        // onFileChange(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                            <h6>(*png,*jpg,*pdf,*docx)</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="list-unstyled mb-0" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  {/* <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  /> */}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="col-auto">
                                <button  type="button" onClick={(e)=>{removeDocuments(e,i)}} className="btn btn-sm btn-danger">Delete</button>  
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                  
                </CardBody>
                <div className="text-end mb-3">
                    <Button
                      color="success"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Create{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Submit</span>
                      </span>
                    </Button>
                  </div>
              </Card>
            

              
            </Form>
            {isUserStatusAssignDocumentsSuccess &&
            <Card className="overflow-hidden pb-2">
              { userStatusAssignDocuments?.length ?<> 
                 {userStatusAssignDocuments.map((item,i) => (
                  <div key={i}> 
                  <Row className="g-3 border-bottom" >
                  
                    <Col xxl={2} lg={2}  className="p-2 ms-sm-3" >
                    <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                      {item?.ticket_status?.name}
                      </Label>
                    </Col>
                    <Col xxl={2} lg={2}  className="p-2 ms-sm-3" >
                      <Moment format="D MMM YYYY">{item?.created_at}</Moment>
                    </Col>
                    <Col xxl={2} lg={2}  className="p-2 ms-sm-3" >
                      {typeof item?.amount ==="number" ?'Amount: '+item?.amount:""}
                    </Col>
                    <Col xxl={2} lg={2}  className="p-2 ms-sm-3" >
                    {typeof item?.point ==="number" ?'Point: '+item?.point:""}
                    </Col>
                    
                    <Col xxl={2} lg={2}  className="p-2 ms-sm-3" >
                    <Link to={`/transaction/${item?.transaction_id}`} className="">{item?.transaction_id}</Link>
                    </Col>
                     
                    </Row>
                   
                    <Row className="g-3 border-bottom py-2" >
                    {
                      item.ticket_galleries?.length?<>
                      {item.ticket_galleries.map((items,index) => (
                        <Col xxl={2} lg={2}  className="p-2" key={index}>
                          <a   href="javascript:void(0)"  onClick={(e) => download(items.file_name,items.original_name)}>
                          <div className="border rounded border-dashed p-2" >
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                <i className={items?.extension && items?.extension ==="pdf"?"ri-file-ppt-2-line":(items?.extension ==="docx"?'ri-file-pdf-fill':"ri-image-2-line")}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block"></Link></h5>
                                        
                                    </div>
                                </div>
                            </div>
                          </a>
                            
                        </Col>
                      ))}
                      </>:<></>
                    }
                  </Row>
                   </div>
                 ))}
              </>:(<></>)
              }
              
            </Card>
             }
          </Col>
          <Col lg={6}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validationAdmin.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Manager : {details && details?.userStatus?.manager_details?.name  ? details?.userStatus?.manager_details?.name : ""}</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                  <Col xxl={12} md={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Admin Status
                      </Label>
                      <Select
                        type="text"
                        name="status"
                        onChange={(selectedOption) => {
                          let event = {
                            target: { name: "status", value: selectedOption },
                          };
                          validationAdmin.handleChange(event);
                          handleChange1(event)
                        }}
                        onBlur={validationAdmin.handleBlur}
                        options={
                          adminStatus &&
                          adminStatus.map((item, index) => ({
                            label: item.name,
                            value: item.code,
                          }))
                        }
                        value={validationAdmin.values.status || ""}
                      />
                      {validationAdmin.touched.status && validationAdmin.errors.status ? (
                        <FormFeedback type="invalid">
                          {validationAdmin.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Assign Documents</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <p className="text-muted">Add Assign Documents.</p>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFilesAdminStatus(acceptedFiles);
                       
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                            <h6>(*png,*jpg,*pdf,*docx)</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="list-unstyled mb-0" id="file-previews">
                      {selectedFilesAdminStatus.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  {/* <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  /> */}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="col-auto">
                                <button  type="button" onClick={(e)=>{removeAdminStatusDocuments(e,i)}} className="btn btn-sm btn-danger">Delete</button>  
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                </CardBody>
                <div className="text-end mb-3">
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isAssignAdminStatus}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isAssignAdminStatus && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Submit</span>
                    </span>
                  </Button>
                </div>
              </Card>
            </Form>
            {isAdminStatusAssignDocumentsSuccess &&
            <Card className="overflow-hidden pb-2">
              { adminStatusAssignDocuments?.length ?<> 
                 {adminStatusAssignDocuments.map((item,i) => (
                   <div key={i}> 
                    <Row className="g-3 border-bottom ">
                      <Col xxl={3} lg={3}  className="p-2 ms-sm-3" >
                      <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                        {item?.ticket_status?.name}
                        </Label>
                 
                      </Col>
                      <Col xxl={3} lg={3}  className="p-2 ms-sm-3" >
                        <Moment format="D MMM YYYY">{item?.created_at}</Moment>
                      </Col>
                     
                    </Row>
                    <Row className="g-3 border-bottom py-2">
                      {
                        item.ticket_galleries?.length?<>
                        {item.ticket_galleries.map((items,index) => (
                          <Col xxl={2} lg={2}  className="p-2" key={index}>
                            <a   href="javascript:void(0)"  onClick={(e) => download(items.file_name,items.original_name)}>
                            <div className="border rounded border-dashed p-2" >
                                  <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 me-3">
                                          <div className="avatar-sm">
                                              <div className="avatar-title bg-light text-secondary rounded fs-24">
                                              <i className={items?.extension && items?.extension ==="pdf"?"ri-file-ppt-2-line":(items?.extension ==="docx"?'ri-file-pdf-fill':"ri-image-2-line")}></i>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block"></Link></h5>
                                          
                                      </div>
                                  </div>
                              </div>
                            </a>
                              
                          </Col>
                        ))}
                        </>:<></>
                      }
                    </Row>
                  </div>
                 ))}
              </>:(<></>)
              }
              
            </Card>
             }
          </Col>

          
        </Row>
        {isAssignTicketUpdate ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {isAssignTicketUpdateFail ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
     }
    </div>
  );
};

export default AssignTicketManager;
