import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_STEPS,
  ADD_NEW_STEP,
  GET_STEP_DETAILS,
  UPDATE_STEP,
  DELETE_STEP,
  STATUS_CHANGE_STEP,
  SAVE_ORDERING_STEP,
} from "./actionTypes"

import {
  getStepsSuccess,
  getStepsFail,

  addNewStepSuccess,
  addNewStepFail,

  getStepDetailsSuccess,
  getStepDetailsFail,

  updateStepSuccess,
  updateStepFail,

  deleteStepSuccess,
  deleteStepFail,

  statusChangeStepSuccess,
  statusChangeStepFail,

  saveOrderingStepSuccess,
  saveOrderingStepFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStepList,
  addNewStep,
  getStepDetails,
  updateStep,
  deleteStep,
  statusChangeStep,
  saveOrderingStep,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

//LIST
function* fetchSteps({ payload: step }) {
  try {
    const response = yield call(getStepList, step)
    yield put(getStepsSuccess(response))
  } catch (error) {
    yield put(getStepsFail(error))
  }
}

export function* watchFetchSteps() {
  yield takeEvery(GET_STEPS, fetchSteps);
}

//ADD NEW
function* onAddNewStep({ payload: step }) {
  try {
      const response = yield call(addNewStep, step);
      if(response.status === 200){
        yield put(addNewStepSuccess(response));
      } else {
        yield put(addNewStepFail(response));
      }
  } catch (error) {
      yield put(addNewStepFail(error));
  }
}

export function* watchAddNewStep() {
  yield takeEvery(ADD_NEW_STEP, onAddNewStep);
}

//DETAILS
function* onStepDetails({ payload: step }) {
  try {
      const response = yield call(getStepDetails, step);
      if(response.status === 200){
        yield put(getStepDetailsSuccess(response));
      } else {
        yield put(getStepDetailsFail(response));
      }
  } catch (error) {
      yield put(getStepDetailsFail(error));
  }
}

export function* watchStepDetails() {
  yield takeEvery(GET_STEP_DETAILS, onStepDetails);
}

//UPDATE
function* onStepUpdate({ payload: step }) {
  try {
      const response = yield call(updateStep, step);
      if(response.status === 200){
        yield put(updateStepSuccess(response));
      } else {
        yield put(updateStepFail(response));
      }
  } catch (error) {
      yield put(updateStepFail(error));
  }
}

export function* watchUpdateStep() {
  yield takeEvery(UPDATE_STEP, onStepUpdate);
}


//Delete Data
function* onDeleteStep({ payload: step }) {
  try {
    const response = yield call(deleteStep, step);
    yield put(deleteStepSuccess({ step, ...response }));
  } catch (error) {
    yield put(deleteStepFail(error));
  }
}

export function* watchDeleteStep() {
  yield takeEvery(DELETE_STEP, onDeleteStep);
}


//Status Change
function* onStatusChangStep({ payload: step }) {
  try {
    const response = yield call(statusChangeStep, step);
    yield put(statusChangeStepSuccess({ step, ...response }));
  } catch (error) {
    yield put(statusChangeStepFail(error));
  }
}

export function* watchStatusChangeStep() {
  yield takeEvery(STATUS_CHANGE_STEP, onStatusChangStep);
}


//Save Ordering
function* onSaveOrderingStep({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingStepSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingStepFail(error));
  }
}

export function* watchSaveOrderingStep() {
  yield takeEvery(SAVE_ORDERING_STEP, onSaveOrderingStep);
}



function* stepSaga() {
  yield all(
    [
      fork(watchFetchSteps),
      fork(watchAddNewStep),
      fork(watchStepDetails),
      fork(watchUpdateStep),
      fork(watchDeleteStep),
      fork(watchStatusChangeStep),
      fork(watchSaveOrderingStep),
    ]
  );

}

export default stepSaga
