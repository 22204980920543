import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_CLIENTS,
  ADD_NEW_CLIENT,
  GET_CLIENT_DETAILS,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  STATUS_CHANGE_CLIENT,
  SAVE_ORDERING_CLIENT
} from "./actionTypes"

import {
  getClientsSuccess,
  getClientsFail,
  addNewClientSuccess,
  addNewClientFail,
  getClientDetailsSuccess,
  getClientDetailsFail,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
  statusChangeClientSuccess,
  statusChangeClientFail,
  saveOrderingClientSuccess,
  saveOrderingClientFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClientList,
  addNewClient,
  getClientDetails,
  updateClient,
  deleteClient,
  statusChangeClient,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchClients({ payload: client }) {
  try {
    const response = yield call(getClientList, client)
    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsFail(error))
  }
}

export function* watchFetchClients() {
  yield takeEvery(GET_CLIENTS, fetchClients);
}

function* onAddNewClient({ payload: client }) {
  try {
      const response = yield call(addNewClient, client);
      if(response.status === 200){
        yield put(addNewClientSuccess(response));
      } else {
        yield put(addNewClientFail(response));
      }
  } catch (error) {
      yield put(addNewClientFail(error));
  }
}

export function* watchAddNewClient() {
  yield takeEvery(ADD_NEW_CLIENT, onAddNewClient);
}

function* onClientDetails({ payload: client }) {
  try {
      const response = yield call(getClientDetails, client);
      if(response.status === 200){
        yield put(getClientDetailsSuccess(response));
      } else {
        yield put(getClientDetailsFail(response));
      }
  } catch (error) {
      yield put(getClientDetailsFail(error));
  }
}

export function* watchClientDetails() {
  yield takeEvery(GET_CLIENT_DETAILS, onClientDetails);
}

function* onClientUpdate({ payload: client }) {
  try {
      const response = yield call(updateClient, client);
      if(response.status === 200){
        yield put(updateClientSuccess(response));
      } else {
        yield put(updateClientFail(response));
      }
  } catch (error) {
      yield put(updateClientFail(error));
  }
}

export function* watchUpdateClient() {
  yield takeEvery(UPDATE_CLIENT, onClientUpdate);
}

function* onClientDelete({ payload: client }) {
  try {
      const response = yield call(deleteClient, client);
      if(response.status === 200){
        yield put(deleteClientSuccess(response));
      } else {
        yield put(deleteClientFail(response));
      }
  } catch (error) {
      yield put(deleteClientFail(error));
  }
}

export function* watchDeleteClient() {
  yield takeEvery(DELETE_CLIENT, onClientDelete);
}
//Status Change
function* onStatusChangeClient({ payload: client }) {
  try {
    const response = yield call(statusChangeClient, client);
    yield put(statusChangeClientSuccess({ client, ...response }));
  } catch (error) {
    yield put(statusChangeClientFail(error));
  }
}

export function* watchStatusChangeClient() {
  yield takeEvery(STATUS_CHANGE_CLIENT, onStatusChangeClient);
}


//Save Ordering
function* onSaveOrderingClient({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingClientSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingClientFail(error));
  }
}

export function* watchSaveOrderingClient() {
  yield takeEvery(SAVE_ORDERING_CLIENT, onSaveOrderingClient);
}



function* clientSaga() {
  yield all(
    [
      fork(watchFetchClients),
      fork(watchAddNewClient),
      fork(watchClientDetails),
      fork(watchUpdateClient),
      fork(watchDeleteClient),
      fork(watchStatusChangeClient),
      fork(watchSaveOrderingClient),
    ]
  );

}

export default clientSaga
