import {
  GET_WHYUS_SUCCESS,
  GET_WHYUS_FAIL,
  ADD_NEW_WHYUS_SUCCESS,
  ADD_NEW_WHYUS_FAIL,
  GET_WHYUS_DETAILS_SUCCESS,
  GET_WHYUS_DETAILS_FAIL,
  UPDATE_WHYUS_SUCCESS,
  UPDATE_WHYUS_FAIL,

  DELETE_WHYUS_SUCCESS,
  DELETE_WHYUS_FAIL,

  STATUS_CHANGE_WHYUS_SUCCESS,
  STATUS_CHANGE_WHYUS_FAIL,

  SAVE_ORDERING_WHYUS_SUCCESS,
  SAVE_ORDERING_WHYUS_FAIL,
  SAVE_ORDERING_WHYUS_RESET_FLAG,

  MODIFY_WHYUS_FLAG,
  RESET_WHYUS_FLAG,

} from "./actionTypes";

const INIT_STATE = {
  whyus: []
};

const Whyus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WHYUS_SUCCESS:
      return {
        ...state,
        whyus: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_WHYUS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_WHYUS_SUCCESS:
      return {
        ...state,
        whyus: [...state.whyus, action.payload.data],
        success: action.payload.message,
        isWhyusAdd: true,
        isWhyusAddFail: false,
      };

    case ADD_NEW_WHYUS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isWhyusAdd: false,
        isWhyusAddFail: true,
      };

    case UPDATE_WHYUS_SUCCESS:
      return {
        ...state,
        whyus: state.whyus.map(whyus =>
          whyus._id.toString() === action.payload.data._id.toString()
            ? { ...whyus, ...action.payload.data }
            : whyus
        ),
        success: action.payload.message,
        isWhyusUpdate: true,
        isWhyusUpdateFail: false
      };

    case UPDATE_WHYUS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isWhyusUpdate: false,
        isWhyusUpdateFail: true,
      };

    case GET_WHYUS_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_WHYUS_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
   





      //Delete
    case DELETE_WHYUS_SUCCESS:
      return { 
        ...state,
        whyus: state.whyus.filter(getData =>
          {
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_WHYUS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_WHYUS_SUCCESS:
      return {
        ...state,
        whyus: state.whyus.map(getData =>
          getData._id === action.payload.data._id
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_WHYUS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_WHYUS_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_WHYUS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_WHYUS_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    //MODIFY RESET  
    case MODIFY_WHYUS_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_WHYUS_FLAG:
      return {
        ...state,
        isWhyusAdd: false,
        isWhyusAddFail: false,
        isSuccess: false,
        isWhyusDetails: false,
        isWhyusUpdate: false,
        isWhyusUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Whyus;
