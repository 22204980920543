import {
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_STATE_DETAILS,
  GET_STATE_DETAILS_SUCCESS,
  GET_STATE_DETAILS_FAIL,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  RESET_STATE_FLAG,
  MODIFY_STATE_FLAG,

} from "./actionTypes";

export const getStates = (data) => ({
  type: GET_STATES,
  payload: data,
});

export const getStateSuccess = (data) => ({
  type: GET_STATES_SUCCESS,
  payload: data,
});

export const getStateFail = (error) => ({
  type: GET_STATES_FAIL,
  payload: error,
});

export const updateState = (state) => ({
  type: UPDATE_STATE,
  payload: state,
});

export const updateStateSuccess = (state) => ({
  type: UPDATE_STATE_SUCCESS,
  payload: state,
});

export const updateStateFail = (error) => ({
  type: UPDATE_STATE_FAIL,
  payload: error,
});

export const getStateDetails = (state) => ({
  type: GET_STATE_DETAILS,
  payload: state,
});

export const getStateDetailsSuccess = (role) => ({
  type: GET_STATE_DETAILS_SUCCESS,
  payload: role,
});

export const getStateDetailsFail = (error) => ({
  type: GET_STATE_DETAILS_FAIL,
  payload: error,
});

export const modifyStateFlag = () => {
  return {
    type: MODIFY_STATE_FLAG,
  }
}
export const resetStateFlag = () => {
  return {
    type: RESET_STATE_FLAG,
  };
};
