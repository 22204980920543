import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_BANNERS,
  ADD_NEW_BANNER,
  GET_BANNER_DETAILS,
  UPDATE_BANNER,
  SAVE_ORDERING_BANNER,
} from "./actionTypes"

import {
  getBannersSuccess,
  getBannersFail,
  addNewBannerSuccess,
  addNewBannerFail,
  getBannerDetailsSuccess,
  getBannerDetailsFail,
  updateBannerSuccess,
  updateBannerFail,
  saveOrderingBannerSuccess,
  saveOrderingBannerFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBannerList,
  addNewBanner,
  getBannerDetails,
  updateBanner,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchBanners({ payload: banner }) {
  try {
    const response = yield call(getBannerList, banner)
    yield put(getBannersSuccess(response))
  } catch (error) {
    yield put(getBannersFail(error))
  }
}

export function* watchFetchBanners() {
  yield takeEvery(GET_BANNERS, fetchBanners);
}

function* onAddNewBanner({ payload: banner }) {
  try {
      const response = yield call(addNewBanner, banner);
      if(response.status === 200){
        yield put(addNewBannerSuccess(response));
      } else {
        yield put(addNewBannerFail(response));
      }
  } catch (error) {
      yield put(addNewBannerFail(error));
  }
}

export function* watchAddNewBanner() {
  yield takeEvery(ADD_NEW_BANNER, onAddNewBanner);
}

function* onBannerDetails({ payload: banner }) {
  try {
      const response = yield call(getBannerDetails, banner);
      if(response.status === 200){
        yield put(getBannerDetailsSuccess(response));
      } else {
        yield put(getBannerDetailsFail(response));
      }
  } catch (error) {
      yield put(getBannerDetailsFail(error));
  }
}

export function* watchBannerDetails() {
  yield takeEvery(GET_BANNER_DETAILS, onBannerDetails);
}

function* onBannerUpdate({ payload: banner }) {
  try {
      const response = yield call(updateBanner, banner);
      if(response.status === 200){
        yield put(updateBannerSuccess(response));
      } else {
        yield put(updateBannerFail(response));
      }
  } catch (error) {
      yield put(updateBannerFail(error));
  }
}

export function* watchUpdateBanner() {
  yield takeEvery(UPDATE_BANNER, onBannerUpdate);
}


//Save Ordering
function* onSaveOrderingBanner({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingBannerSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingBannerFail(error));
  }
}

export function* watchSaveOrderingBanner() {
  yield takeEvery(SAVE_ORDERING_BANNER, onSaveOrderingBanner);
}

function* bannerSaga() {
  yield all(
    [
      fork(watchFetchBanners),
      fork(watchAddNewBanner),
      fork(watchBannerDetails),
      fork(watchUpdateBanner),
      fork(watchSaveOrderingBanner),
    ]
  );

}

export default bannerSaga
