import {
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  STATUS_CHANGE_TRANSACTION_STATEPAY_SUCCESS,
  STATUS_CHANGE_TRANSACTION_STATEPAY_FAIL,
  ASSIGN_MANAGER_SUCCESS,
  ASSIGN_MANAGER_FAIL,
  ASSIGN_USER_SUCCESS,
  ASSIGN_USER_FAIL,
  GET_PAYMENT_TICKETS_SUCCESS,
  GET_PAYMENT_TICKETS_FAIL,
  GET_PAYMENT_TICKETS_TLC_SUCCESS,
  GET_PAYMENT_TICKETS_TLC_FAIL,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_FAIL,
  UPLOAD_STATEPAY_DOCUMENT_SUCCESS,
  UPLOAD_STATEPAY_DOCUMENT_FAIL,
  RESET_TRANSACTION_FLAG,
  RESET_STATUS_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  transactions: [],
};

const Transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case GET_PAYMENT_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_PAYMENT_TICKETS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case GET_PAYMENT_TICKETS_TLC_SUCCESS:
      return {
        ...state,
        ticketsTlc: action.payload.data,
        totalDataCountTlc: action.payload.count,
        isSuccessTlc: true,
      };

    case GET_PAYMENT_TICKETS_TLC_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCountTlc: 0,
        isSuccessTlc: false,
      };

    case STATUS_CHANGE_TRANSACTION_STATEPAY_SUCCESS:
      return {
        ...state,
        tickets: action.payload.data,
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };

    case STATUS_CHANGE_TRANSACTION_STATEPAY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case ASSIGN_MANAGER_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        assignManagerSuccess: true,
        assignManagerFaild: false,
      };

    case ASSIGN_MANAGER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        assignManagerSuccess: false,
        assignManagerFaild: true,
      };
    case ASSIGN_USER_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        assignUserSuccess: true,
        assignUserFaild: false,
      };

    case ASSIGN_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        assignUserSuccess: false,
        assignUserFaild: true,
      };

    case UPLOAD_STATEPAY_DOCUMENT_SUCCESS:
      return {
        ...state,
        tickets: action.payload.data,
        success: action.payload.message,
        uploadStatepayDocumentSuccess: true,
        uploadStatepayDocumentFaild: false,
      };

    case UPLOAD_STATEPAY_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        uploadStatepayDocumentSuccess: false,
        uploadStatepayDocumentFaild: true,
      };
    case GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data ? action.payload.data : [],
        isTransactionDetails: true,
      };

    case GET_TRANSACTION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTransactionDetails: false,
      };

    case RESET_TRANSACTION_FLAG:
      return {
        ...state,

        isSuccess: false,
        isSuccessTlc: false,
        statusSuccess: false,
        statusFaild: false,
        isTransactionDetails: false,
        uploadStatepayDocumentSuccess: false,
        uploadStatepayDocumentFaild: false,
        error: false,
      };
    case RESET_STATUS_FLAG:
      return {
        ...state,

        statusSuccess: false,
        statusFaild: false,
        assignManagerSuccess: false,
        assignManagerFaild: false,
        assignUserSuccess: false,
        assignUserFaild: false,
        uploadStatepayDocumentSuccess: false,
        uploadStatepayDocumentFaild: false,
        success: false,
        error: false,
      };

    default:
      return state;
  }
};

export default Transactions;
