import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_SOLUTIONS,
  ADD_NEW_SOLUTION,
  GET_SOLUTION_DETAILS,
  UPDATE_SOLUTION,
  DELETE_SOLUTION,
  STATUS_CHANGE_SOLUTION,
  SAVE_ORDERING_SOLUTION,
} from "./actionTypes"

import {
  getSolutionsSuccess,
  getSolutionsFail,
  addNewSolutionSuccess,
  addNewSolutionFail,
  getSolutionDetailsSuccess,
  getSolutionDetailsFail,
  updateSolutionSuccess,
  updateSolutionFail,
  deleteSolutionSuccess,
  deleteSolutionFail,
  statusChangeSolutionSuccess,
  statusChangeSolutionFail,
  saveOrderingSolutionSuccess,
  saveOrderingSolutionFail,

} from "./actions"

//Include Both Helper File with needed methods
import {
  getSolutionList,
  addNewSolution,
  getSolutionDetails,
  updateSolution,
  deleteSolution,
  statusChangeSolution,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchSolutions({ payload: solution }) {
  try {
    const response = yield call(getSolutionList, solution)
    yield put(getSolutionsSuccess(response))
  } catch (error) {
    yield put(getSolutionsFail(error))
  }
}

export function* watchFetchSolutions() {
  yield takeEvery(GET_SOLUTIONS, fetchSolutions);
}

function* onAddNewSolution({ payload: solution }) {
  try {
      const response = yield call(addNewSolution, solution);
      if(response.status === 200){
        yield put(addNewSolutionSuccess(response));
      } else {
        yield put(addNewSolutionFail(response));
      }
  } catch (error) {
      yield put(addNewSolutionFail(error));
  }
}

export function* watchAddNewSolution() {
  yield takeEvery(ADD_NEW_SOLUTION, onAddNewSolution);
}

function* onSolutionDetails({ payload: solution }) {
  try {
      const response = yield call(getSolutionDetails, solution);
      if(response.status === 200){
        yield put(getSolutionDetailsSuccess(response));
      } else {
        yield put(getSolutionDetailsFail(response));
      }
  } catch (error) {
      yield put(getSolutionDetailsFail(error));
  }
}

export function* watchSolutionDetails() {
  yield takeEvery(GET_SOLUTION_DETAILS, onSolutionDetails);
}

function* onSolutionUpdate({ payload: solution }) {
  try {
      const response = yield call(updateSolution, solution);
      if(response.status === 200){
        yield put(updateSolutionSuccess(response));
      } else {
        yield put(updateSolutionFail(response));
      }
  } catch (error) {
      yield put(updateSolutionFail(error));
  }
}

export function* watchUpdateSolution() {
  yield takeEvery(UPDATE_SOLUTION, onSolutionUpdate);
}


//Delete Data
function* onDeleteSolution({ payload: data }) {
  try {
    const response = yield call(deleteSolution, data);
    yield put(deleteSolutionSuccess({ data, ...response }));
  } catch (error) {
    yield put(deleteSolutionFail(error));
  }
}

export function* watchDeleteSolution() {
  yield takeEvery(DELETE_SOLUTION, onDeleteSolution);
}


//Status Change
function* onStatusChangSolution({ payload: data }) {
  try {
    const response = yield call(statusChangeSolution, data);
    yield put(statusChangeSolutionSuccess({ data, ...response }));
  } catch (error) {
    yield put(statusChangeSolutionFail(error));
  }
}

export function* watchStatusChangeSolution() {
  yield takeEvery(STATUS_CHANGE_SOLUTION, onStatusChangSolution);
}


//Save Ordering
function* onSaveOrderingSolution({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingSolutionSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingSolutionFail(error));
  }
}

export function* watchSaveOrderingSolution() {
  yield takeEvery(SAVE_ORDERING_SOLUTION, onSaveOrderingSolution);
}



function* solutionSaga() {
  yield all(
    [
      fork(watchFetchSolutions),
      fork(watchAddNewSolution),
      fork(watchSolutionDetails),
      fork(watchUpdateSolution),
      fork(watchDeleteSolution),
      fork(watchStatusChangeSolution),
      fork(watchSaveOrderingSolution),
    ]
  );

}

export default solutionSaga
