import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";





//Project
import Projects from "./projects/reducer";

// Tasks
import Tasks from "./tasks/reducer";


// //TicketsList
// import Tickets from "./tickets/reducer";

//Crm
import Crm from "./crm/reducer";

//Invoice
import Invoice from "./invoice/reducer";



//Role
import Roles from "./role/reducer";

//Vehicle
import Vehicles from "./vehicle/reducer";

//Solution
import Solutions from "./solution/reducer";

//Whyus
import Whyus from "./whyus/reducer";

//Banner
import Banners from "./banner/reducer";

//Testimonial
import Testimonials from "./testimonial/reducer";

 //Step
 import Steps from "./step/reducer";
 //Client
import Clients from "./client/reducer";
//Page
import Pages from "./page/reducer";
//Sitesetting
import Sitesettings from "./sitesetting/reducer"
//TicketsList
import Tickets from "./tickets/reducer";
//Driver
import Drivers from "./driver/reducer";
//mission vision
import Missionvisions from "./missionVision/reducer";
    
//SEO
import Seos from "./seo/reducer";

//Job
import Jobs from "./jobs/reducer";

//Transaction
import Transactions from "./transactions/reducer";

//Transaction
import Billings from "./billings/reducer";

//State
import States from "./state/reducer";

//User
import Users from "./user/reducer";

//Referral
import Referrals from "./referral/reducer";
import ReferralCode from "./referralCode/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Coupon
import Coupons from "./coupon/reducer";

import Notification from "./notification/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Projects,
    Tasks,
    Tickets,
    Crm,
    Invoice,
    Roles,
    Vehicles,
    Solutions,
    Banners,
    Testimonials,
    Steps,
    Clients,
    Whyus,
    Pages,
    Sitesettings,
    Drivers,
    Missionvisions,
    Seos,
    Jobs,
    Transactions,
    Billings,
    States,
    Users,
    Referrals,
    Dashboard,
    Coupons,
    ReferralCode,
    Notification

});

export default rootReducer;