import React, { useEffect, useState, useRef, Fragment } from "react";
import Select from "react-select";

import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ModalHeader,
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Form,
  Spinner,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getTicketsList,
  getRoles,
  getUsers,
  assignUser,
  resetStatusFlag,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import ReactPaginate from "react-paginate";
import { useFormik } from "formik";
import MsgToast from "../../../Components/Common/MsgToast";
import { ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
const TicketList = () => {
  document.title = "TLC Ticket list | Defendit - Admin Panel";
  const history = useHistory();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let now = new Date();
  const backdate = new Date();
  backdate.setDate(backdate.getDate() - 60);
  const dateRange = { e: [backdate.toISOString(), now.toISOString()] };

  const year_now = now.getFullYear();
  const date_now = now.getDate();
  const monthName_now = months[now.getMonth()];
  const currentDateStr = date_now + " " + monthName_now + ", " + year_now;

  const year_back = backdate.getFullYear();
  const date_back = backdate.getDate();
  const monthName_back = months[backdate.getMonth()];
  const backDateStr = date_back + " " + monthName_back + ", " + year_back;

  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [ticket_type, setTicketType] = useState("tlc");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState();
  const [sortByValue, setSortByValue] = useState();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [ticketNo, setTicketNo] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const dispatch = useDispatch();
  const {
    roles,
    userList,
    tickets,
    totalDataCount,
    isSuccess,
    error,
    userAssignError,
    success,
    assignUserSuccess,
    assignUserFaild,
  } = useSelector((state) => ({
    roles: state.Roles.roles,
    userList: state.Users.users,

    tickets: state.Tickets.ticketsList,
    totalDataCount: state.Tickets.totalDataCount,
    isSuccess: state.Tickets.isSuccess,
    error: state.Tickets.error,
    userAssignError: state.Transactions.error,
    success: state.Transactions.success,
    assignUserSuccess: state.Transactions.assignUserSuccess,
    assignUserFaild: state.Transactions.assignUserFaild,
  }));
  useEffect(() => {
    dispatch(
      getRoles({
        code: "user",
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getUsers({
        role: roles[0]?._id,
      })
    );
  }, [roles]);
  useEffect(() => {
    dispatch(
      getTicketsList({
        keyword,
        sortByField,
        sortByValue,
        limit,
        offset,
        ticket_type,
      })
    );
  }, [dispatch, keyword, sortByField, sortByValue, limit, offset, ticket_type]);

  useEffect(() => {
    let dateRange = "";
    setLoading(false);
    if (date && date.e && date.e[0] && date.e[1]) {
      dispatch(
        getTicketsList({
          keyword,
          sortByField,
          sortByValue,
          limit,
          offset,
          date,
          ticket_type,
        })
      );
    }
  }, [keyword, sortByField, sortByValue, limit, offset, date, ticket_type]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);
  useEffect(() => {
    setLoading(true);
  }, [tickets]);

  useEffect(() => {
    if (assignUserSuccess || assignUserFaild) {
      var inputs = document.getElementsByName("chk_child");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
      setModal(false);
      setIsCreating(false);
      dispatch(
        getTicketsList({
          keyword,
          sortByField,
          sortByValue,
          limit,
          offset,
          ticket_type,
        })
      );
      setTimeout(() => {
        dispatch(resetStatusFlag());
      }, 3000);
    }
  }, [
    dispatch,
    keyword,
    sortByField,
    sortByValue,
    limit,
    offset,
    ticket_type,
    assignUserSuccess,
    assignUserFaild,
  ]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setOffset(newOffset);
  };

  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    dispatch(
      getTicketsList({ keyword, sortByField, sortByValue, limit, offset })
    );
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: "",
    },
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        ticket_no: ticketNo,
        userId: values.userId?.value,
        paymentType: values?.paymentType,
      };

      dispatch(assignUser(data));
    },
  });
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Tickets" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Tlc Ticket List
                  </h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(parseInt(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      {/* <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={ticket_type}
                          onChange={(e) => setTicketType(e.target.value)}
                        >
                          <option value="">Ticket Type</option>
                          <option value="dmv">DMV</option>
                          <option value="tlc">TLC</option>
                        </select>
                      </Col> */}

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              // defaultDate: [backDateStr, currentDateStr],
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={3} className=" col-xxl-4 d-flex gap-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                        <div className="d-flex">
                          <Button
                            color="success"
                            onClick={() => {
                              history.push("/tickets/add");
                            }}
                            id="resetTooltip"
                          >
                            <i className="ri-add-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Add
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {tickets?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Ticket-ID</th>
                                <th>Ticket Type</th>
                                <th>Issue Date</th>
                                <th>Fine</th>
                                <th>Due amount</th>
                                <th>Issuing Agency</th>
                                {/* <th>Status</th>
                                 */}
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {loading ? (
                                tickets.map((item) => (
                                  <tr key={item._id}>
                                    <td>{item?.summons_number}</td>
                                    <td>{item?.ticket_type.toUpperCase()}</td>
                                    <td>
                                      <Moment format="D MMM YYYY">
                                        {item?.issue_date}
                                      </Moment>
                                    </td>
                                    <td>
                                      $
                                      {item?.fine_amount
                                        ? parseInt(item.fine_amount).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>
                                      $
                                      {item?.due_amount
                                        ? parseInt(item.due_amount).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{item?.issuing_agency}</td>
                                    {/* <td>{item?.ticket_assignee?.status}</td> */}
                                    <td>
                                      <UncontrolledDropdown>
                                        <DropdownToggle className="dropdown-menu-icon btn-transparent text-white">
                                          <i className="ri-more-2-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <DropdownItem>
                                            <Link
                                              to={`/tickets/edit/${item._id}`}
                                              className="text-primary d-inline-block edit-item-btn"
                                            >
                                              <i className="ri-pencil-fill fs-16"></i>{" "}
                                              Edit
                                            </Link>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <Link
                                              to={`/tickets/details/${item._id}`}
                                              className="text-primary d-inline-block edit-item-btn"
                                            >
                                              <i className="ri-eye-line fs-16"></i>{" "}
                                              Details
                                            </Link>
                                          </DropdownItem>

                                          {!item.user_id ? (
                                            <DropdownItem>
                                              <Button
                                                onClick={(e) => {
                                                  setModal(true);
                                                  setTicketNo(
                                                    item.summons_number
                                                  );
                                                }}
                                                className="p-0 bg-transparent border-0 text-success"
                                              >
                                                <i className="ri-add-fill fs-16"></i>{" "}
                                                Assign User
                                              </Button>
                                            </DropdownItem>
                                          ) : null}

                                          <DropdownItem>
                                            {item?.ticket_type == "tlc" &&
                                            item?.transaction?.payment_for ==
                                              "assignee" ? (
                                              <Link
                                                to={`/tickets/assign-ticket/${item._id}`}
                                                className="text-primary d-inline-block edit-item-btn"
                                              >
                                                <i className="ri-external-link-line fs-16"></i>{" "}
                                                Change Status
                                              </Link>
                                            ) : (
                                              <></>
                                            )}
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <Loader error={error} />
                              )}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <div className="pagination-inner">
                              <nav
                                className="px-3"
                                aria-label="Page navigation example"
                              >
                                <ReactPaginate
                                  activeClassName="active"
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  containerClassName="pagination"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  breakLabel="..."
                                  nextLabel="Next &nbsp;→"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  pageCount={noOfPage}
                                  previousLabel="←&nbsp;Prev"
                                  renderOnZeroPageCount={null}
                                />
                              </nav>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {assignUserSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {assignUserFaild ? (
          <MsgToast
            msg={userAssignError}
            color="danger"
            icon="ri-error-warning-line"
          />
        ) : null}
      </div>
      <ToastContainer limit={1} closeButton={false} />

      <Modal
        className="zoomIn"
        id="addDocument"
        size="md"
        isOpen={modal}
        toggle={toggle}
        centered
      >
        <ModalHeader toggle={toggle}>Assign User</ModalHeader>
        <div className="modal-content border-0 mt-3"></div>
        <ModalBody>
          <TabContent activeTab="2">
            <TabPane tabId="2">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        for="confirmationnumberInput"
                        className="form-label"
                      >
                        User
                      </Label>
                      <Select
                        type="text"
                        name="userId"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "userId",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          userList &&
                          userList.map((item, index) => ({
                            label:
                              item.name +
                              " (" +
                              (item.email ? item.email : "N/A") +
                              ")",
                            value: item._id,
                          }))
                        }
                        value={validation.values.userId || ""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Label className="form-label">Payment Type</Label>
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="paymentType"
                          id="inlineRadio1"
                          value="settle"
                          onChange={validation.handleChange}
                          checked={validation.values.paymentType === "settle"}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Settle
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="paymentType"
                          id="inlineRadio2"
                          value="fight"
                          onChange={validation.handleChange}
                          checked={validation.values.paymentType === "fight"}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          Fight
                        </Label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <Button
                        color="success"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Create{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Submit</span>
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TicketList;
