import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateJob,
  resetJobFlag,
  getJobDetails,
  getCountryList,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { call } from "redux-saga/effects";
import Select from "react-select";

const JobEdit = () => {
  document.title = "Edit Job | Velzon - React Admin & Dashboard Template";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [long_description, setData] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const { isJobUpdate, isJobUpdateFail, error, success, country, details } =
    useSelector((state) => ({
      isJobUpdate: state.Jobs.isJobUpdate,
      isJobUpdateFail: state.Jobs.isJobUpdateFail,
      error: state.Jobs.error,
      success: state.Jobs.success,
      details: state.Jobs.details,
      country: state.Drivers.country,
    }));
  console.log(details);
  const selectedCountryOption = {
    value: details && details.country,
    label: details && details.country
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      long_description:
        details && details.long_description ? details.long_description : "",
      city: details && details.city ? details.city : "",
      state: details && details.state ? details.state : "",
      country: selectedCountryOption,
      short_description:
        details && details.short_description ? details.short_description : "",
      degree: details && details.degree ? details.degree : "",
      experience: details && details.experience ? details.experience : "",
      vacancy: details && details.vacancy ? details.vacancy : "",
      salary: details && details.salary ? details.salary : "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      degree: Yup.string().required("Please Enter Degree"),
      salary: Yup.string().required("Please Enter Salary"),
      experience: Yup.string().required("Please Enter Experience"),
      vacancy: Yup.string().required("Please Enter Vacancy"),
      city: Yup.string().required("Please Enter City"),
      state: Yup.string().required("Please Enter State"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        degree: values.degree,
        experience: values.experience,
        salary: values.salary,
        short_description: values.short_description,
        vacancy: values.vacancy,
        city: values.city,
        country: values.country?.label,
        long_description: long_description,
        state: values.state,
      };
      dispatch(updateJob(data));
      console.log(data);
    },
  });
  console.log(validation.values.country);
  useEffect(() => {
    if (isJobUpdate || isJobUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetJobFlag());
      }, 3000);
    }
  }, [dispatch, isJobUpdate, isJobUpdateFail]);
  useEffect(() => {
    dispatch(getCountryList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJobDetails({ id: id }));
  }, [dispatch, id]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Job" pageTitle="Jobs" pageUrl="/jobs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Job" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Job title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Degree *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Degree name"
                              name="degree"
                              value={validation.values.degree || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.degree &&
                                validation.errors.degree
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.degree &&
                            validation.errors.degree ? (
                              <FormFeedback type="invalid">
                                {validation.errors.degree}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Experience *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Experience name"
                              name="experience"
                              value={validation.values.experience || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.experience &&
                                validation.errors.experience
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.experience &&
                            validation.errors.experience ? (
                              <FormFeedback type="invalid">
                                {validation.errors.experience}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Vacancy *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Vacancy name"
                              name="vacancy"
                              value={validation.values.vacancy || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.vacancy &&
                                validation.errors.vacancy
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.vacancy &&
                            validation.errors.vacancy ? (
                              <FormFeedback type="invalid">
                                {validation.errors.vacancy}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Salary *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Salary name"
                              name="salary"
                              value={validation.values.salary || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.salary &&
                                validation.errors.salary
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.salary &&
                            validation.errors.salary ? (
                              <FormFeedback type="invalid">
                                {validation.errors.salary}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              City *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="City name"
                              name="city"
                              value={validation.values.city || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.city &&
                                validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              State *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="State name"
                              name="state"
                              value={validation.values.state || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              Country
                            </Label>
                            <Select
                              type="text"
                              name="country"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "country",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                country &&
                                country.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              value={validation.values.country || ""}
                            />
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <FormFeedback type="invalid">
                                {validation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Short description *
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              className="form-control"
                              name="short_description"
                              value={validation.values.short_description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.short_description &&
                                validation.errors.short_description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.short_description &&
                            validation.errors.short_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.short_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description *
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="long_description"
                              data={validation.values.long_description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.long_description &&
                            validation.errors.long_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.long_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/jobs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isJobUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isJobUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default JobEdit;
