import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions, resetTransactionFlag } from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import MsgToast from "../../../Components/Common/MsgToast";
//import { ExcelExport } from '@progress/kendo-react-excel-export';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactPaginate from "react-paginate";

const TransactionList = () => {
  document.title =
    "Transaction list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [payment_status, setPaymentStatus] = useState('');

  const tableRef = useRef(null);

  const dispatch = useDispatch();
  const {
    transactions,
    totalDataCount,
    success,
    isSuccess,
    error,
    statusSuccess,
    statusFailed,
  } = useSelector((state) => ({
    transactions: state.Transactions.transactions,
    totalDataCount: state.Transactions.totalDataCount,
    isSuccess: state.Transactions.isSuccess,
    success: state.Transactions.success,
    error: state.Transactions.error,
  }));

  useEffect(() => {
    dispatch(
      getTransactions({
        keyword,
        sortByField,
        sortByValue,
        limit,
        offset,
        payment_status
      })
    );
  }, [dispatch, keyword, sortByField, sortByValue, limit, offset, payment_status]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getTransactions({ limit, dateRange, offset, keyword }));
    }
  }, [dispatch, date, limit, offset, keyword]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setOffset(newOffset);
  };

  const resetFilter = () => {
    dispatch(resetTransactionFlag());
    setKeyword("");
    setPaymentStatus("")
    fp.current.flatpickr.clear();
    setDate(null);
    dispatch(
      getTransactions({ keyword, sortByField, sortByValue, limit, offset, setPaymentStatus })
    );
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Transactions" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Transactions</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Button color="warning" onClick={resetFilter} id="resetTooltip"><i className="ri-restart-line me-1 align-bottom"></i></Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">Reset</UncontrolledTooltip>
                    <DownloadTableExcel
                      filename="transaction"
                      sheet="transaction"
                      currentTableRef={tableRef.current}
                    >
                      <button className="btn btn-success m-2">Export excel</button>
                    </DownloadTableExcel>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <select
                          className="form-select"
                          value={payment_status}
                          onChange={(e) => setPaymentStatus(e.target.value)}
                        >
                          <option value="">Payment Status</option>
                          <option value="success">Paid</option>
                          <option value="pending">Pending</option>
                          <option value="cancel">Cancel</option>
                        </select>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {transactions?.length ? (
                        <>
                        <div style={{display: 'none' }}>
                          <table ref={tableRef}>
                            <header>
                              <tr>
                                <th>Transaction ID</th>
                                <th>Ticket Number</th>
                                <th>Ticket Type</th>
                                <th>User</th>
                                <th>Transaction Date</th>
                                <th>State</th>
                                <th>Processing Fee</th>
                                <th>State Fee</th>
                                <th>Credit Fee</th>
                                <th>Coupon Amount</th>
                                <th>Payment Amount</th>
                                <th>Payment Mode</th>
                                <th>Payment Status</th>
                              </tr>
                            </header>
                            <tbody>
                              {transactions.map((item) => (
                                <tr key={item._id}>
                                  <td>{item?.payment_transaction}</td>
                                  <td>{item?.ticket_details}</td>
                                  <td>{item?.ticket_type?.toUpperCase()}</td>
                                  <td>
                                    {item?.user_details?.name}, {item?.user_details?.email}
                                  </td>
                                  <td>
                                    {item?.transaction_date ?
                                    <Moment format="D MMM YYYY">
                                      {item?.transaction_date}
                                    </Moment>
                                    : ''}
                                  </td>
                                  <td>{item?.state}</td>
                                  <td>${item?.processing_fee?item?.processing_fee.toFixed(2):'00.00'}</td>
                                  <td>${item?.state_fee?item?.state_fee.toFixed(2):'00.00'}</td>
                                  <td>${item?.creadit_fee?item?.creadit_fee.toFixed(2):'00.00'}</td>
                                  <td>${item?.coupon_amount?item?.coupon_amount.toFixed(2):'00.00'}</td>
                                  <td>${item?.amount?item?.amount.toFixed(2):'00.00'}</td>
                                  <td>{item?.payment_mode?.toUpperCase()}</td>
                                  <td>
                                    {
                                      item.payment_status && (item?.payment_status === 'success')?
                                      <span className="badge badge-soft-success text-uppercase">Paid</span>
                                      : item.payment_status && (item?.payment_status === 'pending')?
                                      <span className="badge badge-soft-warning text-uppercase">Pending</span>
                                      :
                                      <span className="badge badge-soft-danger text-uppercase">Cancel</span>
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>




                        {/* <ExcelExport useRef={_export}> */}
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                            //ref={tableRef}
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Transaction ID</th>
                                <th>Ticket Number</th>
                                <th>Ticket Type</th>
                                <th>User</th>
                                <th>Transaction Date</th>
                                <th>State</th>
                                <th>Processing Fee</th>
                                <th>State Fee</th>
                                <th>Credit Fee</th>
                                <th>Coupon Amount</th>
                                <th>Payment Amount</th>
                                <th>Payment Mode</th>
                                <th>Payment Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {transactions.map((item) => (
                                <tr key={item._id}>
                                  <td>{item?.payment_transaction}</td>
                                  <td>{item?.ticket_details}</td>
                                  <td>{item?.ticket_type?.toUpperCase()}</td>
                                  <td>
                                    {item?.user_details?.name}<br />
                                    {item?.user_details?.email}
                                  </td>
                                  <td>
                                    {item?.transaction_date ?
                                    <Moment format="D MMM YYYY">
                                      {item?.transaction_date}
                                    </Moment>
                                    : ''}
                                  </td>
                                  <td>{item?.state}</td>
                                  <td>${item?.processing_fee?item?.processing_fee.toFixed(2):'00.00'}</td>
                                  <td>${item?.state_fee?item?.state_fee.toFixed(2):'00.00'}</td>
                                  <td>${item?.creadit_fee?item?.creadit_fee.toFixed(2):'00.00'}</td>
                                  <td>${item?.coupon_amount?item?.coupon_amount.toFixed(2):'00.00'}</td>
                                  <td>${item?.amount?item?.amount.toFixed(2):'00.00'}</td>
                                  <td>{item?.payment_mode?.toUpperCase()}</td>
                                  <td>
                                    {
                                      item.payment_status && (item?.payment_status === 'success')?
                                      <span className="badge badge-soft-success text-uppercase">Paid</span>
                                      : item.payment_status && (item?.payment_status === 'pending')?
                                      <span className="badge badge-soft-warning text-uppercase">Pending</span>
                                      :
                                      <span className="badge badge-soft-danger text-uppercase">Cancel</span>
                                    }
                                  </td>

                                  <td>
                                    {/* <ul className="list-inline hstack gap-2 mb-0">
                                      <li
                                        className="list-inline-item edit"
                                        title="Edit"
                                      >
                                        <Link
                                          to={`/transaction/${item._id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-information-line fs-16"></i>
                                        </Link>
                                      </li>
                                    </ul> */}

                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white"><i className="ri-more-2-fill"></i></DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Link
                                            to={`/transaction/${item._id}`}
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-information-line fs-16"></i> Details
                                          </Link>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>



                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        {/* </ExcelExport> */}
                          
                          {
                            noOfPage > 1 &&
                            <div className='pagination-inner'>
                                <nav className="px-3" aria-label="Page navigation example">
                                  <ReactPaginate
                                    activeClassName = "active"
                                    breakClassName = "page-item"
                                    breakLinkClassName = "page-link"
                                    nextClassName = "page-item"
                                    nextLinkClassName = "page-link"
                                    previousClassName = "page-item"
                                    previousLinkClassName = "page-link"
                                    containerClassName = "pagination"
                                    pageClassName = "page-item"
                                    pageLinkClassName = "page-link"
                                    breakLabel="..."
                                    nextLabel="Next &nbsp;→"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={noOfPage}
                                    previousLabel="←&nbsp;Prev"
                                    renderOnZeroPageCount={null}
                                  />
                                </nav>
                            </div>
                          }
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </>
  );
};

export default TransactionList;
