import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateTicket,
  getTicketDetails,
  resetTicketFlag,
  getStateList,
  getCountryList,
  getCityList,
  addNewTicket,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import axios from "axios";

const TicketEditDmv = () => {
    document.title = "Add DMV Ticket | Defendit - Admin Panel";
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const [ticketid, setTicketId] = useState("");
    const [loader, setLoader] = useState(false);


    const [fineAmount, setFineAmount] = useState('');
    const [penaltyAmount, setPenaltyAmount] = useState('');
    const [interestAmount, setInterestAmount] = useState('');
    const [reductionAmount, setReductionAmount] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [dueAmount, setDueAmount] = useState('');
    const [issueAgency, setIssueAgency] = useState('');
    const [violationDescription, setViolationDescription] = useState('');
    const {
      isTicketUpdate,
      isTicketAdd,
      isTicketUpdateFail,
      isTicketAddFail,
      error,
      success,
      country,
      city,
      vehicleState,
      details,
    } = useSelector((state) => ({
        
        isTicketAdd: state.Tickets.isTicketAdd,
      isTicketUpdate: state.Tickets.isTicketUpdate,
      isTicketUpdateFail: state.Tickets.isTicketUpdateFail,
      isTicketAddFail: state.Tickets.isTicketAddFail,
      error: state.Tickets.error,
      success: state.Tickets.success,
      country: state.Drivers.country,
      city: state.Drivers.city,
      vehicleState: state.Vehicles.vehicleState,
      details: state.Tickets.details,
    }));
 
    useEffect(() => {
      if (isTicketUpdate || isTicketUpdateFail || isTicketAddFail || isTicketAdd) {
        setIsCreating(false);
        setTimeout(() => {
          dispatch(resetTicketFlag());
          history.push('/dmv-tickets')
        }, 3000);
      }
    }, [dispatch, isTicketUpdate, isTicketUpdateFail,isTicketAdd,isTicketAddFail]);
    useEffect(() => {
      dispatch(getStateList({ country_code: "US" }));
    }, [dispatch]);
    useEffect(() => {
      dispatch(getTicketDetails({ id: id }));
    }, [dispatch, id]);
  
  
  
    const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
        fine_amount: fineAmount,
        penalty_amount: penaltyAmount,
        interest_amount: interestAmount,
        reduction_amount:reductionAmount,
        payment_amount: paymentAmount,
        due_amount: dueAmount,
        violation_description: violationDescription,
        issuing_agency: issueAgency,
     
      },
      validationSchema: Yup.object({
      }),
  
      onSubmit: (values) => {
        if(ticketid === ''){
            toast.error('ticketid is required')
            return false;
        }
        setIsCreating(true);
        const data = {
          ticket_number: ticketid,
          ticket_type: 'dmv',
          fine_amount: values.fine_amount,
          penalty_amount: values.penalty_amount,
          interest_amount: values.interest_amount,
          reduction_amount: values.reduction_amount,
          payment_amount: values.payment_amount,
          due_amount: values.due_amount,
          violation_description: values.violation_description,
          issuing_agency: values.issuing_agency,
          type: "Admin",
  
        };

        dispatch(addNewTicket(data));
      },
    });
  
  
    
    return (
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add DMV Ticket" pageTitle="TIcket" pageUrl="/dmv-tickets" />
          <Row>
            <Col lg={8}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                 <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Ticket Id</h5>
                  <CardBody>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Ticket ID
                      </Label>
                      <Input
                        type="text"
                        name="total_violation_amount"
                        className="form-control"
                        placeholder="Ticket ID"
                        onChange={(e) => setTicketId(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <Button
                        type="button"
                        onClick={async () => {
                          try {
                            const token = JSON.parse(
                              sessionStorage.getItem("authUser")
                            )
                              ? JSON.parse(sessionStorage.getItem("authUser"))
                                  .access_token
                              : null;
                            setLoader(true);
                            const getData = await axios.post(
                              "https://api.defendit.io/api/search-ticket",
                              {
                                ticket_id: ticketid,
                                type: "dmv",
                              },
                              
                            );
                            if (getData.status === 200) {
                                getData.data.length === 0
                                    ? toast.error(getData.message):
                                    setFineAmount(getData.data[0].fine_amount)
                                    setPenaltyAmount(getData.data[0].penalty_amount)
                                    setInterestAmount(getData.data[0].interest_amount)
                                    setReductionAmount(getData.data[0].reduction_amount)
                                    setPaymentAmount(getData.data[0].payment_amount)
                                    setDueAmount(getData.data[0].amount_due)
                                    setIssueAgency(getData.data[0].issuing_agency)
                                    setViolationDescription(getData.data[0].violation)
                               // console.log(getData,'getData')
                                setLoader(false);
                            }
                          } catch (error) {
                            console.log(error);
                            setLoader(false);
                          }
                        }}
                      >
                        {loader ? "Searching..." : "Search"}
                      </Button>
                    </div>
                  </CardBody>
                </CardHeader>
              
              </Card>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Ticket Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="gy-4">
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Fine Amount
                          </Label>
                          <Input
                            type="number"
                            name="fine_amount"
                            className="form-control"
                            placeholder="Enter Fine Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fine_amount || ""}
                            invalid={
                              validation.touched.fine_amount &&
                              validation.errors.fine_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.fine_amount &&
                          validation.errors.fine_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.fine_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Penalty Amount
                          </Label>
                          <Input
                            type="number"
                            name="penalty_amount"
                            className="form-control"
                            placeholder="Enter Penalty Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.penalty_amount || ""}
                            invalid={
                              validation.touched.penalty_amount &&
                              validation.errors.penalty_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.penalty_amount &&
                          validation.errors.penalty_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.penalty_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Interest Amount
                          </Label>
                          <Input
                            type="number"
                            name="interest_amount"
                            className="form-control"
                            placeholder="Enter Interest Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interest_amount || ""}
                            invalid={
                              validation.touched.interest_amount &&
                              validation.errors.interest_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.interest_amount &&
                          validation.errors.interest_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.interest_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Reduction Amount
                          </Label>
                          <Input
                            type="number"
                            name="reduction_amount"
                            className="form-control"
                            placeholder="Enter Reduction Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reduction_amount || ""}
                            invalid={
                              validation.touched.reduction_amount &&
                              validation.errors.reduction_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.reduction_amount &&
                          validation.errors.reduction_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.reduction_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Payment Amount
                          </Label>
                          <Input
                            type="number"
                            name="payment_amount"
                            className="form-control"
                            placeholder="Enter Payment Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.payment_amount || ""}
                            invalid={
                              validation.touched.payment_amount &&
                              validation.errors.payment_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.payment_amount &&
                          validation.errors.payment_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Due Amount
                          </Label>
                          <Input
                            type="number"
                            name="due_amount"
                            className="form-control"
                            placeholder="Enter Due Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.due_amount || ""}
                            invalid={
                              validation.touched.due_amount &&
                              validation.errors.due_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.due_amount &&
                          validation.errors.due_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.due_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
  
  
                <div className="text-end mb-3">
                  <Link to="/dmv-tickets" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Submit</span>
                    </span>
                  </Button>
                  
                </div>
              </Form>
            </Col>
  
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Ticket Info</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Issuing Agency
                          </Label>
                      <Input
                        type="textarea"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Issuing Agency"
                        name="issuing_agency"
                        value={validation.values.issuing_agency || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                        invalid={
                            validation.touched.issuing_agency &&
                            validation.errors.issuing_agency
                            ? true
                            : false
                        }
                        />
                        {validation.touched.issuing_agency &&
                        validation.errors.issuing_agency ? (
                        <FormFeedback type="invalid">
                            {validation.errors.issuing_agency}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Description
                          </Label>
                      <Input
                        type="textarea"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Violation Description"
                        name="violation_description"
                        value={validation.values.violation_description || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                        invalid={
                            validation.touched.violation_description &&
                            validation.errors.violation_description
                            ? true
                            : false
                        }
                        />
                        {validation.touched.violation_description &&
                        validation.errors.violation_description ? (
                        <FormFeedback type="invalid">
                            {validation.errors.violation_description}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                   
                  </Row>
                </CardBody>
              </Card>
  
            </Col>
          </Row>
          {isTicketUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isTicketUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    );
  };
export default TicketEditDmv;
