import {
  GET_REFERRAL_CODE_SUCCESS,
  GET_REFERRAL_CODE_FAIL,

  STATUS_CHANGE_REFERRAL_CODE_SUCCESS,
  STATUS_CHANGE_REFERRAL_CODE_FAIL,

  MODIFY_REFERRAL_CODE_FLAG,
  RESET_REFERRAL_CODE_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  referralCode: []
};

const referralCode = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        referralCode: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_REFERRAL_CODE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    //Status Change
    case STATUS_CHANGE_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        referralCode: state.referralCode.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_REFERRAL_CODE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    case MODIFY_REFERRAL_CODE_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_REFERRAL_CODE_FLAG:
      return {
        ...state,
        isSuccess: false,
        error: false
      };

    default:
      return state;
  }
};

export default referralCode;
