import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getUsers,
  deleteUser,
  statusChangeUser,
  modifyUserFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";

const UserList = () => {
  document.title = "Admin list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("62cc03295e16de046e6db96c");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const {
    users,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFaild,
  } = useSelector((state) => ({
    users: state.Users.users,
    totalDataCount: state.Users.totalDataCount,
    isSuccess: state.Users.isSuccess,
    statusSuccess: state.Users.statusSuccess,
    statusFaild: state.Users.statusFaild,
    error: state.Users.error,
    success: state.Users.success,
  }));

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(
      getUsers({
        role,
        keyword,
        date,
        status,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [
    dispatch,
    keyword,
    date,
    role,
    status,
    sortByField,
    sortByValue,
    limit,
    offset,
  ]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    setStatus("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  //On Click Delete Data
  function onClickDeleteData(id) {
    dispatch(deleteUser({ id }));
  }

  //On Click Status Change
  function onClickStatusChange(id, status) {
    dispatch(statusChangeUser({ id, status }));
  }

  useEffect(() => {
    if (statusSuccess || statusFaild) {
      setTimeout(() => {
        dispatch(modifyUserFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFaild]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Admins" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Admin List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/admin/create"
                      className="btn btn-success add-btn"
                      id="createStepTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createStepTooltip"
                    >
                      Create admin
                    </UncontrolledTooltip>
                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="">Select status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                          <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {users?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}
                                <th>Image</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")}
                                >
                                  Name
                                </th>

                                <th>Contact</th>
                                <th>Role</th>

                                <th>Status</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {users.map((item) => (
                                <tr key={item._id}>
                                  {/* <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                      />
                                    </div>
                                  </th> */}
                                  <td>
                                    {" "}
                                    <img
                                      src={item.image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>
                                    {item.email ? (
                                      <span className="d-block">
                                        Email : {item.email}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {item.phone ? (
                                      <span className="d-block">
                                        Phone : {item.phone}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{item.role?.name}</td>
                                  <td>
                                    {item.status && item.status === "active" ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {/* <ul className="list-inline hstack gap-2 mb-0">

                                    <li
                                        className="list-inline-item edit"
                                        title="Change Password"
                                      >
                                        <Link
                                          to={`/admin/edit/${item._id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>

                                      <li
                                        className="list-inline-item edit"
                                        title="Change Password"
                                      >
                                        <Link
                                          to={`/user/change-password/${item._id}`}
                                          className="text-danger d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-lock-password-line fs-16"></i>
                                        </Link>
                                      </li>




                                      






                                      <li
                                        className="list-inline-item"
                                        title="Remove"
                                        id={"deletePopover" + item._id}
                                      >
                                        <Button className="p-0 bg-transparent border-0 text-danger">
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Button>
                                      </li>
                                      <UncontrolledPopover
                                        placement="left"
                                        trigger="focus"
                                        target={"deletePopover" + item._id}
                                      >
                                        <PopoverHeader>
                                          Delete Admin
                                        </PopoverHeader>
                                        <PopoverBody>
                                          Are you sure want to delete?
                                          <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Button className="btn btn-sm btn-light">
                                              No
                                            </Button>
                                            <Button
                                              onClick={() =>
                                                onClickDeleteData(item._id)
                                              }
                                              className="btn btn-sm btn-danger ms-2"
                                            >
                                              Yes
                                            </Button>
                                          </div>
                                        </PopoverBody>
                                      </UncontrolledPopover>

                                      <li
                                        className="list-inline-item"
                                        title="Status Change"
                                      >
                                        <Button
                                          onClick={() =>
                                            onClickStatusChange(
                                              item._id,
                                              item.status &&
                                                item.status === "active"
                                                ? "inactive"
                                                : "active"
                                            )
                                          }
                                          className="p-0 bg-transparent border-0 text-warning"
                                        >
                                          <i className="ri-exchange-line fs-16"></i>
                                        </Button>
                                      </li>
                                    </ul> */}

                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white">
                                        <i className="ri-more-2-fill"></i>
                                      </DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Link
                                            to={`/admin/edit/${item._id}`}
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>{" "}
                                            Edit
                                          </Link>
                                        </DropdownItem>

                                        <DropdownItem>
                                          <Link
                                            to={`/user/change-password/${item._id}`}
                                            className="text-danger d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-lock-password-line fs-16"></i>{" "}
                                            Change Password
                                          </Link>
                                        </DropdownItem>

                                        <DropdownItem>
                                          <Button
                                            onClick={() =>
                                              onClickStatusChange(
                                                item._id,
                                                item.status &&
                                                  item.status === "active"
                                                  ? "inactive"
                                                  : "active"
                                              )
                                            }
                                            className="p-0 bg-transparent border-0 text-warning"
                                          >
                                            <i className="ri-exchange-line fs-16"></i>{" "}
                                            Status Change
                                          </Button>
                                        </DropdownItem>

                                        <DropdownItem>
                                          <Button
                                            className="p-0 bg-transparent border-0 text-danger"
                                            onClick={() =>
                                              openDeleteConfrontation(item?._id)
                                            }
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>{" "}
                                            Delete
                                          </Button>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {statusSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {statusFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}

          <Modal
            isOpen={modal}
            toggle={openDeleteConfrontation}
            trigger="click"
          >
            <ModalHeader>Delete Admin</ModalHeader>
            <ModalBody>
              Are you sure want to delete?
              <div className="d-flex align-items-center justify-content-end mt-2">
                <Button
                  onClick={() => {
                    setModal(!modal);
                  }}
                  className="btn btn-sm btn-light"
                >
                  No
                </Button>

                <Button
                  onClick={() => {
                    onClickDeleteData(deletedId);
                    setModal(!modal);
                  }}
                  className="btn btn-sm btn-danger ms-2"
                >
                  Yes
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default UserList;
