import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getReferralTransactionDetails,
  getReferralTransaction,
  resetReferralFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";

const ReferralList = () => {
  document.title = "Referral list | Defendit - Admin Panel";
  const fp = useRef(null);
  const { id } = useParams();
  const { referred_signup_id } = useParams();
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { referralsDetails, totalDataCount, isSuccess, error, success, referralCode} = useSelector(
    (state) => ({
      referralsDetails: state.Referrals.referralsDetails,
      totalDataCount: state.Referrals.totalDataCount,
      isSuccess: state.Referrals.isSuccess,
      error: state.Referrals.error,
      success: state.Referrals.success,
      referralCode: state.Referrals.referralCode,
    })
  );

  useEffect(() => {
    dispatch(
      getReferralTransactionDetails({
        id: id,
        referred_signup_id: referred_signup_id, 
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [id, referred_signup_id, dispatch, keyword, date, sortByField, sortByValue, limit, offset]);

  useEffect(() => {
    dispatch(
      getReferralTransaction({
        referred_signup_id: id,
      })
    );
  }, [id, dispatch]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Referral Details" pageTitle="Referral" pageUrl={`/referral/${referred_signup_id}`} />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Referral Code : {referralCode?referralCode:''}</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>


                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      {/* <Col sm={4} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col> */}
                      
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {referralsDetails?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Transaction Id</th>
                                <th>Ticket Number</th>
                                <th>Amount</th>
                                <th>Payment Mode</th>
                                <th>Payment Status</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {referralsDetails.map((item) => {

                                let allTickets = '';
                                let count = item?.trans_details.length;
                                item?.trans_details?.forEach((element, index) =>{
                                  if(element.ticket_id){
                                    if(count <= index+1){
                                      // if(count == 1){
                                      //   allTickets += element.ticket_id;
                                      // }
                                      allTickets += element.ticket_id+' ';
                                    }else{
                                      allTickets += element.ticket_id+', ';
                                    }
                                  }
                                })
                                return(
                                <tr key={item?._id}>
                                  <td>{item?.payment_transaction?item?.payment_transaction:"N.A."}</td>
                                  <td>{allTickets?allTickets:"N.A."}</td>
                                  <td>${item?.referred_amount? item?.referred_amount.toFixed(2) : '0.00' }</td>
                                  <td><span className="text-capitalize">{item?.payment_mode}</span></td>

                                  <td>
                                    {item?.payment_status && item?.payment_status === "success" ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Success
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        Pending
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item?.created_at}
                                    </Moment>
                                  </td>
                                  
                                </tr>
                                )
                              })}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ReferralList;
