import {
  GET_REFERRAL,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAIL,

  GET_REFERRAL_TRANSACTION,
  GET_REFERRAL_TRANSACTION_SUCCESS,
  GET_REFERRAL_TRANSACTION_FAIL,

  GET_REFERRAL_TRANSACTION_DETAILS,
  GET_REFERRAL_TRANSACTION_DETAILS_SUCCESS,
  GET_REFERRAL_TRANSACTION_DETAILS_FAIL,

  
  MODIFY_REFERRAL_DOCUMENT,
  MODIFY_REFERRAL_DOCUMENT_SUCCESS,
  MODIFY_REFERRAL_DOCUMENT_FAIL,


  MODIFY_REFERRAL_PAID_STATUS,
  MODIFY_REFERRAL_PAID_STATUS_SUCCESS,
  MODIFY_REFERRAL_PAID_STATUS_FAIL,

  RESET_REFERRAL_FLAG,
  RESET_REFERRAL_STATUS_FLAG

} from "./actionTypes";

export const getReferral = data => ({
  type: GET_REFERRAL,
  payload: data,
});

export const getReferralSuccess = data => ({
  type: GET_REFERRAL_SUCCESS,
  payload: data,
});

export const getReferralFail = error => ({
  type: GET_REFERRAL_FAIL,
  payload: error,
});


export const getReferralTransaction = data => ({
  type: GET_REFERRAL_TRANSACTION,
  payload: data,
});

export const getReferralTransactionSuccess = data => ({
  type: GET_REFERRAL_TRANSACTION_SUCCESS,
  payload: data,
});

export const getReferralTransactionFail = error => ({
  type: GET_REFERRAL_TRANSACTION_FAIL,
  payload: error,
});

//Details
export const getReferralTransactionDetails = data => ({
  type: GET_REFERRAL_TRANSACTION_DETAILS,
  payload: data,
});

export const getReferralTransactionDetailsSuccess = data => ({
  type: GET_REFERRAL_TRANSACTION_DETAILS_SUCCESS,
  payload: data,
});

export const getReferralTransactionDetailsFail = error => ({
  type: GET_REFERRAL_TRANSACTION_DETAILS_FAIL,
  payload: error,
});
export const modifyReferalPaidStatus = data => ({
  type: MODIFY_REFERRAL_PAID_STATUS,
  payload: data,
});

export const modifyReferalPaidStatusSuccess = data => ({
  type: MODIFY_REFERRAL_PAID_STATUS_SUCCESS,
  payload: data,
});

export const modifyReferalPaidStatusFail = error => ({
  type: MODIFY_REFERRAL_PAID_STATUS_FAIL,
  payload: error,
});

export const modifyReferalDocument = data => ({
  type: MODIFY_REFERRAL_DOCUMENT,
  payload: data,
});

export const modifyReferalDocumentSuccess = data => ({
  type: MODIFY_REFERRAL_DOCUMENT_SUCCESS,
  payload: data,
});

export const modifyReferalDocumentFail = error => ({
  type: MODIFY_REFERRAL_DOCUMENT_FAIL,
  payload: error,
});






//Reset Flag
export const resetReferralFlag = () => {
  return {
    type: RESET_REFERRAL_FLAG,
  }
}
export const resetReferralUploadDocumentStatusFlag = () => {
  return {
    type: RESET_REFERRAL_STATUS_FLAG,
  }
}



