module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    //API_URL: "https://api-node.themesbrand.website", 
    API_URL: "https://api.defendit.io/api",
    // API_URL: "https://api.staging.defendit.io/api",
    // FRONT_URL: "https://staging.defendit.io",
    FRONT_URL: "https://defendit.io",
    
  }
};