import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewStep, resetStepFlag } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";

const StepCreate = () => {
    document.title = "Create Step | Defendit - Admin Panel";
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const { isStepAdd, isStepAddFail, error, success } = useSelector(state => ({
        isStepAdd: state.Steps.isStepAdd,
        isStepAddFail: state.Steps.isStepAddFail,
        error: state.Steps.error,
        success: state.Steps.success,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            description:'',
            icon:''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Step Name"),
            description: Yup.string().required("Please Enter Description"),
            icon: Yup.string().required("Please Enter Icon")
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                name: values.name,
                description: values.description,
                icon: values.icon
            }
            dispatch(addNewStep(data))
        },
    });

    useEffect(() => {
        if(isStepAdd || isStepAddFail){
            setIsCreating(false)
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetStepFlag())
            }, 3000);
        }
      }, [dispatch, isStepAdd, isStepAddFail, validation]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Step" pageTitle="Steps" pageUrl="/steps" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Create Step" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Step name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Icon</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Icon"
                                                            name='icon'
                                                            value={validation.values.icon || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.icon && validation.errors.icon ? true : false
                                                            }
                                                        />
                                                        {validation.touched.icon && validation.errors.icon ? (
                                                            <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={12}>
                      <div>
                        <Label
                          htmlFor="placeholderInput"
                          className="form-label"
                        >
                         Description*
                        </Label>
                        <Input
                          type="textarea"
                          id="placeholderInput"
                          rows="3"
                          className="form-control"
                          placeholder="Enter description"
                          name="description"
                          value={validation.values.description || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/steps" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isStepAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isStepAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default StepCreate;