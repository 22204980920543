import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
// import Select from "react-select";
// import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Moment from "react-moment";
import "moment-timezone";
import {
  updateVehicle,
  resetVehicleFlag,
  getStateList,
  getPurchaseTypeList,
  getDriver,
  getVehicleDetails,
} from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const VehicleEdit = () => {
  document.title = "Edit Vehicle | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isCreating, setIsCreating] = useState(false);

  const history = useHistory();

  const [isAutoPay, issetAutoPay] = useState(true);
  const [isSMSNotification, issetSMSNotification] = useState(true);
  const [isEmailNotification, issetEmailNotification] = useState(true);

  // const [state, setState] = useState([]);
  // const [allType, setAllType] = useState([]);
  // const [allDriver, setAllDriver] = useState([]);
  // const [selectState, setSelectState] = useState([]);

  const {
    isVehicleUpdate,
    isVehicleUpdateFail,
    error,
    success,
    details,

    //stateList,
    // vehicleState,
    // vehiclePurchaseType,
    // drivers,
  } = useSelector((state) => ({
    isVehicleUpdate: state.Vehicles.isVehicleUpdate,
    isVehicleUpdateFail: state.Vehicles.isVehicleUpdateFail,
    error: state.Vehicles.error,
    success: state.Vehicles.success,
    //vehicleState:state.Vehicles.vehicleState,
    stateList: state.Vehicles.vehicleState,
    vehiclePurchaseType: state.Vehicles.vehiclePurchaseType,
    drivers: state.Vehicles.drivers,
    details: state.Vehicles.details,
  }));

  const isAutoPayChange = (event) => {
    issetAutoPay((current) => !current);
  };

  const isSMSNotificationChange = (event) => {
    issetSMSNotification((current) => !current);
  };
  const issetEmailNotificationChange = (event) => {
    issetEmailNotification((current) => !current);
  };

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getPurchaseTypeList());
    dispatch(getDriver());
  }, []);

  const selectedStateOption = {
    value: details && details.vehicle_state ? details.vehicle_state?._id : "",
    label: details && details.vehicle_state ? details.vehicle_state?.name : "",
  };

  const selectedPurchaseTypesOption = {
    value:
      details && details.purchasetypes_details
        ? details.purchasetypes_details?._id
        : "",
    label:
      details && details.purchasetypes_details
        ? details.purchasetypes_details?.name
        : "",
  };

  useEffect(() => {
    dispatch(getVehicleDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    issetAutoPay(details?.auto_pay);
    issetSMSNotification(details?.sms_notification);
    issetEmailNotification(details?.email_notification);
  }, [dispatch, details]);

  useEffect(() => {
    if (isVehicleUpdate || isVehicleUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetVehicleFlag());
        history.push("/vehicles");
      }, 3000);
    }
  }, [dispatch, isVehicleUpdate, isVehicleUpdateFail]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      auto_pay: isAutoPay,
      sms_notification: isSMSNotification,
      email_notification: isEmailNotification,
    },
    validationSchema: Yup.object({
      // auto_pay: Yup.string().required("Please Choose Vehicle Type"),
      // sms_notification: Yup.string().required("Please Choose Vehicle Type"),
      // email_notification: Yup.string().required("Please Choose Vehicle Type"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        auto_pay: isAutoPay,
        sms_notification: isSMSNotification,
        email_notification: isEmailNotification,
      };
      dispatch(updateVehicle(data));
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Edit Vehicle"
          pageTitle="Vehicle"
          pageUrl="/vehicles"
        />
        <Row>
          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Vehicle Info</h5>
              </CardHeader>
              <CardBody>
                {details?.plate_type ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Plate Type
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.plate_type
                        ? details.plate_type
                        : "Personal"}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {details?.plate_no ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Plate No
                    </Col>
                    <Col xxl={8} md={8}>
                      : {details && details?.plate_no ? details.plate_no : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {details?.vehicle_vin ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle VIN
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_vin
                        ? details.vehicle_vin
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {details?.vehicle_state ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle State
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_state
                        ? details.vehicle_state?.name
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {details?.vehicle_year ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle Year
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_year
                        ? details.vehicle_year
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {details?.vehicle_make ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle Make
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_make
                        ? details.vehicle_make
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {details?.vehicle_model ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle Model
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_model
                        ? details.vehicle_model
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {details?.vehicle_type ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle Type
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_type
                        ? details.vehicle_type
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {details?.vehicle_color ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vehicle Color
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vehicle_color
                        ? details.vehicle_color
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Vendor Info</h5>
              </CardHeader>
              <CardBody>
                {details?.vendor_name ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Vendor Name
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vendor_name
                        ? details.vendor_name
                        : "Personal"}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {details?.vendor_Agent_name ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Agent name
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.vendor_Agent_name
                        ? details.vendor_Agent_name
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {details?.vendor_insurance_expiratione ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Insurance Expiratione
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      <Moment format="D MMM YYYY">
                        {details && details?.vendor_insurance_expiratione
                          ? details.vendor_insurance_expiratione
                          : ""}
                      </Moment>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Driver Info</h5>
              </CardHeader>
              <CardBody>
                {details?.driver?.driver_image ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Image
                    </Col>
                    <Col xxl={8} md={8}>
                      <img
                        src={details?.driver?.driver_image}
                        alt=""
                        width="50px"
                      ></img>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {details?.driver?.name ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Driver Name
                    </Col>
                    <Col xxl={8} md={8}>
                      : {details?.driver?.name}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {details?.driver?.license_number ? (
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      License Number
                    </Col>
                    <Col xxl={8} md={8}>
                      :{" "}
                      {details && details?.driver?.license_number
                        ? details?.driver?.license_number
                        : ""}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardBody>
                  <p className="text-muted">Auto Pay</p>
                  <div className="mb-3">
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="auto_pay"
                        id="inlineRadio1"
                        value={true}
                        checked={isAutoPay === true}
                        onChange={isAutoPayChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="auto_pay"
                        id="inlineRadio2"
                        value={false}
                        checked={isAutoPay === false}
                        onChange={isAutoPayChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        No
                      </Label>
                    </div>
                  </div>

                  <p className="text-muted">SMS Notification</p>
                  <div className="mb-3">
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="sms_notification"
                        id="inlineRadio3"
                        value={true}
                        checked={isSMSNotification === true}
                        onChange={isSMSNotificationChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio3"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="sms_notification"
                        id="inlineRadio4"
                        value={false}
                        checked={isSMSNotification === false}
                        onChange={isSMSNotificationChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio4"
                      >
                        No
                      </Label>
                    </div>
                  </div>

                  <p className="text-muted">Email Notification</p>
                  <div className="mb-3">
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="email_notification"
                        id="inlineRadio5"
                        value="true"
                        checked={isEmailNotification === true}
                        onChange={issetEmailNotificationChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio5"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="email_notification"
                        id="inlineRadio6"
                        checked={isEmailNotification === false}
                        onChange={issetEmailNotificationChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio6"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <Button
                  color="success"
                  className="btn-load"
                  disabled={isCreating}
                  type="submit"
                >
                  <span className="d-flex align-items-center">
                    {isCreating && (
                      <Spinner size="sm" className="flex-shrink-0 me-2">
                        {" "}
                        Create{" "}
                      </Spinner>
                    )}
                    <span className="flex-grow-1">Update</span>
                  </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button> */}
              </div>
            </Form>
          </Col>
        </Row>
        {isVehicleUpdate ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {isVehicleUpdateFail ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default VehicleEdit;
