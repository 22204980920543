import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Form,
  Spinner,
  Input
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getReferral,
  getReferralTransaction,
  resetReferralFlag,modifyReferalDocument,resetReferralUploadDocumentStatusFlag,modifyReferalPaidStatus
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";
import {  toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { convertToBase64 } from "../../../helpers/image_helper";
import { useFormik } from "formik";
const ReferralList = () => {
  document.title = "Referral list | Defendit - Admin Panel";
  const allowedExts = ["pdf",'png','jpg','jpeg'];
  const fp = useRef(null);
  const { id } = useParams();
  const [keyword, setKeyword] = useState("");
  const [setId, setSetId] = useState([]);
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [isCreating, setIsCreating] = useState(false);
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { referrals, totalDataCount, isSuccess, error, success, referralCode, totalSpend, totalEarning, total ,isModifyReferralDocumentSuccess, isModifyReferralDocumentFail,isModifyReferralPaidStatusSuccess, isModifyReferralPaidStatusFail
  } = useSelector(
    (state) => ({
      referrals: state.Referrals.referrals,
      totalDataCount: state.Referrals.totalDataCount,
      isSuccess: state.Referrals.isSuccess,
      error: state.Referrals.error,
      success: state.Referrals.success,
      totalSpend: state.Referrals.totalSpend,
      totalEarning: state.Referrals.totalEarning,
      total: state.Referrals.total,
      referralCode: state.Referrals.referralCode,
      isModifyReferralDocumentSuccess: state.Referrals.isModifyReferralDocumentSuccess,
      isModifyReferralDocumentFail: state.Referrals.isModifyReferralDocumentFail,
      isModifyReferralPaidStatusSuccess: state.Referrals.isModifyReferralPaidStatusSuccess,
      isModifyReferralPaidStatusFail: state.Referrals.isModifyReferralPaidStatusFail,
    })
  );
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  useEffect(() => {
    dispatch(
      getReferral({
        referred_signup_id: id,
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [id, dispatch, keyword, date, sortByField, sortByValue, limit, offset]);

  useEffect(() => {
    dispatch(
      getReferralTransaction({
        referred_signup_id: id,
      })
    );
  }, [id, dispatch]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);
  useEffect(() => {
    if (isModifyReferralDocumentSuccess || isModifyReferralDocumentFail) {
      setModal(false)
      setIsCreating(false)
      dispatch( getReferral({
        referred_signup_id: id,
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
      }))
      setTimeout(() => {
       
        dispatch(resetReferralUploadDocumentStatusFlag());
      }, 3000);
    }
  }, [id,isModifyReferralDocumentSuccess,isModifyReferralDocumentFail, dispatch, keyword, date, sortByField, sortByValue, limit, offset]);
  useEffect(() => {
    if (isModifyReferralPaidStatusSuccess || isModifyReferralPaidStatusFail) {
      dispatch( getReferral({
        referred_signup_id: id,
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
      }))
      setTimeout(() => {
       
        dispatch(resetReferralUploadDocumentStatusFlag());
      }, 3000);
    }
  }, [id,isModifyReferralPaidStatusSuccess,isModifyReferralPaidStatusFail, dispatch, keyword, date, sortByField, sortByValue, limit, offset]);
  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
  };
  const onFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("File not allowed.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("fileInput");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setFile);
      }
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: "",
    },
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        data: setId,
        file: file,
        referred_note:values.referred_note
      };

      dispatch(modifyReferalDocument(data));
    },
  });
  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  function changeStatus(status,ids) { 
  dispatch(
    modifyReferalPaidStatus({
      data: ids,
      status:status}
    )
  )

  }


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Referrals" pageTitle="Users" pageUrl="/users" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Referral Code : {referralCode?referralCode:'N.A.'}</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>

                <CardBody className="border border-dashed border-end-0 border-start-0 card-body border-bottom-0">
                  <div className="row">
                    <Col xl={3} md={3}>
                      <Card className="card m-0 bg-light mr-2">
                        <CardBody className="p-2">
                          <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0 text-center">User Signed up</p>
                          </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-center mt-2">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-1">
                                <span className="counter-value">{totalDataCount?totalDataCount:'0.00'} Users</span>
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={3} md={3}>
                      <Card className="bg-light m-0">
                        <CardBody className="p-2">
                          <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0 text-center">Total Earning</p>
                          </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-center mt-2">
                              <h4 className="fs-22 fw-semibold ff-secondary mb-1">
                                <span className="counter-value">${totalEarning?totalEarning.toFixed(2):'0.00'}</span>
                              </h4>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col xl={3} md={3}>
                      <Card className="bg-light m-0">
                        <CardBody className="p-2">
                          <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0 text-center">Total Redeem</p>
                          </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-center mt-2">
                              <h4 className="fs-22 fw-semibold ff-secondary mb-1">
                                <span className="counter-value">${totalSpend?totalSpend.toFixed(2):'0.00'}</span>
                              </h4>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={3} md={3}>
                      <Card className="bg-light m-0">
                        <CardBody className="p-2">
                          <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0 text-center">Remaining Balance</p>
                          </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-center mt-2">
                              <h4 className="fs-22 fw-semibold ff-secondary mb-1">
                                <span className="counter-value">${total?total.toFixed(2):'0.00'}</span>
                              </h4>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </div>
                </CardBody>

                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>                      
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {referrals.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Image</th>
                                <th>
                                  Name
                                </th>

                                <th>Email</th>
                                <th>Phone</th>

                                <th>
                                  Signup date
                                </th>
                                <th>
                                  Referral paid
                                </th>
                                <th>
                                  Referral note
                                </th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {referrals.map((item) => (
                                <tr key={item?._id}>
                                  <td>
                                    {" "}
                                    <img
                                      src={item?.image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td>
                                  <td>{item?.name}</td>
                                  <td>{item?.email?item?.email:"N.A."}</td>
                                  <td>{item?.phone?item?.phone:"N.A."}</td>

                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item?.created_at}
                                    </Moment>
                                  </td> 
                                  <td>{item?.transaction_details?.is_referred_paid?<div className="badge badge-soft-success text-uppercase">
                                        Yes
                                      </div>:<div className="badge badge-soft-danger text-uppercase">
                                        No
                                      </div>}</td>
                                      <td>{item?.transaction_details?.referred_note?item?.transaction_details?.referred_note:"N.A."}
                                      <a
                                      className="ms-xl-2"
                                      href={item?.transaction_details?.referred_file}
                                      target="_blank"
                                    >
                                      <i className="ri-eye-line"></i>
                                    </a>
                                      </td>
                                  <td>
                                
                                  <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white">
                                        <i className="ri-more-2-fill"></i>
                                      </DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                        <Link
                                          to={`/referral-details/${item._id}/${id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-eye-line fs-16"></i>{" "}
                                          Details
                                        </Link>
                                        </DropdownItem>
                                        {item?.transaction_details?._id &&
                                        <>
                                        <DropdownItem>
                                          <Button
                                            onClick={(e) => {
                                              setSetId(item?.transaction_details?._id?[{ id: item?.transaction_details?._id }]:[]);
                                              changeStatus(item?.transaction_details?.is_referred_paid?false:true,item?.transaction_details?._id?[{ id: item?.transaction_details?._id }]:[])
                                              
                                            }}
                                            className="p-0 bg-transparent border-0 text-warning"
                                          >
                                            <i className="ri-exchange-line fs-16"></i>{" "}
                                            Change Payment Status
                                          </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <Button
                                            onClick={(e) => {
                                              setModal(true);
                                              setSetId(item?.transaction_details?._id?[{ id: item?.transaction_details?._id }]:[]);
                                            }}
                                            className="p-0 bg-transparent border-0 text-success"
                                          >
                                            <i className="ri-add-fill fs-16"></i>{" "}
                                            Add Document
                                          </Button>
                                        </DropdownItem>
                                        </>
                                        }
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  
                                  </td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isModifyReferralDocumentSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isModifyReferralDocumentFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
        {isModifyReferralPaidStatusSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isModifyReferralPaidStatusFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}

        <Modal
        className="zoomIn"
        id="addDocument"
        size="lg"
        isOpen={modal}
        toggle={toggle}
        centered
      >
        <ModalHeader toggle={toggle}>Add Document</ModalHeader>
        <div className="modal-content border-0 mt-3"></div>
        <ModalBody>
          <TabContent activeTab="2">
            <TabPane tabId="2">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        for="confirmationnumberInput"
                        className="form-label"
                      >
                        Referral note
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="confirmationnumberInput"
                        onChange={validation.handleChange}
                    
                        placeholder="Enter Referral note"
                        name="referred_note"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label for="fileInput" className="form-label">
                        File
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="fileInput"
                        onChange={onFileChange}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                 
                      <Button
                        color="success"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Create{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Submit</span>
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
      </div>
    </>
  );
};

export default ReferralList;
