import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { 
  FORGET_PASSWORD, 
  FORGET_PASSWORD_OTP,
  FORGET_PASSWORD_CHANGE,
} from "./actionTypes";
import { 
  userForgetPasswordSuccess,
  userForgetPasswordError,

  userForgetPasswordSuccessOTP,
  userForgetPasswordErrorOTP,
  
  userForgetPasswordSuccessChange,
  userForgetPasswordErrorChange,
} from "./actions";

//Include Both Helper File with needed methods
import { 
  getFirebaseBackend, 
} from "../../../helpers/firebase_helper";
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  forgetPasswordOTP,
  forgetPasswordChange,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, user, {
        email: user.email,
      });
      if (response) {
        yield put(userForgetPasswordSuccess(response));
      }
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeForgetPwd, user);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}



function* onOtpVerify({ payload: data }) {
  try {
    const response = yield call(forgetPasswordOTP, data)
    yield put(userForgetPasswordSuccessOTP(response))
  } catch (error) {
    yield put(userForgetPasswordErrorOTP(error))
  }
}

export function* watchOtpVerify() {
  yield takeEvery(FORGET_PASSWORD_OTP, onOtpVerify);
}



function* onForgetPasswordChange({ payload: data }) {
  try {
    const response = yield call(forgetPasswordChange, data)
    yield put(userForgetPasswordSuccessChange(response))
  } catch (error) {
    yield put(userForgetPasswordErrorChange(error))
  }
}

export function* watchForgetPasswordChange() {
  yield takeEvery(FORGET_PASSWORD_CHANGE, onForgetPasswordChange);
}




function* forgetPasswordSaga() {
  yield all(
    [
      fork(watchUserPasswordForget),
      fork(watchOtpVerify),
      fork(watchForgetPasswordChange),
    ]
  );
}

export default forgetPasswordSaga;
