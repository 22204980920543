import {
  GET_REFERRAL_CODE,
  GET_REFERRAL_CODE_SUCCESS,
  GET_REFERRAL_CODE_FAIL,

  STATUS_CHANGE_REFERRAL_CODE,
  STATUS_CHANGE_REFERRAL_CODE_SUCCESS,
  STATUS_CHANGE_REFERRAL_CODE_FAIL,

  MODIFY_REFERRAL_CODE_FLAG,
  RESET_REFERRAL_CODE_FLAG
  
} from "./actionTypes";

//List
export const getReferralCode = data => ({
  type: GET_REFERRAL_CODE,
  payload: data,
});

export const getReferralCodeSuccess = data => ({
  type: GET_REFERRAL_CODE_SUCCESS,
  payload: data,
});

export const getReferralCodeFail = error => ({
  type: GET_REFERRAL_CODE_FAIL,
  payload: error,
});



//Status Change
export const statusChangeReferralCode = data => ({
  type: STATUS_CHANGE_REFERRAL_CODE,
  payload: data,
});

export const statusChangeReferralCodeSuccess = data => ({
  type: STATUS_CHANGE_REFERRAL_CODE_SUCCESS,
  payload: data,
});

export const statusChangeReferralCodeFail = error => ({
  type: STATUS_CHANGE_REFERRAL_CODE_FAIL,
  payload: error,
});




//Modify Flag for toaster
export const modifyReferralCodeFlag = () => {
  return {
    type: MODIFY_REFERRAL_CODE_FLAG,
  }
}

export const resetReferralCodeFlag = () => {
  return {
    type: RESET_REFERRAL_CODE_FLAG,
  }
}