import {
    GET_TICKETS_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,


    GET_TICKET_DETAILS_SUCCESS,
    GET_TICKET_DETAILS_FAIL,


    GET_TLC_TICKET_USER_STATUS_SUCCESS,
    GET_TLC_TICKET_USER_STATUS_FAIL,


    GET_TLC_TICKET_ADMIN_STATUS_SUCCESS,
    GET_TLC_TICKET_ADMIN_STATUS_FAIL,


    GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_SUCCESS,
    GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_FAIL,


    GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_SUCCESS,
    GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_FAIL,

    GET_TICKET_ASSIGN_DETAILS_SUCCESS,
    GET_TICKET_ASSIGN_DETAILS_FAIL,

    GET_TICKET_SIGNATURE_DETAILS_SUCCESS,
    GET_TICKET_SIGNATURE_DETAILS_FAIL,

    UPDATE_ASSIGN_TICKET_SUCCESS,
    UPDATE_ASSIGN_TICKET_FAIL,

    GET_DMV_TICKETS_SUCCESS,
    GET_DMV_TICKETS_FAIL,

    ADD_TICKET_SUCCESS,
    RESET_TICKET_ASSIGN_DOCUMENTS_FLAG,
    ADD_TICKET_FAIL,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_FAIL,
    DELETE_TICKET_SUCCESS,
    DELETE_TICKET_FAIL,
    RESET_TICKET_FLAG,
} from "./actionType";

const INIT_STATE = {
    ticketsList: [],
    ticketsDmvList: [],
};

const Tickets = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            
            return {
                ...state,
                ticketsList: action.payload.data,
                totalDataCount: action.payload.count,
                
                isSuccess: true
            };

        case API_RESPONSE_ERROR:
         

            return {
                ...state,
                error: action.payload.message,
                totalDataCount: 0,
                
                isSuccess: false
            };


        case GET_DMV_TICKETS_SUCCESS:

            return {
                ...state,
                ticketsDmvList: action.payload.data,
                totalDataDmvCount: action.payload.count,
                isDmvSuccess: true
            };

        case GET_DMV_TICKETS_FAIL:
            
            return {
                ...state,
                error: action.payload.message,
                totalDataDmvCount: 0,
                isDmvSuccess: false
            };


        case GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_SUCCESS:
            return{
            ...state,
            userStatusAssignDocuments: action.payload.data,
            isUserStatusAssignDocumentsSuccess: true,
             }

        case GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_FAIL:
            return {
            ...state,
            error: action.payload.message,
            isUserStatusAssignDocumentsSuccess: false,
            };
       
        case GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_SUCCESS:
           return {
            ...state,
            adminStatusAssignDocuments: action.payload.data,
            isAdminStatusAssignDocumentsSuccess: true,
            };

        case GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_FAIL: 
           return {
            ...state,
            error: action.payload.message,
            isAdminStatusAssignDocumentsSuccess: false,
            };    


        case GET_TLC_TICKET_USER_STATUS_SUCCESS:
            return{
            ...state,
            userStatus: action.payload.data,
             }

        case GET_TLC_TICKET_USER_STATUS_FAIL:
            return {
            ...state,
            error: action.payload.message,
            };
        case GET_TLC_TICKET_ADMIN_STATUS_SUCCESS:
            return{
            ...state,
            adminStatus: action.payload.data,
             }

        case GET_TLC_TICKET_ADMIN_STATUS_FAIL:
            return {
            ...state,
            error: action.payload.message,
            };

        case GET_TICKET_DETAILS_SUCCESS:
            return{
            ...state,
            details: action.payload.data,
            }

        case GET_TICKET_DETAILS_FAIL:
            return {
            ...state,
            error: action.payload.message,
            };


        case GET_TICKET_SIGNATURE_DETAILS_SUCCESS:
            return{
            ...state,
            signatureDetails: action.payload.data,
            }

        case GET_TICKET_SIGNATURE_DETAILS_FAIL:
            return {
            ...state,
            error: action.payload.message,
            };

        
        case GET_TICKET_ASSIGN_DETAILS_SUCCESS:
            return{
            ...state,
            assignDetails: action.payload.data,
            }

        case GET_TICKET_ASSIGN_DETAILS_FAIL:
            return {
            ...state,
            error: action.payload.message,
            };

        
            

        case UPDATE_ASSIGN_TICKET_SUCCESS:
            return {
                ...state,
                ticketsList: state.ticketsList.map(ticket =>
                ticket._id === action.payload?.data?._id
                    ? { ...ticket, ...action.payload.data }
                    : ticket
                ),
                success: action.payload.message,
                isAssignTicketUpdate: true,
                isAssignTicketUpdateFail: false
            };
            
        
        case UPDATE_ASSIGN_TICKET_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isAssignTicketUpdate: false,
                isAssignTicketUpdateFail: true,
            };







            
        case ADD_TICKET_SUCCESS:
            return {
                ...state,
                isTicketCreated: true,
                ticketsList: [...state.ticketsList, action.payload.data],
                isTicketAdd: true,
                isTicketAddFail: false
            };

        case ADD_TICKET_FAIL:
            return {
                ...state,
                error: action.payload,
                isTicketAdd: false,
                isTicketAddFail: true
            };

        case UPDATE_TICKET_SUCCESS:
            return {
                ...state,
                
                success:action.payload.message,
                isTicketUpdate: true,
                isTicketUpdateFail: false
            };

        case UPDATE_TICKET_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isTicketUpdate: false,
                isTicketUpdateFail: true
            };

        case DELETE_TICKET_SUCCESS:
            return {
                ...state,
                ticketsList: state.ticketsList.filter(
                    ticket => ticket._id.toString() !== action.payload.ticket.toString()
                ),
                isTicketDelete: true,
                isTicketDeleteFail: false
            };

        case DELETE_TICKET_FAIL:
            return {
                ...state,
                error: action.payload,
                isTicketDelete: false,
                isTicketDeleteFail: true
            };

        case RESET_TICKET_ASSIGN_DOCUMENTS_FLAG:
            return {
                ...state,
                isAdminStatusAssignDocumentsSuccess: false,
                isUserStatusAssignDocumentsSuccess: false,
                error: false
            };
        case RESET_TICKET_FLAG:
            return {
                ...state,
                isAssignTicketUpdate : false,
                isSuccess: false,
                isDmvSuccess: false,
                isAssignTicketUpdateFail: false,
                isTicketAdd: false,
                isTicketAddFail: false,
                isTicketDelete: false,
                isTicketDeleteFail: false,
                isTicketUpdate: false,
                isTicketUpdateFail: false,
                error: false
            };

        default:
            return { ...state };
    }
};

export default Tickets;
