/* ROLES */
export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAIL = "GET_STATES_FAIL";


export const UPDATE_STATE = "UPDATE_STATE";
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
export const UPDATE_STATE_FAIL = "UPDATE_STATE_FAIL";




export const GET_STATE_DETAILS = "GET_STATE_DETAILS";
export const GET_STATE_DETAILS_SUCCESS = "GET_STATE_DETAILS_SUCCESS";
export const GET_STATE_DETAILS_FAIL = "GET_STATE_DETAILS_FAIL";

export const RESET_STATE_FLAG = "RESET_STATE_FLAG";
export const MODIFY_STATE_FLAG = "MODIFY_STATE_FLAG";




