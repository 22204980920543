import {
  GET_DRIVERS,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAIL,
  ADD_NEW_DRIVER,
  ADD_NEW_DRIVER_SUCCESS,
  ADD_NEW_DRIVER_FAIL,
  GET_DRIVER_DETAILS,
  GET_DRIVER_DETAILS_SUCCESS,
  GET_DRIVER_DETAILS_FAIL,
  UPDATE_DRIVER,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAIL,
  DELETE_DRIVER,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAIL,
  RESET_DRIVER_FLAG,
  GET_CITY_LIST,
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_FAIL,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,
  MODIFY_DRIVER_FLAG

} from "./actionTypes";


export const getDriver = data => ({
  type: GET_DRIVERS,
  payload: data,
});

export const getDriverSuccess = data => ({
  type: GET_DRIVERS_SUCCESS,
  payload: data,
});

export const getDriverFail = error => ({
  type: GET_DRIVERS_FAIL,
  payload: error,
});

export const addNewDriver = driver => ({
  type: ADD_NEW_DRIVER,
  payload: driver,
});

export const addNewDriverSuccess = driver => ({
  type: ADD_NEW_DRIVER_SUCCESS,
  payload: driver,
});

export const addNewDriverFail = error => ({
  type: ADD_NEW_DRIVER_FAIL,
  payload: error,
});

export const updateDriver = driver => ({
  type: UPDATE_DRIVER,
  payload: driver,
});

export const updateDriverSuccess = driver => ({
  type: UPDATE_DRIVER_SUCCESS,
  payload: driver,
});

export const updateDriverFail = error => ({
  type: UPDATE_DRIVER_FAIL,
  payload: error,
});

export const deleteDriver = driver => ({
  type: DELETE_DRIVER,
  payload: driver,
});

export const deleteDriverSuccess = driver => ({
  type: DELETE_DRIVER_SUCCESS,
  payload: driver,
});

export const deleteDriverFail = error => ({
  type: DELETE_DRIVER_FAIL,
  payload: error,
});

export const getDriverDetails = driver => ({
  type: GET_DRIVER_DETAILS,
  payload: driver,
});

export const getDriverDetailsSuccess = role => ({
  type: GET_DRIVER_DETAILS_SUCCESS,
  payload: role,
});

export const getDriverDetailsFail = error => ({
  type: GET_DRIVER_DETAILS_FAIL,
  payload: error,
});



export const getCityList = () => ({
  type: GET_CITY_LIST
});

export const getCityListSuccess = role => ({
  type: GET_CITY_LIST_SUCCESS,
  payload: role,
});

export const getCityListFail = error => ({
  type: GET_CITY_LIST_FAIL,
  payload: error,
});


export const getCountryList = () => ({
  type: GET_COUNTRY_LIST
});

export const getCountryListSuccess = role => ({
  type: GET_COUNTRY_LIST_SUCCESS,
  payload: role,
});

export const getCountryListFail = error => ({
  type: GET_COUNTRY_LIST_FAIL,
  payload: error,
});

export const modifyDriverFlag = () => {
  return {
    type: MODIFY_DRIVER_FLAG,
  }
}
export const resetDriverFlag = () => {
  return {
    type: RESET_DRIVER_FLAG,
  }
}
