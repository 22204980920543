import React from "react";
import { Redirect } from "react-router-dom";



//Calendar


//Jobs
import JobList from "../pages/Jobs/JobList";
import JobCreate from "../pages/Jobs/JobCreate";
import JobEdit from "../pages/Jobs/JobEdit";
import JobApplyList from "../pages/Jobs/JobApplyList";


// Project
import ProjectList from "../pages/Projects/ProjectList";
import ProjectOverview from "../pages/Projects/ProjectOverview";
import CreateProject from "../pages/Projects/CreateProject";


//Transactions
import TransactionList from "../pages/Transactions/TransactionList";
import PaymentTicketList from "../pages/Transactions/PaymentTicketList";
import PaymentTicketListTlc from "../pages/Transactions/PaymentTicketListTlc";
import TransactionDetails from "../pages/Transactions/TransactionDetails";
// Role
import RoleList from "../pages/Roles/RoleList";
import RoleCreate from "../pages/Roles/RoleCreate";

//SEO
import SeoList from "../pages/Seos/SeoList";
import SeoCreate from "../pages/Seos/SeoCreate";
import SeoEdit from "../pages/Seos/SeoEdit";

// Vehicle
import VehicleList from "../pages/Vehicles/VehicleList";
import VehicleCreate from "../pages/Vehicles/VehicleCreate";
import VehicleEdit from "../pages/Vehicles/VehicleEdit";

// Banner
import BannerList from "../pages/Banners/BannerList";
import BannerCreate from "../pages/Banners/BannerCreate";
import BannerEdit from "../pages/Banners/BannerEdit";

// Testimonial
import TestimonialList from "../pages/Testimonials/TestimonialList";
import TestimonialCreate from "../pages/Testimonials/TestimonialCreate";
import TestimonialEdit from "../pages/Testimonials/TestimonialEdit";

// Solution
import SolutionList from "../pages/Solutions/SolutionList";
import SolutionCreate from "../pages/Solutions/SolutionCreate";
import SolutionEdit from "../pages/Solutions/SolutionEdit";

// Whyus
import WhyusList from "../pages/Whyus/WhyusList";
import WhyusCreate from "../pages/Whyus/WhyusCreate";
import WhyusEdit from "../pages/Whyus/WhyusEdit";

// Step
import StepList from "../pages/Steps/StepList";
import StepCreate from "../pages/Steps/StepCreate";
import StepEdit from "../pages/Steps/StepEdit";


// Billing
import BillingList from "../pages/Billings/BillingList";
import BillingCreate from "../pages/Billings/BillingCreate";
import BillingEdit from "../pages/Billings/BillingEdit";


// Client
import ClientList from "../pages/Clients/ClientList";
import ClientCreate from "../pages/Clients/ClientCreate";
import ClientEdit from "../pages/Clients/ClientEdit";

// Page
import PageList from "../pages/MyPages/PageList";
import PageCreate from "../pages/MyPages/PageCreate";
import PageEdit from "../pages/MyPages/PageEdit";

// Sitesetting
import SitesettingEdit from "../pages/SiteSettings/SitesettingEdit";

//Task
import TaskDetails from "../pages/Tasks/TaskDetails";
import TaskList from "../pages/Tasks/TaskList";

// Ticket
import TicketList from "../pages/Tickets/TicketList";
import TicketEdit from "../pages/Tickets/TicketEdit";
import TicketEditDmv from "../pages/Tickets/TicketEditDmv";
import TicketAddDmv from "../pages/Tickets/TicketAddDmv";
import TicketDmvList from "../pages/Tickets/TicketListDmv";
import TicketDetails from "../pages/Tickets/TicketDetails";
import AssignTicket from "../pages/Tickets/AssignTicket";
import AddTicket from "../pages/Tickets/TicketAdd";



// State
import StateList from "../pages/States/StateList";
import StateEdit from "../pages/States/StateEdit";
// Driver
import DriverList from "../pages/Drivers/DriverList";
import DriverCreate from "../pages/Drivers/DriverCreate";
import DriverEdit from "../pages/Drivers/DriverEdit";

 // Mission Vision
 import MissionvisionList from "../pages/MissionVision/MissionvisionList";
 import MissionvisionCreate from "../pages/MissionVision/MissionvisionCreate";
 import MissionvisionEdit from "../pages/MissionVision/MissionvisionEdit";



//Crm Pages
import CrmCompanies from "../pages/Crm/CrmCompanies";
import CrmContacts from "../pages/Crm/CrmContacts";
import CrmDeals from "../pages/Crm/CrmDeals/index";
import CrmLeads from "../pages/Crm/CrmLeads/index";

//Invoices
import InvoiceList from "../pages/Invoices/InvoiceList";
import InvoiceCreate from "../pages/Invoices/InvoiceCreate";
import InvoiceDetails from "../pages/Invoices/InvoiceDetails";

// Support Tickets
import ListView from '../pages/SupportTickets/ListView';
import TicketsDetails from '../pages/SupportTickets/TicketsDetails';



// Base Ui
import UiAlerts from "../pages/BaseUi/UiAlerts/UiAlerts";
import UiBadges from "../pages/BaseUi/UiBadges/UiBadges";
import UiButtons from "../pages/BaseUi/UiButtons/UiButtons";
import UiColors from "../pages/BaseUi/UiColors/UiColors";
import UiCards from "../pages/BaseUi/UiCards/UiCards";
import UiCarousel from "../pages/BaseUi/UiCarousel/UiCarousel";
import UiDropdowns from "../pages/BaseUi/UiDropdowns/UiDropdowns";
import UiGrid from "../pages/BaseUi/UiGrid/UiGrid";
import UiImages from "../pages/BaseUi/UiImages/UiImages";
import UiTabs from "../pages/BaseUi/UiTabs/UiTabs";
import UiAccordions from "../pages/BaseUi/UiAccordion&Collapse/UiAccordion&Collapse";
import UiModals from "../pages/BaseUi/UiModals/UiModals";
import UiOffcanvas from "../pages/BaseUi/UiOffcanvas/UiOffcanvas";
import UiPlaceholders from "../pages/BaseUi/UiPlaceholders/UiPlaceholders";
import UiProgress from "../pages/BaseUi/UiProgress/UiProgress";
import UiNotifications from "../pages/BaseUi/UiNotifications/UiNotifications";
import UiMediaobject from "../pages/BaseUi/UiMediaobject/UiMediaobject";
import UiEmbedVideo from "../pages/BaseUi/UiEmbedVideo/UiEmbedVideo";
import UiTypography from "../pages/BaseUi/UiTypography/UiTypography";
import UiList from "../pages/BaseUi/UiLists/UiLists";
import UiGeneral from "../pages/BaseUi/UiGeneral/UiGeneral";
import UiRibbons from "../pages/BaseUi/UiRibbons/UiRibbons";
import UiUtilities from "../pages/BaseUi/UiUtilities/UiUtilities";

// Advance Ui
import UiScrollbar from "../pages/AdvanceUi/UiScrollbar/UiScrollbar";
import UiAnimation from "../pages/AdvanceUi/UiAnimation/UiAnimation";
import UiTour from "../pages/AdvanceUi/UiTour/UiTour";
import UiHighlight from "../pages/AdvanceUi/UiHighlight/UiHighlight";

// Widgets
import Widgets from '../pages/Widgets/Index';


//Tables
import BasicTables from '../pages/Tables/BasicTables/BasicTables';
import ListTables from '../pages/Tables/ListTables/ListTables';

//AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn';
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset';
//pages
import Starter from '../pages/Pages/Starter/Starter';
import Settings from '../pages/Pages/Profile/Settings/Settings';
import Faqs from '../pages/Pages/Faqs/Faqs';
import Pricing from '../pages/Pages/Pricing/Pricing';
import Gallery from '../pages/Pages/Gallery/Gallery';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import SiteMap from '../pages/Pages/SiteMap/SiteMap';

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset';
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr';
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr';
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout';
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout';
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";



//Charts
import LineCharts from "../pages/Charts/ApexCharts/LineCharts";
import AreaCharts from "../pages/Charts/ApexCharts/AreaCharts";
import ColumnCharts from "../pages/Charts/ApexCharts/ColumnCharts";
import BarCharts from "../pages/Charts/ApexCharts/BarCharts";
import MixedCharts from "../pages/Charts/ApexCharts/MixedCharts";
import TimelineCharts from "../pages/Charts/ApexCharts/TimelineCharts";
import CandlestickChart from "../pages/Charts/ApexCharts/CandlestickChart";
import BoxplotCharts from "../pages/Charts/ApexCharts/BoxplotCharts";
import BubbleChart from "../pages/Charts/ApexCharts/BubbleChart";
import ScatterCharts from "../pages/Charts/ApexCharts/ScatterCharts";
import HeatmapCharts from "../pages/Charts/ApexCharts/HeatmapCharts";
import TreemapCharts from "../pages/Charts/ApexCharts/TreemapCharts";
import PieCharts from "../pages/Charts/ApexCharts/PieCharts";
import RadialbarCharts from "../pages/Charts/ApexCharts/RadialbarCharts";
import RadarCharts from "../pages/Charts/ApexCharts/RadarCharts";
import PolarCharts from "../pages/Charts/ApexCharts/PolarCharts";

import ChartsJs from "../pages/Charts/ChartsJs/index";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import RoleEdit from "../pages/Roles/RoleEdit";

// User
import UserList from "../pages/Users/UserList";
import Dashboard from "../pages/Dashboard/DashboardList";
import UserCreate from "../pages/Users/UserCreate";
import UserEdit from "../pages/Users/UserEdit";
import UserChangePassword from "../pages/Users/UserChangePassword";
import AdminList from "../pages/Users/AdminList";
import ManagerList from "../pages/Users/ManagerList";
import RegionalManagerList from "../pages/Users/RegionalManagerList";
import ManagerCreate from "../pages/Users/ManagerCreate";
import ManagerEdit from "../pages/Users/ManagerEdit";
import AdminCreate from "../pages/Users/AdminCreate";
import AdminEdit from "../pages/Users/AdminEdit";

//Referral
import ReferralList from "../pages/Referral/ReferralList";
import ReferralDetails from "../pages/Referral/ReferralDetails";



//Referral Code
import ReferralCodeList from "../pages/ReferralCode/ReferralCodeList";


// Coupons
import CouponList from "../pages/Coupons/CouponList";
import CouponCreate from "../pages/Coupons/CouponCreate";
import CouponEdit from "../pages/Coupons/CouponEdit";

// Notification
import NotificationList from "../pages/Notification/NotificationList";
import NotificationHistoryList from "../pages/Notification/NotificationHistoryList";
// import StepCreate from "../pages/Steps/StepCreate";
// import StepEdit from "../pages/Steps/StepEdit";
const role=JSON.parse(sessionStorage.getItem("authUser"))?JSON.parse(sessionStorage.getItem("authUser")).data.role_details.code:null;
const visibleAllArray=['admin']
const visibleManagerAdmin=['managers','admin']
const authProtectedRoutes = [
 
  { path: "/dashboard", component: Dashboard  ,visible: visibleAllArray.includes(role)},   

  //Projects
  { path: "/apps-projects-list", component: ProjectList  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-projects-overview", component: ProjectOverview  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-projects-create", component: CreateProject  ,visible: visibleAllArray.includes(role)},

  //Roles
  { path: "/roles", component: RoleList  ,visible: visibleAllArray.includes(role)},
  { path: "/role/create", component: RoleCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/role/edit/:id", component: RoleEdit  ,visible: visibleAllArray.includes(role)},
 // SEO

 { path: "/seos", component: SeoList  ,visible: visibleAllArray.includes(role)},
 { path: "/seo/create", component: SeoCreate  ,visible: visibleAllArray.includes(role)},
 { path: "/seo/edit/:id", component: SeoEdit  ,visible: visibleAllArray.includes(role)},
 { path: "/seo/:type/:id", component: SeoEdit  ,visible: visibleAllArray.includes(role)},
  //Vehicles
  { path: "/vehicles", component: VehicleList ,visible: visibleAllArray.includes(role)},
  { path: "/vehicle/create", component: VehicleCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/vehicle/edit/:id", component: VehicleEdit ,visible: visibleAllArray.includes(role)},

  //Banner
  { path: "/banners", component: BannerList ,visible: visibleAllArray.includes(role)},
  { path: "/banner/create", component: BannerCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/banner/edit/:id", component: BannerEdit ,visible: visibleAllArray.includes(role)},

  //Testimonial
  { path: "/testimonials", component: TestimonialList ,visible: visibleAllArray.includes(role)},
  { path: "/testimonial/create", component: TestimonialCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/testimonial/edit/:id", component: TestimonialEdit ,visible: visibleAllArray.includes(role)},

  
  //Solution
  { path: "/solutions", component: SolutionList ,visible: visibleAllArray.includes(role)},
  { path: "/solution/create", component: SolutionCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/solution/edit/:id", component: SolutionEdit ,visible: visibleAllArray.includes(role)},


  // Jobs

  { path: "/jobs", component: JobList  ,visible: visibleAllArray.includes(role)},
  { path: "/job/create", component: JobCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/job/edit/:id", component: JobEdit  ,visible: visibleAllArray.includes(role)},
  { path: "/jobapplies/:id", component: JobApplyList  ,visible: visibleAllArray.includes(role)},
  
  //Whyus
  { path: "/whyus", component: WhyusList ,visible: visibleAllArray.includes(role)},
  { path: "/whyus/create", component: WhyusCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/whyus/edit/:id", component: WhyusEdit ,visible: visibleAllArray.includes(role)},

  //Steps
  { path: "/steps", component: StepList ,visible: visibleAllArray.includes(role)},
  { path: "/step/create", component: StepCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/step/edit/:id", component: StepEdit ,visible: visibleAllArray.includes(role)},


  //Biling
  { path: "/billings", component: BillingList ,visible: visibleAllArray.includes(role)},
  { path: "/billing/create", component: BillingCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/billing/edit/:id", component: BillingEdit ,visible: visibleAllArray.includes(role)},

  //Client
  { path: "/clients", component: ClientList ,visible: visibleAllArray.includes(role)},
  { path: "/client/create", component: ClientCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/client/edit/:id", component: ClientEdit ,visible: visibleAllArray.includes(role)},

  //Pages
  { path: "/pages", component: PageList ,visible: visibleAllArray.includes(role)},
  { path: "/page/create", component: PageCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/page/edit/:id", component: PageEdit ,visible: visibleAllArray.includes(role)},

  // Settings
  { path: "/settings", component: SitesettingEdit ,visible: visibleAllArray.includes(role)},
  // { path: "/sitesetting/edit/:id", component: SitesettingEdit ,visible: visibleAllArray.includes(role)},

  // Tickets
  { path: "/tickets", component: TicketList ,visible: visibleAllArray.includes(role)},
  { path: "/tickets/add", component: AddTicket ,visible: visibleAllArray.includes(role)},
  { path: "/tickets/edit/:id", component: TicketEdit ,visible: visibleAllArray.includes(role)},
  { path: "/tickets/add-dmv", component: TicketAddDmv ,visible: visibleAllArray.includes(role)},
  { path: "/tickets/edit-dmv/:id", component: TicketEditDmv ,visible: visibleAllArray.includes(role)},
  { path: "/dmv-tickets", component: TicketDmvList ,visible: visibleAllArray.includes(role)},
  { path: "/tickets/details/:id", component: TicketDetails ,visible: visibleAllArray.includes(role)},
  { path: "/tickets/assign-ticket/:id", component: AssignTicket ,visible: visibleManagerAdmin.includes(role)},
 
   
  //Driver
  { path: "/drivers", component: DriverList ,visible: visibleAllArray.includes(role)},
  { path: "/driver/create", component: DriverCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/driver/edit/:id", component: DriverEdit ,visible: visibleAllArray.includes(role)},

  //Mission Vision
  { path: "/mission-visions", component: MissionvisionList  ,visible: visibleAllArray.includes(role)},
  { path: "/mission-vision/create", component: MissionvisionCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/mission-vision/edit/:id", component: MissionvisionEdit  ,visible: visibleAllArray.includes(role)},
  
  //Task
  { path: "/apps-tasks-list-view", component: TaskList  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-tasks-details", component: TaskDetails  ,visible: visibleAllArray.includes(role)},
  //Crm
  { path: "/apps-crm-contacts", component: CrmContacts  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-crm-companies", component: CrmCompanies  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-crm-deals", component: CrmDeals  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-crm-leads", component: CrmLeads  ,visible: visibleAllArray.includes(role)},

  //Invoices
  { path: "/apps-invoices-list", component: InvoiceList  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-invoices-details", component: InvoiceDetails  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-invoices-create", component: InvoiceCreate  ,visible: visibleAllArray.includes(role)},

  //Supports Tickets
  { path: "/apps-tickets-list", component: ListView  ,visible: visibleAllArray.includes(role)},
  { path: "/apps-tickets-details", component: TicketsDetails  ,visible: visibleAllArray.includes(role)},

  //charts
  { path: "/charts-apex-line", component: LineCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-area", component: AreaCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-column", component: ColumnCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-bar", component: BarCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-mixed", component: MixedCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-timeline", component: TimelineCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-candlestick", component: CandlestickChart  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-boxplot", component: BoxplotCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-bubble", component: BubbleChart  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-scatter", component: ScatterCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-heatmap", component: HeatmapCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-treemap", component: TreemapCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-pie", component: PieCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-radialbar", component: RadialbarCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-radar", component: RadarCharts  ,visible: visibleAllArray.includes(role)},
  { path: "/charts-apex-polar", component: PolarCharts  ,visible: visibleAllArray.includes(role)},

  { path: "/charts-chartjs", component: ChartsJs  ,visible: visibleAllArray.includes(role)},


  // Base Ui
  { path: "/ui-alerts", component: UiAlerts  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-badges", component: UiBadges  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-buttons", component: UiButtons  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-colors", component: UiColors  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-cards", component: UiCards  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-carousel", component: UiCarousel  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-dropdowns", component: UiDropdowns  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-grid", component: UiGrid  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-images", component: UiImages  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-tabs", component: UiTabs  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-accordions", component: UiAccordions  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-modals", component: UiModals  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-offcanvas", component: UiOffcanvas  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-placeholders", component: UiPlaceholders  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-progress", component: UiProgress  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-notifications", component: UiNotifications  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-media", component: UiMediaobject  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-embed-video", component: UiEmbedVideo  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-typography", component: UiTypography  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-lists", component: UiList  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-general", component: UiGeneral  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-ribbons", component: UiRibbons  ,visible: visibleAllArray.includes(role)},
  { path: "/ui-utilities", component: UiUtilities  ,visible: visibleAllArray.includes(role)},

  // Advance Ui
  { path: "/advance-ui-scrollbar", component: UiScrollbar  ,visible: visibleAllArray.includes(role)},
  { path: "/advance-ui-animation", component: UiAnimation  ,visible: visibleAllArray.includes(role)},
  { path: "/advance-ui-tour", component: UiTour  ,visible: visibleAllArray.includes(role)},
  { path: "/advance-ui-highlight", component: UiHighlight  ,visible: visibleAllArray.includes(role)},

     // Transactions

     { path: "/transactions/payment-tickets", component: PaymentTicketList ,visible: visibleAllArray.includes(role)},

     { path: "/transactions/payment-tickets-tlc", component: PaymentTicketListTlc ,visible: visibleManagerAdmin.includes(role)},
     { path: "/transactions", component: TransactionList ,visible: visibleAllArray.includes(role)},
     { path: "/transaction/:id", component: TransactionDetails ,visible: visibleAllArray.includes(role)},

  // Widgets
  { path: "/widgets", component: Widgets  ,visible: visibleAllArray.includes(role)},

  

  //Tables
  { path: "/tables-basic", component: BasicTables  ,visible: visibleAllArray.includes(role)},
  { path: "/tables-listjs", component: ListTables  ,visible: visibleAllArray.includes(role)},

 

  
   //State
   { path: "/states", component: StateList ,visible: visibleAllArray.includes(role)},
   { path: "/state/edit/:id", component: StateEdit ,visible: visibleAllArray.includes(role)},
  // //Pages
  // { path: "/pages-starter", component: Starter  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-profile", component: SimplePage  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-profile-settings", component: Settings  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-team", component: Team  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-timeline", component: Timeline  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-faqs", component: Faqs  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-gallery", component: Gallery  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-pricing", component: Pricing  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-sitemap", component: SiteMap  ,visible: visibleAllArray.includes(role)},
  // { path: "/pages-search-results", component: SearchResults  ,visible: visibleAllArray.includes(role)},

  //User Profile
  { path: "/profile", component: UserProfile  ,visible: visibleManagerAdmin.includes(role)},


  //Users
  { path: "/users", component: UserList ,visible: visibleAllArray.includes(role)},
  { path: "/user/create", component: UserCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/user/edit/:id", component: UserEdit ,visible: visibleAllArray.includes(role)},
  { path: "/user/change-password/:id", component: UserChangePassword ,visible: visibleAllArray.includes(role)},
  { path: "/admins", component: AdminList ,visible: visibleAllArray.includes(role)},
  { path: "/managers", component: ManagerList ,visible: visibleAllArray.includes(role)},
  { path: "/regional-managers", component: RegionalManagerList ,visible: visibleAllArray.includes(role)},
  { path: "/manager/create", component: ManagerCreate ,visible: visibleAllArray.includes(role)},
  { path: "/manager/edit/:id", component: ManagerEdit ,visible: visibleAllArray.includes(role)},
  { path: "/admin/create", component: AdminCreate ,visible: visibleAllArray.includes(role)},
  { path: "/admin/edit/:id", component: AdminEdit ,visible: visibleAllArray.includes(role)},

  //Referral
  { path: "/referral/:id", component: ReferralList ,visible: visibleAllArray.includes(role)},
  { path: "/referral-details/:id/:referred_signup_id", component: ReferralDetails ,visible: visibleAllArray.includes(role)},

  //Referral Code
  { path: "/referral-code/", component: ReferralCodeList ,visible: visibleAllArray.includes(role)},



  //Coupons
  { path: "/coupons", component: CouponList ,visible: visibleAllArray.includes(role)},
  { path: "/coupon/create", component: CouponCreate  ,visible: visibleAllArray.includes(role)},
  { path: "/coupon/edit/:id", component: CouponEdit ,visible: visibleAllArray.includes(role)},



  // Notification
  { path: "/notifications", component: NotificationList ,visible: visibleAllArray.includes(role)},
  { path: "/notifications-history", component: NotificationHistoryList ,visible: visibleAllArray.includes(role)},
  // { path: "/step/create", component: StepCreate  ,visible: visibleAllArray.includes(role)},
  // { path: "/step/edit/:id", component: StepEdit ,visible: visibleAllArray.includes(role)},

  


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: BasicSignIn },
  { path: "/auth-signin-cover", component: CoverSignIn },
  { path: "/auth-signup-basic", component: BasicSignUp },
  { path: "/auth-signup-cover", component: CoverSignUp },
  { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: CoverLogout },
  { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  { path: "/auth-twostep-basic", component: BasicTwosVerify },
  { path: "/auth-twostep-cover", component: CoverTwosVerify },
  { path: "/auth-404-basic", component: Basic404 },
  { path: "/auth-404-cover", component: Cover404 },
  { path: "/auth-404-alt", component: Alt404 },
  { path: "/auth-500", component: Error500 },
  { path: "/pages-maintenance", component: Maintenance },
  { path: "/pages-coming-soon", component: ComingSoon },

  

  { path: "/auth-pass-change-basic", component: BasicPasswCreate },
  { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  { path: "/auth-offline", component: Offlinepage },

];

export { authProtectedRoutes, publicRoutes };