// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TICKETS_LIST = "GET_TICKETS_LIST";
// export const ADD_NEW_TICKETS_LIST = "ADD_NEW_TICKETS_LIST";
// export const UPDATE_TICKETS_LIST = "UPDATE_TICKETS_LIST";
// export const DELETE_TICKETS_LIST = "DELETE_TICKETS_LIST";




//ASSIGN TICKET
export const UPDATE_ASSIGN_TICKET = "UPDATE_ASSIGN_TICKET";
export const UPDATE_ASSIGN_TICKET_SUCCESS = "UPDATE_ASSIGN_TICKET_SUCCESS";
export const UPDATE_ASSIGN_TICKET_FAIL = "UPDATE_ASSIGN_TICKET_FAIL";

//DETAILS
export const GET_TICKET_ASSIGN_DETAILS = "GET_TICKET_ASSIGN_DETAILS";
export const GET_TICKET_ASSIGN_DETAILS_SUCCESS = "GET_TICKET_ASSIGN_DETAILS_SUCCESS";
export const GET_TICKET_ASSIGN_DETAILS_FAIL = "GET_TICKET_ASSIGN_DETAILS_FAIL";



//DETAILS
export const GET_TICKET_DETAILS = "GET_TICKET_DETAILS";
export const GET_TICKET_DETAILS_SUCCESS = "GET_TICKET_DETAILS_SUCCESS";
export const GET_TICKET_DETAILS_FAIL = "GET_TICKET_DETAILS_FAIL"

;
export const GET_DMV_TICKETS_LIST = "GET_DMV_TICKETS_LIST";
export const GET_DMV_TICKETS_SUCCESS = "GET_DMV_TICKETS_SUCCESS";
export const GET_DMV_TICKETS_FAIL = "GET_DMV_TICKETS_FAIL";
export const GET_DMV_TICKETS_SUCCESS_FAIL = "GET_DMV_TICKETS_SUCCESS_FAIL";

//Ticket Signature details
export const GET_TICKET_SIGNATURE_DETAILS = "GET_TICKET_SIGNATURE_DETAILS";
export const GET_TICKET_SIGNATURE_DETAILS_SUCCESS = "GET_TICKET_SIGNATURE_DETAILS_SUCCESS";
export const GET_TICKET_SIGNATURE_DETAILS_FAIL = "GET_TICKET_SIGNATURE_DETAILS_FAIL";
//Ticket Signature details
export const GET_TLC_TICKET_USER_STATUS = "GET_TLC_TICKET_USER_STATUS";
export const GET_TLC_TICKET_USER_STATUS_SUCCESS = "GET_TLC_TICKET_USER_STATUS_SUCCESS";
export const GET_TLC_TICKET_USER_STATUS_FAIL = "GET_TLC_TICKET_USER_STATUS_FAIL";
//Ticket Signature details
export const GET_TLC_TICKET_ADMIN_STATUS = "GET_TLC_TICKET_ADMIN_STATUS";
export const GET_TLC_TICKET_ADMIN_STATUS_SUCCESS = "GET_TLC_TICKET_ADMIN_STATUS_SUCCESS";
export const GET_TLC_TICKET_ADMIN_STATUS_FAIL = "GET_TLC_TICKET_ADMIN_STATUS_FAIL";
//Ticket Signature details
export const GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS = "GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS";
export const GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_SUCCESS = "GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_SUCCESS";
export const GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_FAIL = "GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_FAIL";
//Ticket Signature details
export const GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS = "GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS";
export const GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_SUCCESS = "GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_SUCCESS";
export const GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_FAIL = "GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_FAIL";

/// Add TICKET
export const ADD_NEW_TICKET = "ADD_NEW_TICKET";
export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS";
export const ADD_TICKET_FAIL = "ADD_TICKET_FAIL";


// Edit TICKET
export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL";


// Delete TICKET
export const DELETE_TICKET = "DELETE_TICKET";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAIL = "DELETE_TICKET_FAIL";

export const RESET_TICKET_FLAG = "RESET_TICKET_FLAG"
export const RESET_TICKET_ASSIGN_DOCUMENTS_FLAG = "RESET_TICKET_ASSIGN_DOCUMENTS_FLAG"

