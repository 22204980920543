import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_HISTORY,
 
} from "./actionTypes"

import {
  getNotificationsSuccess,
  getNotificationsFail,
  getNotificationsHistorySuccess,
  getNotificationsHistoryFail

} from "./actions"

//Include Both Helper File with needed methods
import {
  getNotificationList,

  getNotificationHistoryList
} from "../../helpers/fakebackend_helper"

//LIST
function* fetchNotifications({ payload: data }) {
  try {
    const response = yield call(getNotificationList, data)
    yield put(getNotificationsSuccess(response))
  } catch (error) {
    yield put(getNotificationsFail(error))
  }
}

export function* watchNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications);
}

//LIST
function* fetchNotificationsHistory({ payload: data }) {
  try {
    const response = yield call(getNotificationHistoryList, data)
    yield put(getNotificationsHistorySuccess(response))
  } catch (error) {
    yield put(getNotificationsHistoryFail(error))
  }
}

export function* watchNotificationsHistory() {
  yield takeEvery(GET_NOTIFICATIONS_HISTORY, fetchNotificationsHistory);
}




function* notificationSaga() {
  yield all(
    [
      fork(watchNotifications),
      fork(watchNotificationsHistory),
     
    ]
  );

}

export default notificationSaga
