import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_DRIVERS,
  ADD_NEW_DRIVER,
  GET_DRIVER_DETAILS,
  UPDATE_DRIVER,
  DELETE_DRIVER,
  GET_CITY_LIST,

  GET_COUNTRY_LIST,

} from "./actionTypes"

import {
  getDriverSuccess,
  getDriverFail,
  addNewDriverSuccess,
  addNewDriverFail,
  getDriverDetailsSuccess, 
  getDriverDetailsFail,
  updateDriverSuccess,
  updateDriverFail,
  deleteDriverSuccess,
  deleteDriverFail,
  getCityListSuccess,
  getCityListFail,
  getCountryListSuccess,
  getCountryListFail,

} from "./actions"

//Include Both Helper File with needed methods
import {
  getDriverList,
  addNewDriver,
  getDriverDetails,
  updateDriver,
  getCityList,
  getCountryList,
  deleteDriver
} from "../../helpers/fakebackend_helper"

function* fetchDrivers({ payload: driver }) {
  try {
    const response = yield call(getDriverList, driver)
    yield put(getDriverSuccess(response))
  } catch (error) {
    yield put(getDriverFail(error))
  }
}

export function* watchFetchDrivers() {
  yield takeEvery(GET_DRIVERS, fetchDrivers);
}

function* onAddNewDriver({ payload: driver }) {
  try {
      const response = yield call(addNewDriver, driver);
      if(response.status === 200){
        yield put(addNewDriverSuccess(response));
      } else {
        yield put(addNewDriverFail(response));
      }
  } catch (error) {
      yield put(addNewDriverFail(error));
  }
}

export function* watchAddNewDriver() {
  yield takeEvery(ADD_NEW_DRIVER, onAddNewDriver);
}

function* onDriverDetails({ payload: driver }) {
  try {
      const response = yield call(getDriverDetails, driver);
      if(response.status === 200){
        yield put(getDriverDetailsSuccess(response));
      } else {
        yield put(getDriverDetailsFail(response));
      }
  } catch (error) {
      yield put(getDriverDetailsFail(error));
  }
}

export function* watchDriverDetails() {
  yield takeEvery(GET_DRIVER_DETAILS, onDriverDetails);
}

function* onDriverUpdate({ payload: driver }) {
  try {
      const response = yield call(updateDriver, driver);
      if(response.status === 200){
        yield put(updateDriverSuccess(response));
      } else {
        yield put(updateDriverFail(response));
      }
  } catch (error) {
      yield put(updateDriverFail(error));
  }
}

export function* watchUpdateDriver() {
  yield takeEvery(UPDATE_DRIVER, onDriverUpdate);
}

function* onDeleteDriver({ payload: driver }) {
  try {
      const response = yield call(deleteDriver, driver);
      yield put(deleteDriverSuccess({ driver, ...response }));
  } catch (error) {
      yield put(deleteDriverFail(error));
  }
}
export function* watchDeleteDriver() {
  yield takeEvery(DELETE_DRIVER, onDeleteDriver);
}
// City
function* onCityList() {
  try {
      const response = yield call(getCityList);
      if(response.status === 200){
        yield put(getCityListSuccess(response));
      } else {
        yield put(getCityListFail(response));
      }
  } catch (error) {
      yield put(getCityListFail(error));
  }
}

export function* watchCityList() {
  yield takeEvery(GET_CITY_LIST, onCityList);
}
// Country
function* onCountryList() {
  try {
      const response = yield call(getCountryList);
      if(response.status === 200){
        yield put(getCountryListSuccess(response));
      } else {
        yield put(getCountryListFail(response));
      }
  } catch (error) {
      yield put(getCountryListFail(error));
  }
}

export function* watchCountryList() {
  yield takeEvery(GET_COUNTRY_LIST, onCountryList);
}

function* driverSaga() {
  yield all(
    [
      fork(watchFetchDrivers),
      fork(watchAddNewDriver),
      fork(watchDriverDetails),
      fork(watchUpdateDriver),
      fork(watchCityList),
      fork(watchCountryList),
      fork(watchDeleteDriver),
    ]
  );

}

export default driverSaga
