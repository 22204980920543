import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewBilling, resetBillingFlag, getUsers } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";

const BillingCreate = () => {
    document.title = "Create Invoice | Defendit - Admin Panel";
    const history = useHistory();
    const dispatch = useDispatch();
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const { isBillingAdd, isBillingAddFail, error, success, userList } = useSelector(state => ({
        userList: state.Users.users,
        isBillingAdd: state.Billings.isBillingAdd,
        isBillingAddFail: state.Billings.isBillingAddFail,
        error: state.Billings.error,
        success: state.Billings.success,
    }));

    const min = 2;
    const max = 15;
    useEffect(() => {
        setInvoiceNumber('INV'+Math.random().toString(36).slice(min, max))
    }, [min, max]);

    useEffect(() => {
        dispatch(getUsers({role:'62de277397c8633a839c6450'}))
      }, [dispatch]);

    const selectedUserOption = {
        // value: details && details.manager_details ? details.manager_details?._id : "",
        // label: details && details.manager_details ? details.manager_details?.name : "",
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            user_id: selectedUserOption,
            invoice_no: '',
            amount: '',
            note:'',
            payment_type: 'invoice',
        },
        validationSchema: Yup.object({
            amount: Yup.number().required("Please Enter Amount"),
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                user_id: values.user_id?.value,
                invoice_no: invoiceNumber,
                amount: values.amount,
                note: values.note,
                payment_type: 'invoice',
                payment_status : 'pending',
            }

            //console.log(data)
            dispatch(addNewBilling(data))
        },
    });

    useEffect(() => {
        if(isBillingAdd || isBillingAddFail){
            setIsCreating(false)
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetBillingFlag());
                history.push("/billings");
            }, 3000);
        }
      }, [dispatch, isBillingAdd, isBillingAddFail, validation]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Billing" pageTitle="Billing" pageUrl="/billings" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Create Billing" />
                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">


                                                <Col xxl={6} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                        className="form-label"
                                                        htmlFor="product-title-input"
                                                        >
                                                        User
                                                        </Label>
                                                        <Select
                                                        type="text"
                                                        name="user_id"
                                                        onChange={(selectedOption) => {
                                                            let event = {
                                                            target: { name: "user_id", value: selectedOption },
                                                            };
                                                            validation.handleChange(event);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        options={
                                                            userList &&
                                                            userList?.map((item, index) => ({
                                                            label: item.name,
                                                            value: item._id,
                                                            }))
                                                        }
                                                        value={validation.values.user_id || ""}
                                                        />
                                                        {validation.touched.user_id && validation.errors.user_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.user_id}
                                                        </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Amount</Label>
                                                        <Input
                                                            type="number"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Enter Amount"
                                                            name='amount'
                                                            value={validation.values.amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.amount && validation.errors.amount ? true : false
                                                            }
                                                        />
                                                        {validation.touched.amount && validation.errors.amount ? (
                                                            <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={12}>
                                                    <div>
                                                        <Label
                                                        htmlFor="placeholderInput"
                                                        className="form-label"
                                                        >
                                                        Note
                                                        </Label>
                                                        <Input
                                                        type="textarea"
                                                        id="placeholderInput"
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Enter Note"
                                                        name="note"
                                                        value={validation.values.note || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        invalid={
                                                            validation.touched.note &&
                                                            validation.errors.description
                                                            ? true
                                                            : false
                                                        }
                                                        />
                                                        {validation.touched.note &&
                                                        validation.errors.note ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.note}
                                                        </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/billings" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isBillingAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isBillingAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default BillingCreate;