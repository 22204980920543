import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_USERS,
  ADD_NEW_USER,
  GET_USER_DETAILS,
  UPDATE_USER,
  DELETE_USER,
  STATUS_CHANGE_USER,
  PASSWORD_CHANGE_USER,
  EMAIL_VERIFIED_CHANGE_USER,
  PHONE_VERIFIED_CHANGE_USER
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,

  addNewUserSuccess,
  addNewUserFail,


  getUserDetailsSuccess,
  getUserDetailsFail,

  updateUserSuccess,
  updateUserFail,

  
  deleteUserSuccess,
  deleteUserFail,
 

  statusChangeUserSuccess,
  statusChangeUserFail,

  emailVerifiedChangeUserSuccess,
  emailVerifiedChangeUserFail,

  phoneVerifiedChangeUserSuccess,
  phoneVerifiedChangeUserFail,

  passwordChangeUserSuccess,
  passwordChangeUserFail,

} from "./actions"

//Include Both Helper File with needed methods
import {
  getUserList,
  addNewUser,
  updateUser,
  getUserDetails,
  deleteUser,
  statusChangeUser,
  passwordChangeUser,
  verifiedPhoneEmail
} from "../../helpers/fakebackend_helper"

//List
function* fetchUsers({ payload: user }) {
  try {
    const response = yield call(getUserList, user)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

export function* watchFetchUsers() {
  yield takeEvery(GET_USERS, fetchUsers);
}

// Create
function* onAddNewUser({ payload: user }) {
  try {
      const response = yield call(addNewUser, user);
      if(response.status === 200){
        yield put(addNewUserSuccess(response));
      } else {
        yield put(addNewUserFail(response));
      }
  } catch (error) {
      yield put(addNewUserFail(error));
  }
}

export function* watchAddNewUser() {
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
}

//Details
function* onUserDetails({ payload: user }) {
  try {
      const response = yield call(getUserDetails, user);
      if(response.status === 200){
        yield put(getUserDetailsSuccess(response));
      } else {
        yield put(getUserDetailsFail(response));
      }
  } catch (error) {
      yield put(getUserDetailsFail(error));
  }
}

export function* watchUserDetails() {
  yield takeEvery(GET_USER_DETAILS, onUserDetails);
}

//Update
function* onUserUpdate({ payload: user }) {
  try {
      const response = yield call(updateUser, user);
      if(response.status === 200){
        yield put(updateUserSuccess(response));
      } else {
        yield put(updateUserFail(response));
      }
  } catch (error) {
      yield put(updateUserFail(error));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, onUserUpdate);
}


//Delete Data
function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user);
    yield put(deleteUserSuccess({ user, ...response }));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, onDeleteUser);
}


//Status Change
function* onStatusChangUser({ payload: user }) {
  try {
    const response = yield call(statusChangeUser, user);
    yield put(statusChangeUserSuccess({ user, ...response }));
  } catch (error) {
    yield put(statusChangeUserFail(error));
  }
}

export function* watchStatusChangeUser() {
  yield takeEvery(STATUS_CHANGE_USER, onStatusChangUser);
}
function* onEmailVerifiedChangUser({ payload: user }) {
  try {
    const response = yield call(verifiedPhoneEmail, user);
    yield put(emailVerifiedChangeUserSuccess({ user, ...response }));
  } catch (error) {
    yield put(emailVerifiedChangeUserFail(error));
  }
}

export function* watchEmailVerifiedChangeUser() {
  yield takeEvery(EMAIL_VERIFIED_CHANGE_USER, onEmailVerifiedChangUser);
}
function* onPhoneVerifiedChangUser({ payload: user }) {
  try {
    const response = yield call(verifiedPhoneEmail, user);
    yield put(phoneVerifiedChangeUserSuccess({ user, ...response }));
  } catch (error) {
    yield put(phoneVerifiedChangeUserFail(error));
  }
}

export function* watchPhoneVerifiedChangeUser() {
  yield takeEvery(PHONE_VERIFIED_CHANGE_USER, onPhoneVerifiedChangUser);
}

//Password Change
function* onChangePassword({ payload: user }) {
  try {
      const response = yield call(passwordChangeUser, user);
      if(response.status === 200){
        yield put(passwordChangeUserSuccess(response));
      } else {
        yield put(passwordChangeUserFail(response));
      }
  } catch (error) {
      yield put(passwordChangeUserFail(error));
  }
}
export function* watchChangePassword() {
  yield takeEvery(PASSWORD_CHANGE_USER, onChangePassword);
}


function* userSaga() {
  yield all(
    [
      fork(watchFetchUsers),
      fork(watchAddNewUser),
      fork(watchUserDetails),
      fork(watchUpdateUser),
      fork(watchDeleteUser),
      fork(watchStatusChangeUser),
      fork(watchChangePassword),
      fork(watchEmailVerifiedChangeUser),
      fork(watchPhoneVerifiedChangeUser),
    ]
  );

}

export default userSaga
