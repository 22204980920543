import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_REFERRAL_CODE,
  STATUS_CHANGE_REFERRAL_CODE,
} from "./actionTypes"

import {
  getReferralCodeSuccess,
  getReferralCodeFail,
  statusChangeReferralCodeSuccess,
  statusChangeReferralCodeFail,

} from "./actions"

//Include Both Helper File with needed methods
import {
  getReferralCodeList,
  statusChangeReferralCode,
} from "../../helpers/fakebackend_helper"

//List
function* fetchReferralCode({ payload: data }) {
  try {
    const response = yield call(getReferralCodeList, data)
    yield put(getReferralCodeSuccess(response))
  } catch (error) {
    yield put(getReferralCodeFail(error))
  }
}

export function* watchReferralCode() {
  yield takeEvery(GET_REFERRAL_CODE, fetchReferralCode);
}

//Status Change
function* onstatusChangeReferralCode({ payload: user }) {
  try {
    const response = yield call(statusChangeReferralCode, user);
    yield put(statusChangeReferralCodeSuccess({ user, ...response }));
  } catch (error) {
    yield put(statusChangeReferralCodeFail(error));
  }
}

export function* watchstatusChangeReferralCode() {
  yield takeEvery(STATUS_CHANGE_REFERRAL_CODE, onstatusChangeReferralCode);
}

function* referralCodeSaga() {
  yield all(
    [
      fork(watchReferralCode),
      fork(watchstatusChangeReferralCode),
    ]
  );

}

export default referralCodeSaga
