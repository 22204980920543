import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
} from "./actionTypes";

export const getDashboard = data => ({
  type: GET_DASHBOARD,
  payload: data,
});

export const getDashboardSuccess = data => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: data,
});

export const getDashboardFail = error => ({
  type: GET_DASHBOARD_FAIL,
  payload: error,
});
