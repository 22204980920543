import {
    GET_TICKETS_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,


    GET_TICKET_DETAILS,
    GET_TICKET_DETAILS_SUCCESS,
    GET_TICKET_DETAILS_FAIL,
    
    GET_DMV_TICKETS_LIST,
    GET_DMV_TICKETS_SUCCESS,
    GET_DMV_TICKETS_FAIL,

    GET_TICKET_ASSIGN_DETAILS,
    GET_TICKET_ASSIGN_DETAILS_SUCCESS,
    GET_TICKET_ASSIGN_DETAILS_FAIL,

    GET_TICKET_SIGNATURE_DETAILS,
    GET_TICKET_SIGNATURE_DETAILS_SUCCESS,
    GET_TICKET_SIGNATURE_DETAILS_FAIL,

    GET_TLC_TICKET_USER_STATUS,
    GET_TLC_TICKET_USER_STATUS_SUCCESS,
    GET_TLC_TICKET_USER_STATUS_FAIL,

    GET_TLC_TICKET_ADMIN_STATUS,
    GET_TLC_TICKET_ADMIN_STATUS_SUCCESS,
    GET_TLC_TICKET_ADMIN_STATUS_FAIL,

    GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS,
    GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_SUCCESS,
    GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_FAIL,

    GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS,
    GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_SUCCESS,
    GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_FAIL,

    GET_DMV_TICKETS_SUCCESS_FAIL,
    UPDATE_ASSIGN_TICKET,
    UPDATE_ASSIGN_TICKET_SUCCESS,
    UPDATE_ASSIGN_TICKET_FAIL,
    ADD_TICKET,
    UPDATE_TICKET,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_FAIL,

    ADD_NEW_TICKET,
    ADD_TICKET_SUCCESS,
    ADD_TICKET_FAIL,

    DELETE_TICKET,
    DELETE_TICKET_SUCCESS,
    DELETE_TICKET_FAIL,

    RESET_TICKET_FLAG,

    RESET_TICKET_ASSIGN_DOCUMENTS_FLAG
} from "./actionType";

// common success
export const TicketsApiResponseSuccess = ticket => ({
    type: API_RESPONSE_SUCCESS,
    payload: ticket,
});
// common error
export const TicketsApiResponseError = ticket => ({
    type: API_RESPONSE_ERROR,
    payload: ticket,
});


// Details
export const getTicketDetails = ticket => ({
    type: GET_TICKET_DETAILS,
    payload: ticket,
});

export const getTicketDetailsSuccess = ticket => ({
    type: GET_TICKET_DETAILS_SUCCESS,
    payload: ticket,
  });
  
  export const getTicketDetailsFail = error => ({
    type: GET_TICKET_DETAILS_FAIL,
    payload: error,
  });


// User Status
export const getTlcTicketUserStatusAssignDocuments = ticket => ({
    type: GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS,
    payload: ticket,
});

export const getTlcTicketUserStatusAssignDocumentsSuccess = ticket => ({
    type: GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_SUCCESS,
    payload: ticket,
  });
  
  export const getTlcTicketUserStatusAssignDocumentsFail = error => ({
    type: GET_TLC_TICKET_USER_STATUS_ASSIGN_DOCUMENTS_FAIL,
    payload: error,
  });


// Admin Status
export const getTlcTicketAdminStatusAssignDocuments = ticket => ({
    type: GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS,
    payload: ticket,
});

export const getTlcTicketAdminStatusAssignDocumentsSuccess = ticket => ({
    type: GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_SUCCESS,
    payload: ticket,
  });
  
  export const getTlcTicketAdminStatusAssignDocumentsFail = error => ({
    type: GET_TLC_TICKET_ADMIN_STATUS_ASSIGN_DOCUMENTS_FAIL,
    payload: error,
  });


// User Status
export const getTlcTicketUserStatus = ticket => ({
    type: GET_TLC_TICKET_USER_STATUS,
    payload: ticket,
});

export const getTlcTicketUserStatusSuccess = ticket => ({
    type: GET_TLC_TICKET_USER_STATUS_SUCCESS,
    payload: ticket,
  });
  
  export const getTlcTicketUserStatusFail = error => ({
    type: GET_TLC_TICKET_USER_STATUS_FAIL,
    payload: error,
  });


// Admin Status
export const getTlcTicketAdminStatus = ticket => ({
    type: GET_TLC_TICKET_ADMIN_STATUS,
    payload: ticket,
});

export const getTlcTicketAdminStatusSuccess = ticket => ({
    type: GET_TLC_TICKET_ADMIN_STATUS_SUCCESS,
    payload: ticket,
  });
  
  export const getTlcTicketAdminStatusFail = error => ({
    type: GET_TLC_TICKET_ADMIN_STATUS_FAIL,
    payload: error,
  });



//Signature Details
export const getSignatureTicketDetails = ticket => ({
    type: GET_TICKET_SIGNATURE_DETAILS,
    payload: ticket,
});

export const getSignatureTicketDetailsSuccess = ticket => ({
    type: GET_TICKET_SIGNATURE_DETAILS_SUCCESS,
    payload: ticket,
  });
  
  export const getSignatureTicketDetailsFail = error => ({
    type: GET_TICKET_SIGNATURE_DETAILS_FAIL,
    payload: error,
  });


// Details
export const getTicketAssignDetails = ticket => ({
    type: GET_TICKET_ASSIGN_DETAILS,
    payload: ticket,
});

export const getTicketAssignDetailsSuccess = ticket => ({
    type: GET_TICKET_ASSIGN_DETAILS_SUCCESS,
    payload: ticket,
  });
  
  export const getTicketAssignDetailsFail = error => ({
    type: GET_TICKET_ASSIGN_DETAILS_FAIL,
    payload: error,
  });




///UPDATE ASSIGH
export const updateAssignTicket = ticket => ({
    type: UPDATE_ASSIGN_TICKET,
    payload: ticket,
});

export const updateAssignTicketSuccess = ticket => ({
    type: UPDATE_ASSIGN_TICKET_SUCCESS,
    payload: ticket,
});

export const updateAssignTicketFail = error => ({
    type: UPDATE_ASSIGN_TICKET_FAIL,
    payload: error,
});






export const getDmvTicketsList = ticket => ({
    type: GET_DMV_TICKETS_LIST,
    payload :ticket,
});
export const getDmvTicketsSuccess = ticket => ({
    type: GET_DMV_TICKETS_SUCCESS,
    payload: ticket,
  });
  
  export const getDmvTicketsFail = error => ({
    type: GET_DMV_TICKETS_FAIL,
    payload: error,
  });
export const getTicketsList = ticket => ({
    type: GET_TICKETS_LIST,
    payload :ticket,
});

export const updateTicket = ticket => ({
    type: UPDATE_TICKET,
    payload: ticket,
});



export const updateTicketSuccess = ticket => ({
    type: UPDATE_TICKET_SUCCESS,
    payload: ticket,
});

export const updateTicketFail = error => ({
    type: UPDATE_TICKET_FAIL,
    payload: error,
});

export const addNewTicket = ticket => ({
    type: ADD_NEW_TICKET,
    payload: ticket,
});

export const addTicketSuccess = ticket => ({
    type: ADD_TICKET_SUCCESS,
    payload: ticket,
});

export const addTicketFail = error => ({
    type: ADD_TICKET_FAIL,
    payload: error,
});

export const deleteTicket = ticket => ({
    type: DELETE_TICKET,
    payload: ticket,
});

export const deleteTicketSuccess = ticket => ({
    type: DELETE_TICKET_SUCCESS,
    payload: ticket,
});

export const deleteTicketFail = error => ({
    type: DELETE_TICKET_FAIL,
    payload: error,
});

export const resetTicketFlag = () => {
    return {
      type: RESET_TICKET_FLAG,
    }
  }
export const resetTicketAssignDocumentsFlag = () => {
    return {
      type: RESET_TICKET_ASSIGN_DOCUMENTS_FLAG,
    }
  }