import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateDriver,
  getDriverDetails,
  resetDriverFlag,
  getStateList,
  getCountryList,
  getCityList,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image_helper";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const DriverEdit = () => {
  document.title = "Edit Driver | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];

  const {
    isDriverUpdate,
    isDriverUpdateFail,
    error,
    success,
    country,
    city,
    vehicleState,
    details,
  } = useSelector((state) => ({
    isDriverUpdate: state.Drivers.isDriverUpdate,
    isDriverUpdateFail: state.Drivers.isDriverUpdateFail,
    error: state.Drivers.error,
    success: state.Drivers.success,
    country: state.Drivers.country,
    city: state.Drivers.city,
    vehicleState: state.Vehicles.vehicleState,
    details: state.Drivers.details,
  }));
  const selectedLicenseOption = {
    value: details && details.license_state ? details.license_state?._id : "",
    label: details && details.license_state ? details.license_state?.name : "",
  };
  const selectedStateOption = {
    value: details && details.state ? details.state?._id : "",
    label: details && details.state ? details.state?.name : "",
  };
  const selectedCountryOption = {
    value: details && details.country ? details.country?._id : "",
    label: details && details.country ? details.country?.name : "",
  };
  const selectedCityOption = {
    value: details && details.city ? details.city?._id : "",
    label: details && details.city ? details.city?.name : "",
  };
  useEffect(() => {
    if (isDriverUpdate || isDriverUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetDriverFlag());
      }, 3000);
    }
  }, [dispatch, isDriverUpdate, isDriverUpdateFail]);
  useEffect(() => {
    dispatch(getStateList());
    dispatch(getCountryList());
    dispatch(getCityList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getDriverDetails({ id: id }));
  }, [dispatch, id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      driver_type:
        details && details.driver_type ? details.driver_type : "personal",
      driver_image: details && details.driver_image ? details.driver_image : "",
      name: details && details.name ? details.name : "",
      date_of_birth:
        details && details.date_of_birth ? details.date_of_birth : "",
      hired_on: details && details.hired_on ? details.hired_on : "",
      license_number:
        details && details.license_number ? details.license_number : "",
      license_state: selectedLicenseOption,
      license_expire_date:
        details && details.license_expire_date
          ? details.license_expire_date
          : "",
      mobile_number:
        details && details.mobile_number ? details.mobile_number : "",
      email_id: details && details.email_id ? details.email_id : "",
      home_phone: details && details.home_phone ? details.home_phone : "",
      emergency_contact:
        details && details.emergency_contact ? details.emergency_contact : "",
      address: details && details.address ? details.address : "",
      address_second_line: details && details.address_second_line ? details.address_second_line : "",
      country: selectedCountryOption,
      state: selectedStateOption,
      zip_code: details && details.zip_code ? details.zip_code : "",
      city: selectedCityOption,
      course: details && details.course ? details.course : "",
      date_taken: details && details.date_taken ? details.date_taken : "",
      defensive_driving_certificate_expire_date:
        details && details.defensive_driving_certificate_expire_date
          ? details.defensive_driving_certificate_expire_date
          : "",
      note: details && details.note ? details.note : "",
      course_cretificate_date: details && details.course_cretificate_date ? details.course_cretificate_date : "",
      course_drug_test: details && details.course_drug_test ? details.course_drug_test : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      license_number: Yup.string().required("Please Enter License Number"),
      mobile_number: Yup.string().required("Please Enter Mobile Number"),
      email_id: Yup.string().required("Please Enter Email Id"),
    }),

    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        driver_type: values.driver_type,
        driver_image: image,
        name: values.name,
        date_of_birth: new Date(`${values.date_of_birth}`).toISOString(),
        hired_on: new Date(`${values.hired_on}`).toISOString(),
        license_number: values.license_number,
        license_state: values.license_state?.value,
        license_expire_date: new Date(
          `${values.license_expire_date}`
        ).toISOString(),
        vendor_agent_name: values.vendor_agent_name,
        vendor_insurance_note: values.vendor_insurance_note,
        mobile_number: values.mobile_number,
        email_id: values.email_id,
        home_phone: values.home_phone,
        emergency_contact: values.emergency_contact,
        address: values.address,
        address_second_line: values.address_second_line,
        country: values.country?.value,
        state: values.state?.value,
        zip_code: values.state?.zip_code,
        city: values.city?.value,
        course: values.city?.course,
        date_taken: values.city?.date_taken,
        defensive_driving_certificate_expire_date: new Date(
          `${values.defensive_driving_certificate_expire_date}`
        ).toISOString(),
        note: values.note,
      };
      dispatch(updateDriver(data));
    },
  });

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Edit Driver" pageTitle="Driver" pageUrl="/drivers" />
        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardBody>
                  <p className="text-muted">Driver Type</p>
                  <div className="mb-3">
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="driver_type"
                        id="inlineRadio1"
                        value="personal"
                        onChange={validation.handleChange}
                        checked={validation.values.driver_type === "personal"}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Personal
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="driver_type"
                        id="inlineRadio2"
                        value="commercial"
                        onChange={validation.handleChange}
                        checked={validation.values.driver_type === "commercial"}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Commercial
                      </Label>
                    </div>
                    {validation.touched.driver_type &&
                    validation.errors.driver_type ? (
                      <FormFeedback type="invalid">
                        {validation.errors.driver_type}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <p className="text-muted">Driver Information</p>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Name
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Email
                    </Label>
                    <Input
                      type="text"
                      name="email_id"
                      className="form-control"
                      placeholder="Enter Email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email_id || ""}
                      invalid={
                        validation.touched.email_id &&
                        validation.errors.email_id
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email_id &&
                    validation.errors.email_id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Mobile
                    </Label>
                    <Input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      placeholder="Enter phone"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobile_number || ""}
                      invalid={
                        validation.touched.mobile_number &&
                        validation.errors.mobile_number
                          ? true
                          : false
                      }
                    />
                    {validation.touched.mobile_number &&
                    validation.errors.mobile_number ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mobile_number}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Phone
                    </Label>
                    <Input
                      type="text"
                      name="home_phone"
                      className="form-control"
                      placeholder="Enter home phone"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.home_phone || ""}
                    />
                    {validation.touched.home_phone &&
                    validation.errors.home_phone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.home_phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Emergency Contact
                    </Label>
                    <Input
                      type="text"
                      name="emergency_contact"
                      className="form-control"
                      placeholder="Enter emergency contact"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.emergency_contact || ""}
                    />
                    {validation.touched.emergency_contact &&
                    validation.errors.emergency_contact ? (
                      <FormFeedback type="invalid">
                        {validation.errors.emergency_contact}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Address Line 1
                    </Label>
                    <Input
                      type="textarea"
                      name="address"
                      className="form-control"
                      placeholder="Enter address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address || ""}
                    />
                    {validation.touched.address && validation.errors.address ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Address Line 2
                    </Label>
                    <Input
                      type="text"
                      name="address_second_line"
                      className="form-control"
                      placeholder="Enter Address Second Line"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address_second_line || ""}
                    />
                    {validation.touched.address_second_line &&
                    validation.errors.address_second_line ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address_second_line}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Note
                    </Label>
                    <Input
                      type="textarea"
                      name="note"
                      className="form-control"
                      placeholder="Enter note"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.note || ""}
                    />
                    {validation.touched.note && validation.errors.note ? (
                      <FormFeedback type="invalid">
                        {validation.errors.note}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Driver License</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      License Number
                    </Label>
                    <Input
                      type="text"
                      name="license_number"
                      className="form-control"
                      placeholder="Enter license number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.license_number || ""}
                      invalid={
                        validation.touched.license_number &&
                        validation.errors.license_number
                          ? true
                          : false
                      }
                    />
                    {validation.touched.license_number &&
                    validation.errors.license_number ? (
                      <FormFeedback type="invalid">
                        {validation.errors.license_number}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <Row className="gy-4">
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          License Expire Date
                        </Label>
                        <Flatpickr
                          type="text"
                          name="license_expire_date"
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          onChange={(selectedDates, dateStr, instance) => {
                            let event = {
                              target: {
                                name: "license_expire_date",
                                value: dateStr,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          options={{
                            dateFormat: "m-d-Y",
                          }}
                          value={validation.values.license_expire_date || ""}
                        />
                        {validation.touched.license_expire_date &&
                        validation.errors.license_expire_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.license_expire_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          License State
                        </Label>
                        <Select
                          type="text"
                          name="license_state"
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "license_state",
                                value: selectedOption,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          onBlur={validation.handleBlur}
                          options={
                            vehicleState &&
                            vehicleState.map((item, index) => ({
                              label: item.name,
                              value: item._id,
                            }))
                          }
                          value={validation.values.license_state || ""}
                        />
                        {validation.touched.license_state &&
                        validation.errors.license_state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.license_state}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <Link to="/drivers" className="btn btn-danger w-sm me-1">
                  Cancel
                </Link>
                <Button
                  color="success"
                  className="btn-load"
                  disabled={isCreating}
                  type="submit"
                >
                  <span className="d-flex align-items-center">
                    {isCreating && (
                      <Spinner size="sm" className="flex-shrink-0 me-2">
                        {" "}
                        Create{" "}
                      </Spinner>
                    )}
                    <span className="flex-grow-1">Submit</span>
                  </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                      Submit
                    </button> */}
              </div>
            </Form>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Driver Image</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                    <Label className="form-label" htmlFor="product-title-input">
                      Image
                    </Label>
                    <Input type="file" onChange={onImgChange} />
                    <img
                      src={validation.values.driver_image || ""}
                      alt=""
                      id="image"
                      width="100px"
                    ></img>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Other Information</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Date of birth
                  </Label>
                  <Flatpickr
                    type="text"
                    name="date_of_birth"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: { name: "date_of_birth", value: dateStr },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "m-d-Y",
                    }}
                    value={validation.values.date_of_birth || ""}
                  />
                  {validation.touched.date_of_birth &&
                  validation.errors.date_of_birth ? (
                    <FormFeedback type="invalid">
                      {validation.errors.date_of_birth}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Hired on
                  </Label>
                  <Flatpickr
                    type="text"
                    name="hired_on"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: { name: "hired_on", value: dateStr },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "m-d-Y",
                    }}
                    value={validation.values.hired_on || ""}
                  />
                  {validation.touched.hired_on && validation.errors.hired_on ? (
                    <FormFeedback type="invalid">
                      {validation.errors.hired_on}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Driving certificate expire date
                  </Label>
                  <Flatpickr
                    type="text"
                    name="defensive_driving_certificate_expire_date"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: {
                          name: "defensive_driving_certificate_expire_date",
                          value: dateStr,
                        },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "m-d-Y",
                    }}
                    value={
                      validation.values
                        .defensive_driving_certificate_expire_date || ""
                    }
                  />
                  {validation.touched
                    .defensive_driving_certificate_expire_date &&
                  validation.errors
                    .defensive_driving_certificate_expire_date ? (
                    <FormFeedback type="invalid">
                      {
                        validation.errors
                          .defensive_driving_certificate_expire_date
                      }
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Country
                  </Label>
                  <Select
                    type="text"
                    name="country"
                    onChange={(selectedOption) => {
                      let event = {
                        target: { name: "country", value: selectedOption },
                      };
                      validation.handleChange(event);
                    }}
                    onBlur={validation.handleBlur}
                    options={
                      country &&
                      country.map((item, index) => ({
                        label: item.name,
                        value: item._id,
                      }))
                    }
                    value={validation.values.country || ""}
                  />
                  {validation.touched.country && validation.errors.country ? (
                    <FormFeedback type="invalid">
                      {validation.errors.country}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    State
                  </Label>
                  <Select
                    type="text"
                    name="state"
                    onChange={(selectedOption) => {
                      let event = {
                        target: { name: "state", value: selectedOption },
                      };
                      validation.handleChange(event);
                    }}
                    onBlur={validation.handleBlur}
                    options={
                      vehicleState &&
                      vehicleState.map((item, index) => ({
                        label: item.name,
                        value: item._id,
                      }))
                    }
                    value={validation.values.state || ""}
                  />
                  {validation.touched.state && validation.errors.state ? (
                    <FormFeedback type="invalid">
                      {validation.errors.state}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Zip
                  </Label>
                  <Input
                    type="text"
                    name="zip_code"
                    className="form-control"
                    placeholder="Enter Zip Code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.zip_code || ""}
                    invalid={
                      validation.touched.zip_code && validation.errors.zip_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.zip_code && validation.errors.zip_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.zip_code}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    City
                  </Label>
                  <Select
                    type="text"
                    name="city"
                    onChange={(selectedOption) => {
                      let event = {
                        target: { name: "city", value: selectedOption },
                      };
                      validation.handleChange(event);
                    }}
                    onBlur={validation.handleBlur}
                    options={
                      city &&
                      city.map((item, index) => ({
                        label: item.name,
                        value: item._id,
                      }))
                    }
                    value={validation.values.city || ""}
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <FormFeedback type="invalid">
                      {validation.errors.city}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Driver Course
                  </Label>
                  <Input
                    type="text"
                    name="course"
                    className="form-control"
                    placeholder="Enter Course"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.course || ""}
                    invalid={
                      validation.touched.course && validation.errors.course
                        ? true
                        : false
                    }
                  />
                  {validation.touched.course && validation.errors.course ? (
                    <FormFeedback type="invalid">
                      {validation.errors.course}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Date Taken
                  </Label>
                  <Input
                    type="text"
                    name="date_taken"
                    className="form-control"
                    placeholder="Enter Date Taken"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.date_taken || ""}
                    invalid={
                      validation.touched.date_taken &&
                      validation.errors.date_taken
                        ? true
                        : false
                    }
                  />
                  {validation.touched.date_taken &&
                  validation.errors.date_taken ? (
                    <FormFeedback type="invalid">
                      {validation.errors.date_taken}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkAll"
                      value="option"
                    />
                  </div>
                  <CardHeader>
                    <h5 className="card-title mb-0">Commercial License</h5>
                  </CardHeader>

                  <Label className="form-label" htmlFor="product-title-input">
                    Course Certification Date
                  </Label>
                  <Flatpickr
                    type="text"
                    name="course_cretificate_date"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: {
                          name: "course_cretificate_date",
                          value: dateStr,
                        },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "d-m-Y",
                    }}
                    value={validation.values.course_cretificate_date || ""}
                  />
                  {validation.touched.course_cretificate_date &&
                  validation.errors.course_cretificate_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.course_cretificate_date}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Drug Test
                  </Label>
                  <Flatpickr
                    type="text"
                    name="course_drug_test"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: {
                          name: "course_drug_test",
                          value: dateStr,
                        },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "d-m-Y",
                    }}
                    value={validation.values.course_drug_test || ""}
                  />
                  {validation.touched.course_drug_test &&
                  validation.errors.course_drug_test ? (
                    <FormFeedback type="invalid">
                      {validation.errors.course_drug_test}
                    </FormFeedback>
                  ) : null}
                </div>
                <br />

                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkAll"
                      value="option"
                    />
                  </div>
                  <CardHeader>
                    <h5 className="card-title mb-0">Expiration Date</h5>
                  </CardHeader>

                  <Label className="form-label" htmlFor="product-title-input">
                    Course Certification Date
                  </Label>
                  <Flatpickr
                    type="text"
                    name="course_cretificate_date"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: {
                          name: "course_cretificate_date",
                          value: dateStr,
                        },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "d-m-Y",
                    }}
                    value={validation.values.course_cretificate_date || ""}
                  />
                  {validation.touched.course_cretificate_date &&
                  validation.errors.course_cretificate_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.course_cretificate_date}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Drug Test
                  </Label>
                  <Flatpickr
                    type="text"
                    name="course_drug_test"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    onChange={(selectedDates, dateStr, instance) => {
                      let event = {
                        target: {
                          name: "course_drug_test",
                          value: dateStr,
                        },
                      };
                      validation.handleChange(event);
                    }}
                    options={{
                      dateFormat: "d-m-Y",
                    }}
                    value={validation.values.course_drug_test || ""}
                  />
                  {validation.touched.course_drug_test &&
                  validation.errors.course_drug_test ? (
                    <FormFeedback type="invalid">
                      {validation.errors.course_drug_test}
                    </FormFeedback>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isDriverUpdate ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {isDriverUpdateFail ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default DriverEdit;
