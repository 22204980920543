import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch,} from "react-redux";
import { getBanners,saveOrderingBanner,
  saveOrderingBannerResetFlag, } from "../../../store/actions";
  import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const BannerList = () => {
  document.title = "Banner list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [sortByField, setSortByField] = useState("ordering");
  const [sortByValue, setSortByValue] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { banners, totalDataCount, isSuccess, success, error,saveOrderingFaild, saveOrderingSuccess } = useSelector(
    (state) => ({
      banners: state.Banners.banners,
      totalDataCount: state.Banners.totalDataCount,
      isSuccess: state.Banners.isSuccess,
      error: state.Banners.error,
      success: state.Banners.success,
      saveOrderingSuccess: state.Banners.saveOrderingSuccess,
      saveOrderingFaild: state.Banners.saveOrderingFaild,
    })
  );

  //Ordaring
  const [orderingData, setOrderingData] = useState(null);
  const [dataList, setDataList] = useState(null);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for(let i = 0; i<tempData.length; i++){
      dataArray.push({ id: tempData[i]._id, ordering: i+1 });
    }
    setOrderingData (dataArray);
    onClickSaveOrdering(dataArray);
  };

  useEffect(() => {
    dispatch(
      getBanners({ keyword, date,status, sortByField, sortByValue, limit, offset })
    );
  }, [dispatch, keyword, date,status, sortByField, sortByValue, limit, offset]);

  useEffect(() => {
    setDataList(banners)
  }, [banners]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    setStatus("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  //Ordaring
  function onClickSaveOrdering(orderingData) {
    dispatch(saveOrderingBanner({
      orderingData: orderingData,
      tableName : 'banner',
    }));
    
  }

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingBannerResetFlag());
      }, 3000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Banners" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Banner List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/banner/create"
                      className="btn btn-success add-btn"
                      id="createBannerTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createBannerTooltip"
                    >
                      Create Banner
                    </UncontrolledTooltip>
                    <Button
                    color="danger"
                    onClick={resetFilter}
                    id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="resetTooltip"
                    >
                      Reset
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}

                    {/* <Button
                      color="warning"
                      onClick={() => onClickSaveOrdering()}
                      id="resetTooltip"
                      className="m-1"
                    >
                      <i className="ri-arrow-up-down-fill me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Save Ordering
                    </UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={3} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                        
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={3} className="col-xxl-3">
                        <div>
                            <select className="form-select" data-choices data-choices-search-false
                                id="choices-privacy-status-input" onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Select status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    
                                    
                            </select>
                        </div>
                        </Col>
                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {banners?.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Image</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")}
                                >
                                  Name
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="status"
                                  onClick={() => sortBy("status")}
                                >
                                  Status
                                </th>
                                
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {/* <tbody className="list form-check-all">
                              {banners.map((item) => (
                                <tr key={item?._id}>
                                  <td > <img src={item?.image} alt="" width="50px" ></img></td>
                                  <td>{item?.title}</td>
                                  <td>
                                    {item?.status && item?.status === "active" ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        Inactive
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item?.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white"><i className="ri-more-2-fill"></i></DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Link
                                           to={`/banner/edit/${item?._id}`}
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i> Edit
                                          </Link>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody> */}

                            <Droppable droppableId="tbody">
                              {
                                (provided) => (<tbody ref={provided.innerRef} {...provided.droppableProps} className="list form-check-all">
                                
                                  {dataList?.map((item,i) => (
                                    <Draggable draggableId={item._id} key={item._id} index={i}>
                                      {(provided) => (
                                        <tr key={item._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <td > <img src={item?.image} alt="" width="50px" ></img></td>
                                        <td>{item?.title}</td>
                                        <td>
                                          {item?.status && item?.status === "active" ? (
                                            <span className="badge badge-soft-success text-uppercase">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-danger text-uppercase">
                                              Inactive
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          <Moment format="D MMM YYYY">
                                            {item?.created_at}
                                          </Moment>
                                        </td>
                                        <td>
                                          <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-menu-icon btn-transparent text-white"><i className="ri-more-2-fill"></i></DropdownToggle>
                                            <DropdownMenu container="body">
                                              <DropdownItem>
                                                <Link
                                                to={`/banner/edit/${item?._id}`}
                                                  className="text-primary d-inline-block edit-item-btn"
                                                >
                                                  <i className="ri-pencil-fill fs-16"></i> Edit
                                                </Link>
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                                
                                )
                              }
                            </Droppable>




                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </DragDropContext>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {saveOrderingSuccess ? (
            <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
            />
          ) : null}
          {saveOrderingFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default BannerList;
