import {
  GET_SOLUTIONS,
  GET_SOLUTIONS_SUCCESS,
  GET_SOLUTIONS_FAIL,

  GET_SOLUTION_DETAILS,
  GET_SOLUTION_DETAILS_SUCCESS,
  GET_SOLUTION_DETAILS_FAIL,

  ADD_NEW_SOLUTION,
  ADD_NEW_SOLUTION_SUCCESS,
  ADD_NEW_SOLUTION_FAIL,

  UPDATE_SOLUTION,
  UPDATE_SOLUTION_SUCCESS,
  UPDATE_SOLUTION_FAIL,

  DELETE_SOLUTION,
  DELETE_SOLUTION_SUCCESS,
  DELETE_SOLUTION_FAIL,

  STATUS_CHANGE_SOLUTION,
  STATUS_CHANGE_SOLUTION_SUCCESS,
  STATUS_CHANGE_SOLUTION_FAIL,

  SAVE_ORDERING_SOLUTION,
  SAVE_ORDERING_SOLUTION_SUCCESS,
  SAVE_ORDERING_SOLUTION_FAIL,
  SAVE_ORDERING_SOLUTION_RESET_FLAG,

  RESET_SOLUTION_FLAG,
  MODIFY_SOLUTION_FLAG
} from "./actionTypes";

export const getSolutions = data => ({
  type: GET_SOLUTIONS,
  payload: data,
});

export const getSolutionsSuccess = data => ({
  type: GET_SOLUTIONS_SUCCESS,
  payload: data,
});

export const getSolutionsFail = error => ({
  type: GET_SOLUTIONS_FAIL,
  payload: error,
});

export const addNewSolution = data => ({
  type: ADD_NEW_SOLUTION,
  payload: data,
});

export const addNewSolutionSuccess = data => ({
  type: ADD_NEW_SOLUTION_SUCCESS,
  payload: data,
});

export const addNewSolutionFail = error => ({
  type: ADD_NEW_SOLUTION_FAIL,
  payload: error,
});

export const updateSolution = data => ({
  type: UPDATE_SOLUTION,
  payload: data,
});

export const updateSolutionSuccess = data => ({
  type: UPDATE_SOLUTION_SUCCESS,
  payload: data,
});

export const updateSolutionFail = error => ({
  type: UPDATE_SOLUTION_FAIL,
  payload: error,
});

export const getSolutionDetails = data => ({
  type: GET_SOLUTION_DETAILS,
  payload: data,
});

export const getSolutionDetailsSuccess = data => ({
  type: GET_SOLUTION_DETAILS_SUCCESS,
  payload: data,
});

export const getSolutionDetailsFail = error => ({
  type: GET_SOLUTION_DETAILS_FAIL,
  payload: error,
});


//Delete
export const deleteSolution = data => ({
  type: DELETE_SOLUTION,
  payload: data,
});

export const deleteSolutionSuccess = data => ({
  type: DELETE_SOLUTION_SUCCESS,
  payload: data,
});

export const deleteSolutionFail = error => ({
  type: DELETE_SOLUTION_FAIL,
  payload: error,
});

//Status Change
export const statusChangeSolution = data => ({
  type: STATUS_CHANGE_SOLUTION,
  payload: data,
});

export const statusChangeSolutionSuccess = data => ({
  type: STATUS_CHANGE_SOLUTION_SUCCESS,
  payload: data,
});

export const statusChangeSolutionFail = error => ({
  type: STATUS_CHANGE_SOLUTION_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingSolution = data => ({
  type: SAVE_ORDERING_SOLUTION,
  payload: data,
});

export const saveOrderingSolutionSuccess = data => ({
  type: SAVE_ORDERING_SOLUTION_SUCCESS,
  payload: data,
});

export const saveOrderingSolutionFail = error => ({
  type: SAVE_ORDERING_SOLUTION_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingSolutionResetFlag = () => {
  return {
    type: SAVE_ORDERING_SOLUTION_RESET_FLAG,
  }
}




//Modify Flag for toaster
export const modifySolutionFlag = () => {
  return {
    type: MODIFY_SOLUTION_FLAG,
  }
}

//Reset Flag for toaster
export const resetSolutionFlag = () => {
  return {
    type: RESET_SOLUTION_FLAG,
  }
}
