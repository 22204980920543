import {
  GET_BILLINGS,
  GET_BILLINGS_SUCCESS,
  GET_BILLINGS_FAIL,

  ADD_NEW_BILLING,
  ADD_NEW_BILLING_SUCCESS,
  ADD_NEW_BILLING_FAIL,

  // GET_STEP_DETAILS,
  // GET_STEP_DETAILS_SUCCESS,
  // GET_STEP_DETAILS_FAIL,

  // UPDATE_STEP,
  // UPDATE_STEP_SUCCESS,
  // UPDATE_STEP_FAIL,

  // DELETE_STEP,
  // DELETE_STEP_SUCCESS,
  // DELETE_STEP_FAIL,

  INVOICE_STATUS_CHANGE,
  INVOICE_STATUS_CHANGE_SUCCESS,
  INVOICE_STATUS_CHANGE_FAIL,


  RESEND_INVOICE,
  RESEND_INVOICE_SUCCESS,
  RESEND_INVOICE_FAIL,

  
  RESET_BILLING_FLAG,
  MODIFY_BILLING_FLAG,
} from "./actionTypes";

export const getBillings = data => ({
  type: GET_BILLINGS,
  payload: data,
});

export const getBillingsSuccess = data => ({
  type: GET_BILLINGS_SUCCESS,
  payload: data,
});

export const getBillingsFail = error => ({
  type: GET_BILLINGS_FAIL,
  payload: error,
});

export const addNewBilling = data=> ({
  type: ADD_NEW_BILLING,
  payload: data,
});

export const addNewBillingSuccess = data => ({
  type: ADD_NEW_BILLING_SUCCESS,
  payload: data,
});

export const addNewBillingFail = error => ({
  type: ADD_NEW_BILLING_FAIL,
  payload: error,
});


//Resend Invoice
export const resendInvoice = data => ({
  type: RESEND_INVOICE,
  payload: data,
});

export const resendInvoiceSuccess = data => ({
  type: RESEND_INVOICE_SUCCESS,
  payload: data,
});

export const resendInvoiceFail = error => ({
  type: RESEND_INVOICE_FAIL,
  payload: error,
});

//Resend Invoice
export const statusChangeInvoice = data => ({
  type: INVOICE_STATUS_CHANGE,
  payload: data,
});

export const statusChangeInvoiceSuccess = data => ({
  type: INVOICE_STATUS_CHANGE_SUCCESS,
  payload: data,
});

export const statusChangeInvoiceFail = error => ({
  type: INVOICE_STATUS_CHANGE_FAIL,
  payload: error,
});



//Reset Flag
export const resetBillingFlag = () => {
  return {
    type: RESET_BILLING_FLAG,
  }
}

//Modify Flag for toaster
export const modifyBillingFlag = () => {
  return {
    type: MODIFY_BILLING_FLAG,
  }
}