import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_WHYUS,
  ADD_NEW_WHYUS,
  GET_WHYUS_DETAILS,
  UPDATE_WHYUS,
  DELETE_WHYUS,
  STATUS_CHANGE_WHYUS,
  SAVE_ORDERING_WHYUS
} from "./actionTypes"

import {
  getWhyusSuccess,
  getWhyusFail,
  addNewWhyusSuccess,
  addNewWhyusFail,
  getWhyusDetailsSuccess,
  getWhyusDetailsFail,
  updateWhyusSuccess,
  updateWhyusFail,
  deleteWhyusSuccess,
  deleteWhyusFail,
  statusChangeWhyusSuccess,
  statusChangeWhyusFail,
  saveOrderingWhyusSuccess,
  saveOrderingWhyusFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getWhyusList,
  addNewWhyus,
  getWhyusDetails,
  updateWhyus,
  deleteWhyus,
  statusChangeWhyus,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchWhyus({ payload: whyus }) {
  try {
    const response = yield call(getWhyusList, whyus)
    yield put(getWhyusSuccess(response))
  } catch (error) {
    yield put(getWhyusFail(error))
  }
}

export function* watchFetchWhyus() {
  yield takeEvery(GET_WHYUS, fetchWhyus);
}

function* onAddNewWhyus({ payload: whyus }) {
  try {
      const response = yield call(addNewWhyus, whyus);
      if(response.status === 200){
        yield put(addNewWhyusSuccess(response));
      } else {
        yield put(addNewWhyusFail(response));
      }
  } catch (error) {
      yield put(addNewWhyusFail(error));
  }
}

export function* watchAddNewWhyus() {
  yield takeEvery(ADD_NEW_WHYUS, onAddNewWhyus);
}

function* onWhyusDetails({ payload: whyus }) {
  try {
      const response = yield call(getWhyusDetails, whyus);
      if(response.status === 200){
        yield put(getWhyusDetailsSuccess(response));
      } else {
        yield put(getWhyusDetailsFail(response));
      }
  } catch (error) {
      yield put(getWhyusDetailsFail(error));
  }
}

export function* watchWhyusDetails() {
  yield takeEvery(GET_WHYUS_DETAILS, onWhyusDetails);
}

function* onWhyusUpdate({ payload: whyus }) {
  try {
      const response = yield call(updateWhyus, whyus);
      if(response.status === 200){
        yield put(updateWhyusSuccess(response));
      } else {
        yield put(updateWhyusFail(response));
      }
  } catch (error) {
      yield put(updateWhyusFail(error));
  }
}

export function* watchUpdateWhyus() {
  yield takeEvery(UPDATE_WHYUS, onWhyusUpdate);
}



//Delete Data
function* onDeleteWhyus({ payload: data }) {
  try {
    const response = yield call(deleteWhyus, data);
    yield put(deleteWhyusSuccess({ data, ...response }));
  } catch (error) {
    yield put(deleteWhyusFail(error));
  }
}

export function* watchDeleteWhyus() {
  yield takeEvery(DELETE_WHYUS, onDeleteWhyus);
}


//Status Change
function* onStatusChangWhyus({ payload: data }) {
  try {
    const response = yield call(statusChangeWhyus, data);
    yield put(statusChangeWhyusSuccess({ data, ...response }));
  } catch (error) {
    yield put(statusChangeWhyusFail(error));
  }
}

export function* watchStatusChangeWhyus() {
  yield takeEvery(STATUS_CHANGE_WHYUS, onStatusChangWhyus);
}


//Save Ordering
function* onSaveOrderingWhyus({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingWhyusSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingWhyusFail(error));
  }
}

export function* watchSaveOrderingWhyus() {
  yield takeEvery(SAVE_ORDERING_WHYUS, onSaveOrderingWhyus);
}



function* whyusSaga() {
  yield all(
    [
      fork(watchFetchWhyus),
      fork(watchAddNewWhyus),
      fork(watchWhyusDetails),
      fork(watchUpdateWhyus),
      fork(watchStatusChangeWhyus),
      fork(watchDeleteWhyus),
      fork(watchSaveOrderingWhyus),
    ]
  );

}

export default whyusSaga
