import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAIL,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  RESET_CLIENT_FLAG,
  DELETE_CLIENT_SUCCESS,
  STATUS_CHANGE_CLIENT_SUCCESS,
  STATUS_CHANGE_CLIENT_FAIL,
  DELETE_CLIENT_FAIL,
  MODIFY_CLIENT_FLAG,
  SAVE_ORDERING_CLIENT_SUCCESS,
  SAVE_ORDERING_CLIENT_FAIL,
  SAVE_ORDERING_CLIENT_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  clients: []
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload.data],
        success: action.payload.message,
        isClientAdd: true,
        isClientAddFail: false,
      };

    case ADD_NEW_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isClientAdd: false,
        isClientAddFail: true,
      };

    case UPDATE_CLIENT_SUCCESS:
        return {
          ...state,
          clients: state.clients.map(client =>
            client._id.toString() === action.payload.data._id.toString()
              ? { ...client, ...action.payload.data }
              : client
          ),
          details: action.payload.data,
          success: action.payload.message,
          isClientUpdate: true,
          isClientUpdateFail: false
        };

    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isClientUpdate: false,
        isClientUpdateFail: true,
      };

    case GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_CLIENT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(client =>

        {
          
          return  client._id !== action.payload.data._id
        }
          
        ),
        success: action.payload.message,
        isClientDelete: true,
        isClientDeleteFail: false
      };
  
    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isClientDelete: false,
        isClientDeleteFail: true,
      };

    //Status Change
    case STATUS_CHANGE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };
          
      case STATUS_CHANGE_CLIENT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFailed: true,
        };

    //Save Ordering
    case SAVE_ORDERING_CLIENT_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_CLIENT_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };


      case MODIFY_CLIENT_FLAG:
        return {
          ...state,
          statusSuccess: false,
          statusFailed: false,
        };
   
    case RESET_CLIENT_FLAG:
      return {
        ...state,
        isClientAdd: false,
        isClientAddFail: false,
        isSuccess: false,
        isClientDetails: false,
        isClientUpdate: false,
        isClientUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Clients;
