import React, { useState,useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { Card,CardBody,CardHeader,Col,Container,Button,Spinner,Row,Input,Label,FormFeedback,Form} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  convertToBase64 
} from "../../../helpers/image_helper"
import { 
  updateClient, 
  resetClientFlag,
  getClientDetails
} from "../../../store/actions";

import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";


import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ClientEdit = () => {
  document.title ="Edit Client | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setClientImage1] = useState();
  const [image2, setClientImage2] = useState();
  const [description, setData] = useState();
  const allowedExts = ['jpg', 'jpeg', 'png'];
  const { isClientUpdate, isClientUpdateFail, error, success,details } = useSelector(state => ({
    isClientUpdate: state.Clients.isClientUpdate,
    isClientUpdateFail: state.Clients.isClientUpdateFail,
    error: state.Clients.error,
    success: state.Clients.success,
    details: state.Clients.details,
  }));  

  //console.log(details);
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];

      if (![...allowedExts].includes(ext)) {
        console.log('Please upload a valid image');
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById('image1');
          output.src = URL.createObjectURL(e.target.files[0]);
          output.onload = function() {
            URL.revokeObjectURL(output.src) // free memory
          }
        convertToBase64(e.target.files[0], setClientImage1);
  
      }
    }
  };
 
 
  

  const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
          name: (details && details.name)?details.name:'',
          description: (details && details.description)?details.description:'',
          image: (details && details.image)?details.image:'',
         
      },
      validationSchema: Yup.object({
          name: Yup.string().required("Please Enter Name"),
  
          
      }),
      onSubmit: (values) => {
        console.log(values);
          setIsCreating(true)
          const data = {
            id: id,
            name : values.name,
            description:description,
            image: image,
            
          }
          dispatch(updateClient(data));
      }
  });

  useEffect(() => {
    if(isClientUpdate || isClientUpdateFail){
        setIsCreating(false)
        validation.resetForm();
        setTimeout(() => {
            dispatch(resetClientFlag())
            history.push('/clients');
        }, 3000);
    }
  }, [dispatch, isClientUpdate, isClientUpdateFail, validation]);



  useEffect(() => {
    dispatch(getClientDetails({id:id}))
  }, [dispatch, id]);

  
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title="Edit Client" pageTitle="Client" pageUrl="/clients" />
        <Row>
          <Col lg={8}>
          <Form onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
          }}>
               <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Client </h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Name
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Name"
                      type="text"
                      name="name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                          validation.touched.name && validation.errors.name ? true : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                    ) : null}
                  </div>
                </div>

              
                
              </CardBody>
            </Card>            

              <div className="text-end mb-3">
              <Link to="/clients" className="btn btn-danger w-sm me-1">Cancel</Link>
                <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                    <span className="d-flex align-items-center">
                        {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                        <span className="flex-grow-1">
                           Submit
                        </span>
                    </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button> */}
              </div>
              </Form>
          </Col>
          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Client Image</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Image
                  </Label>
                  <Input type="file" onChange={onImg1Change}/>
                  <img src={validation.values.image || ""} alt=""  id="image1" width="100px" ></img>
                  </div>
                </div>
                             

                
              </CardBody>
            </Card>

          
          </Col>

         
        </Row>
        {isClientUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
        {isClientUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default ClientEdit;
