import {
  GET_SITESETTING_DETAILS,
  GET_SITESETTING_DETAILS_SUCCESS,
  GET_SITESETTING_DETAILS_FAIL,
  UPDATE_SITESETTING,
  UPDATE_SITESETTING_SUCCESS,
  UPDATE_SITESETTING_FAIL,
  RESET_SITESETTING_FLAG,
} from "./actionTypes";



export const updateSitesetting = sitesetting => ({
  type: UPDATE_SITESETTING,
  payload: sitesetting,
});

export const updateSitesettingSuccess = sitesetting => ({
  type: UPDATE_SITESETTING_SUCCESS,
  payload: sitesetting,
});

export const updateSitesettingFail = error => ({
  type: UPDATE_SITESETTING_FAIL,
  payload: error,
});

export const getSitesettingDetails = sitesetting => ({
  type: GET_SITESETTING_DETAILS,
  payload: sitesetting,
});

export const getSitesettingDetailsSuccess = role => ({
  type: GET_SITESETTING_DETAILS_SUCCESS,
  payload: role,
});

export const getSitesettingDetailsFail = error => ({
  type: GET_SITESETTING_DETAILS_FAIL,
  payload: error,
});

export const resetSitesettingFlag = () => {
  return {
    type: RESET_SITESETTING_FLAG,
  }
}
