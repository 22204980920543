import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_PAYMENT_TICKETS,
  GET_PAYMENT_TICKETS_SUCCESS,
  GET_PAYMENT_TICKETS_FAIL,
  GET_PAYMENT_TICKETS_TLC,
  GET_PAYMENT_TICKETS_TLC_SUCCESS,
  GET_PAYMENT_TICKETS_TLC_FAIL,
  STATUS_CHANGE_TRANSACTION_STATEPAY,
  STATUS_CHANGE_TRANSACTION_STATEPAY_SUCCESS,
  STATUS_CHANGE_TRANSACTION_STATEPAY_FAIL,
  ASSIGN_MANAGER,
  ASSIGN_MANAGER_SUCCESS,
  ASSIGN_MANAGER_FAIL,
  ASSIGN_USER,
  ASSIGN_USER_SUCCESS,
  ASSIGN_USER_FAIL,
  UPLOAD_STATEPAY_DOCUMENT,
  UPLOAD_STATEPAY_DOCUMENT_SUCCESS,
  UPLOAD_STATEPAY_DOCUMENT_FAIL,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_FAIL,
  RESET_TRANSACTION_FLAG,
  RESET_STATUS_FLAG,
} from "./actionTypes";

export const getTransactions = (data) => ({
  type: GET_TRANSACTIONS,
  payload: data,
});

export const getTransactionsSuccess = (data) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getTransactionsFail = (error) => ({
  type: GET_TRANSACTIONS_FAIL,
  payload: error,
});

export const getPaymentTickets = (data) => ({
  type: GET_PAYMENT_TICKETS,
  payload: data,
});

export const getPaymentTicketsSuccess = (data) => ({
  type: GET_PAYMENT_TICKETS_SUCCESS,
  payload: data,
});

export const getPaymentTicketsFail = (error) => ({
  type: GET_PAYMENT_TICKETS_FAIL,
  payload: error,
});

export const getPaymentTicketsTLc = (data) => ({
  type: GET_PAYMENT_TICKETS_TLC,
  payload: data,
});

export const getPaymentTicketsTlcSuccess = (data) => ({
  type: GET_PAYMENT_TICKETS_TLC_SUCCESS,
  payload: data,
});

export const getPaymentTicketsTlcFail = (error) => ({
  type: GET_PAYMENT_TICKETS_TLC_FAIL,
  payload: error,
});

export const statusChangeStatepay = (data) => ({
  type: STATUS_CHANGE_TRANSACTION_STATEPAY,
  payload: data,
});

export const statusChangeStatepaySuccess = (data) => ({
  type: STATUS_CHANGE_TRANSACTION_STATEPAY_SUCCESS,
  payload: data,
});

export const statusChangeStatepayFail = (error) => ({
  type: STATUS_CHANGE_TRANSACTION_STATEPAY_FAIL,
  payload: error,
});

export const assignManager = (data) => ({
  type: ASSIGN_MANAGER,
  payload: data,
});

export const assignManagerSuccess = (data) => ({
  type: ASSIGN_MANAGER_SUCCESS,
  payload: data,
});

export const assignManagerFail = (error) => ({
  type: ASSIGN_MANAGER_FAIL,
  payload: error,
});

export const assignUser = (data) => ({
  type: ASSIGN_USER,
  payload: data,
});

export const assignUserSuccess = (data) => ({
  type: ASSIGN_USER_SUCCESS,
  payload: data,
});

export const assignUserFail = (error) => ({
  type: ASSIGN_USER_FAIL,
  payload: error,
});

export const uploadStatepayDocument = (data) => ({
  type: UPLOAD_STATEPAY_DOCUMENT,
  payload: data,
});

export const uploadStatepayDocumentSuccess = (data) => ({
  type: UPLOAD_STATEPAY_DOCUMENT_SUCCESS,
  payload: data,
});

export const uploadStatepayDocumentFail = (error) => ({
  type: UPLOAD_STATEPAY_DOCUMENT_FAIL,
  payload: error,
});

export const getTransactionDetails = (transaction) => ({
  type: GET_TRANSACTION_DETAILS,
  payload: transaction,
});

export const getTransactionDetailsSuccess = (transaction) => ({
  type: GET_TRANSACTION_DETAILS_SUCCESS,
  payload: transaction,
});

export const getTransactionDetailsFail = (error) => ({
  type: GET_TRANSACTION_DETAILS_FAIL,
  payload: error,
});

export const resetTransactionFlag = () => {
  return {
    type: RESET_TRANSACTION_FLAG,
  };
};
export const resetStatusFlag = () => {
  return {
    type: RESET_STATUS_FLAG,
  };
};
