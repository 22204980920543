import {
  GET_PAGES,
  GET_PAGES_SUCCESS,
  GET_PAGES_FAIL,
  ADD_NEW_PAGE,
  ADD_NEW_PAGE_SUCCESS,
  ADD_NEW_PAGE_FAIL,
  GET_PAGE_DETAILS,
  GET_PAGE_DETAILS_SUCCESS,
  GET_PAGE_DETAILS_FAIL,
  UPDATE_PAGE,
  UPDATE_PAGE_SUCCESS,
  UPDATE_PAGE_FAIL,
  RESET_PAGE_FLAG
} from "./actionTypes";

export const getPages = data => ({
  type: GET_PAGES,
  payload: data,
});

export const getPagesSuccess = data => ({
  type: GET_PAGES_SUCCESS,
  payload: data,
});

export const getPagesFail = error => ({
  type: GET_PAGES_FAIL,
  payload: error,
});

export const addNewPage = page=> ({
  type: ADD_NEW_PAGE,
  payload: page,
});

export const addNewPageSuccess = page => ({
  type: ADD_NEW_PAGE_SUCCESS,
  payload: page,
});

export const addNewPageFail = error => ({
  type: ADD_NEW_PAGE_FAIL,
  payload: error,
});

export const updatePage = page => ({
  type: UPDATE_PAGE,
  payload: page,
});

export const updatePageSuccess = page => ({
  type: UPDATE_PAGE_SUCCESS,
  payload: page,
});

export const updatePageFail = error => ({
  type: UPDATE_PAGE_FAIL,
  payload: error,
});

export const getPageDetails = page => ({
  type: GET_PAGE_DETAILS,
  payload: page,
});

export const getPageDetailsSuccess = page => ({
  type: GET_PAGE_DETAILS_SUCCESS,
  payload: page,
});

export const getPageDetailsFail = error => ({
  type: GET_PAGE_DETAILS_FAIL,
  payload: error,
});

export const resetPageFlag = () => {
  return {
    type: RESET_PAGE_FLAG,
  }
}