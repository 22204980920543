/* COUPONS */
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";

export const ADD_NEW_COUPON = "ADD_NEW_COUPON";
export const ADD_NEW_COUPON_SUCCESS = "ADD_NEW_COUPON_SUCCESS";
export const ADD_NEW_COUPON_FAIL = "ADD_NEW_COUPON_FAIL";

export const GET_COUPON_DETAILS = "GET_COUPON_DETAILS";
export const GET_COUPON_DETAILS_SUCCESS = "GET_COUPON_DETAILS_SUCCESS";
export const GET_COUPON_DETAILS_FAIL = "GET_COUPON_DETAILS_FAIL";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";

export const STATUS_CHANGE_COUPON = "STATUS_CHANGE_COUPON";
export const STATUS_CHANGE_COUPON_SUCCESS = "STATUS_CHANGE_COUPON_SUCCESS";
export const STATUS_CHANGE_COUPON_FAIL = "STATUS_CHANGE_COUPON_FAIL";

export const MODIFY_COUPON_FLAG = "MODIFY_COUPON_FLAG";
export const RESET_COUPON_FLAG = "RESET_COUPON_FLAG";