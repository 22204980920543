import {
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  ADD_NEW_VEHICLE_SUCCESS,
  ADD_NEW_VEHICLE_FAIL,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAIL,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  RESET_VEHICLE_FLAG,

  GET_STATE_LIST_SUCCESS,
  GET_STATE_LIST_FAIL,

  GET_PURCHASE_TYPE_LIST_SUCCESS,
  GET_PURCHASE_TYPE_LIST_FAIL,

  // GET_DRIVERS_SUCCESS,
  // GET_DRIVERS_FAIL
} from "./actionTypes";

const INIT_STATE = {
  vehicles: []
};

const Vehicles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_VEHICLES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: [...state.vehicles, action.payload.data],
        success: action.payload.message,
        isVehicleAdd: true,
        isVehicleAddFail: false,
      };

    case ADD_NEW_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isVehicleAdd: false,
        isVehicleAddFail: true,
      };

    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: state.vehicles.map(vehicle =>
          vehicle._id.toString() === action.payload.data._id.toString()
            ? { ...vehicle, ...action.payload.data }
            : vehicle
        ),
        success: action.payload.message,
        isVehicleUpdate: true,
        isVehicleUpdateFail: false
      };

    case UPDATE_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isVehicleUpdate: false,
        isVehicleUpdateFail: true,
      };

    case GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_VEHICLE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    
    //State
    case GET_STATE_LIST_SUCCESS:
      return {
        ...state,
        vehicleState: action.payload.data,
      };
  
    case GET_STATE_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    
    //Purchase Type
    case GET_PURCHASE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        vehiclePurchaseType: action.payload.data,
      };
  
    case GET_PURCHASE_TYPE_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    //Driver
    // case GET_DRIVERS_SUCCESS:
    //   return {
    //     ...state,
    //     drivers: action.payload.data,
    //   };
  
    // case GET_DRIVERS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload.message,
    //   };

    case RESET_VEHICLE_FLAG:
      return {
        ...state,
        isVehicleAdd: false,
        isVehicleAddFail: false,
        isSuccess: false,
        isVehicleDetails: false,
        isVehicleUpdate: false,
        isVehicleUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Vehicles;
