import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
    getDashboard,
 
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Widget from "./Widgets";
import Section from "./Section";



const DashboardEcommerce = () => {
    const dispatch = useDispatch();
    const [date, setDate] = useState(null);
    let storageSession = JSON.parse(sessionStorage.getItem("authUser")); 
    const {
        dashboard,
        isSuccess,
        
      } = useSelector((state) => ({
        dashboard: state.Dashboard.dashboard,
        isSuccess: state.Dashboard.isSuccess,
      }));
    useEffect(() => {
        dispatch(getDashboard());
      }, [dispatch]);
      useEffect(() => {
        let dateRange = '';
        if(date && date.e && date.e[0] && date.e[1]){
            dateRange =  {
                "start" : date.e[0],
                "end" : date.e[1]
            };
            dispatch(getDashboard({dateRange}));
        }
       
    }, [date]);
  document.title ="Dashboard | Defendit - Admin Panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="Dashboards" />
          <Row>
            <Col>
              <div className="h-100">
              <Row className="mb-3 pb-1">
                <Col xs={12}>
                  
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                      
                        <div className="flex-grow-1">
                            <h4 className="fs-16 mb-1">Hello, {storageSession?.data?.name}!</h4>
                            {/* <p className="text-muted mb-0">Here's what's happening with your store today.</p> */}
                        </div>
                        <div className="mt-3 mt-lg-0">
                            <form action="#">
                                <Row className="g-3 mb-0 align-items-center">
                                    {/* <div className="col-sm-auto">
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d M, Y",
                                                    defaultDate: ["01 Jan 2022", "31 Jan 2022"]
                                                }}
                                                onChange={(e) => {
                                                    setDate({ e });
                                                  }}
                                            />
                                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-auto">
                                        <button type="button" className="btn btn-soft-success"><i className="ri-add-circle-line align-middle me-1"></i> Add Product</button>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn" onClick={() => sidebar()} ><i className="ri-pulse-line"></i></button>
                                    </div> */}
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
              </Row>
                <Row>
                <Widget dashboard={dashboard}  isSuccess={isSuccess}/>
                </Row>
            
              </div>
            </Col>
    
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
