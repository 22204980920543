import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Alert,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import {Link, useHistory } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "../../assets/images/logo_alt.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import MsgToast from "../../Components/Common/MsgToast";

// action
import { 
  userForgetPassword,
  userForgetPasswordOTP,
  userForgetPasswordChange,
  forgetPasswordResetFlag,
} from "../../store/actions";

const ForgetPasswordPage = () => {
  const dispatch = useDispatch();
  const [isSendOtpPop, setisSendOtpPop] = useState(true);
  const [isOtpPop, setisOtpPop] = useState(false);
  const [isPasswordChangePop, setisPasswordChangePop] = useState(false);
  const history = useHistory();
  //OTP SEND
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
     onSubmit: (values) => {
      dispatch(userForgetPassword(values));
    },


    onSubmit1 : (values) => {
      console.log(values);
      //dispatch(userForgetPassword(values));
    },


  });

  //OTP VERIFY
  const validationOTP = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please Enter Your OTP"),
    }),
     
    onSubmit : (values) => {
      const data = {
        email : forgetSuccessData.email,
        otp : values.otp, 
      }
      dispatch(userForgetPasswordOTP(data));
    },
  });

  //PASSWORD CHANGE
  const validationPasswordChange = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required("Please Enter New Password"),
      confirm_password: Yup.string().required("Please Enter Confirm Password"),
    }),
     
    onSubmit : (values) => {
      const data = {
        email : forgetSuccessData.email,
        new_password : values.new_password, 
        confirm_password : values.confirm_password, 
      }
      dispatch(userForgetPasswordChange(data));
    },
  });




  const { forgetError, forgetSuccessMsg, forgetSuccessData, otpSuccess, passwordChangePop, redirectLogin  } =
  useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    forgetSuccessData: state.ForgetPassword.forgetSuccessData,
    otpSuccess: state.ForgetPassword.otpSuccess,
    passwordChangePop: state.ForgetPassword.passwordChangePop,
    redirectLogin: state.ForgetPassword.redirectLogin,

  }));


  useEffect(() => {
    setisSendOtpPop(otpSuccess && (otpSuccess === true) ? false : true);
    setisOtpPop(otpSuccess && (otpSuccess === true) ?otpSuccess:false);
  }, [otpSuccess]);

  useEffect(() => {
    setisSendOtpPop(passwordChangePop && (passwordChangePop === true) ? false : true);
    setisPasswordChangePop(passwordChangePop?passwordChangePop:false)
  }, [passwordChangePop]);

  useEffect(() => {
    if (redirectLogin === true) {
      setTimeout(() => {
        dispatch(forgetPasswordResetFlag());
        history.push('/login');
      }, 1000);
    }
  }, [dispatch, redirectLogin]);

  document.title = "Reset Password | Velzon - React Admin & Dashboard Template";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20" />
                  </Link>
                </div>
                {/* <p className="mt-3 fs-15 fw-medium">
                  Premium Admin & Dashboard Template
                </p> */}
              </div>
            </Col>
          </Row>

          {isSendOtpPop === true ? (
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Forgot Password?</h5>
                    <p className="text-muted">Reset password with Defendit</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>

                  <div className="p-2">

                  {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Send OTP
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
          ) : (
            ""
          )}


          {isOtpPop === true ? (
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Forgot Password?</h5>
                      <p className="text-muted">Reset password with Defendit</p>

                      <lord-icon
                        src="https://cdn.lordicon.com/rhvddzym.json"
                        trigger="loop"
                        colors="primary:#0ab39c"
                        className="avatar-xl"
                        style={{ width: "120px", height: "120px" }}
                      ></lord-icon>
                    </div>

                    <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationOTP.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-4">
                          <Label className="form-label">OTP</Label>
                          <Input
                            name="otp"
                            className="form-control"
                            placeholder="Enter OTP"
                            type="text"
                            onChange={validationOTP.handleChange}
                            onBlur={validationOTP.handleBlur}
                            value={validationOTP.values.otp || ""}
                            invalid={
                              validationOTP.touched.otp && validationOTP.errors.otp
                                ? true
                                : false
                            }
                          />
                          {validationOTP.touched.otp && validationOTP.errors.otp ? (
                            <FormFeedback type="invalid">
                              <div>{validationOTP.errors.otp}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="text-center mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Wait, I remember my password...{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Click here{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}


          {isPasswordChangePop === true ? (
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Forgot Password?</h5>
                      <p className="text-muted">Reset password with Defendit</p>

                      <lord-icon
                        src="https://cdn.lordicon.com/rhvddzym.json"
                        trigger="loop"
                        colors="primary:#0ab39c"
                        className="avatar-xl"
                        style={{ width: "120px", height: "120px" }}
                      ></lord-icon>
                    </div>

                    <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationPasswordChange.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-4">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="new_password"
                            className="form-control"
                            placeholder="Enter New Password"
                            type="password"
                            onChange={validationPasswordChange.handleChange}
                            onBlur={validationPasswordChange.handleBlur}
                            value={validationPasswordChange.values.new_password || ""}
                            invalid={
                              validationPasswordChange.touched.new_password && validationPasswordChange.errors.new_password
                                ? true
                                : false
                            }
                          />
                          {validationPasswordChange.touched.new_password && validationPasswordChange.errors.new_password ? (
                            <FormFeedback type="invalid">
                              <div>{validationPasswordChange.errors.new_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="confirm_password"
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            type="password"
                            onChange={validationPasswordChange.handleChange}
                            onBlur={validationPasswordChange.handleBlur}
                            value={validationPasswordChange.values.confirm_password || ""}
                            invalid={
                              validationPasswordChange.touched.confirm_password && validationPasswordChange.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validationPasswordChange.touched.confirm_password && validationPasswordChange.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              <div>{validationPasswordChange.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="text-center mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Change Password
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Wait, I remember my password...{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Click here{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

       {/*  {forgetError && forgetError ? (
          <MsgToast msg={forgetError} color="danger" icon="ri-error-warning-line" />
        ) : null}

        {forgetSuccessMsg && forgetSuccessMsg ? (
          <MsgToast msg={forgetSuccessMsg} color="success" icon="ri-checkbox-circle-line" />
        ) : null} */}

        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default ForgetPasswordPage;
