import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_PAGES,
  ADD_NEW_PAGE,
  GET_PAGE_DETAILS,
  UPDATE_PAGE
} from "./actionTypes"

import {
  getPagesSuccess,
  getPagesFail,
  addNewPageSuccess,
  addNewPageFail,
  getPageDetailsSuccess,
  getPageDetailsFail,
  updatePageSuccess,
  updatePageFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPageList,
  addNewPage,
  getPageDetails,
  updatePage
} from "../../helpers/fakebackend_helper"

function* fetchPages({ payload: page }) {
  try {
    const response = yield call(getPageList, page)
    yield put(getPagesSuccess(response))
  } catch (error) {
    yield put(getPagesFail(error))
  }
}

export function* watchFetchPages() {
  yield takeEvery(GET_PAGES, fetchPages);
}

function* onAddNewPage({ payload: page }) {
  try {
      const response = yield call(addNewPage, page);
      if(response.status === 200){
        yield put(addNewPageSuccess(response));
      } else {
        yield put(addNewPageFail(response));
      }
  } catch (error) {
      yield put(addNewPageFail(error));
  }
}

export function* watchAddNewPage() {
  yield takeEvery(ADD_NEW_PAGE, onAddNewPage);
}

function* onPageDetails({ payload: page }) {
  try {
      const response = yield call(getPageDetails, page);
      if(response.status === 200){
        yield put(getPageDetailsSuccess(response));
      } else {
        yield put(getPageDetailsFail(response));
      }
  } catch (error) {
      yield put(getPageDetailsFail(error));
  }
}

export function* watchPageDetails() {
  yield takeEvery(GET_PAGE_DETAILS, onPageDetails);
}

function* onPageUpdate({ payload: page }) {
  try {
      const response = yield call(updatePage, page);
      if(response.status === 200){
        yield put(updatePageSuccess(response));
      } else {
        yield put(updatePageFail(response));
      }
  } catch (error) {
      yield put(updatePageFail(error));
  }
}

export function* watchUpdatePage() {
  yield takeEvery(UPDATE_PAGE, onPageUpdate);
}

function* pageSaga() {
  yield all(
    [
      fork(watchFetchPages),
      fork(watchAddNewPage),
      fork(watchPageDetails),
      fork(watchUpdatePage),
    ]
  );

}

export default pageSaga
