import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_TESTIMONIALS,
  ADD_NEW_TESTIMONIAL,
  GET_TESTIMONIAL_DETAILS,
  UPDATE_TESTIMONIAL,
} from "./actionTypes"

import {
  getTestimonialsSuccess,
  getTestimonialsFail,
  addNewTestimonialSuccess,
  addNewTestimonialFail,
  getTestimonialDetailsSuccess,
  getTestimonialDetailsFail,
  updateTestimonialSuccess,
  updateTestimonialFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTestimonialList,
  addNewTestimonial,
  getTestimonialDetails,
  updateTestimonial,
} from "../../helpers/fakebackend_helper"

function* fetchTestimonials({ payload: testimonial }) {
  try {
    const response = yield call(getTestimonialList, testimonial)
    yield put(getTestimonialsSuccess(response))
  } catch (error) {
    yield put(getTestimonialsFail(error))
  }
}

export function* watchFetchTestimonials() {
  yield takeEvery(GET_TESTIMONIALS, fetchTestimonials);
}

function* onAddNewTestimonial({ payload: testimonial }) {
  try {
      const response = yield call(addNewTestimonial, testimonial);
      if(response.status === 200){
        yield put(addNewTestimonialSuccess(response));
      } else {
        yield put(addNewTestimonialFail(response));
      }
  } catch (error) {
      yield put(addNewTestimonialFail(error));
  }
}

export function* watchAddNewTestimonial() {
  yield takeEvery(ADD_NEW_TESTIMONIAL, onAddNewTestimonial);
}

function* onTestimonialDetails({ payload: testimonial }) {
  try {
      const response = yield call(getTestimonialDetails, testimonial);
      if(response.status === 200){
        yield put(getTestimonialDetailsSuccess(response));
      } else {
        yield put(getTestimonialDetailsFail(response));
      }
  } catch (error) {
      yield put(getTestimonialDetailsFail(error));
  }
}

export function* watchTestimonialDetails() {
  yield takeEvery(GET_TESTIMONIAL_DETAILS, onTestimonialDetails);
}

function* onTestimonialUpdate({ payload: testimonial }) {
  try {
      const response = yield call(updateTestimonial, testimonial);
      if(response.status === 200){
        yield put(updateTestimonialSuccess(response));
      } else {
        yield put(updateTestimonialFail(response));
      }
  } catch (error) {
      yield put(updateTestimonialFail(error));
  }
}

export function* watchUpdateTestimonial() {
  yield takeEvery(UPDATE_TESTIMONIAL, onTestimonialUpdate);
}

function* testimonialSaga() {
  yield all(
    [
      fork(watchFetchTestimonials),
      fork(watchAddNewTestimonial),
      fork(watchTestimonialDetails),
      fork(watchUpdateTestimonial),
    ]
  );

}

export default testimonialSaga
