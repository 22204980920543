export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

//OTP
export const FORGET_PASSWORD_OTP = "FORGET_PASSWORD_OTP"
export const FORGET_PASSWORD_SUCCESS_OTP = "FORGET_PASSWORD_SUCCESS_OTP"
export const FORGET_PASSWORD_ERROR_OTP = "FORGET_PASSWORD_ERROR_OTP"


//UPDATE PASSEORD
export const FORGET_PASSWORD_CHANGE = "FORGET_PASSWORD_CHANGE"
export const FORGET_PASSWORD_CHANGE_SUCCESS = "FORGET_PASSWORD_CHANGE_SUCCESS"
export const FORGET_PASSWORD_CHANGE_ERROR = "FORGET_PASSWORD_CHANGE_ERROR"

export const FORGET_PASSWORD_RESET_FLAG = "FORGET_PASSWORD_RESET_FLAG"

