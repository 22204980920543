import {
  GET_SOLUTIONS_SUCCESS,
  GET_SOLUTIONS_FAIL,

  ADD_NEW_SOLUTION_SUCCESS,
  ADD_NEW_SOLUTION_FAIL,

  GET_SOLUTION_DETAILS_SUCCESS,
  GET_SOLUTION_DETAILS_FAIL,

  UPDATE_SOLUTION_SUCCESS,
  UPDATE_SOLUTION_FAIL,

  DELETE_SOLUTION_SUCCESS,
  DELETE_SOLUTION_FAIL,

  STATUS_CHANGE_SOLUTION_SUCCESS,
  STATUS_CHANGE_SOLUTION_FAIL,

  SAVE_ORDERING_SOLUTION_SUCCESS,
  SAVE_ORDERING_SOLUTION_FAIL,
  SAVE_ORDERING_SOLUTION_RESET_FLAG,

  RESET_SOLUTION_FLAG,
  MODIFY_SOLUTION_FLAG, 

} from "./actionTypes";

const INIT_STATE = {
  solutions: []
};

const Solutions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SOLUTIONS_SUCCESS:
      return {
        ...state,
        solutions: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_SOLUTIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_SOLUTION_SUCCESS:
      return {
        ...state,
        solutions: [...state.solutions, action.payload.data],
        success: action.payload.message,
        isSolutionAdd: true,
        isSolutionAddFail: false,
      };

    case ADD_NEW_SOLUTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSolutionAdd: false,
        isSolutionAddFail: true,
      };

    case UPDATE_SOLUTION_SUCCESS:
      return {
        ...state,
        solutions: state.solutions.map(solution =>
          solution._id.toString() === action.payload.data._id.toString()
            ? { ...solution, ...action.payload.data }
            : solution
        ),
        success: action.payload.message,
        isSolutionUpdate: true,
        isSolutionUpdateFail: false
      };

    case UPDATE_SOLUTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSolutionUpdate: false,
        isSolutionUpdateFail: true,
      };

    case GET_SOLUTION_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_SOLUTION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
   

      
    //Delete
    case DELETE_SOLUTION_SUCCESS:
      return { 
        ...state,
        solutions: state.solutions.filter(getData =>
          {
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_SOLUTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_SOLUTION_SUCCESS:
      return {
        ...state,
        solutions: state.solutions.map(getData =>
          getData._id === action.payload.data._id
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_SOLUTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_SOLUTION_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_SOLUTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_SOLUTION_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };


    //MODIFY RESET  
    case MODIFY_SOLUTION_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };


    case RESET_SOLUTION_FLAG:
      return {
        ...state,
        isSolutionAdd: false,
        isSolutionAddFail: false,
        isSuccess: false,
        isSolutionDetails: false,
        isSolutionUpdate: false,
        isSolutionUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Solutions;
