/* TRANSACTIONS */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const GET_PAYMENT_TICKETS = "GET_PAYMENT_TICKETS";
export const GET_PAYMENT_TICKETS_SUCCESS = "GET_PAYMENT_TICKETS_SUCCESS";
export const GET_PAYMENT_TICKETS_FAIL = "GET_PAYMENT_TICKETS_FAIL";

export const GET_PAYMENT_TICKETS_TLC = "GET_PAYMENT_TICKETS_TLC";
export const GET_PAYMENT_TICKETS_TLC_SUCCESS =
  "GET_PAYMENT_TICKETS_TLC_SUCCESS";
export const GET_PAYMENT_TICKETS_TLC_FAIL = "GET_PAYMENT_TICKETS_TLC_FAIL";

export const UPLOAD_STATEPAY_DOCUMENT = "UPLOAD_STATEPAY_DOCUMENT";
export const UPLOAD_STATEPAY_DOCUMENT_SUCCESS =
  "UPLOAD_STATEPAY_DOCUMENT_SUCCESS";
export const UPLOAD_STATEPAY_DOCUMENT_FAIL = "UPLOAD_STATEPAY_DOCUMENT_FAIL";

export const STATUS_CHANGE_TRANSACTION_STATEPAY =
  "STATUS_CHANGE_TRANSACTION_STATEPAY";
export const STATUS_CHANGE_TRANSACTION_STATEPAY_SUCCESS =
  "STATUS_CHANGE_TRANSACTION_STATEPAY_SUCCESS";
export const STATUS_CHANGE_TRANSACTION_STATEPAY_FAIL =
  "STATUS_CHANGE_TRANSACTION_STATEPAY_FAIL";

export const ASSIGN_MANAGER = "ASSIGN_MANAGER";
export const ASSIGN_MANAGER_SUCCESS = "ASSIGN_MANAGER_SUCCESS";
export const ASSIGN_MANAGER_FAIL = "ASSIGN_MANAGER_FAIL";

export const ASSIGN_USER = "ASSIGN_USER";
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";
export const ASSIGN_USER_FAIL = "ASSIGN_USER_FAIL";

export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_FAIL = "GET_TRANSACTION_DETAILS_FAIL";

export const RESET_TRANSACTION_FLAG = "RESET_TRANSACTION_FLAG";
export const RESET_STATUS_FLAG = "RESET_STATUS_FLAG";
