import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";



//Project
import projectSaga from "./projects/saga";
// Task
import taskSaga from "./tasks/saga";

//TicketsList
//import ticketsSaga from "./tickets/saga";

//crm
import crmSaga from "./crm/saga";
//invoice
import invoiceSaga from "./invoice/saga";


//Role
import roleSaga from "./role/saga";
//Vehicle
import vehicleSaga from "./vehicle/saga";
// Solutions
import solutionSaga from "./solution/saga";
//Banner 
import bannerSaga from "./banner/saga";
// Testimonial
import testimonialSaga from "./testimonial/saga";
// Step
import stepSaga from "./step/saga";
// Client
import clientSaga from "./client/saga";
// Why Us
import whyusSaga from "./whyus/saga";
// Page
import pageSaga from "./page/saga";
// Site Settings
import sitesettingSaga from "./sitesetting/saga";
//TicketsList
import ticketsSaga from "./tickets/saga";
// Driver
import driverSaga from "./driver/saga";
//Missionisions
import missionvisionSaga from "./missionVision/saga";   

// SEO
import seoSaga from "./seo/saga";
// Job
import jobSaga from "./jobs/saga";


// Transaction
import transactionSaga from "./transactions/saga";

// Billings
import billingSaga from "./billings/saga";
// State
import stateSaga from "./state/saga";

// Transaction
import userSaga from "./user/saga";

// Referral
import referrallSaga from "./referral/saga";
import referralCodeSaga from "./referralCode/saga";

// Dashboard
import dashboardSaga from "./dashboard/saga";

// Coupons
import couponSaga from "./coupon/saga";

import notificationSaga from "./notification/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(ticketsSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(roleSaga),
    fork(vehicleSaga),
    fork(solutionSaga),
    fork(bannerSaga),
    fork(testimonialSaga),
    fork(stepSaga),
    fork(clientSaga),
    fork(whyusSaga),
    fork(pageSaga),
    fork(sitesettingSaga),
    fork(driverSaga),
    fork(missionvisionSaga),
    fork(seoSaga),
    fork(jobSaga),
    fork(transactionSaga),
    fork(billingSaga),
    fork(stateSaga),
    fork(userSaga),
    fork(referrallSaga),
    fork(dashboardSaga),
    fork(couponSaga),
    fork(referralCodeSaga),
    fork(notificationSaga),
  ]);
}
