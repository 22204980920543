import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaymentTickets,
  resetTransactionFlag,
  resetStatusFlag,
  statusChangeStatepay,
  uploadStatepayDocument,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import MsgToast from "../../../Components/Common/MsgToast";
//import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ToastContainer, toast } from "react-toastify";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactPaginate from "react-paginate";
import { convertToBase64 } from "../../../helpers/image_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
const PaymentTicketList = () => {
  document.title = "Payment ticket list | Defendit - Admin Panel";
  const fp = useRef(null);
  const allowedExts = ["pdf"];
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [changeStatePayStatus, setChangeStatePayStatus] = useState({
    data: [],
  });
  const [setId, setSetId] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticket_type, setTicketType] = useState("dmv");
  const tableRef = useRef(null);
  const [activeTab, setActiveTab] = useState("2");
  const [paidStatus, setPaidStatus] = useState(null);
  const [file, setDmvStatePayFile] = useState(null);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const {
    tickets,
    totalDataCount,
    success,
    isSuccess,
    error,
    statusSuccess,
    statusFaild,
    uploadStatepayDocumentSuccess,
    uploadStatepayDocumentFaild,
  } = useSelector((state) => ({
    tickets: state.Transactions.tickets,
    totalDataCount: state.Transactions.totalDataCount,
    success: state.Transactions.success,
    isSuccess: state.Transactions.isSuccess,
    error: state.Transactions.error,
    statusSuccess: state.Transactions.statusSuccess,
    statusFaild: state.Transactions.statusFaild,
    uploadStatepayDocumentSuccess:
      state.Transactions.uploadStatepayDocumentSuccess,
    uploadStatepayDocumentFaild: state.Transactions.uploadStatepayDocumentFaild,
  }));

  useEffect(() => {
    dispatch(
      getPaymentTickets({
        keyword,
        sortKey: sortByField,
        sortValue: sortByValue,
        limit,
        offset,
        ticket_type,
        state_pay: paidStatus,
      })
    );
  }, [
    dispatch,
    keyword,
    sortByField,
    sortByValue,
    limit,
    offset,
    ticket_type,
    paidStatus,
  ]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(
        getPaymentTickets({
          limit,
          dateRange,
          offset,
          keyword,
          ticket_type,
          state_pay: paidStatus,
        })
      );
    }
  }, [dispatch, date, limit, offset,ticket_type, keyword, paidStatus]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);
  useEffect(() => {
    if (statusSuccess || statusFaild) {
      var inputs = document.getElementsByName("chk_child");

      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
      setChangeStatePayStatus({
        data: []})
        setSetId([])
      setTimeout(() => {
        dispatch(resetStatusFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFaild]);
  useEffect(() => {
    if (uploadStatepayDocumentSuccess || uploadStatepayDocumentFaild) {
      var inputs = document.getElementsByName("chk_child");

      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
      setSetId([])
      setChangeStatePayStatus({
        data: []})
      setModal(false);
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetStatusFlag());
      }, 3000);
    }
  }, [dispatch, uploadStatepayDocumentSuccess, uploadStatepayDocumentFaild]);
  console.log(setId);
  console.log(changeStatePayStatus,statusSuccess);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setOffset(newOffset);
  };
  const handleChange = (e, index, ids, status) => {
    var value = e.target.checked;
    if (value) {
      setChangeStatePayStatus({
        data: [...changeStatePayStatus.data, { id: ids, state_pay: status }],
      });
      setSetId([...setId, { id: ids }]);
    } else {
      const updated = changeStatePayStatus.data.filter(
        (list) => list.id !== ids
      );
      const updated1 = setId.filter((list) => list.id !== ids);
      setChangeStatePayStatus({ data: updated });
      setSetId(updated1);
    }
  };
  function onClickStatusChange() {
    dispatch(statusChangeStatepay(changeStatePayStatus));
  }
  function singleStatusChange(id, state_pay) {
    dispatch(
      statusChangeStatepay({ data: [{ id: id, state_pay: state_pay }] })
    );
  }
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const resetFilter = () => {
    dispatch(resetTransactionFlag());
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    setPaidStatus("");
    dispatch(
      getPaymentTickets({
        keyword,
        sortKey: sortByField,
        sortValue: sortByValue,
        limit,
        ticket_type,
        offset,
        ticket_type,
      })
    );
  };
  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };
  const onFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload Pdf only.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("fileInput");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setDmvStatePayFile);
      }
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      confirmation_number: "",
      file: "",
    },
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        data: setId,
        confirmation_number: values.confirmation_number,
        file: file,
      };

      dispatch(uploadStatepayDocument(data));
    },
  });
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Tickets" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Dmv Ticket List
                  </h5>
                  <div className=" flex-shrink-0 ms-auto">
                    <Button
                      color="danger"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>

                    <Button
                      className="ms-sm-2"
                      color="warning"
                      onClick={() => onClickStatusChange()}
                      id="resetTooltip"
                    >
                      <i className="ri-exchange-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Change Status
                    </UncontrolledTooltip>
                    <button
                      className="btn btn-success ms-sm-2"
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Document
                    </button>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={paidStatus}
                          onChange={(e) => setPaidStatus(e.target.value)}
                        >
                          <option value="">Select Status</option>
                          <option value="1">Paid</option>
                          <option value="2">Pending</option>
                        </select>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select Payment Date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                             
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {tickets?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col" style={{ width: "50px" }}>
                                  {/* <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div> */}
                                </th>
                                <th>Plate</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="ticket_id"
                                  onClick={() => sortBy("ticket_id")}
                                >
                                  Ticket-ID
                                </th>
                                <th>Ticket Type</th>
                                <th>Fine</th>
                                <th>Due amount</th>
                                <th>Violation</th>
                                <th>Status</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="transaction_id"
                                  onClick={() => sortBy("transaction_id")}
                                >
                                  Transaction Id
                                </th>
                                <th>Payment Date</th>
                                <th>Confirmation Number</th>
                                <th  className="sort cursor-pointer"
                                  data-sort="updated_at"
                                  onClick={() => sortBy("updated_at")}
                                  >
                                     Last Updated </th>
                                <th>User Details</th>
                                
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all" id="list">
                              {tickets.map((item, i) => (
                                <tr key={item._id}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input checkbox1"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                        onChange={(e) => {
                                          handleChange(
                                            e,
                                            i,
                                            item._id,
                                            item?.state_pay === "1" ? "2" : "1"
                                          );
                                          console.log(e.target.checked);
                                        }}
                                      />
                                    </div>
                                  </th>
                                  <td>
                                    <a href={item?.file} target="_blank">
                                      {item?.ticket_details?.plate_no}
                                    </a>
                                  </td>
                                  <td>
                                    {item?.ticket_details?._id ? (
                                      <Link
                                        to={`/tickets/details/${item?.ticket_details?._id}`}
                                        className=""
                                      >
                                        {item?.ticket_id}
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{item?.ticket_details?.ticket_type}</td>
                                  <td>
                                    $
                                    {item?.ticket_details?.fine_amount
                                      ? parseInt(
                                          item?.ticket_details?.fine_amount
                                        ).toFixed(2)
                                      : "0.00"}
                                  </td>
                                  <td>
                                    $
                                    {item?.ticket_details?.due_amount
                                      ? parseInt(
                                          item?.ticket_details?.due_amount
                                        ).toFixed(2)
                                      : "0.00"}
                                  </td>
                                  <td>
                                    {
                                      item?.ticket_details
                                        ?.violation_description
                                    }
                                  </td>
                                  <td>
                                    {item?.ticket_details &&
                                    item?.ticket_details?.ticket_type ===
                                      "dmv" &&
                                    item?.state_pay === "1" ? (
                                      <div className="badge badge-soft-success text-uppercase">
                                        Manager Paid
                                      </div>
                                    ) : item?.ticket_details &&
                                      item?.ticket_details?.ticket_type ===
                                        "dmv" ? (
                                      <div className="badge badge-soft-danger text-uppercase">
                                        Pending
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                 
                                  <td>
                                    <Link
                                      to={`/transaction/${item?.transaction_id}`}
                                      className=""
                                    >
                                      {item?.payment_transaction?item?.payment_transaction:"N.A."}
                                    </Link>
                                  </td>
                                  <td>
                                   <Moment format="D MMM YYYY">
                                      {item?.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {item?.confirmation_number
                                      ? item?.confirmation_number
                                      : " N.A."}
                                    <a
                                      className="ms-xl-2"
                                      href={item?.file}
                                      target="_blank"
                                    >
                                      <i className="ri-eye-line"></i>
                                    </a>
                                  </td>
                                  <td>
                                   <Moment format="D MMM YYYY">
                                      {item?.updated_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {item?.transaction_details?.user_info ? (
                                      <>
                                        {
                                          item?.transaction_details?.user_info
                                            ?.name
                                        }
                                        ,
                                        {
                                          item?.transaction_details?.user_info
                                            ?.email
                                        }
                                        ,
                                        {
                                          item?.transaction_details?.user_info
                                            ?.phone
                                        }
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white">
                                        <i className="ri-more-2-fill"></i>
                                      </DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Button
                                            onClick={(e) =>
                                              singleStatusChange(
                                                item._id,
                                                item?.state_pay === "1"
                                                  ? "2"
                                                  : "1"
                                              )
                                            }
                                            className="p-0 bg-transparent border-0 text-warning"
                                          >
                                            <i className="ri-exchange-line fs-16"></i>{" "}
                                            Status Change
                                          </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <Button
                                            onClick={(e) => {
                                              setModal(true);
                                              setSetId([{ id: item._id }]);
                                            }}
                                            className="p-0 bg-transparent border-0 text-success"
                                          >
                                            <i className="ri-add-fill fs-16"></i>{" "}
                                            Add Document
                                          </Button>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <div className="pagination-inner">
                              <nav
                                className="px-3"
                                aria-label="Page navigation example"
                              >
                                <ReactPaginate
                                  activeClassName="active"
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  containerClassName="pagination"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  breakLabel="..."
                                  nextLabel="Next &nbsp;→"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  pageCount={noOfPage}
                                  previousLabel="←&nbsp;Prev"
                                  renderOnZeroPageCount={null}
                                />
                              </nav>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        {uploadStatepayDocumentSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {uploadStatepayDocumentFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
      <ToastContainer limit={1} closeButton={false} />
      <Modal
        className="zoomIn"
        id="addDocument"
        size="lg"
        isOpen={modal}
        toggle={toggle}
        centered
      >
        <ModalHeader toggle={toggle}>Add Document</ModalHeader>
        <div className="modal-content border-0 mt-3"></div>
        <ModalBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="2">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        for="confirmationnumberInput"
                        className="form-label"
                      >
                        Confirmation Number
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="confirmationnumberInput"
                        onChange={validation.handleChange}
                        placeholder="Enter confirmation number"
                        name="confirmation_number"
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label for="fileInput" className="form-label">
                        File
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="fileInput"
                        onChange={onFileChange}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      {/* <button type="submit" className="btn btn-primary">
                            <i className="ri-save-3-line align-bottom me-1"></i>
                            Save
                          </button> */}
                      <Button
                        color="success"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Create{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Submit</span>
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PaymentTicketList;
