/* STEPS */
export const GET_REFERRAL_CODE = "GET_REFERRAL_CODE";
export const GET_REFERRAL_CODE_SUCCESS = "GET_REFERRAL_CODE_SUCCESS";
export const GET_REFERRAL_CODE_FAIL = "GET_REFERRAL_CODE_FAIL";

export const STATUS_CHANGE_REFERRAL_CODE = "STATUS_CHANGE_REFERRAL_CODE";
export const STATUS_CHANGE_REFERRAL_CODE_SUCCESS = "STATUS_CHANGE_REFERRAL_CODE_SUCCESS";
export const STATUS_CHANGE_REFERRAL_CODE_FAIL = "STATUS_CHANGE_REFERRAL_CODE_FAIL";

export const MODIFY_REFERRAL_CODE_FLAG = "MODIFY_REFERRAL_CODE_FLAG";
export const RESET_REFERRAL_CODE_FLAG = "RESET_REFERRAL_CODE_FLAG";