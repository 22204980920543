/* TESTIMONIAL */
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const GET_TESTIMONIALS_SUCCESS = "GET_TESTIMONIALS_SUCCESS";
export const GET_TESTIMONIALS_FAIL = "GET_TESTIMONIALS_FAIL";

export const ADD_NEW_TESTIMONIAL = "ADD_NEW_TESTIMONIAL";
export const ADD_NEW_TESTIMONIAL_SUCCESS = "ADD_NEW_TESTIMONIAL_SUCCESS";
export const ADD_NEW_TESTIMONIAL_FAIL = "ADD_NEW_TESTIMONIAL_FAIL";

export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL";
export const UPDATE_TESTIMONIAL_SUCCESS = "UPDATE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_FAIL = "UPDATE_TESTIMONIAL_FAIL";

export const GET_TESTIMONIAL_DETAILS = "GET_TESTIMONIAL_DETAILS";
export const GET_TESTIMONIAL_DETAILS_SUCCESS = "GET_TESTIMONIAL_DETAILS_SUCCESS";
export const GET_TESTIMONIAL_DETAILS_FAIL = "GET_TESTIMONIAL_DETAILS_FAIL";

export const RESET_TESTIMONIAL_FLAG = "RESET_TESTIMONIAL_FLAG";


