import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getDriver,
  deleteDriver,
  modifyDriverFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";
import { ToastContainer, toast } from "react-toastify";

const DriverList = () => {
  document.title = "Driver list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [user_id, setUser] = useState("");
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const {
    drivers,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    userList,
  } = useSelector((state) => ({
    drivers: state.Drivers.drivers,
    totalDataCount: state.Drivers.totalDataCount,
    isSuccess: state.Drivers.isSuccess,
    error: state.Drivers.error,
    success: state.Drivers.success,
    statusSuccess: state.Drivers.statusSuccess,
    statusFailed: state.Drivers.statusFailed,
    userList: state.Users.users,
  }));

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
  };

  useEffect(() => {
 
    dispatch(
      getDriver({ keyword, sortByField, sortByValue, user_id, limit, offset })
    );
  }, [dispatch, keyword, sortByField, sortByValue, user_id, limit, offset]);
  // useEffect(() => {
  //   let dateRange = "";
  //   if (date && date.e && date.e[0] && date.e[1]) {
  //     dispatch(
  //       getDriver({
  //         keyword,
  //         sortByField,
  //         sortByValue,
  //         user_id,
  //         limit,
  //         offset,
  //         date,
  //       })
  //     );
  //   }
  // }, [
  //   dispatch,
  //   keyword,
  //   sortByField,
  //   sortByValue,
  //   user_id,
  //   limit,
  //   offset,
  //   date,
  // ]);

 
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const onClickDeleteData = (id, status) => {
    dispatch(deleteDriver({ id, status }));
  };
  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    setUser("");
   
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyDriverFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Drivers" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Driver List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    {/* <Link
                      to="/driver/create"
                      className="btn btn-success add-btn"
                      id="createDriverTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Driver
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createDriverTooltip"
                    >
                      Create Driver
                    </UncontrolledTooltip> */}
                    {/* <Button color="danger" id='deleteTooltip'><i className="ri-delete-bin-2-line"></i></Button>
                                        <UncontrolledTooltip placement="top" target="deleteTooltip">Delete</UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={3} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for Driver name"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={user_id}
                            onChange={(e) => setUser(e.target.value)}
                          >
                            <option value="">Select User</option>
                            {userList &&
                              userList.map((item) => (
                                <option key={item?._id} value={item?._id}>
                                  {item?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={1} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {drivers?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="image"
                                  onClick={() => sortBy("image")}
                                >
                                  Image
                                </th> */}
                                <th
                                /* className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")} */
                                >
                                  Name
                                </th>
                                <th
                                /* className="sort cursor-pointer"
                                  data-sort="license_number"
                                  onClick={() => sortBy("license_number")} */
                                >
                                  DMV License #
                                </th>
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="driver_type"
                                  onClick={() => sortBy("driver_type")}
                                >
                                  Type
                                </th> */}

                                <th
                                /* className="sort cursor-pointer"
                                  data-sort="hired_on"
                                  onClick={() => sortBy("hired_on")} */
                                >
                                  Commercial Licence #
                                </th>
                                <th
                                /* className="sort cursor-pointer"
                                  data-sort="license_state"
                                  onClick={() => sortBy("license_state")} */
                                >
                                  Email
                                </th>
                                <th>User Details</th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {drivers.map((item) => (
                                <tr key={item?._id}>
                                  {/* <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                      />
                                    </div>
                                  </th> */}
                                  {/* <td>
                                    <img
                                      src={item.driver_image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td> */}
                                  <td>{item?.name}</td>
                                  <td>{item?.license_number}</td>
                                  <td>{item?.tlc_license}</td>
                                  {/* <td>
                                    <Moment format="D MMM YYYY">
                                      {item.hired_on}
                                    </Moment>
                                  </td> */}
                                  <td>{item?.email_id}</td>

                                  <td>
                                    {" "}
                                    <Link
                                      to={`/user/edit/${item?.user_details?._id}`}
                                      className=""
                                    >
                                     {item?.user_details?.name}
                                    <br />
                                    {item?.user_details?.email}
                                     
                                    </Link>
                                    
                                  </td>

                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white">
                                        <i className="ri-more-2-fill"></i>
                                      </DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Link
                                            to={`/driver/edit/${item._id}`}
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>{" "}
                                            Edit
                                          </Link>
                                        </DropdownItem>

                                        {/* <DropdownItem>
                                          <Button className="p-0 bg-transparent border-0 text-danger"
                                            onClick={()=>openDeleteConfrontation(item?._id)}
                                          >
                                              <i className="ri-delete-bin-5-fill fs-16"></i> Delete
                                          </Button>
                                        </DropdownItem> */}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {statusSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {statusFailed ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}

          <Modal
            isOpen={modal}
            toggle={openDeleteConfrontation}
            trigger="click"
          >
            <ModalHeader>Delete Driver</ModalHeader>
            <ModalBody>
              Are you sure want to delete?
              <div className="d-flex align-items-center justify-content-end mt-2">
                <Button
                  onClick={() => {
                    setModal(!modal);
                  }}
                  className="btn btn-sm btn-light"
                >
                  No
                </Button>

                <Button
                  onClick={() => {
                    onClickDeleteData(deletedId);
                    setModal(!modal);
                  }}
                  className="btn btn-sm btn-danger ms-2"
                >
                  Yes
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default DriverList;
