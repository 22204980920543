/* MISSIONVISIONS */
export const GET_MISSIONVISIONS = "GET_MISSIONVISIONS";
export const GET_MISSIONVISIONS_SUCCESS = "GET_MISSIONVISIONS_SUCCESS";
export const GET_MISSIONVISIONS_FAIL = "GET_MISSIONVISIONS_FAIL";

export const ADD_NEW_MISSIONVISION = "ADD_NEW_MISSIONVISION";
export const ADD_NEW_MISSIONVISION_SUCCESS = "ADD_NEW_MISSIONVISION_SUCCESS";
export const ADD_NEW_MISSIONVISION_FAIL = "ADD_NEW_MISSIONVISION_FAIL";

export const UPDATE_MISSIONVISION = "UPDATE_MISSIONVISION";
export const UPDATE_MISSIONVISION_SUCCESS = "UPDATE_MISSIONVISION_SUCCESS";
export const UPDATE_MISSIONVISION_FAIL = "UPDATE_MISSIONVISION_FAIL";

export const GET_MISSIONVISION_DETAILS = "GET_MISSIONVISION_DETAILS";
export const GET_MISSIONVISION_DETAILS_SUCCESS = "GET_MISSIONVISION_DETAILS_SUCCESS";
export const GET_MISSIONVISION_DETAILS_FAIL = "GET_MISSIONVISION_DETAILS_FAIL";

export const DELETE_MISSIONVISION = "DELETE_MISSIONVISION";
export const DELETE_MISSIONVISION_SUCCESS = "DELETE_MISSIONVISION_SUCCESS";
export const DELETE_MISSIONVISION_FAIL = "DELETE_MISSIONVISION_FAIL";

export const STATUS_CHANGE_MISSIONVISION = "STATUS_CHANGE_MISSIONVISION";
export const STATUS_CHANGE_MISSIONVISION_SUCCESS = "STATUS_CHANGE_MISSIONVISION_SUCCESS";
export const STATUS_CHANGE_MISSIONVISION_FAIL = "STATUS_CHANGE_MISSIONVISION_FAIL";

export const SAVE_ORDERING_MISSIONVISION = "SAVE_ORDERING_MISSIONVISION";
export const SAVE_ORDERING_MISSIONVISION_SUCCESS = "SAVE_ORDERING_MISSIONVISION_SUCCESS";
export const SAVE_ORDERING_MISSIONVISION_FAIL = "SAVE_ORDERING_MISSIONVISION_FAIL";
export const SAVE_ORDERING_MISSIONVISION_RESET_FLAG = "SAVE_ORDERING_MISSIONVISION_RESET_FLAG";

export const RESET_MISSIONVISION_FLAG = "RESET_MISSIONVISION_FLAG";

export const MODIFY_MISSIONVISION_FLAG = "MODIFY_MISSIONVISION_FLAG";

