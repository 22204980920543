import {
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  ADD_NEW_BANNER_SUCCESS,
  ADD_NEW_BANNER_FAIL,
  GET_BANNER_DETAILS_SUCCESS,
  GET_BANNER_DETAILS_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  RESET_BANNER_FLAG,

  SAVE_ORDERING_BANNER_SUCCESS,
  SAVE_ORDERING_BANNER_FAIL,
  SAVE_ORDERING_BANNER_RESET_FLAG,

} from "./actionTypes";

const INIT_STATE = {
  banners: []
};

const Banners = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_BANNERS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_BANNER_SUCCESS:
      return {
        ...state,
        banners: [...state.banners, action.payload.data],
        success: action.payload.message,
        isBannerAdd: true,
        isBannerAddFail: false,
      };

    case ADD_NEW_BANNER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBannerAdd: false,
        isBannerAddFail: true,
      };

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.map(banner =>
          banner._id.toString() === action.payload.data._id.toString()
            ? { ...banner, ...action.payload.data }
            : banner
        ),
        success: action.payload.message,
        isBannerUpdate: true,
        isBannerUpdateFail: false
      };

    case UPDATE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBannerUpdate: false,
        isBannerUpdateFail: true,
      };

    case GET_BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_BANNER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };


      //Save Ordering
    case SAVE_ORDERING_BANNER_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_BANNER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_BANNER_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

   
    case RESET_BANNER_FLAG:
      return {
        ...state,
        isBannerAdd: false,
        isBannerAddFail: false,
        isSuccess: false,
        isBannerDetails: false,
        isBannerUpdate: false,
        isBannerUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Banners;
