/* NOTIFICATIONS */
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const GET_NOTIFICATIONS_HISTORY = "GET_NOTIFICATIONS_HISTORY";
export const GET_NOTIFICATIONS_HISTORY_SUCCESS = "GET_NOTIFICATIONS_HISTORY_SUCCESS";
export const GET_NOTIFICATIONS_HISTORY_FAIL = "GET_NOTIFICATIONS_HISTORY_FAIL";

export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION";
export const ADD_NEW_NOTIFICATION_SUCCESS = "ADD_NEW_NOTIFICATION_SUCCESS";
export const ADD_NEW_NOTIFICATION_FAIL = "ADD_NEW_NOTIFICATION_FAIL";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";

export const GET_NOTIFICATION_DETAILS = "GET_NOTIFICATION_DETAILS";
export const GET_NOTIFICATION_DETAILS_SUCCESS = "GET_NOTIFICATION_DETAILS_SUCCESS";
export const GET_NOTIFICATION_DETAILS_FAIL = "GET_NOTIFICATION_DETAILS_FAIL";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";

export const STATUS_CHANGE_NOTIFICATION = "STATUS_CHANGE_NOTIFICATION";
export const STATUS_CHANGE_NOTIFICATION_SUCCESS = "STATUS_CHANGE_NOTIFICATION_SUCCESS";
export const STATUS_CHANGE_NOTIFICATION_FAIL = "STATUS_CHANGE_NOTIFICATION_FAIL";

export const SAVE_ORDERING_NOTIFICATION = "SAVE_ORDERING_NOTIFICATION";
export const SAVE_ORDERING_NOTIFICATION_SUCCESS = "SAVE_ORDERING_NOTIFICATION_SUCCESS";
export const SAVE_ORDERING_NOTIFICATION_FAIL = "SAVE_ORDERING_NOTIFICATION_FAIL";
export const SAVE_ORDERING_NOTIFICATION_RESET_FLAG = "SAVE_ORDERING_NOTIFICATION_RESET_FLAG";

export const RESET_NOTIFICATION_FLAG = "RESET_NOTIFICATION_FLAG";

export const MODIFY_NOTIFICATION_FLAG = "MODIFY_NOTIFICATION_FLAG";

export const RESET_NOTIFICATION_HISTORY_FLAG = "RESET_NOTIFICATION_HISTORY_FLAG";

