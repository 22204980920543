/* REFERRAL */
export const GET_REFERRAL = "GET_REFERRAL";
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAIL = "GET_REFERRAL_FAIL";


export const GET_REFERRAL_TRANSACTION = "GET_REFERRAL_TRANSACTION";
export const GET_REFERRAL_TRANSACTION_SUCCESS = "GET_REFERRAL_TRANSACTION_SUCCESS";
export const GET_REFERRAL_TRANSACTION_FAIL = "GET_REFERRAL_TRANSACTION_FAIL";

export const GET_REFERRAL_TRANSACTION_DETAILS = "GET_REFERRAL_TRANSACTION_DETAILS";
export const GET_REFERRAL_TRANSACTION_DETAILS_SUCCESS = "GET_REFERRAL_TRANSACTION_DETAILS_SUCCESS";
export const GET_REFERRAL_TRANSACTION_DETAILS_FAIL = "GET_REFERRAL_TRANSACTION_DETAILS_FAIL";

export const MODIFY_REFERRAL_DOCUMENT = "MODIFY_REFERRAL_DOCUMENT";
export const MODIFY_REFERRAL_DOCUMENT_SUCCESS = "MODIFY_REFERRAL_DOCUMENT_SUCCESS";
export const MODIFY_REFERRAL_DOCUMENT_FAIL = "MODIFY_REFERRAL_DOCUMENT_FAIL";

export const MODIFY_REFERRAL_PAID_STATUS = "MODIFY_REFERRAL_PAID_STATUS";
export const MODIFY_REFERRAL_PAID_STATUS_SUCCESS = "MODIFY_REFERRAL_PAID_STATUS_SUCCESS";
export const MODIFY_REFERRAL_PAID_STATUS_FAIL = "MODIFY_REFERRAL_PAID_STATUS_FAIL";

export const RESET_REFERRAL_FLAG = "RESET_REFERRAL_FLAG";
export const RESET_REFERRAL_STATUS_FLAG = "RESET_REFERRAL_STATUS_FLAG";