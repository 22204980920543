import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  RESET_CLIENT_FLAG,
  MODIFY_CLIENT_FLAG,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  STATUS_CHANGE_CLIENT,
  STATUS_CHANGE_CLIENT_SUCCESS,
  STATUS_CHANGE_CLIENT_FAIL,

  SAVE_ORDERING_CLIENT,
  SAVE_ORDERING_CLIENT_SUCCESS,
  SAVE_ORDERING_CLIENT_FAIL,
  SAVE_ORDERING_CLIENT_RESET_FLAG,
} from "./actionTypes";

export const getClients = data => ({
  type: GET_CLIENTS,
  payload: data,
});

export const getClientsSuccess = data => ({
  type: GET_CLIENTS_SUCCESS,
  payload: data,
});

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
});

export const addNewClient = client => ({
  type: ADD_NEW_CLIENT,
  payload: client,
});

export const addNewClientSuccess = client => ({
  type: ADD_NEW_CLIENT_SUCCESS,
  payload: client,
});

export const addNewClientFail = error => ({
  type: ADD_NEW_CLIENT_FAIL,
  payload: error,
});

export const updateClient = client => ({
  type: UPDATE_CLIENT,
  payload: client,
});

export const updateClientSuccess = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
});

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error,
});

export const getClientDetails = client => ({
  type: GET_CLIENT_DETAILS,
  payload: client,
});

export const getClientDetailsSuccess = role => ({
  type: GET_CLIENT_DETAILS_SUCCESS,
  payload: role,
});

export const getClientDetailsFail = error => ({
  type: GET_CLIENT_DETAILS_FAIL,
  payload: error,
});

export const resetClientFlag = () => {
  return {
    type: RESET_CLIENT_FLAG,
  }
}

export const deleteClient = client => ({
  type: DELETE_CLIENT,
  payload: client,
});

export const deleteClientSuccess = client => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: client,
});

export const deleteClientFail = error => ({
  type: DELETE_CLIENT_FAIL,
  payload: error,
});

export const statusChangeClient = client => ({
  type: STATUS_CHANGE_CLIENT,
  payload: client,
});

export const statusChangeClientSuccess = client => ({
  type: STATUS_CHANGE_CLIENT_SUCCESS,
  payload: client,
});


//Save Ordaring
export const saveOrderingClient = data => ({
  type: SAVE_ORDERING_CLIENT,
  payload: data,
});

export const saveOrderingClientSuccess = data => ({
  type: SAVE_ORDERING_CLIENT_SUCCESS,
  payload: data,
});

export const saveOrderingClientFail = error => ({
  type: SAVE_ORDERING_CLIENT_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingClientResetFlag = () => {
  return {
    type: SAVE_ORDERING_CLIENT_RESET_FLAG,
  }
}



export const statusChangeClientFail = error => ({
  type: STATUS_CHANGE_CLIENT_FAIL,
  payload: error,
});

export const modifyClientFlag = () => {
  return {
    type: MODIFY_CLIENT_FLAG,
  }
}
