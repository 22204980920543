import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
    const history = useHistory();
//     const [userData, setUserData] = useState(false);


// setUserData(localStorage.getItem('data'))
const role=JSON.parse(sessionStorage.getItem("authUser"))?JSON.parse(sessionStorage.getItem("authUser")).data.role_details.code:null;
    const visibleAllArray=['admin']
    const visibleManagerAdmin=['managers','admin']
    const [isRoles, setIsRoles] = useState(false);
    const [isVehicles, setIsVehicles] = useState(false);
    const [isCms, setIsCms] = useState(false);
    const [isPaidTickets, setIsPaidTickets] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    


    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);


    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);


    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Roles') {
            setIsRoles(false);
        }
        if (iscurrentState !== 'Vehicles') {
            setIsVehicles(false);
        }
      
        if (iscurrentState !== 'Cms') {
            setIsCms(false);
        }
      
        if (iscurrentState !== 'PaidTickets') {
            setIsPaidTickets(false);
        }
        if (iscurrentState !== 'Users') {
            setIsUsers(false);
        }

        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history.push("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
        isCms,
        isPaidTickets,
        isUsers
    ]);

    const menuItems = [
        {
            id: "roles",
            label: "Roles",
            icon: "ri-team-line",
            link: "/roles",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Roles');
            }
        },
        {
            id: "users",
            label: "Users",
            icon: "ri-user-line",
            link: "/#",
            stateVariables: isUsers,
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIsUsers(!isUsers);
                setIscurrentState('Users');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "users",
                    label: "Users",
                    link: "/users",
                    visible: visibleAllArray.includes(role),
                    parentId: "users",
                },
                {
                    id: "admins",
                    label: "Admins",
                    link: "/admins",
                    visible: visibleAllArray.includes(role),
                    parentId: "users",
                },
                {
                    id: "managers",
                    label: "Managers",
                    link: "/managers",
                    visible: visibleAllArray.includes(role),
                    parentId: "users",
                },
                {
                    id: "regional-managers",
                    label: "Regional managers",
                    link: "/regional-managers",
                    visible: visibleAllArray.includes(role),
                    parentId: "users",
                },
            ],
        },
        {
            id: "vehicles",
            label: "Vehicles",
            icon: " ri-car-washing-line",
            link: "/vehicles",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Vehicles');
            }
        },
        {
            id: "drivers",
            label: "Drivers",
            icon: " ri-shield-user-line",
            link: "/drivers",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Drivers');
            }
        },
        {
            id: "tickets",
            label: "Tlc Tickets",
            icon: " ri-ticket-2-line",
            link: "/tickets",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Tickets');
            }
        },
        {
            id: "dmv-tickets",
            label: "Dmv Tickets",
            icon: " ri-ticket-2-line",
            link: "/dmv-tickets",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('DmvTickets');
            }
        },
        {
            id: "pages",
            label: "Pages",
            icon: "  ri-pages-line",
            link: "/pages",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Pages');
            }
        },
        {
            id: "cms",
            label: "Cms",
            icon: "ri-article-line",
            link: "/#",
            visible: visibleAllArray.includes(role),
            stateVariables: isCms,
            click: function (e) {
                e.preventDefault();
                setIsCms(!isCms);
                setIscurrentState('Cms');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "banners",
                    label: "Banners",
                    link: "/banners",
                    parentId: "cms",
                    visible: visibleAllArray.includes(role),
                },
                {
                    id: "steps",
                    label: "Steps",
                    link: "/steps",
                    parentId: "cms",
                    visible: visibleAllArray.includes(role),
                },
                {
                    id: "solutions",
                    label: "Solutions",
                    link: "/solutions",
                    parentId: "cms",
                    visible: visibleAllArray.includes(role),
                },
                {
                    id: "clients",
                    label: "Clients",
                    link: "/clients",
                    parentId: "cms",
                    visible: visibleAllArray.includes(role),
                },
                {
                    id: "whyus",
                    label: "Why Us",
                    link: "/whyus",
                    parentId: "cms",
                    visible: visibleAllArray.includes(role),
                },
               
                {
                    id: "missionvisions",
                    label: "Mission Visions",
                    link: "/mission-visions",
                    parentId: "cms",
                    visible: visibleAllArray.includes(role),
                },
            ],
        },
        {
            id: "payment-tickets",
            label: "Paid Tickets",
            icon: "ri-bill-line",
            link: "/#",
            visible: visibleManagerAdmin.includes(role),
            stateVariables: isPaidTickets,
            click: function (e) {
                e.preventDefault();
                setIsPaidTickets(!isPaidTickets);
                setIscurrentState('PaidTickets');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "dmv-tickets",
                    label: "Dmv Tickets",
                    link: "/transactions/payment-tickets",
                    visible: visibleAllArray.includes(role),
                    parentId: "payment-tickets",
                },
                {
                    id: "tlc-tickets",
                    label: "Tlc Tickets",
                    link: "/transactions/payment-tickets-tlc",
                    visible: visibleManagerAdmin.includes(role),
                    parentId: "payment-tickets",
                },
              
            ],
        },
        {
            id: "transactions",
            label: "Transactions",
            icon: "ri-bill-line",
            link: "/transactions",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Transactions');
            }
        },
       
        {
            id: "invoice",
            label: "Invoices",
            icon: "ri-bill-line",
            link: "/billings",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Billings');
            }
        },
        {
            id: "coupons",
            label: "Coupons",
            icon: "ri-bill-line",
            link: "/coupons",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Coupons');
            }
        },
        {
            id: "referral-code",
            label: "Referral Code",
            icon: "ri-bill-line",
            link: "/referral-code",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('ReferralCode');
            }
        },
        {
            id: "notification",
            label: "Notifications",
            icon: "ri-message-2-line",
            link: "/notifications",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Notification');
            }
        },
        {
            id: "notification-history",
            label: "Notification History",
            icon: "ri-message-2-line",
            link: "/notifications-history",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Notification History');
            }
        },
        {
            id: "job",
            label: "Jobs",
            icon: "ri-briefcase-line",
            link: "/jobs",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Jobs');
            }
        },
        {
            id: "states",
            label: "States",
            icon: "ri-road-map-line",
            link: "/states",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('States');
            }
        },
        {
            id: "settings",
            label: "Settings",
            icon: " ri-settings-2-line",
            link: "/settings",
            visible: visibleAllArray.includes(role),
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Settings');
            }
        },

    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;