import {
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  ADD_NEW_ROLE_SUCCESS,
  ADD_NEW_ROLE_FAIL,
  GET_ROLE_DETAILS_SUCCESS,
  GET_ROLE_DETAILS_FAIL,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  RESET_ROLE_FLAG
} from "./actionTypes";

const INIT_STATE = {
  roles: []
};

const Roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_ROLE_SUCCESS:
      return {
        ...state,
        roles: [...state.roles, action.payload.data],
        success: action.payload.message,
        isRoleAdd: true,
        isRoleAddFail: false,
      };

    case ADD_NEW_ROLE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isRoleAdd: false,
        isRoleAddFail: true,
      };

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.map(role =>
          role._id.toString() === action.payload.data._id.toString()
            ? { ...role, ...action.payload.data }
            : role
        ),
        success: action.payload.message,
        isRoleUpdate: true,
        isRoleUpdateFail: false
      };

    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isRoleUpdate: false,
        isRoleUpdateFail: true,
      };

    case GET_ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_ROLE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case RESET_ROLE_FLAG:
      return {
        ...state,
        isRoleAdd: false,
        isRoleAddFail: false,
        isSuccess: false,
        isRoleDetails: false,
        isRoleUpdate: false,
        isRoleUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Roles;
