import {
  GET_WHYUS,
  GET_WHYUS_SUCCESS,
  GET_WHYUS_FAIL,

  ADD_NEW_WHYUS,
  ADD_NEW_WHYUS_SUCCESS,
  ADD_NEW_WHYUS_FAIL,

  GET_WHYUS_DETAILS,
  GET_WHYUS_DETAILS_SUCCESS,
  GET_WHYUS_DETAILS_FAIL,

  UPDATE_WHYUS,
  UPDATE_WHYUS_SUCCESS,
  UPDATE_WHYUS_FAIL,

  DELETE_WHYUS,
  DELETE_WHYUS_SUCCESS,
  DELETE_WHYUS_FAIL,

  STATUS_CHANGE_WHYUS,
  STATUS_CHANGE_WHYUS_SUCCESS,
  STATUS_CHANGE_WHYUS_FAIL,

  SAVE_ORDERING_WHYUS,
  SAVE_ORDERING_WHYUS_SUCCESS,
  SAVE_ORDERING_WHYUS_FAIL,
  SAVE_ORDERING_WHYUS_RESET_FLAG,

  RESET_WHYUS_FLAG,
  MODIFY_WHYUS_FLAG,



} from "./actionTypes";

export const getWhyus = data => ({
  type: GET_WHYUS,
  payload: data,
});

export const getWhyusSuccess = data => ({
  type: GET_WHYUS_SUCCESS,
  payload: data,
});

export const getWhyusFail = error => ({
  type: GET_WHYUS_FAIL,
  payload: error,
});

export const addNewWhyus = whyus => ({
  type: ADD_NEW_WHYUS,
  payload: whyus,
});

export const addNewWhyusSuccess = whyus => ({
  type: ADD_NEW_WHYUS_SUCCESS,
  payload: whyus,
});

export const addNewWhyusFail = error => ({
  type: ADD_NEW_WHYUS_FAIL,
  payload: error,
});

export const updateWhyus = whyus => ({
  type: UPDATE_WHYUS,
  payload: whyus,
});

export const updateWhyusSuccess = whyus => ({
  type: UPDATE_WHYUS_SUCCESS,
  payload: whyus,
});

export const updateWhyusFail = error => ({
  type: UPDATE_WHYUS_FAIL,
  payload: error,
});

export const getWhyusDetails = whyus => ({
  type: GET_WHYUS_DETAILS,
  payload: whyus,
});

export const getWhyusDetailsSuccess = role => ({
  type: GET_WHYUS_DETAILS_SUCCESS,
  payload: role,
});

export const getWhyusDetailsFail = error => ({
  type: GET_WHYUS_DETAILS_FAIL,
  payload: error,
});




//Delete
export const deleteWhyus = data => ({
  type: DELETE_WHYUS,
  payload: data,
});

export const deleteWhyusSuccess = data => ({
  type: DELETE_WHYUS_SUCCESS,
  payload: data,
});

export const deleteWhyusFail = error => ({
  type: DELETE_WHYUS_FAIL,
  payload: error,
});

//Status Change
export const statusChangeWhyus = data => ({
  type: STATUS_CHANGE_WHYUS,
  payload: data,
});

export const statusChangeWhyusSuccess = data => ({
  type: STATUS_CHANGE_WHYUS_SUCCESS,
  payload: data,
});

export const statusChangeWhyusFail = error => ({
  type: STATUS_CHANGE_WHYUS_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingWhyus = data => ({
  type: SAVE_ORDERING_WHYUS,
  payload: data,
});

export const saveOrderingWhyusSuccess = data => ({
  type: SAVE_ORDERING_WHYUS_SUCCESS,
  payload: data,
});

export const saveOrderingWhyusFail = error => ({
  type: SAVE_ORDERING_WHYUS_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingWhyusResetFlag = () => {
  return {
    type: SAVE_ORDERING_WHYUS_RESET_FLAG,
  }
}



//Modify Flag for toaster
export const modifyWhyusFlag = () => {
  return {
    type: MODIFY_WHYUS_FLAG,
  }
}

//Reset Flag for toaster
export const resetWhyusFlag = () => {
  return {
    type: RESET_WHYUS_FLAG,
  }
}









