import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_JOBS,
  GET_JOB_APPLIES,
  ADD_NEW_JOB,
  GET_JOB_DETAILS,
  UPDATE_JOB,
  REMOVE_JOB,
  STATUS_CHANGE_JOB,
} from "./actionTypes";

import {
  getJobsSuccess,
  getJobsFail,
  getJobAppliesSuccess,
  getJobAppliesFail,
  addNewJobSuccess,
  addNewJobFail,
  getJobDetailsSuccess,
  getJobDetailsFail,
  updateJobSuccess,
  updateJobFail,
  removeJobSuccess,
  removeJobFail,
  statusChangeJobSuccess,
  statusChangeJobFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  getJobList,
  getJobApplyList,
  addNewJob,
  getJobDetails,
  updateJob,
  removeJob,
  statusChangeJob,
} from "../../helpers/fakebackend_helper";


function* fetchJobs({ payload: job }) {
  try {
    const response = yield call(getJobList, job);
    yield put(getJobsSuccess(response));
  } catch (error) {
    yield put(getJobsFail(error));
  }
}
export function* watchFetchJobs() {
  yield takeEvery(GET_JOBS, fetchJobs);
}

function* fetchJobApplies({ payload: job }) {
  try {
    const response = yield call(getJobApplyList, job);
    yield put(getJobAppliesSuccess(response));
  } catch (error) {
    yield put(getJobAppliesFail(error));
  }
}
export function* watchFetchJobApplies() {
  yield takeEvery(GET_JOB_APPLIES, fetchJobApplies);
}



function* onAddNewJob({ payload: job }) {
  try {
    const response = yield call(addNewJob, job);
    if (response.status === 200) {
      yield put(addNewJobSuccess(response));
    } else {
      yield put(addNewJobFail(response));
    }
  } catch (error) {
    yield put(addNewJobFail(error));
  }
}

export function* watchAddNewJob() {
  yield takeEvery(ADD_NEW_JOB, onAddNewJob);
}

function* onJobDetails({ payload: job }) {
  try {
    const response = yield call(getJobDetails, job);
    if (response.status === 200) {
      yield put(getJobDetailsSuccess(response));
    } else {
      yield put(getJobDetailsFail(response));
    }
  } catch (error) {
    yield put(getJobDetailsFail(error));
  }
}

export function* watchJobDetails() {
  yield takeEvery(GET_JOB_DETAILS, onJobDetails);
}

function* onJobUpdate({ payload: job }) {
  try {
    const response = yield call(updateJob, job);
    if (response.status === 200) {
      yield put(updateJobSuccess(response));
    } else {
      yield put(updateJobFail(response));
    }
  } catch (error) {
    yield put(updateJobFail(error));
  }
}

export function* watchUpdateJob() {
  yield takeEvery(UPDATE_JOB, onJobUpdate);
}

function* onJobRemove({ payload: job }) {
  try {
    const response = yield call(removeJob, job);
    yield put(removeJobSuccess({ job, ...response }));
  } catch (error) {
    yield put(removeJobFail(error));
  }
}

export function* watchRemoveJob() {
  yield takeEvery(REMOVE_JOB, onJobRemove);
}

//Status Change
function* onStatusChangeJob({ payload: job }) {
  try {
    const response = yield call(statusChangeJob, job);
    yield put(statusChangeJobSuccess({ job, ...response }));
  } catch (error) {
    yield put(statusChangeJobFail(error));
  }
}

export function* watchStatusChangeJob() {
  yield takeEvery(STATUS_CHANGE_JOB, onStatusChangeJob);
}

function* jobSaga() {
  yield all([
    fork(watchFetchJobs),
    fork(watchFetchJobApplies),
    fork(watchAddNewJob),
    fork(watchJobDetails),
    fork(watchUpdateJob),
    fork(watchRemoveJob),
    fork(watchStatusChangeJob),
  ]);
}

export default jobSaga;
