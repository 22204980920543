import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal, 
  ModalHeader, 
  ModalBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getVehicles, getStateList, getUsers } from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import ReactPaginate from "react-paginate";

const VehicleList = () => {
  document.title = "Vehicle list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const [auto_pay, setAutoPay] = useState(null);
  const [plate_type, setPalteType] = useState("");
  const [state, setState] = useState("");
  const [user_id, setUser] = useState("");
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { vehicles, totalDataCount, isSuccess, error, stateList, userList } = useSelector(
    (state) => ({
      vehicles: state.Vehicles.vehicles,
      totalDataCount: state.Vehicles.totalDataCount,
      isSuccess: state.Vehicles.isSuccess,
      error: state.Vehicles.error,
      stateList: state.Vehicles.vehicleState,
      userList: state.Users.users,
    })
  );


  // const openDeleteConfrontation = (id) => {
  //   setDeletedId(id);
  //   setModal(!modal);
    
  // }

  useEffect(() => {
    dispatch(getStateList({country_code:'US'}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsers({role:'62de277397c8633a839c6450'}))
  }, [dispatch]);

  useEffect(() => {
    dispatch(
        getVehicles({ 
            keyword,
            date, 
            sortByField, 
            sortByValue, 
            limit, 
            offset,
            plate_type,
            auto_pay,
            vehicle_state:state,
            user_id, 
        })
    );
  }, [
        dispatch, 
        keyword, 
        date, 
        sortByField, 
        sortByValue, 
        limit, 
        offset,
        plate_type,
        auto_pay,
        state,
        user_id, 
    ]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setOffset(newOffset);
  };

  const resetFilter = () => {
    setKeyword("");
    setAutoPay(null);
    setPalteType("");
    setState("");
    setUser("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Vehicles" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Vehicle List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Button
                        color="danger"
                        onClick={resetFilter}
                        id="resetTooltip"
                        >
                        <i className="ri-restart-line me-1 align-bottom ml-2"></i>
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="resetTooltip"
                        >
                        Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value = {auto_pay}
                            onChange={(e) => {
                              const value = e.target.value === 'true' ? Boolean(e.target.value) : e.target.value === 'false' ? false : '';
                              setAutoPay(value)
                            }}
                          >
                            <option value="">Auto Pay</option>
                            <option value={true} >Yes</option>
                            <option value={false} >No</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value = {plate_type}
                            onChange={(e) => setPalteType(e.target.value)}
                          >
                            <option value="">Palte Type</option>
                            <option value='commercial' >Commercial</option>
                            <option value='personal' >Personal</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value = {state}
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option value="">Select State</option>
                            {stateList && stateList.map((item) => (
                              <option key={item?._id} value={item?._id}>{item?.name} - {item?.state_code}</option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>




                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value = {user_id}
                            onChange={(e) => setUser(e.target.value)}
                          >
                            <option value="">Select User</option>
                            {userList && userList.map((item) => (
                              <option key={item?._id} value={item?._id}>{item?.name}</option>
                            ))}
                          </select>
                        </div>
                      </Col>




                   







                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          {/* <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip> */}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {vehicles?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th
                                  // className="sort cursor-pointer"
                                  // data-sort="plate_no"
                                  // onClick={() => sortBy("plate_no")}
                                >
                                  Plate Number
                                </th>
                                <th
                                  // className="sort cursor-pointer"
                                  // data-sort="vehicle_vin"
                                  // onClick={() => sortBy("vehicle_vin")}
                                >
                                  VIN
                                </th>

                                <th>State</th>
                                <th>Type</th>
                                <th>Auto Pay</th>
                                <th>User Details</th>
                                <th
                                  // className="sort cursor-pointer"
                                  // data-sort="created_at"
                                  // onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {vehicles.map((item) => (
                                <tr key={item?._id}>
                                  <td>{item?.plate_no}</td>
                                  <td>{item?.vehicle_vin}</td>
                                  <td>{item?.vehicle_state?.state_code}</td>
                                  <td>{item?.plate_type}</td>
                                  <td>
                                    {item?.auto_pay &&
                                    item.auto_pay === true ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Yes
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        No
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <Link
                                      to={`/user/edit/${item?.user_details?._id}`}
                                      className=""
                                    >
                                     {item?.user_details?.name}
                                    <br />
                                    {item?.user_details?.email}
                                     
                                    </Link>
                                    
                                  </td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item?.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white"><i className="ri-more-2-fill"></i></DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Link
                                           to={`/vehicle/edit/${item._id}`}
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i> Edit
                                          </Link>
                                        </DropdownItem>

                                        {/* <DropdownItem>
                                          <Button className="p-0 bg-transparent border-0 text-danger"
                                            onClick={()=>openDeleteConfrontation(item?._id)}
                                          >
                                              <i className="ri-delete-bin-5-fill fs-16"></i> Delete
                                          </Button>
                                        </DropdownItem> */}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {
                            noOfPage > 1 &&
                            <div className='pagination-inner'>
                                <nav className="px-3" aria-label="Page navigation example">
                                  <ReactPaginate
                                    activeClassName = "active"
                                    breakClassName = "page-item"
                                    breakLinkClassName = "page-link"
                                    nextClassName = "page-item"
                                    nextLinkClassName = "page-link"
                                    previousClassName = "page-item"
                                    previousLinkClassName = "page-link"
                                    containerClassName = "pagination"
                                    pageClassName = "page-item"
                                    pageLinkClassName = "page-link"
                                    breakLabel="..."
                                    nextLabel="Next &nbsp;→"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={noOfPage}
                                    previousLabel="←&nbsp;Prev"
                                    renderOnZeroPageCount={null}
                                  />
                                </nav>
                            </div>
                          }
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Modal
            isOpen={modal}
            toggle={openDeleteConfrontation}
            trigger="click"
          >
            <ModalHeader>
              Delete Driver
            </ModalHeader>
            <ModalBody>
              Are you sure want to delete?
              <div className="d-flex align-items-center justify-content-end mt-2">
                <Button 
                  onClick={ () =>
                    {
                      setModal(!modal);
                    }
                  }
                  className="btn btn-sm btn-light">
                  No
                </Button>

                <Button
                  onClick={ () =>
                    {
                      onClickDeleteData(deletedId);
                      setModal(!modal);
                    }
                  }
                  className="btn btn-sm btn-danger ms-2"
                >
                  Yes
                </Button>
              </div>
            </ModalBody>
          </Modal> */}
        </Container>
      </div>
    </>
  );
};

export default VehicleList;
