import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewMissionvision, resetMissionvisionFlag } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";

const MissionvisionCreate = () => {
    document.title = "Create Mission vision | Defendit - Admin Panel";
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const history = useHistory();
    const { isMissionvisionAdd, isMissionvisionAddFail, error, success } = useSelector(state => ({
        isMissionvisionAdd: state.Missionvisions.isMissionvisionAdd,
        isMissionvisionAddFail: state.Missionvisions.isMissionvisionAddFail,
        error: state.Missionvisions.error,
        success: state.Missionvisions.success,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            icon: '',
            description: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            icon: Yup.string().required("Please Enter icon"),
            description: Yup.string().required("Please Enter description")
        }),

        
        onSubmit: (values) => {
            console.log(values);
            setIsCreating(true)
            const data = {
                name: values.name,
                icon: values.icon,
                description: values.description,
                type: 2
            }
            dispatch(addNewMissionvision(data))
        },
    });

    useEffect(() => {
        if(isMissionvisionAdd || isMissionvisionAddFail){
            setIsCreating(false)
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetMissionvisionFlag())
                history.push('/mission-visions');
            }, 3000);
        }
      }, [dispatch, isMissionvisionAdd, isMissionvisionAddFail, validation]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Mission vision" pageTitle="Mission visions" pageUrl="/mission-vision" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Create Mission vision" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Icon</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Icon"
                                                            name='icon'
                                                            value={validation.values.icon || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.icon && validation.errors.icon ? true : false
                                                            }
                                                        />
                                                        {validation.touched.icon && validation.errors.icon ? (
                                                            <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Description</Label>
                                                        <Input
                                                            type="textarea"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Description"
                                                            name='description'
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/mission-visions" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isMissionvisionAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isMissionvisionAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default MissionvisionCreate;