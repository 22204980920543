/* Banner */
export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL";

export const ADD_NEW_BANNER = "ADD_NEW_BANNER";
export const ADD_NEW_BANNER_SUCCESS = "ADD_NEW_BANNER_SUCCESS";
export const ADD_NEW_BANNER_FAIL = "ADD_NEW_BANNER_FAIL";

export const UPDATE_BANNER = "UPDATE_BANNER";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL";

export const GET_BANNER_DETAILS = "GET_BANNER_DETAILS";
export const GET_BANNER_DETAILS_SUCCESS = "GET_BANNER_DETAILS_SUCCESS";
export const GET_BANNER_DETAILS_FAIL = "GET_BANNER_DETAILS_FAIL";

export const SAVE_ORDERING_BANNER = "SAVE_ORDERING_BANNER";
export const SAVE_ORDERING_BANNER_SUCCESS = "SAVE_ORDERING_BANNER_SUCCESS";
export const SAVE_ORDERING_BANNER_FAIL = "SAVE_ORDERING_BANNER_FAIL";
export const SAVE_ORDERING_BANNER_RESET_FLAG = "SAVE_ORDERING_BANNER_RESET_FLAG";

export const RESET_BANNER_FLAG = "RESET_BANNER_FLAG";


