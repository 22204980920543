/* PAGES */
export const GET_PAGES = "GET_PAGES";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAIL = "GET_PAGES_FAIL";

export const ADD_NEW_PAGE = "ADD_NEW_PAGE";
export const ADD_NEW_PAGE_SUCCESS = "ADD_NEW_PAGE_SUCCESS";
export const ADD_NEW_PAGE_FAIL = "ADD_NEW_PAGE_FAIL";

export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const UPDATE_PAGE_FAIL = "UPDATE_PAGE_FAIL";

export const GET_PAGE_DETAILS = "GET_PAGE_DETAILS";
export const GET_PAGE_DETAILS_SUCCESS = "GET_PAGE_DETAILS_SUCCESS";
export const GET_PAGE_DETAILS_FAIL = "GET_PAGE_DETAILS_FAIL";

export const RESET_PAGE_FLAG = "RESET_PAGE_FLAG";