import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  ADD_NEW_ROLE,
  ADD_NEW_ROLE_SUCCESS,
  ADD_NEW_ROLE_FAIL,
  GET_ROLE_DETAILS,
  GET_ROLE_DETAILS_SUCCESS,
  GET_ROLE_DETAILS_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  RESET_ROLE_FLAG
} from "./actionTypes";

export const getRoles = data => ({
  type: GET_ROLES,
  payload: data,
});

export const getRolesSuccess = data => ({
  type: GET_ROLES_SUCCESS,
  payload: data,
});

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
});

export const addNewRole = role => ({
  type: ADD_NEW_ROLE,
  payload: role,
});

export const addNewRoleSuccess = role => ({
  type: ADD_NEW_ROLE_SUCCESS,
  payload: role,
});

export const addNewRoleFail = error => ({
  type: ADD_NEW_ROLE_FAIL,
  payload: error,
});

export const updateRole = role => ({
  type: UPDATE_ROLE,
  payload: role,
});

export const updateRoleSuccess = role => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
});

export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
});

export const getRoleDetails = role => ({
  type: GET_ROLE_DETAILS,
  payload: role,
});

export const getRoleDetailsSuccess = role => ({
  type: GET_ROLE_DETAILS_SUCCESS,
  payload: role,
});

export const getRoleDetailsFail = error => ({
  type: GET_ROLE_DETAILS_FAIL,
  payload: error,
});

export const resetRoleFlag = () => {
  return {
    type: RESET_ROLE_FLAG,
  }
}