import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_REFERRAL,
  GET_REFERRAL_TRANSACTION,
  GET_REFERRAL_TRANSACTION_DETAILS,
  MODIFY_REFERRAL_DOCUMENT,

  MODIFY_REFERRAL_PAID_STATUS
} from "./actionTypes"

import {
  getReferralSuccess,
  getReferralFail,

  getReferralTransactionSuccess,
  getReferralTransactionFail,

  getReferralTransactionDetailsSuccess,
  getReferralTransactionDetailsFail,

  modifyReferalDocumentSuccess,
  modifyReferalDocumentFail,

  modifyReferalPaidStatusSuccess,
  modifyReferalPaidStatusFail

} from "./actions"

//Include Both Helper File with needed methods
import {
  getReferralList,
  getReferralTransaction,
  getReferralTransactionDetails,
  modifyReferralDocument
} from "../../helpers/fakebackend_helper"

function* fetchReferral({ payload: referral }) {
  try {
    const response = yield call(getReferralList, referral)
    yield put(getReferralSuccess(response))
  } catch (error) {
    yield put(getReferralFail(error))
  }
}

export function* watchFetchReferral() {
  yield takeEvery(GET_REFERRAL, fetchReferral);
}


function* onGetReferralTransaction({ payload: referral }) {
  try {
    const response = yield call(getReferralTransaction, referral)
    yield put(getReferralTransactionSuccess(response))
  } catch (error) {
    yield put(getReferralTransactionFail(error))
  }
}

export function* watchGetReferralTransaction() {
  yield takeEvery(GET_REFERRAL_TRANSACTION, onGetReferralTransaction);
}


//Details
function* onGetReferralTransactionDetails({ payload: referral }) {
  try {
    const response = yield call(getReferralTransactionDetails, referral)
    yield put(getReferralTransactionDetailsSuccess(response))
  } catch (error) {
    yield put(getReferralTransactionDetailsFail(error))
  }
}

export function* watchGetReferralTransactionDetails() {
  yield takeEvery(GET_REFERRAL_TRANSACTION_DETAILS, onGetReferralTransactionDetails);
}

function* onModifyReferralDocument({ payload: referral }) {
  try {
    const response = yield call(modifyReferralDocument, referral)
    yield put(modifyReferalDocumentSuccess(response))
  } catch (error) {
    yield put(modifyReferalDocumentFail(error))
  }
}

export function* watchModifyReferralDocument() {
  yield takeEvery(MODIFY_REFERRAL_DOCUMENT, onModifyReferralDocument);
}


function* onModifyReferralPaidStatus({ payload: referral }) {
  try {
    const response = yield call(modifyReferralDocument, referral)
    yield put(modifyReferalPaidStatusSuccess(response))
  } catch (error) {
    yield put(modifyReferalPaidStatusFail(error))
  }
}

export function* watchModifyReferralPaidStatus() {
  yield takeEvery(MODIFY_REFERRAL_PAID_STATUS, onModifyReferralPaidStatus);
}


function* referralSaga() {
  yield all(
    [
      fork(watchFetchReferral),
      fork(watchGetReferralTransaction),
      fork(watchGetReferralTransactionDetails),
      fork(watchModifyReferralDocument),
      fork(watchModifyReferralPaidStatus),
    ]
  );

}

export default referralSaga
