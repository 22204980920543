import {
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  dashboard: [],
  isSuccess: false,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload.data,
        isSuccess: true
      };

    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload.message,
        dashboard:[],
        isSuccess: false
      };
    default:
      return state;
  }
};

export default Dashboard;
