import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data) =>
  api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) =>
  api.update(url.POST_EDIT_PROFILE + "/" + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postJwtLogin = (data) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// OTP Verification
export const forgetPasswordOTP = (data) =>
  api.create(url.FORGET_PASSWORD_OTP, data);

// Change Update password
export const forgetPasswordChange = (data) =>
  api.create(url.FORGET_PASSWORD_CHANGE, data);

// postSocialLogin
export const postSocialLogin = (data) => api.create(url.SOCIAL_LOGIN, data);

// Calendar
// get Events
export const getEvents = () => api.get(url.GET_EVENTS);

// get Events
export const getCategories = () => api.get(url.GET_CATEGORIES);

// get Upcomming Events
export const getUpCommingEvent = () => api.get(url.GET_UPCOMMINGEVENT);

// add Events
export const addNewEvent = (event) => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event) => api.update(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event) =>
  api.delete(url.DELETE_EVENT, { headers: { event } });

// Chat
// get Contact
export const getDirectContact = () => api.get(url.GET_DIRECT_CONTACT);

// get Messages
export const getMessages = (roomId) =>
  api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// add Message
export const addMessage = (message) => api.create(url.ADD_MESSAGE, message);

// get Channels
export const getChannels = () => api.get(url.GET_CHANNELS);

// MailBox
//get Mail
export const getMailDetails = () => api.get(url.GET_MAIL_DETAILS);

// delete Mail
export const deleteMail = (forId) =>
  api.delete(url.DELETE_MAIL, { headers: { forId } });

// Ecommerce
// get Products
export const getProducts = () => api.get(url.GET_PRODUCTS);

// delete Product
export const deleteProducts = (product) =>
  api.delete(url.DELETE_PRODUCT + "/" + product);

// get Orders
export const getOrders = () => api.get(url.GET_ORDERS);

// add Order
export const addNewOrder = (order) => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = (order) =>
  api.update(url.UPDATE_ORDER + "/" + order._id, order);

// delete Order
export const deleteOrder = (order) =>
  api.delete(url.DELETE_ORDER + "/" + order);

// get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS);

// add Customers
export const addNewCustomer = (customer) =>
  api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = (customer) =>
  api.update(url.UPDATE_CUSTOMER + "/" + customer._id, customer);

// delete Customers
export const deleteCustomer = (customer) =>
  api.delete(url.DELETE_CUSTOMER + "/" + customer);

// get Sellers
export const getSellers = () => api.get(url.GET_SELLERS);

// Project
// get Project list
export const getProjectList = () => api.get(url.GET_PROJECT_LIST);

// Tasks
// get Task
export const getTaskList = () => api.get(url.GET_TASK_LIST);

// add Task
export const addNewTask = (task) => api.create(url.ADD_NEW_TASK, task);

// update Task
export const updateTask = (task) =>
  api.update(url.UPDATE_TASK + "/" + task._id, task);

// delete Task
export const deleteTask = (task) => api.delete(url.DELETE_TASK + "/" + task);

// CRM
// get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS);

// add Contact
export const addNewContact = (contact) =>
  api.create(url.ADD_NEW_CONTACT, contact);

// update Contact
export const updateContact = (contact) =>
  api.update(url.UPDATE_CONTACT + "/" + contact._id, contact);

// delete Contact
export const deleteContact = (contact) =>
  api.delete(url.DELETE_CONTACT + "/" + contact);

// get Companies
export const getCompanies = () => api.get(url.GET_COMPANIES);

// add Companies
export const addNewCompanies = (company) =>
  api.create(url.ADD_NEW_COMPANIES, company);

// update Companies
export const updateCompanies = (company) =>
  api.update(url.UPDATE_COMPANIES + "/" + company._id, company);

// delete Companies
export const deleteCompanies = (company) =>
  api.delete(url.DELETE_COMPANIES + "/" + company);

// get Deals
export const getDeals = () => api.get(url.GET_DEALS);

// get Leads
export const getLeads = () => api.get(url.GET_LEADS);

// add Lead
export const addNewLead = (lead) => api.create(url.ADD_NEW_LEAD, lead);

// update Lead
export const updateLead = (lead) =>
  api.update(url.UPDATE_LEAD + "/" + lead._id, lead);

// delete Lead
export const deleteLead = (lead) => api.delete(url.DELETE_LEAD + "/" + lead);

// Crypto
// Transation
export const getTransationList = () => api.get(url.GET_TRANSACTION_LIST);

// Order List
export const getOrderList = () => api.get(url.GET_ORDRER_LIST);

// Invoice
//get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES);

// add Invoice
export const addNewInvoice = (invoice) =>
  api.create(url.ADD_NEW_INVOICE, invoice);

// add Invoice
export const statusChangeInvoice = (data) =>
  api.create(url.INVOICE_STATUS_CHANGE, data);

// update Invoice
export const updateInvoice = (invoice) =>
  api.update(url.UPDATE_INVOICE + "/" + invoice._id, invoice);

// delete Invoice
export const deleteInvoice = (invoice) =>
  api.delete(url.DELETE_INVOICE + "/" + invoice);

// Support Tickets
// Tickets
//export const getTicketsList = () => api.get(url.GET_TICKETS_LIST);

// add Tickets
export const addNewTicket = (ticket) => api.create(url.ADD_NEW_TICKET, ticket);

// update Tickets
export const updateTicket = (ticket) => api.create(url.UPDATE_TICKET, ticket);

// delete Tickets
export const deleteTicket = (ticket) =>
  api.delete(url.DELETE_TICKET + "/" + ticket);

// Role list
export const getRoleList = (role) => api.create(url.GET_ROLE_LIST, role);

// Role Create
export const addNewRole = (role) => api.create(url.ADD_NEW_ROLE, role);

// Role Update
export const updateRole = (role) => api.create(url.UPDATE_ROLE, role);

// Role Details
export const getRoleDetails = (role) => api.create(url.GET_ROLE_DETAILS, role);

// Vehicle list
export const getVehicleList = (vehicle) =>
  api.create(url.GET_VEHICLE_LIST, vehicle);

// Vehicle Create
export const addNewVehicle = (vehicle) =>
  api.create(url.ADD_NEW_VEHICLE, vehicle);

// Vehicle Update
export const updateVehicle = (vehicle) =>
  api.create(url.UPDATE_VEHICLE, vehicle);

// Vehicle Details
export const getVehicleDetails = (vehicle) =>
  api.create(url.GET_VEHICLE_DETAILS, vehicle);

// State List
export const getStateList = (data) => api.create(url.GET_STATE_LIST, data);

// Purchase type List
export const getPurchaseTypeList = () => api.create(url.GET_PURCHASE_TYPE_LIST);

// whyus list
export const getWhyusList = (whyus) => api.create(url.GET_WHYUS_LIST, whyus);

// whyus Create
export const addNewWhyus = (whyus) => api.create(url.ADD_NEW_WHYUS, whyus);

// whyus Update
export const updateWhyus = (whyus) => api.create(url.UPDATE_WHYUS, whyus);

// whyus Details
export const getWhyusDetails = (whyus) =>
  api.create(url.GET_WHYUS_DETAILS, whyus);

export const deleteWhyus = (whyus) => api.create(url.DELETE_WHYUS, whyus);
export const statusChangeWhyus = (whyus) =>
  api.create(url.STATUS_CHANGE_WHYUS, whyus);

// solution list
export const getSolutionList = (solution) =>
  api.create(url.GET_SOLUTION_LIST, solution);

// solution  Create
export const addNewSolution = (solution) =>
  api.create(url.ADD_NEW_SOLUTION, solution);

// solution Update
export const updateSolution = (solution) =>
  api.create(url.UPDATE_SOLUTION, solution);

// solution Details
export const getSolutionDetails = (solution) =>
  api.create(url.GET_SOLUTION_DETAILS, solution);
export const deleteSolution = (solution) =>
  api.create(url.DELETE_SOLUTION, solution);
export const statusChangeSolution = (solution) =>
  api.create(url.STATUS_CHANGE_SOLUTION, solution);

// Banner list
export const getBannerList = (banner) =>
  api.create(url.GET_BANNER_LIST, banner);

// Banner Create
export const addNewBanner = (banner) => api.create(url.ADD_NEW_BANNER, banner);

// Banner Update
export const updateBanner = (banner) => api.create(url.UPDATE_BANNER, banner);

// Banner Details
export const getBannerDetails = (banner) =>
  api.create(url.GET_BANNER_DETAILS, banner);

// testimonial list
export const getTestimonialList = (testimonial) =>
  api.create(url.GET_TESTIMONIAL_LIST, testimonial);

// testimonial Create
export const addNewTestimonial = (testimonial) =>
  api.create(url.ADD_NEW_TESTIMONIAL, testimonial);

// testimonial Update
export const updateTestimonial = (testimonial) =>
  api.create(url.UPDATE_TESTIMONIAL, testimonial);

// testimonial Details
export const getTestimonialDetails = (testimonial) =>
  api.create(url.GET_TESTIMONIAL_DETAILS, testimonial);

// Step list
export const getStepList = (step) => api.create(url.GET_STEP_LIST, step);

// Step Create
export const addNewStep = (step) => api.create(url.ADD_NEW_STEP, step);

// Step Update
export const updateStep = (step) => api.create(url.UPDATE_STEP, step);

// Step Details
export const getStepDetails = (step) => api.create(url.GET_STEP_DETAILS, step);

export const deleteStep = (step) => api.create(url.DELETE_STEP, step);
export const statusChangeStep = (step) =>
  api.create(url.STATUS_CHANGE_STEP, step);

// Client list
export const getClientList = (client) =>
  api.create(url.GET_CLIENT_LIST, client);

// Client Create
export const addNewClient = (client) => api.create(url.ADD_NEW_CLIENT, client);

// Client Update
export const updateClient = (client) => api.create(url.UPDATE_CLIENT, client);

// Client Details
export const getClientDetails = (client) =>
  api.create(url.GET_CLIENT_DETAILS, client);

// delete Client
export const deleteClient = (client) => api.create(url.DELETE_CLIENT, client);

// status change  client
export const statusChangeClient = (client) =>
  api.create(url.STATUS_CHANGE_CLIENT, client);

// Page list
export const getPageList = (page) => api.create(url.GET_PAGE_LIST, page);

// Page Create
export const addNewPage = (page) => api.create(url.ADD_NEW_PAGE, page);

// Page Update
export const updatePage = (page) => api.create(url.UPDATE_PAGE, page);

// Page Details
export const getPageDetails = (page) => api.create(url.GET_PAGE_DETAILS, page);

// sitesetting Update
export const updateSitesetting = (sitesetting) =>
  api.create(url.UPDATE_SITESETTING, sitesetting);

// sitesetting Details
export const getSitesettingDetails = (sitesetting) =>
  api.create(url.GET_SITESETTING_DETAILS, sitesetting);

// Tickets
export const getTicketsList = (ticket) =>
  api.create(url.GET_TICKETS_LIST, ticket);
export const getTicketsDetails = (ticket) =>
  api.create(url.GET_TICKETS_DETAILS, ticket);
export const getSignatureTicketDetails = (ticket) =>
  api.create(url.GET_TICKET_SIGNATURE_DETAILS, ticket);
export const getTicketAssignDetails = (ticket) =>
  api.create(url.GET_TICKET_ASSIGN_DETAILS, ticket);
export const updateAssignTicket = (ticket) =>
  api.create(url.UPDATE_ASSIGN_TICKET, ticket);

// driver list
export const getDriverList = (driver) =>
  api.create(url.GET_DRIVER_LIST, driver);

// driver Create
export const addNewDriver = (driver) => api.create(url.ADD_NEW_DRIVER, driver);

// driver Update
export const updateDriver = (driver) => api.create(url.UPDATE_DRIVER, driver);

// driver Details
export const getDriverDetails = (driver) =>
  api.create(url.GET_DRIVER_DETAILS, driver);

// delete Driver
export const deleteDriver = (driver) => api.create(url.DELETE_DRIVER, driver);

// City List
export const getCityList = () => api.create(url.GET_CITY_LIST);
// Country List
export const getCountryList = () => api.create(url.GET_COUNTRY_LIST);
// Mission vision list
export const getMissionvisionList = (missionvision) =>
  api.create(url.GET_MISSION_VISION_LIST, missionvision);
// Mission vision Create
export const addNewMissionvision = (missionvision) =>
  api.create(url.ADD_NEW_MISSION_VISION, missionvision);
// Mission vision Details
export const getMissionvisionDetails = (missionvision) =>
  api.create(url.GET_MISSION_VISION_DETAILS, missionvision);
// Mission vision Update
export const updateMissionvision = (missionvision) =>
  api.create(url.UPDATE_MISSION_VISION, missionvision);
// Mission vision Update
export const deleteMissionvision = (missionvision) =>
  api.create(url.DELETE_MISSIONVISION, missionvision);
// Mission vision Status change
export const statusChangeMissionvision = (missionvision) =>
  api.create(url.STATUS_CHANGE_MISSIONVISION, missionvision);

// Seo list
export const getSeoList = (seo) => api.create(url.GET_SEO_LIST, seo);

// Seo Create
export const addNewSeo = (seo) => api.create(url.ADD_NEW_SEO, seo);

// Seo Update
export const updateSeo = (seo) => api.create(url.UPDATE_SEO, seo);

// Seo Details
export const getSeoDetails = (seo) => api.create(url.GET_SEO_DETAILS, seo);

// Job list
export const getJobList = (job) => api.create(url.GET_JOB_LIST, job);

// Job  apply list
export const getJobApplyList = (job) => api.create(url.GET_JOB_APPLY_LIST, job);

// Job Create
export const addNewJob = (job) => api.create(url.ADD_NEW_JOB, job);

// Job Update
export const updateJob = (job) => api.create(url.UPDATE_JOB, job);

// Job Details
export const getJobDetails = (job) => api.create(url.GET_JOB_DETAILS, job);
// status change  job
export const statusChangeJob = (job) => api.create(url.STATUS_CHANGE_JOB, job);
// Job Remove
export const removeJob = (job) => api.create(url.REMOVE_JOB, job);

// Payment Ticket list
export const getTicketStatusList = (data) =>
  api.create(url.GET_TICKET_STATUS_LIST, data);
export const getTicketAssignStatusDocumentsList = (data) =>
  api.create(url.GET_TICKET_ASSIGN_STATUS_DOCUMENTS_LIST, data);

// Payment Ticket list
export const getPaymentTicketList = (data) =>
  api.create(url.GET_PAYMENT_TICKET_LIST, data);
export const statusChangeStatepay = (data) =>
  api.create(url.STATUS_CHANGE_TRANSACTION_STATEPAY, data);
export const uploadStatepayDocument = (data) =>
  api.create(url.UPLOAD_STATEPAY_DOCUMENT, data);
export const assignManager = (data) => api.create(url.ASSIGN_MANAGER, data);
export const assignUser = (data) => api.create(url.ASSIGN_USER, data);
// Billing list
export const getTransactionList = (billing) =>
  api.create(url.GET_BILLING_LIST, billing);
// Billing list
export const getTransactionDetails = (billing) =>
  api.create(url.GET_BILLING_DETAILS, billing);

// Biling
export const getBillingList = (billing) =>
  api.create(url.GET_BILLING_INVOICE_LIST, billing);
export const addNewBilling = (billing) =>
  api.create(url.ADD_NEW_BILLING_INVOICE, billing);
export const resendInvoice = (billing) =>
  api.create(url.RESEND_INVOICE, billing);

// State Update
export const updateState = (state) => api.create(url.UPDATE_STATE, state);

// State Details
export const getStateDetails = (state) =>
  api.create(url.GET_STATE_DETAILS, state);

// User list
export const getUserList = (user) => api.create(url.GET_USER_LIST, user);
// User Create
export const addNewUser = (user) => api.create(url.ADD_NEW_USER, user);
// User Update
export const updateUser = (user) => api.create(url.UPDATE_USER, user);
// User Details
export const getUserDetails = (user) => api.create(url.GET_USER_DETAILS, user);
// User Status Change
export const statusChangeUser = (user) =>
  api.create(url.STATUS_CHANGE_USER, user);
export const verifiedPhoneEmail = (user) =>
  api.create(url.VERIFIED_EMAIL_PHONE, user);
// User Change Password
export const passwordChangeUser = (user) =>
  api.create(url.PASSWORD_CHANGE_USER, user);
//Delete User
export const deleteUser = (user) => api.create(url.DELETE_USER, user);

// Referral list
export const getReferralList = (referral) =>
  api.create(url.GET_REFERRAL_LIST, referral);
// Referral code list
export const getReferralCodeList = (referral) =>
  api.create(url.GET_REFERRAL_CODE_LIST, referral);

export const getReferralTransaction = (referral) =>
  api.create(url.GET_REFERRAL_TRANSACTION, referral);

export const modifyReferralDocument = (data) =>
  api.create(url.MODIFY_REFERRAL_DOCUMENT, data);

export const getReferralTransactionDetails = (referral) =>
  api.create(url.GET_REFERRAL_TRANSACTION_DETAILS, referral);

export const statusChangeReferralCode = (referral) =>
  api.create(url.REFERRAL_CODE_STATUS_CHANGE, referral);

//Dashboard
export const getDashboard = (data) => api.create(url.GET_DASHBOARD, data);

// Coupons
export const getCoupons = (data) => api.create(url.GET_COUPONS, data);
export const addNewCoupon = (data) => api.create(url.ADD_NEW_COUPON, data);
export const getCouponDetails = (data) =>
  api.create(url.GET_COUPON_DETAILS, data);
export const updateCoupon = (data) => api.create(url.UPDATE_COUPON, data);
export const deleteCoupons = (data) => api.create(url.DELETE_COUPON, data);
export const statusChangeCoupons = (data) =>
  api.create(url.STATUS_CHANGE_COUPON, data);

// Notification
export const getNotificationList = (data) =>
  api.create(url.GET_NOTIFICATION_LIST, data);

export const getNotificationHistoryList = (data) =>
  api.create(url.GET_NOTIFICATION_HISTORY_LIST, data);

//Save Ordering
export const saveOrdering = (data) => api.create(url.SAVE_ORDERING, data);
