/* ROLES */
export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_FAIL = "GET_DRIVERS_FAIL";

export const ADD_NEW_DRIVER = "ADD_NEW_DRIVER";
export const ADD_NEW_DRIVER_SUCCESS = "ADD_NEW_DRIVER_SUCCESS";
export const ADD_NEW_DRIVER_FAIL = "ADD_NEW_DRIVER_FAIL";

export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_SUCCESS";
export const UPDATE_DRIVER_FAIL = "UPDATE_DRIVER_FAIL";


export const DELETE_DRIVER = "DELETE_DRIVER";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_FAIL = "DELETE_DRIVER_FAIL";

export const GET_DRIVER_DETAILS = "GET_DRIVER_DETAILS";
export const GET_DRIVER_DETAILS_SUCCESS = "GET_DRIVER_DETAILS_SUCCESS";
export const GET_DRIVER_DETAILS_FAIL = "GET_DRIVER_DETAILS_FAIL";

export const RESET_DRIVER_FLAG = "RESET_DRIVER_FLAG";

// CITY 
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS";
export const GET_CITY_LIST_FAIL = "GET_CITY_LIST_FAIL";

// COUNTRY 
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAIL = "GET_COUNTRY_LIST_FAIL";


// MODIFY DRIVER FLAG
export const MODIFY_DRIVER_FLAG = "MODIFY_DRIVER_FLAG";

