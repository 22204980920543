import {
  GET_BILLINGS_SUCCESS,
  GET_BILLINGS_FAIL,

  ADD_NEW_BILLING_SUCCESS,
  ADD_NEW_BILLING_FAIL,

  RESEND_INVOICE_SUCCESS,
  RESEND_INVOICE_FAIL,

  INVOICE_STATUS_CHANGE_SUCCESS,
  INVOICE_STATUS_CHANGE_FAIL,
  // GET_STEP_DETAILS_SUCCESS,
  // GET_STEP_DETAILS_FAIL,
  // UPDATE_STEP_SUCCESS,
  // UPDATE_STEP_FAIL,
  // DELETE_STEP_SUCCESS,
  // DELETE_STEP_FAIL,
  // STATUS_CHANGE_STEP_SUCCESS,
  // STATUS_CHANGE_STEP_FAIL,

  RESET_BILLING_FLAG,
  MODIFY_BILLING_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  billings: []
};

const Billings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLINGS_SUCCESS:
      return {
        ...state,
        billings: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_BILLINGS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_BILLING_SUCCESS:
      return {
        ...state,
        billings: [...state.billings, action.payload.data],
        success: action.payload.message,
        isBillingAdd: true,
        isBillingAddFail: false,
      };

    case ADD_NEW_BILLING_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBillingAdd: false,
        isBillingAddFail: true,
      };


  case RESEND_INVOICE_SUCCESS:
    return {
      ...state,
      success: action.payload.message,
      resendSuccess: true,
      resendFaild: false,
    };

    case ADD_NEW_BILLING_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBillingAdd: false,
        isBillingAddFail: true,
      };


  case RESEND_INVOICE_SUCCESS:
    return {
      ...state,
      success: action.payload.message,
      resendSuccess: true,
      resendFaild: false,
    };

  case RESEND_INVOICE_FAIL:
    return {
      ...state,
      error: action.payload.message,
      resendSuccess: true,
      resendFaild: false,
    };


  case INVOICE_STATUS_CHANGE_SUCCESS:
    return {
      ...state,
      success: action.payload.message,
      statusChangeSuccess: true,
      statusChangeFaild: false,
    };

  case INVOICE_STATUS_CHANGE_FAIL:
    return {
      ...state,
      error: action.payload.message,
      statusChangeSuccess: false,
      statusChangeFaild: true,
    };


    case MODIFY_BILLING_FLAG:
      return {
        ...state,
        resendSuccess: false,
        resendFaild: false,
        statusChangeSuccess: false,
        statusChangeFaild: false,
        error: false
      };

    case RESET_BILLING_FLAG:
      return {
        ...state,
        isBillingAdd: false,
        isBillingAddFail: false,
        isSuccess: false,
        statusChangeSuccess: false,
        statusChangeFaild: false,
        error: false
      };

    default:
      return state;
  }
};

export default Billings;
