import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,

  GET_NOTIFICATIONS_HISTORY_SUCCESS,
  GET_NOTIFICATIONS_HISTORY_FAIL,

  MODIFY_NOTIFICATION_FLAG,
  RESET_NOTIFICATION_FLAG, 
  RESET_NOTIFICATION_HISTORY_FLAG
} from "./actionTypes";

const INIT_STATE = {
  notifications: [],
  notifications_history: []
};

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    //LIST
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        
        ...state,
        notifications: action.payload.data,
        totalDataCount: action.payload.count,
        totalUnreadDataCount: action.payload.unreadCountAdmin,
        isSuccess: true
      };

    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        totalUnreadDataCount: 0,
        isSuccess: false
      };

    case GET_NOTIFICATIONS_HISTORY_SUCCESS:
      return {
        
        ...state,
        notifications_history: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccessNotificationHistory: true
      };

    case GET_NOTIFICATIONS_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccessNotificationHistory: false
      };


    //MODIFY RESET  
    case MODIFY_NOTIFICATION_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_NOTIFICATION_FLAG:
      return {
        ...state,
       
        error: false
      };
    case RESET_NOTIFICATION_HISTORY_FLAG:
      return {
        ...state,
       
        error: false
      };

    default:
      return state;
  }
};

export default Notifications;
