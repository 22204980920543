import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getNotificationsHistory,
  getUsers,
  getRoles,

} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const NotificationHistoryList = () => {
  document.title = "Notification History list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("create_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [usersId, setUserId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('authUser')));
  const dispatch = useDispatch();
  const {
    notifications_history,
    totalDataCount,
    isSuccessNotificationHistory,
    error,
    success,
   roles,
   userList
  } = useSelector((state) => ({
    notifications_history: state.Notification.notifications_history,
    totalDataCount: state.Notification.totalDataCount,
    isSuccessNotificationHistory: state.Notification.isSuccessNotificationHistory,
    error: state.Notification.error,
    success: state.Notification.success,
    roles: state.Roles.roles,
    userList: state.Users.users,

  }));
  useEffect(() => {
    dispatch(
      getRoles({ keyword:"user"})
    );
  }, []);
  useEffect(()=>{
    dispatch(getUsers({
      role:roles[0]?._id
    }))
  },[roles]);
  useEffect(() => {
    dispatch(
      getNotificationsHistory({
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
        user_id:usersId
      })
    );
  }, [
    dispatch,
    keyword,
    date,

    sortByField,
    sortByValue,
    limit,
    offset,
    userDetails,
    usersId
  ]);
  
 
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setOffset(newOffset);
  };

  const resetFilter = () => {
    setKeyword("");
    setUserId("");
    fp.current.flatpickr.clear();
    setDate(null);
  };
const replaceData = (temp, config) => {
  let output = temp.replace(/{%SENDER_NAME%}/g, config);
  return output;
}

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Notification history" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Notification History List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={4} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={usersId}
                            onChange={(e) => setUserId(e.target.value)}
                          >
                            <option value="">Select User</option>
                            {userList &&
                              userList.map((item) => (
                                <option key={item?._id} value={item?._id}>
                                  {item?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                  
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccessNotificationHistory ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {notifications_history?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>
                                  Type
                                </th>
                                <th>Ticket</th>
                                <th>User</th>
                                <th>Message</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Date
                                </th>
                                
                              </tr>
                            </thead>
                          
                            <tbody className="list form-check-all">
                              {notifications_history?.map((item) =>{
                                return(
                                  <tr key={item._id}>
                                    <td>
                                      <span className="d-block">
                                        {item?.type|| "N.A."}
                                      </span>
                                      
                                    </td>

                                    <td>
                                    {item?.summons_number|| "N.A."}
                                    </td>

                                    <td>
                                      {
                                       item?.user_details?.name
                                        ||"N.A."
                                      }
                                    </td>
                                    <td>
                                    {item?.body|| "N.A."}
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY , h:mm a">
                                        {item.created_at}
                                      </Moment>
                                    </td>
                                     
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                          
                            {
                              noOfPage > 1 &&
                              <div className='pagination-inner'>
                                  <nav className="px-3" aria-label="Page navigation example">
                                    <ReactPaginate
                                      activeClassName = "active"
                                      breakClassName = "page-item"
                                      breakLinkClassName = "page-link"
                                      nextClassName = "page-item"
                                      nextLinkClassName = "page-link"
                                      previousClassName = "page-item"
                                      previousLinkClassName = "page-link"
                                      containerClassName = "pagination"
                                      pageClassName = "page-item"
                                      pageLinkClassName = "page-link"
                                      breakLabel="..."
                                      nextLabel="Next &nbsp;→"
                                      onPageChange={handlePageClick}
                                      pageRangeDisplayed={3}
                                      pageCount={noOfPage}
                                      previousLabel="←&nbsp;Prev"
                                      renderOnZeroPageCount={null}
                                    />
                                  </nav>
                              </div>
                            }
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
    
        </Container>
      </div>
    </>
  );
};

export default NotificationHistoryList;
