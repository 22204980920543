import {
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAIL,

  GET_REFERRAL_TRANSACTION_SUCCESS,
  GET_REFERRAL_TRANSACTION_FAIL,

  GET_REFERRAL_TRANSACTION_DETAILS_SUCCESS,
  GET_REFERRAL_TRANSACTION_DETAILS_FAIL,
  
  MODIFY_REFERRAL_DOCUMENT_FAIL,
  MODIFY_REFERRAL_DOCUMENT_SUCCESS,

  MODIFY_REFERRAL_PAID_STATUS_FAIL,
  MODIFY_REFERRAL_PAID_STATUS_SUCCESS,


  RESET_REFERRAL_FLAG,
  RESET_REFERRAL_STATUS_FLAG,

} from "./actionTypes";

const INIT_STATE = {
  referrals: []
};

const Referrals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REFERRAL_SUCCESS:
      return {
        ...state,
        referrals: action.payload.data,
        totalDataCount: action.payload.count,
        referralCode: action.payload.referralCode,
        isSuccess: true
      };

    case GET_REFERRAL_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

      


     case GET_REFERRAL_TRANSACTION_SUCCESS:
      return {
        ...state,
        totalSpend: action.payload.totalSpend,
        totalEarning: action.payload.totalEarning,
        total: action.payload.total,
        isSuccess: true
      };

     case GET_REFERRAL_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalSpend: 0,
        totalEarning: 0,
        total: 0,
        isSuccess: false
      };
     
      case MODIFY_REFERRAL_DOCUMENT_SUCCESS:
      return {
        success: action.payload.message,
        isModifyReferralDocumentSuccess: true,
        isModifyReferralDocumentFail: false
      };

     case MODIFY_REFERRAL_DOCUMENT_FAIL:
      return {
        isModifyReferralDocumentSuccess: false,
        isModifyReferralDocumentFail: true
      };
      case MODIFY_REFERRAL_PAID_STATUS_SUCCESS:
      return {
        success: action.payload.message,
        isModifyReferralPaidStatusSuccess: true,
        isModifyReferralPaidStatusFail: false
      };

     case MODIFY_REFERRAL_PAID_STATUS_FAIL:
      return {
        isModifyReferralPaidStatusSuccess: false,
        isModifyReferralPaidStatusFail: true
      };

      //Details
    case GET_REFERRAL_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        referralsDetails: action.payload.data,
        totalDataCount: action.payload.count,
        referralCode: action.payload.referralCode,
        isSuccess: true
      };

    case GET_REFERRAL_TRANSACTION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };


    case RESET_REFERRAL_FLAG:
      return {
        ...state,
        isSuccess: false,
        error: false,
        isModifyReferralDocumentSuccess:false,
        isModifyReferralDocumentFail: false,
        isModifyReferralPaidStatusSuccess:false,
        isModifyReferralPaidStatusFail: false
      };
    case RESET_REFERRAL_STATUS_FLAG:
      return {
        ...state,
        isModifyReferralDocumentSuccess:false,
        isModifyReferralDocumentFail: false,
        isModifyReferralPaidStatusSuccess:false,
        isModifyReferralPaidStatusFail: false
      };

    default:
      return state;
  }
};

export default Referrals;
