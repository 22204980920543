import React, { useEffect, useState, useRef, Fragment } from "react";
import { api } from "../../../config";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getReferralCode,
  deleteUser,
  statusChangeReferralCode,
  modifyReferralCodeFlag,
  getUsers,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";

const UserList = () => {
  
  document.title = "Referral Code List | Defendit - Admin Panel";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [role, setRole] = useState("62de277397c8633a839c6450");
  const [date, setDate] = useState(null);
  const [isDisabledReferredCode, setisDisabledReferredCode] = useState(null);
  const [user_id, setUser] = useState("");
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const {
    referralCode,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFaild,
    userList,
  } = useSelector((state) => ({
    referralCode: state.ReferralCode.referralCode,
    totalDataCount: state.ReferralCode.totalDataCount,
    isSuccess: state.ReferralCode.isSuccess,
    statusSuccess: state.ReferralCode.statusSuccess,
    statusFaild: state.ReferralCode.statusFaild,
    error: state.ReferralCode.error,
    success: state.ReferralCode.success,
    userList: state.Users.users,
  }));


  useEffect(() => {
    dispatch(
      getReferralCode({
        role,
        keyword,
        date,
        is_disabled_referred_code : isDisabledReferredCode,
        sortByField,
        sortByValue,
        limit,
        offset,
        user_id,
      })
    );
  }, [
    dispatch,
    keyword,
    date,
    role,
    isDisabledReferredCode,
    sortByField,
    sortByValue,
    limit,
    offset,
    user_id,
  ]);



  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    dispatch(getUsers({role:'62de277397c8633a839c6450'}))
  }, [dispatch]);

  const resetFilter = () => {
    setKeyword("");
    setisDisabledReferredCode("");
    setUser("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };


  //On Click Status Change
  function onClickStatusChange(id, is_disabled_referred_code) {
    dispatch(statusChangeReferralCode({ id, is_disabled_referred_code }));
  }

  useEffect(() => {
    if (statusSuccess || statusFaild) {
      setTimeout(() => {
        dispatch(modifyReferralCodeFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFaild]);



  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Referral Code" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Referral Code List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={isDisabledReferredCode}
                            //onChange={(e) => setStatus(e.target.value)}

                            onChange={(e) => {
                              const value = e.target.value === 'true' ? Boolean(e.target.value) : e.target.value === 'false' ? false : '';
                              setisDisabledReferredCode(value)
                            }}
                          >
                            <option value="">Select status</option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                          </select>
                        </div>
                      </Col>
                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value = {user_id}
                            onChange={(e) => setUser(e.target.value)}
                          >
                            <option value="">Select User</option>
                            {userList && userList.map((item) => (
                              <option key={item?._id} value={item?._id}>{item?.name}</option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {referralCode?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th>Image</th> */}
                                <th>Referral Code</th>
                                <th>User</th>
                                <th>Code Status</th>
                                <th> Created Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {referralCode.map((item) => (
                                <tr key={item._id}>
                                  {/* <td>
                                    {" "}
                                    <img
                                      src={item.image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td> */}
                                  <td>{item?.referred_code}</td>
                                  <td>
                                    {item.name ? (
                                      <span className="d-block">
                                        Name : {item.name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {item.email ? (
                                      <span className="d-block">
                                        Email : {item.email}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {item.phone ? (
                                      <span className="d-block">
                                        Phone : {item.phone}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                 
                                  <td>
                                    {item.is_disabled_referred_code && item.is_disabled_referred_code === true ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white"><i className="ri-more-2-fill"></i></DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Button
                                            onClick={() =>
                                              onClickStatusChange(
                                                item._id,
                                                item.is_disabled_referred_code &&
                                                  item.is_disabled_referred_code === true
                                                  ? false
                                                  : true
                                              )
                                            }
                                            className="p-0 bg-transparent border-0 text-success"
                                          >
                                            <i className="ri-exchange-line fs-16"></i> Code Status Change
                                          </Button>
                                        </DropdownItem>

                                        <DropdownItem>
                                          <Link
                                            to={`/referral/${item._id}`}
                                            className="text-success d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-share-line fs-16"></i> Details
                                          </Link>
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>



                          
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          {statusSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {statusFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}

        </Container>
      </div>
    </>
  );
};

export default UserList;
