import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { updateRole, resetRoleFlag, getRoleDetails } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import { call} from "redux-saga/effects"

const RoleEdit = () => {
    document.title = "Edit Role | Defendit - Admin Panel";
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const { isRoleUpdate, isRoleUpdateFail, error, success, details } = useSelector(state => ({
        isRoleUpdate: state.Roles.isRoleUpdate,
        isRoleUpdateFail: state.Roles.isRoleUpdateFail,
        error: state.Roles.error,
        success: state.Roles.success,
        details: state.Roles.details,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (details && details.name)?details.name:''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Role Name")
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                id: id,
                name: values.name
            }
            dispatch(updateRole(data))
        },
    });

    useEffect(() => {
        if(isRoleUpdate || isRoleUpdateFail){
            setIsCreating(false)
            setTimeout(() => {
                dispatch(resetRoleFlag())
            }, 3000);
        }
      }, [dispatch, isRoleUpdate, isRoleUpdateFail]);

      useEffect(() => {
        dispatch(getRoleDetails({id:id}))
      }, [dispatch, id]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Edit Role" pageTitle="Roles" pageUrl="/roles" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Edit Role" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Role name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/roles" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Update </Spinner>}
                                            <span className="flex-grow-1">
                                                Update
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isRoleUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isRoleUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default RoleEdit;