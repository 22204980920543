import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card,  CardHeader,CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { updateSitesetting, resetSitesettingFlag, getSitesettingDetails } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import { call} from "redux-saga/effects"
import {
    convertToBase64 
  } from "../../../helpers/image_helper"
const SitesettingEdit = () => {
    document.title = "Edit Sitesetting | Defendit - Admin Panel";
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const [creditFeesType, setCreditFeesType] = useState("fixed");
    const [processingFeesType, setProcessingFeesType] = useState("fixed");
    const [image, setImage] = useState();
    const allowedExts = ['jpg', 'jpeg', 'png','svg','svg+xml'];
    const { isSitesettingUpdate, isSitesettingUpdateFail, error, success, details } = useSelector(state => ({
        isSitesettingUpdate: state.Sitesettings.isSitesettingUpdate,
        isSitesettingUpdateFail: state.Sitesettings.isSitesettingUpdateFail,
        error: state.Sitesettings.error,
        success: state.Sitesettings.success,
        details: state.Sitesettings.details,
    }));
    const onImgChange = (e) => {
        if (e.target.files[0]) {
          const ext = e.target.files[0].type.split('/')[1];
    
          if (![...allowedExts].includes(ext)) {
            console.log('Please upload a valid image');
            toast("Please upload a valid image.");
          } else {
            // getBase64(e.target.files[0]);
            var output = document.getElementById('image');
              output.src = URL.createObjectURL(e.target.files[0]);
              output.onload = function() {
                URL.revokeObjectURL(output.src) // free memory
              }
            convertToBase64(e.target.files[0], setImage);
      
          }
        }
      };
     
    

    useEffect(() => {
        if(isSitesettingUpdate || isSitesettingUpdateFail){
            setIsCreating(false)
            setTimeout(() => {
                dispatch(resetSitesettingFlag())
            }, 3000);
        }
      }, [dispatch, isSitesettingUpdate, isSitesettingUpdateFail]);

      useEffect(() => {
        dispatch(getSitesettingDetails({id:id}))
      }, [dispatch, id]);
      useEffect(() => {
       
        setCreditFeesType(details?.creadit_fee_type)
        setProcessingFeesType(details?.processing_fee_type)
    }, [dispatch, details]);
      const isCreditFeesTypeChange= (event) => {
     console.log(event.target.value)
        setCreditFeesType(event.target.value);
      };
      const isProcessingFeesTypeChange= (event) => {
     console.log(event.target.value)
     setProcessingFeesType(event.target.value);
      };
      const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: (details && details.title)?details.title:'',
            referred_percentage: (details && details.referred_percentage)?details.referred_percentage:'',
            address: (details && details.address)?details.address:'',
            appstore_link: (details && details.appstore_link)?details.appstore_link:'',
            contact_no: (details && details.contact_no)?details.contact_no:'',
            copyright: (details && details.copyright)?details.copyright:'',
            email: (details && details.email)?details.email:'',
            facebook_link: (details && details.facebook_link)?details.facebook_link:'',
            instagram_link: (details && details.instagram_link)?details.instagram_link:'',
            playstore_link: (details && details.playstore_link)?details.playstore_link:'',
            twitter_link: (details && details.twitter_link)?details.twitter_link:'',
            whatsapp_no: (details && details.whatsapp_no)?details.whatsapp_no:'',
            tlc_amount: (details && details.tlc_amount)?details.tlc_amount:'',
            fight_tlc_amount: (details && details.fight_tlc_amount)?details.fight_tlc_amount:'',
            logo: (details && details.logo)?details.logo:'',
            video: (details && details.video)?details.video:'',
            creadit_fee: (details && details.creadit_fee)?details.creadit_fee:'',
            processing_fee: (details && details.processing_fee)?details.processing_fee:'',
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Sitesetting Title"),
            tlc_amount: Yup.string().required("Please Enter Settle amount"),
            fight_tlc_amount: Yup.string().required("Please Enter fight amount"),
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                id: (details && details._id)?details._id:'',
                title: values.title,
                referred_percentage: values.referred_percentage,
                address: values.address,
                appstore_link: values.appstore_link,
                contact_no: values.contact_no,
                copyright: values.copyright,
                email: values.email,
                instagram_link: values.instagram_link,
                facebook_link: values.facebook_link,
                playstore_link: values.playstore_link,
                twitter_link: values.twitter_link,
                whatsapp_no: values.whatsapp_no,
                fight_tlc_amount: values.fight_tlc_amount,
                tlc_amount: values.tlc_amount,
                creadit_fee: values.creadit_fee,
                processing_fee: values.processing_fee,
                creadit_fee_type: creditFeesType,
                processing_fee_type: processingFeesType,
             
                logo: image,
            }
            dispatch(updateSitesetting(data))
        },
    });
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Edit Sitesetting" pageTitle="Sitesettings" pageUrl="/settings" />
                    <Row>
                    <Col lg={8}>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                                <Card>
                                    <PreviewCardHeader title="Edit Sitesetting" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting name"
                                                            name='title'
                                                            value={validation.values.title || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.title && validation.errors.title ? true : false
                                                            }
                                                        />
                                                        {validation.touched.title && validation.errors.title ? (
                                                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Email</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting email"
                                                            name='email'
                                                            value={validation.values.email || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Referred percentage</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Referred percentage"
                                                            name='referred_percentage'
                                                            value={validation.values.referred_percentage || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.referred_percentage && validation.errors.referred_percentage ? true : false
                                                            }
                                                        />
                                                        {validation.touched.referred_percentage && validation.errors.referred_percentage ? (
                                                            <FormFeedback type="invalid">{validation.errors.referred_percentage}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Creadit Fee</Label>
                                                        <Input
                                                            type="number"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Enter Creadit Fee"
                                                            name='creadit_fee'
                                                            value={validation.values.creadit_fee || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.creadit_fee && validation.errors.creadit_fee ? true : false
                                                            }
                                                        />
                                                        {validation.touched.creadit_fee && validation.errors.creadit_fee ? (
                                                            <FormFeedback type="invalid">{validation.errors.creadit_fee}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Creadit Fee Type</Label>
                                                        <div className="mb-3">
                                                        <div className="form-check form-check-inline">
                                                            <Input 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="creadit_fee_type" 
                                                            id="inlineRadio1" 
                                                            value="fixed"
                                                            onChange={isCreditFeesTypeChange}
                                                            checked={creditFeesType == "fixed"}
                                                            />
                                                            <Label className="form-check-label" htmlFor="inlineRadio1">Fixed</Label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                            <Input 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="creadit_fee_type" 
                                                            id="inlineRadio2" 
                                                            value="percentage" 
                                                            onChange={isCreditFeesTypeChange}
                                                            checked={creditFeesType == "percentage"}
                                                            
                                                            />
                                                            <Label className="form-check-label" htmlFor="inlineRadio2">Percentage</Label>
                                                            </div>
                                                            
                                                        {validation.touched.creditFeesType && validation.errors.creditFeesType ? (
                                                            <FormFeedback type="invalid">{validation.errors.creditFeesType}</FormFeedback>
                                                        ) : null}
                                                        </div>
                                                        
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Platform Fee</Label>
                                                        <Input
                                                            type="number"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Enter Platform Fee"
                                                            name='processing_fee'
                                                            value={validation.values.processing_fee || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.processing_fee && validation.errors.processing_fee ? true : false
                                                            }
                                                        />
                                                        {validation.touched.processing_fee && validation.errors.processing_fee ? (
                                                            <FormFeedback type="invalid">{validation.errors.processing_fee}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Platform Fee Type</Label>
                                                        <div className="mb-3">
                                                        <div className="form-check form-check-inline">
                                                            <Input 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="processing_fee_type" 
                                                            id="inlineRadio3" 
                                                            value="fixed"
                                                            onChange={isProcessingFeesTypeChange}
                                                            checked={processingFeesType == "fixed"}
                                                            />
                                                            <Label className="form-check-label" htmlFor="inlineRadio3">Fixed</Label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                            <Input 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="processing_fee_type" 
                                                            id="inlineRadio4" 
                                                            value="percentage" 
                                                            onChange={isProcessingFeesTypeChange}
                                                            checked={processingFeesType == "percentage"}
                                                            
                                                            />
                                                            <Label className="form-check-label" htmlFor="inlineRadio4">Percentage</Label>
                                                            </div>
                                                            
                                                        {validation.touched.processingFeesType && validation.errors.processingFeesType ? (
                                                            <FormFeedback type="invalid">{validation.errors.processingFeesType}</FormFeedback>
                                                        ) : null}
                                                        </div>
                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="gy-4">
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="settleamount" className="form-label">Settle Amount</Label>
                                                        <Input
                                                            type="text"
                                                            id='settleamount'
                                                            className="form-control"
                                                            placeholder="Settle Amount"
                                                            name='tlc_amount'
                                                            value={validation.values.tlc_amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.tlc_amount && validation.errors.tlc_amount ? true : false
                                                            }
                                                        />
                                                        {validation.touched.tlc_amount && validation.errors.tlc_amount ? (
                                                            <FormFeedback type="invalid">{validation.errors.tlc_amount}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="fightAmount" className="form-label">Fight Amount</Label>
                                                        <Input
                                                            type="text"
                                                            id='fightAmount'
                                                            className="form-control"
                                                            placeholder="Fight Amount"
                                                            name='fight_tlc_amount'
                                                            value={validation.values.fight_tlc_amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.fight_tlc_amount && validation.errors.fight_tlc_amount ? true : false
                                                            }
                                                        />
                                                        {validation.touched.fight_tlc_amount && validation.errors.fight_tlc_amount ? (
                                                            <FormFeedback type="invalid">{validation.errors.fight_tlc_amount}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row className="gy-4">
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Contact no</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting Contact no"
                                                            name='contact_no'
                                                            value={validation.values.contact_no || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.contact_no && validation.errors.contact_no ? true : false
                                                            }
                                                        />
                                                        {validation.touched.contact_no && validation.errors.contact_no ? (
                                                            <FormFeedback type="invalid">{validation.errors.contact_no}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Whatsapp no</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting Whatsapp no"
                                                            name='whatsapp_no'
                                                            value={validation.values.whatsapp_no || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.whatsapp_no && validation.errors.whatsapp_no ? true : false
                                                            }
                                                        />
                                                        {validation.touched.whatsapp_no && validation.errors.whatsapp_no ? (
                                                            <FormFeedback type="invalid">{validation.errors.whatsapp_no}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row className="gy-4">

                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Facebook link</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting facebook link"
                                                            name='facebook_link'
                                                            value={validation.values.facebook_link || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.facebook_link && validation.errors.facebook_link ? true : false
                                                            }
                                                        />
                                                        {validation.touched.facebook_link && validation.errors.facebook_link ? (
                                                            <FormFeedback type="invalid">{validation.errors.facebook_link}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Instagram link</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting instagram link"
                                                            name='instagram_link'
                                                            value={validation.values.instagram_link || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.instagram_link && validation.errors.instagram_link ? true : false
                                                            }
                                                        />
                                                        {validation.touched.instagram_link && validation.errors.instagram_link ? (
                                                            <FormFeedback type="invalid">{validation.errors.instagram_link}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row className="gy-4">                                                
                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Twitter link</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting twitter link"
                                                            name='twitter_link'
                                                            value={validation.values.twitter_link || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.twitter_link && validation.errors.twitter_link ? true : false
                                                            }
                                                        />
                                                        {validation.touched.twitter_link && validation.errors.twitter_link ? (
                                                            <FormFeedback type="invalid">{validation.errors.twitter_link}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row className="gy-4">
                                            <Col xxl={6} md={6}>
                                                <div>
                                                    <Label htmlFor="placeholderInput" className="form-label">Playstore link</Label>
                                                    <Input
                                                        type="text"
                                                        id='placeholderInput'
                                                        className="form-control"
                                                        placeholder="Sitesetting playstore link"
                                                        name='playstore_link'
                                                        value={validation.values.playstore_link || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        invalid={
                                                            validation.touched.playstore_link && validation.errors.playstore_link ? true : false
                                                        }
                                                    />
                                                    {validation.touched.playstore_link && validation.errors.playstore_link ? (
                                                        <FormFeedback type="invalid">{validation.errors.playstore_link}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col xxl={6} md={6}>
                                                <div>
                                                    <Label htmlFor="placeholderInput" className="form-label">Appstore link</Label>
                                                    <Input
                                                        type="text"
                                                        id='placeholderInput'
                                                        className="form-control"
                                                        placeholder="Sitesetting appstore link"
                                                        name='appstore_link'
                                                        value={validation.values.appstore_link || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        invalid={
                                                            validation.touched.appstore_link && validation.errors.appstore_link ? true : false
                                                        }
                                                    />
                                                    {validation.touched.appstore_link && validation.errors.appstore_link ? (
                                                        <FormFeedback type="invalid">{validation.errors.appstore_link}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            </Row>
                                            <Row className="gy-4">
                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Copyright</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting copyright"
                                                            name='copyright'
                                                            value={validation.values.copyright || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.copyright && validation.errors.copyright ? true : false
                                                            }
                                                        />
                                                        {validation.touched.copyright && validation.errors.copyright ? (
                                                            <FormFeedback type="invalid">{validation.errors.copyright}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="gy-4">
                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Address</Label>
                                                        <Input
                                                            type="textarea"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Sitesetting address"
                                                            name='address'
                                                            value={validation.values.address || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.address && validation.errors.address ? true : false
                                                            }
                                                        />
                                                        {validation.touched.address && validation.errors.address ? (
                                                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="text-end mb-4">
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Update </Spinner>}
                                            <span className="flex-grow-1">
                                                Update
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                        </Form>
                    </Col>
                    <Col lg={4}>
                        <Card>
                        <CardHeader>
                            <h5 className="card-title mb-0">Site Setting Image</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="hstack gap-3 align-items-start mb-3">
                            <div className="flex-grow-1">
                            <Label className="form-label" htmlFor="product-title-input">
                                Image
                            </Label>
                            <Input type="file" onChange={onImgChange}/>
                            <img src={validation.values.logo || ""} alt=""  id="image" width="100px" ></img>
                            </div>
                            </div>
                                        

                            
                        </CardBody>
                        </Card>

                    
                    </Col>
                    </Row>
                    {isSitesettingUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isSitesettingUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default SitesettingEdit;