import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { updateMissionvision, resetMissionvisionFlag, getMissionvisionDetails } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import { call} from "redux-saga/effects"

const MissionvisionEdit = () => {
    document.title = "Edit Mission vision | Defendit - Admin Panel";
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const { isMissionvisionUpdate, isMissionvisionUpdateFail, error, success, details } = useSelector(state => ({
        isMissionvisionUpdate: state.Missionvisions.isMissionvisionUpdate,
        isMissionvisionUpdateFail: state.Missionvisions.isMissionvisionUpdateFail,
        error: state.Missionvisions.error,
        success: state.Missionvisions.success,
        details: state.Missionvisions.details,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (details && details.name)?details.name:'',
            icon: (details && details.icon)?details.icon:'',
            description: (details && details.description)?details.description:''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            icon: Yup.string().required("Please Enter Icon"),
            description: Yup.string().required("Please Enter Description")
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                id: id,
                name: values.name,
                icon: values.icon,
                description: values.description
            }
            dispatch(updateMissionvision(data))
        },
    });

    useEffect(() => {
        if(isMissionvisionUpdate || isMissionvisionUpdateFail){
            setIsCreating(false)
            setTimeout(() => {
                dispatch(resetMissionvisionFlag())
                history.push('/mission-visions');
            }, 3000);
        }
      }, [dispatch, isMissionvisionUpdate, isMissionvisionUpdateFail]);

      useEffect(() => {
        dispatch(getMissionvisionDetails({id:id}))
      }, [dispatch, id]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Edit Mission vision" pageTitle="Mission vision" pageUrl="/mission-vision" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Edit Mission vision" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Icon</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Icon"
                                                            name='icon'
                                                            value={validation.values.icon || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.icon && validation.errors.icon ? true : false
                                                            }
                                                        />
                                                        {validation.touched.icon && validation.errors.icon ? (
                                                            <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Description</Label>
                                                        <Input
                                                            type="textarea"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Description"
                                                            name='description'
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/mission-visions" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Update </Spinner>}
                                            <span className="flex-grow-1">
                                                Update
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isMissionvisionUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isMissionvisionUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default MissionvisionEdit;