import {
  GET_SITESETTING_DETAILS_SUCCESS,
  GET_SITESETTING_DETAILS_FAIL,
  UPDATE_SITESETTING_SUCCESS,
  UPDATE_SITESETTING_FAIL,
  RESET_SITESETTING_FLAG,

} from "./actionTypes";

const INIT_STATE = {
  sitesettings: []
};

const Sitesettings = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case UPDATE_SITESETTING_SUCCESS:
      return {
        ...state,
        sitesettings: state.sitesettings.map(sitesetting =>
          sitesetting._id.toString() === action.payload.data._id.toString()
            ? { ...sitesetting, ...action.payload.data }
            : sitesetting
        ),
        success: action.payload.message,
        isSitesettingUpdate: true,
        isSitesettingUpdateFail: false
      };

    case UPDATE_SITESETTING_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSitesettingUpdate: false,
        isSitesettingUpdateFail: true,
      };

    case GET_SITESETTING_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_SITESETTING_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
   
    case RESET_SITESETTING_FLAG:
      return {
        ...state,
        isSitesettingAdd: false,
        isSitesettingAddFail: false,
        isSuccess: false,
        isSitesettingDetails: false,
        isSitesettingUpdate: false,
        isSitesettingUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Sitesettings;
