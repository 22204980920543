import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal, 
  ModalHeader, 
  ModalBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getCoupons,
  deleteCoupon,
  statusChangeCoupon,
  modifyCouponFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";

const StepList = () => {
  document.title = "Coupon list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState(1);
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const {
    coupons,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFaild,
  } = useSelector((state) => ({
    coupons: state.Coupons.coupons,
    totalDataCount: state.Coupons.totalDataCount,
    isSuccess: state.Coupons.isSuccess,
    statusSuccess: state.Coupons.statusSuccess,
    statusFaild: state.Coupons.statusFaild,
    error: state.Coupons.error,
    success: state.Coupons.success,
  }));


  /* var totalRedeemValue= 0.00;
  for(i=0 coupons) */


  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
    
  }

  useEffect(() => {
    dispatch(
      getCoupons({
        keyword,
        date,
        type,
        status,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [
    dispatch,
    keyword,
    date,
    type,
    status,
    sortByField,
    sortByValue,
    limit,
    offset,
  ]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    setStatus("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  //On Click Delete Data
  function onClickDeleteData(id) {
    dispatch(deleteCoupon({id}));
  }

  //On Click Status Change
  function onClickStatusChange(id, status) {
    dispatch(statusChangeCoupon({ id, status }));
  }

  useEffect(() => {
    if (statusSuccess || statusFaild) {
      setTimeout(() => {
        dispatch(modifyCouponFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFaild]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Coupons" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Coupon List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/coupon/create"
                      className="btn btn-success add-btn"
                      id="createStepTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createStepTooltip"
                    >
                      Create
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}

                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="resetTooltip"
                    >
                      Reset
                    </UncontrolledTooltip>

                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                            <select className="form-select" data-choices data-choices-search-false
                            id="choices-privacy-status-input" onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Select status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {coupons?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")}
                                >
                                  Name
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="code"
                                  onClick={() => sortBy("code")}
                                >
                                  Coupon Code
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="ammount"
                                  onClick={() => sortBy("ammount")}
                                >
                                  Benefits
                                </th>
                                <th>Type</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="start_date"
                                  onClick={() => sortBy("start_date")}
                                >
                                  Valid from
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="end_date"
                                  onClick={() => sortBy("end_date")}
                                >
                                  Valid thru
                                </th>
                                <th>Total Redeem</th>
                                <th>Redeem Amount</th>
                                <th>Status</th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {coupons?.map((item) => {
                                //Total Redemption Sum
                                let totalRedemption = 0.00;
                                item?.transactions?.forEach(redemptionSum);
                                function redemptionSum(item1) {
                                  totalRedemption += item1.coupon_amount;
                                }

                                return (
                                <tr key={item._id}>
                                  <td><span className="text-capitalize">{item?.name}</span></td>
                                  <td>{item?.code}</td>
                                  <td> {item?.type && (item?.type == 'fixed') ? '$' : '' }{item?.ammount ? item?.ammount : '00.00'}{item?.type && (item?.type == 'fixed') ? '' : '%' }</td>
                                  <td><span className="text-capitalize">{item?.type}</span></td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item?.start_date}
                                    </Moment>
                                  </td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item?.end_date}
                                    </Moment>
                                  </td>
                                  <td>{item?.transactions?.length} Times</td>
                                  <td>${totalRedemption.toFixed(2)}</td>
                                  <td>{item?.status && (item?.status === 'active') ?<span className="badge badge-soft-success text-uppercase">Active</span>:<span className="badge badge-soft-danger text-uppercase">Inactive</span>}</td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="dropdown-menu-icon btn-transparent text-white"><i className="ri-more-2-fill"></i></DropdownToggle>
                                      <DropdownMenu container="body">
                                        <DropdownItem>
                                          <Link
                                            to={`/coupon/edit/${item._id}`}
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i> Edit
                                          </Link>
                                        </DropdownItem>

                                        <DropdownItem
                                          className="list-inline-item"
                                          title="Status Change"
                                        >
                                          <div
                                            onClick={() =>
                                              onClickStatusChange(
                                                item._id,
                                                item.status &&
                                                item.status === "active"
                                                  ? "inactive"
                                                  : "active"
                                              )
                                            }
                                            className="p-0 bg-transparent border-0 text-warning"
                                          >
                                            <i className="ri-exchange-line fs-16"></i> Status Change
                                          </div>
                                          <i className="fs-16"></i>
                                        </DropdownItem>

                                        <DropdownItem>
                                          <Button className="p-0 bg-transparent border-0 text-danger"
                                            onClick={()=>openDeleteConfrontation(item?._id)}
                                          >
                                              <i className="ri-delete-bin-5-fill fs-16"></i> Delete
                                          </Button>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                                )
                              })}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}

                  <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>
                      Delete Coupon
                    </ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button 
                          onClick={ () =>
                            {
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          onClick={ () =>
                            {
                              onClickDeleteData(deletedId);
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
          {statusSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {statusFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default StepList;
