import {
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAIL,
  ADD_NEW_DRIVER_SUCCESS,
  ADD_NEW_DRIVER_FAIL,
  GET_DRIVER_DETAILS_SUCCESS,
  GET_DRIVER_DETAILS_FAIL,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAIL,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAIL,
  RESET_DRIVER_FLAG,
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_FAIL,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,
  MODIFY_DRIVER_FLAG


} from "./actionTypes";

const INIT_STATE = {
  drivers: []
};

const Drivers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_DRIVERS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: [...state.drivers, action.payload.data],
        success: action.payload.message,
        isDriverAdd: true,
        isDriverAddFail: false,
      };

    case ADD_NEW_DRIVER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isDriverAdd: false,
        isDriverAddFail: true,
      };

    case UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: state.drivers.map(driver =>
          driver._id.toString() === action.payload.data._id.toString()
            ? { ...driver, ...action.payload.data }
            : driver
        ),
        success: action.payload.message,
        isDriverUpdate: true,
        isDriverUpdateFail: false
      };

    case UPDATE_DRIVER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isDriverUpdate: false,
        isDriverUpdateFail: true,
      };

    case DELETE_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: state.drivers.filter(drivers =>
         drivers._id !== action.payload.data._id 
           
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case DELETE_DRIVER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    case GET_DRIVER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_DRIVER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
     //City
     case GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        city: action.payload.data,
      };
  
    case GET_CITY_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    
     //Countery
     case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        country: action.payload.data,
      };
  
    case GET_COUNTRY_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    
    
    case RESET_DRIVER_FLAG:
      return {
        ...state,
        isDriverAdd: false,
        isDriverAddFail: false,
        isSuccess: false,
        isDriverDetails: false,
        isDriverUpdate: false,
        isDriverUpdateFail: false,
        isDriverDelete: false,
        isDriverDeleteFail: false,
        error: false
      };

      
    case MODIFY_DRIVER_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    default:
      return state;
  }
};

export default Drivers;
