import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,

  FORGET_PASSWORD_OTP,
  FORGET_PASSWORD_SUCCESS_OTP,
  FORGET_PASSWORD_ERROR_OTP,

  FORGET_PASSWORD_CHANGE,
  FORGET_PASSWORD_CHANGE_SUCCESS,
  FORGET_PASSWORD_CHANGE_ERROR,

  FORGET_PASSWORD_RESET_FLAG
} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  redirectLogin: false,
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
        otpSuccess: false,
        passwordChangePop: false,
        redirectLogin: false,
      };
      break;



    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload.message,
        forgetSuccessData : action.payload.data,
        otpSuccess : true,
        redirectLogin: false,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload.message, otpSuccess : false, redirectLogin: false };
      break;
    


    case FORGET_PASSWORD_SUCCESS_OTP:
      return {
        ...state,
        forgetSuccessMsg: action.payload.message,
        otpSuccess : false,
        passwordChangePop: true,
        redirectLogin: false,
        
      };

    case FORGET_PASSWORD_ERROR_OTP:
      return {
        ...state,
        forgetError: action.payload.message,
        otpSuccess : false,
        passwordChangePop: false,
        redirectLogin: false,
      };


    case FORGET_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        forgetSuccessMsg: action.payload.message,
        otpSuccess : false,
        passwordChangePop: true,
        redirectLogin : true,
        
      };

    case FORGET_PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        forgetError: action.payload.message,
        otpSuccess : false,
        passwordChangePop: false,
        redirectLogin:false,
      };


      case FORGET_PASSWORD_RESET_FLAG:
      return {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
        otpSuccess: false,
        passwordChangePop: false,
        redirectLogin : false,
      };
    
    
      default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
