import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { getTransactionDetails } from "../../../store/actions";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import Loader from "../../../Components/Common/Loader";

import { Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

const TransactionDetails = () => {
  document.title = "Transaction Details | Defendit - Admin Panel";
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTransactionDetails({ id: id }));
  }, [dispatch, id]);

  const { transactionDetails, isTransactionDetails, error } = useSelector(
    (state) => ({
      transactionDetails: state.Transactions.details,
      isTransactionDetails: state.Transactions.isTransactionDetails,
      error: state.Transactions.error,
    })
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Transaction details"
            pageTitle="Transactions"
            pageUrl="/transactions"
          />
          {isTransactionDetails ? (
            <Row className="mb-3">
              <Col xl={8}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <span className="text-muted">TRANSACTION ID</span>
                              <h5>
                                {transactionDetails.details.payment_transaction?transactionDetails.details.payment_transaction:"N.A."}
                              </h5>
                            </td>
                            <td className="text-end" id="cart-subtotal">
                              <span
                                className={
                                  transactionDetails.details
                                    .transaction_payment_status &&
                                  transactionDetails.details
                                    .transaction_payment_status == "cancel"
                                    ? "text-danger text-upper"
                                    : "text-success text-uppercase"
                                }
                              >
                                {
                                  transactionDetails.details
                                    .transaction_payment_status
                                }
                               
                             
                              </span>
                            </td>
                          </tr>
                          <tr><td>
                              <span className="text-muted">TRANSACTION DATE</span>
                              <h5>
                              <Moment format="D MMM YYYY">
                                    {transactionDetails.details.created_at}
                                  </Moment>
                              </h5>
                            </td></tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody className="pt-0">
                    <div className="table-responsive table-card mt-3 mb-1">
                      <>
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          {transactionDetails?.tickets.map((item, key) => (
                            <tr key={item._id}>
                              <td>
                                <table className="w-100">
                                  <tr>
                                    <td className="w-50">
                                      <span className="text-muted">
                                        TICKET NUMBER
                                      </span>
                                      <h6>{item.ticket_number}</h6>
                                    </td>
                                    <td className="w-50 text-end">
                                      <span className="text-muted">
                                        ISSUE DATE
                                      </span>
                                      <h6>
                                        <Moment format="D MMM YYYY">
                                          {item.issue_date}
                                        </Moment>
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr className="w-100">
                                    <td>
                                      <span className="mb-3">
                                        {item.ticket_name}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span className="text-muted">
                                        PLATE NO.
                                      </span>
                                      <h6>{item.plate_no}</h6>
                                    </td>
                                    <td>
                                      <span className="text-muted">STATE</span>
                                      <h6>{item.state}</h6>
                                    </td>
                                    <td>
                                      <span className="text-muted">
                                        PENALTY
                                      </span>
                                      <h6>{item.penalty}</h6>
                                    </td>
                                    <td>
                                      <h5 className="text-danger">
                                        ${item.amount ? item.amount : "00.00"}
                                      </h5>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={4}>
                <div className="sticky-side-div">
                  <Card>
                    <CardHeader className="border-bottom-dashed">
                      <h5 className="card-title mb-0">Payment Summary</h5>
                    </CardHeader>

                    {transactionDetails.details.transaction_coupon_code ? (
                      <CardHeader className="bg-soft-light border-bottom-dashed">
                        <div className="text-center">
                          <h5 className="mb-2">COUPON CODE </h5>
                        </div>
                        <div className="text-center">
                          #{transactionDetails.details.transaction_coupon_code}
                        </div>
                      </CardHeader>
                    ) : (
                      ""
                    )}

                    <CardBody className="pt-2">
                      <div className="table-responsive">
                        <table className="table table-borderless mb-0">
                          <tbody>
                            {transactionDetails.details
                              .transaction_total_ticket ? (
                              <tr>
                                <td>Total Tickets:</td>
                                <td className="text-end" id="cart-subtotal">
                                  {
                                    transactionDetails.details
                                      .transaction_total_ticket
                                  }
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {transactionDetails.details
                              .transaction_ticket_fee ? (
                              <tr>
                                <td>Ticket Fee:</td>
                                <td className="text-end" id="cart-subtotal">
                                  $
                                  {
                                    transactionDetails.details
                                      .transaction_ticket_fee
                                  }
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {transactionDetails.details
                              .transaction_credit_card_fee ? (
                              <tr>
                                <td>Credit Card Fee</td>
                                <td className="text-end" id="cart-subtotal">
                                  $
                                  {
                                    transactionDetails.details
                                      .transaction_credit_card_fee
                                  }
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {transactionDetails.details
                              .transaction_processing_fee ? (
                              <tr>
                                <td>Processing Fee:</td>
                                <td className="text-end" id="cart-subtotal">
                                  $
                                  {
                                    transactionDetails.details
                                      .transaction_processing_fee
                                  }
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {transactionDetails.details.transaction_coupon ? (
                              <tr>
                                <td>
                                  Coupon:
                                  <span className="text-muted">
                                    (#
                                    {
                                      transactionDetails.details
                                        .transaction_coupon_code
                                    }
                                    )
                                  </span>{" "}
                                  :
                                </td>
                                <td className="text-end" id="cart-discount">
                                  -${" "}
                                  {
                                    transactionDetails.details
                                      .transaction_coupon
                                  }
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {transactionDetails.details.transaction_amount ? (
                              <tr className="table-active">
                                <th>TOTAL AMOUNT</th>
                                <td className="text-end">
                                  <span className="fw-semibold" id="cart-total">
                                    $
                                    {
                                      transactionDetails.details
                                        .transaction_amount
                                    }
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          ) : (
            <Loader error={error} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default TransactionDetails;
