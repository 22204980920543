import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateDriver,
  getDriverDetails,
  resetDriverFlag,
  getStateList,
  getCountryList,
  getCityList,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image_helper";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const DriverEdit = () => {
  document.title = "Edit Driver | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);

  const [image, setImage] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];

  const [isCommercial, setisCommercial] = useState(false);
  const [isInsurance, setisInsurance] = useState(false);
  const [isDragTest, setisDragTest] = useState(false);
  const [isTlcCource, setisTlcCource] = useState(false);
  const [isDrivingCourse, setisDrivingCourse] = useState(false);

  const isCommercialChange = (event) => {
    //if (event.target.checked) {}
    setisCommercial((current) => !current);
  };

  const isInsuranceInformationChange = (event) => {
    setisInsurance((current) => !current);
  };

  const dragTestChange = (event) => {
    setisDragTest((current) => !current);
  };

  const tlcCourceChange = (event) => {
    setisTlcCource((current) => !current);
  };

  const DrivingCourseChange = (event) => {
    setisDrivingCourse((current) => !current);
  };

  const {
    isDriverUpdate,
    isDriverUpdateFail,
    error,
    success,
    country,
    city,
    vehicleState,
    details,
  } = useSelector((state) => ({
    isDriverUpdate: state.Drivers.isDriverUpdate,
    isDriverUpdateFail: state.Drivers.isDriverUpdateFail,
    error: state.Drivers.error,
    success: state.Drivers.success,
    country: state.Drivers.country,
    city: state.Drivers.city,
    vehicleState: state.Vehicles.vehicleState,
    details: state.Drivers.details,
  }));
  const selectedLicenseOption = {
    value: details && details.license_state ? details.license_state?._id : "",
    label: details && details.license_state ? details.license_state?.name : "",
  };
  const selectedStateOption = {
    value: details && details.state ? details.state?._id : "",
    label: details && details.state ? details.state?.name : "",
  };
  const selectedCountryOption = {
    value: details && details.country ? details.country?._id : "",
    label: details && details.country ? details.country?.name : "",
  };
  const selectedCityOption = {
    value: details && details.city ? details.city?._id : "",
    label: details && details.city ? details.city?.name : "",
  };
  useEffect(() => {
    if (isDriverUpdate || isDriverUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetDriverFlag());
        history.push('/drivers')
      }, 3000);
    }
  }, [dispatch, isDriverUpdate, isDriverUpdateFail]);
  useEffect(() => {
    dispatch(getStateList({ country_code: "US" }));
    //dispatch(getCountryList());
    //dispatch(getCityList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getDriverDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    setisCommercial(details?.is_commercial);
    setisInsurance(details?.is_insurance);
    setisDragTest(details?.is_drag_test);
    setisTlcCource(details?.is_tlc_cource);
    setisDrivingCourse(details?.is_defensive_driving_course);
  }, [dispatch, details]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: details && details.first_name ? details.first_name : "",
      last_name: details && details.last_name ? details.last_name : "",
      email_id: details && details.email_id ? details.email_id : "",

      license_number:
        details && details.license_number ? details.license_number : "",
      license_expire_date:
        details && details.license_expire_date
          ? details.license_expire_date
          : "",
      dmv_license_image:
        details && details.dmv_license_image ? details.dmv_license_image : "",

      tlc_license: details && details.tlc_license ? details.tlc_license : "",
      tlc_license_exp_date:
        details && details.tlc_license_exp_date
          ? details.tlc_license_exp_date
          : "",
      tlc_license_image:
        details && details.tlc_license_image ? details.tlc_license_image : "",

      mobile_number:
        details && details.mobile_number ? details.mobile_number : "",
      date_of_birth:
        details && details.date_of_birth ? details.date_of_birth : "",
      address: details && details.address ? details.address : "",
      apartment: details && details.apartment ? details.apartment : "",
      state: selectedStateOption.value,
      city: details && details.city ? details.city : "",
      ZIP_code: details && details.ZIP_code ? details.ZIP_code : "",

      company_name: details && details.company_name ? details.company_name : "",
      insurance_issue_date:
        details && details.insurance_issue_date
          ? details.insurance_issue_date
          : "",
      insurance_expire_date:
        details && details.insurance_expire_date
          ? details.insurance_expire_date
          : "",
      insurance_image:
        details && details.insurance_image ? details.insurance_image : "",

      driving_course_date:
        details && details.driving_course_date
          ? details.driving_course_date
          : "",
      date_taken_expire_date:
        details && details.date_taken_expire_date
          ? details.date_taken_expire_date
          : "",

      drag_test_date:
        details && details.drag_test_date ? details.drag_test_date : "",

      tlc_course_date:
        details && details.tlc_course_date ? details.tlc_course_date : "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      // email_id: Yup.string().required("Please Enter Email Id"),

      // license_number: Yup.string().required("Please Enter DMV License Number"),
      // license_expire_date: Yup.string().required("Please Enter DMV Expiration Date"),
      // dmv_license_image :'',

      // tlc_license: Yup.string().required("Please Enter TLC License"),
      // tlc_license_exp_date: Yup.string().required("Please Enter Expiration Date"),
      // tlc_license_image : '',

      // mobile_number: Yup.string().required("Please Enter Mobile Number"),
      // date_of_birth: Yup.string().required("Please Enter Date of Birth"),
      // address: Yup.string().required("Please Enter Address"),
      // apartment: Yup.string().required("Please Enter Apartment"),
      // state: Yup.string().required("Please Enter Atate"),
      // city: Yup.string().required("Please Enter City"),
      // ZIP_code: Yup.string().required("Please Enter ZIP code"),

      // company_name: Yup.string().required("Please Enter Company Name"),
      // insurance_issue_date: Yup.string().required("Please Enter Insurance issue Date"),
      // insurance_expire_date: Yup.string().required("Please Enter Insurance Expiration Date"),
      // insurance_image : '',

      // driving_course_date: Yup.string().required("Please Enter Driving Course Date"),
      // date_taken_expire_date: Yup.string().required("Please Enter Expiration Date"),

      // drag_test_date: Yup.string().required("Please Enter Drug Test Date"),

      // tlc_course_date: Yup.string().required("Please Enter TLC course Date"),
    }),

    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        first_name: values.first_name,
        last_name: values.last_name,
        name: values.first_name + " " + values.last_name,
        email_id: values.email_id,

        license_number: values.license_number,
        license_expire_date: new Date(
          `${values.license_expire_date}`
        ).toISOString(),
        dmv_license_image: image,

        is_commercial: isCommercial,
        tlc_license: values.tlc_license,
        tlc_license_exp_date: new Date(
          `${values.tlc_license_exp_date}`
        ).toISOString(),
        tlc_license_image: image1,

        mobile_number: values.mobile_number,
        date_of_birth: new Date(`${values.date_of_birth}`).toISOString(),
        address: values.address,
        apartment: values.apartment,
        state: values.state,
        city: values.city,
        ZIP_code: values.ZIP_code,

        is_insurance: isInsurance,
        company_name: values.company_name,
        insurance_issue_date: new Date(
          `${values.insurance_issue_date}`
        ).toISOString(),
        insurance_expire_date: new Date(
          `${values.insurance_expire_date}`
        ).toISOString(),
        insurance_image: image2,

        is_defensive_driving_course: isDrivingCourse,
        driving_course_date: new Date(
          `${values.driving_course_date}`
        ).toISOString(),
        date_taken_expire_date: new Date(
          `${values.date_taken_expire_date}`
        ).toISOString(),

        is_drag_test: isDragTest,
        drag_test_date: new Date(`${values.drag_test_date}`).toISOString(),

        is_tlc_cource: isTlcCource,
        tlc_course_date: new Date(`${values.tlc_course_date}`).toISOString(),
      };

      dispatch(updateDriver(data));
    },
  });

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const onImgChange1 = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage1);
      }
    }
  };

  const onImgChange2 = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image2");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage2);
      }
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Edit Driver" pageTitle="Driver" pageUrl="/drivers" />
        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Driver Information</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          First Name
                        </Label>
                        <Input
                          type="text"
                          name="first_name"
                          className="form-control"
                          placeholder="Enter First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                        validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          name="last_name"
                          className="form-control"
                          placeholder="Enter Last Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                        validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Email
                        </Label>
                        <Input
                          type="text"
                          name="email_id"
                          className="form-control"
                          placeholder="Enter Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email_id || ""}
                          invalid={
                            validation.touched.email_id &&
                            validation.errors.email_id
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email_id &&
                        validation.errors.email_id ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email_id}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">DMV License Info</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          DMV License Number
                        </Label>
                        <Input
                          type="text"
                          name="license_number"
                          className="form-control"
                          placeholder="Enter license number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.license_number || ""}
                          invalid={
                            validation.touched.license_number &&
                            validation.errors.license_number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.license_number &&
                        validation.errors.license_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.license_number}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          License Expire Date
                        </Label>
                        <Flatpickr
                          type="text"
                          name="license_expire_date"
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          onChange={(selectedDates, dateStr, instance) => {
                            let event = {
                              target: {
                                name: "license_expire_date",
                                value: dateStr,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          options={{
                            dateFormat: "m-d-Y",
                          }}
                          value={validation.values.license_expire_date || ""}
                        />
                        {validation.touched.license_expire_date &&
                        validation.errors.license_expire_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.license_expire_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <div className="hstack gap-3 align-items-start mb-3">
                      <div className="flex-grow-1">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          DMV License Image
                        </Label>
                        <Input type="file" onChange={onImgChange} />
                        <img
                          src={validation.values.dmv_license_image || ""}
                          alt=""
                          id="image"
                          width="100px"
                        ></img>
                      </div>
                    </div>
                    {/* <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          License State
                        </Label>
                        <Select
                          type="text"
                          name="license_state"
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "license_state",
                                value: selectedOption,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          onBlur={validation.handleBlur}
                          options={
                            vehicleState &&
                            vehicleState.map((item, index) => ({
                              label: item.name,
                              value: item._id,
                            }))
                          }
                          value={validation.values.license_state || ""}
                        />
                        {validation.touched.license_state &&
                        validation.errors.license_state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.license_state}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">TLC License Info</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={12} md={12}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="commercialLicense"
                          value={isCommercial}
                          onChange={isCommercialChange}
                          checked={isCommercial === true}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="commercialLicense"
                        >
                          Commercial License
                        </Label>
                      </div>
                    </Col>

                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          TLC License Number#
                        </Label>
                        <Input
                          type="text"
                          name="tlc_license"
                          className="form-control"
                          placeholder="Enter TLC license number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tlc_license || ""}
                          invalid={
                            validation.touched.tlc_license &&
                            validation.errors.tlc_license
                              ? true
                              : false
                          }
                        />
                        {validation.touched.tlc_license &&
                        validation.errors.tlc_license ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tlc_license}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Expire Date
                        </Label>
                        <Flatpickr
                          type="text"
                          name="tlc_license_exp_date"
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          onChange={(selectedDates, dateStr, instance) => {
                            let event = {
                              target: {
                                name: "tlc_license_exp_date",
                                value: dateStr,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          options={{
                            dateFormat: "m-d-Y",
                          }}
                          value={validation.values.tlc_license_exp_date || ""}
                        />
                        {validation.touched.tlc_license_exp_date &&
                        validation.errors.tlc_license_exp_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tlc_license_exp_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <div className="hstack gap-3 align-items-start mb-3">
                      <div className="flex-grow-1">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          TLC License Image
                        </Label>
                        <Input type="file" onChange={onImgChange1} />
                        <img
                          src={validation.values.tlc_license_image || ""}
                          alt=""
                          id="image1"
                          width="100px"
                        ></img>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Mobile
                        </Label>
                        <Input
                          type="text"
                          name="mobile_number"
                          className="form-control"
                          placeholder="Enter Mobile Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile_number || ""}
                          invalid={
                            validation.touched.mobile_number &&
                            validation.errors.mobile_number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mobile_number &&
                        validation.errors.mobile_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.mobile_number}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Date of birth
                        </Label>
                        <Flatpickr
                          type="text"
                          name="date_of_birth"
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          onChange={(selectedDates, dateStr, instance) => {
                            let event = {
                              target: { name: "date_of_birth", value: dateStr },
                            };
                            validation.handleChange(event);
                          }}
                          options={{
                            dateFormat: "m-d-Y",
                          }}
                          value={validation.values.date_of_birth || ""}
                        />
                        {validation.touched.date_of_birth &&
                        validation.errors.date_of_birth ? (
                          <FormFeedback type="invalid">
                            {validation.errors.date_of_birth}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Address
                        </Label>
                        <Input
                          type="text"
                          name="address"
                          className="form-control"
                          placeholder="Enter address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Apt #
                        </Label>
                        <Input
                          type="text"
                          name="apartment"
                          className="form-control"
                          placeholder="Enter apartment"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.apartment || ""}
                        />
                        {validation.touched.apartment &&
                        validation.errors.apartment ? (
                          <FormFeedback type="invalid">
                            {validation.errors.apartment}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          State
                        </Label>
                        <Select
                          type="text"
                          name="state"
                          onChange={(selectedOption) => {
                            let event = {
                              target: { name: "state", value: selectedOption },
                            };
                            validation.handleChange(event);
                          }}
                          onBlur={validation.handleBlur}
                          options={
                            vehicleState &&
                            vehicleState.map((item, index) => ({
                              label: item.name,
                              value: item._id,
                            }))
                          }
                          value={validation.values.state || ""}
                        />
                        {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.state}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {/* <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="product-title-input">
                          City
                        </Label>
                        <Select
                          type="text"
                          name="city"
                          onChange={(selectedOption) => {
                            let event = {
                              target: { name: "city", value: selectedOption },
                            };
                            validation.handleChange(event);
                          }}
                          onBlur={validation.handleBlur}
                          options={
                            city &&
                            city.map((item, index) => ({
                              label: item.name,
                              value: item._id,
                            }))
                          }
                          value={validation.values.city || ""}
                        />
                        {validation.touched.city && validation.errors.city ? (
                          <FormFeedback type="invalid">
                            {validation.errors.city}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col> */}
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          City
                        </Label>
                        <Input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="Enter Zip Code"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.city || ""}
                          invalid={
                            validation.touched.city && validation.errors.city
                              ? true
                              : false
                          }
                        />
                        {validation.touched.city && validation.errors.city ? (
                          <FormFeedback type="invalid">
                            {validation.errors.city}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Zip
                        </Label>
                        <Input
                          type="text"
                          name="ZIP_code"
                          className="form-control"
                          placeholder="Enter Zip Code"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ZIP_code || ""}
                          invalid={
                            validation.touched.ZIP_code &&
                            validation.errors.ZIP_code
                              ? true
                              : false
                          }
                        />
                        {validation.touched.ZIP_code &&
                        validation.errors.ZIP_code ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ZIP_code}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Insurance Information</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={3} md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={isInsurance}
                          onChange={isInsuranceInformationChange}
                          checked={isInsurance === true}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="InsuranceInformation"
                        >
                          Insurance Information
                        </Label>
                      </div>
                    </Col>

                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Show Company
                        </Label>
                        <Input
                          type="text"
                          name="company_name"
                          className="form-control"
                          placeholder="Enter Company Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.company_name || ""}
                          invalid={
                            validation.touched.company_name &&
                            validation.errors.company_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.company_name &&
                        validation.errors.company_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.company_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Insurance issue Date
                        </Label>
                        <Flatpickr
                          type="text"
                          name="insurance_issue_date"
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          onChange={(selectedDates, dateStr, instance) => {
                            let event = {
                              target: {
                                name: "insurance_issue_date",
                                value: dateStr,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          options={{
                            dateFormat: "m-d-Y",
                          }}
                          value={validation.values.insurance_issue_date || ""}
                        />
                        {validation.touched.insurance_issue_date &&
                        validation.errors.insurance_issue_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.insurance_issue_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xxl={3} md={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Expiration Date
                        </Label>
                        <Flatpickr
                          type="text"
                          name="insurance_expire_date"
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          onChange={(selectedDates, dateStr, instance) => {
                            let event = {
                              target: {
                                name: "insurance_expire_date",
                                value: dateStr,
                              },
                            };
                            validation.handleChange(event);
                          }}
                          options={{
                            dateFormat: "m-d-Y",
                          }}
                          value={validation.values.insurance_expire_date || ""}
                        />
                        {validation.touched.insurance_expire_date &&
                        validation.errors.insurance_expire_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.insurance_expire_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <div className="hstack gap-3 align-items-start mb-3">
                      <div className="flex-grow-1">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Upload Insurance Card
                        </Label>
                        <Input type="file" onChange={onImgChange2} />
                        <img
                          src={validation.values.tlc_license_image || ""}
                          alt=""
                          id="image2"
                          width="100px"
                        ></img>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <Link to="/drivers" className="btn btn-danger w-sm me-1">
                  Cancel
                </Link>
                <Button
                  color="success"
                  className="btn-load"
                  disabled={isCreating}
                  type="submit"
                >
                  <span className="d-flex align-items-center">
                    {isCreating && (
                      <Spinner size="sm" className="flex-shrink-0 me-2">
                        {" "}
                        Create{" "}
                      </Spinner>
                    )}
                    <span className="flex-grow-1">Submit</span>
                  </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                      Submit
                    </button> */}
              </div>
            </Form>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Driving Course Info</h5>
              </CardHeader>
              <CardBody>
                <Row className="gy-4">
                  <Col xxl={12} md={12}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value={isDrivingCourse}
                        onChange={DrivingCourseChange}
                        checked={isDrivingCourse === true}
                      />

                      <Label className="form-check-label" htmlFor="checkAll">
                        Defensive Driving Course
                      </Label>
                    </div>
                  </Col>
                  <Col xxl={12} md={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Driving Course Date
                      </Label>
                      <Flatpickr
                        type="text"
                        name="driving_course_date"
                        className="form-control"
                        placeholder="DD-MM-YYYY"
                        onChange={(selectedDates, dateStr, instance) => {
                          let event = {
                            target: {
                              name: "driving_course_date",
                              value: dateStr,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        options={{
                          dateFormat: "m-d-Y",
                        }}
                        value={validation.values.driving_course_date || ""}
                      />
                      {validation.touched.driving_course_date &&
                      validation.errors.driving_course_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.driving_course_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xxl={12} md={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Expiration Date
                      </Label>
                      <Flatpickr
                        type="text"
                        name="date_taken_expire_date"
                        className="form-control"
                        placeholder="DD-MM-YYYY"
                        onChange={(selectedDates, dateStr, instance) => {
                          let event = {
                            target: {
                              name: "date_taken_expire_date",
                              value: dateStr,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        options={{
                          dateFormat: "m-d-Y",
                        }}
                        value={validation.values.date_taken_expire_date || ""}
                      />
                      {validation.touched.date_taken_expire_date &&
                      validation.errors.date_taken_expire_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.date_taken_expire_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Drug Test Info</h5>
              </CardHeader>
              <CardBody>
                <Row className="gy-4">
                  <Col xxl={12} md={12}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value={isDragTest}
                        onChange={dragTestChange}
                        checked={isDragTest === true}
                      />

                      <Label className="form-check-label" htmlFor="checkAll">
                        Drug Test
                      </Label>
                    </div>
                  </Col>
                  <Col xxl={12} md={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Drag Test Date
                      </Label>
                      <Flatpickr
                        type="text"
                        name="drag_test_date"
                        className="form-control"
                        placeholder="DD-MM-YYYY"
                        onChange={(selectedDates, dateStr, instance) => {
                          let event = {
                            target: { name: "drag_test_date", value: dateStr },
                          };
                          validation.handleChange(event);
                        }}
                        options={{
                          dateFormat: "m-d-Y",
                        }}
                        value={validation.values.drag_test_date || ""}
                      />
                      {validation.touched.drag_test_date &&
                      validation.errors.drag_test_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.drag_test_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">TLC Course Info</h5>
              </CardHeader>
              <CardBody>
                <Row className="gy-4">
                  <Col xxl={12} md={12}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="tlcCourseCheckbox"
                        value={isTlcCource}
                        onChange={tlcCourceChange}
                        checked={isTlcCource === true}
                      />

                      <Label
                        className="form-check-label"
                        htmlFor="tlcCourseCheckbox"
                      >
                        TLC Course
                      </Label>
                    </div>
                  </Col>
                  <Col xxl={12} md={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        TLC Course Date
                      </Label>
                      <Flatpickr
                        type="text"
                        name="tlc_course_date"
                        className="form-control"
                        placeholder="DD-MM-YYYY"
                        onChange={(selectedDates, dateStr, instance) => {
                          let event = {
                            target: { name: "tlc_course_date", value: dateStr },
                          };
                          validation.handleChange(event);
                        }}
                        options={{
                          dateFormat: "m-d-Y",
                        }}
                        value={validation.values.tlc_course_date || ""}
                      />
                      {validation.touched.tlc_course_date &&
                      validation.errors.tlc_course_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tlc_course_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isDriverUpdate ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {isDriverUpdateFail ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default DriverEdit;
