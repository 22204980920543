import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,

FORGET_PASSWORD_OTP,
FORGET_PASSWORD_SUCCESS_OTP,
FORGET_PASSWORD_ERROR_OTP,

FORGET_PASSWORD_CHANGE,
FORGET_PASSWORD_CHANGE_SUCCESS,
FORGET_PASSWORD_CHANGE_ERROR,

FORGET_PASSWORD_RESET_FLAG,

} from "./actionTypes";

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  };
};

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};


//OTP
export const userForgetPasswordOTP = (data) => {
  return {
    type: FORGET_PASSWORD_OTP,
    payload: data,
  };
};

export const userForgetPasswordSuccessOTP = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS_OTP,
    payload: message,
  };
};

export const userForgetPasswordErrorOTP = message => {
  return {
    type: FORGET_PASSWORD_ERROR_OTP,
    payload: message,
  };
};



//UPDATE PASSWORD
export const userForgetPasswordChange = (data) => {
  return {
    type: FORGET_PASSWORD_CHANGE,
    payload: data,
  };
};

export const userForgetPasswordSuccessChange = message => {
  return {
    type: FORGET_PASSWORD_CHANGE_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordErrorChange = message => {
  return {
    type: FORGET_PASSWORD_CHANGE_ERROR,
    payload: message,
  };
};

//Modify Flag for toaster
export const forgetPasswordResetFlag = () => {
  return {
    type: FORGET_PASSWORD_RESET_FLAG,
  }
}



