import {
  GET_SEOS_SUCCESS,
  GET_SEOS_FAIL,
  ADD_NEW_SEO_SUCCESS,
  ADD_NEW_SEO_FAIL,
  GET_SEO_DETAILS_SUCCESS,
  GET_SEO_DETAILS_FAIL,
  UPDATE_SEO_SUCCESS,
  UPDATE_SEO_FAIL,
  RESET_SEO_FLAG
} from "./actionTypes";

const INIT_STATE = {
  seos: []
};

const Seos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SEOS_SUCCESS:
      return {
        ...state,
        seos: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_SEOS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_SEO_SUCCESS:
      return {
        ...state,
        seos: [...state.seos, action.payload.data],
        success: action.payload.message,
        isSeoAdd: true,
        isSeoAddFail: false,
      };

    case ADD_NEW_SEO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSeoAdd: false,
        isSeoAddFail: true,
      };

    case UPDATE_SEO_SUCCESS:
      return {
        ...state,
        seos: state.seos.map(seo =>
          seo._id.toString() === action.payload.data._id.toString()
            ? { ...seo, ...action.payload.data }
            : seo
        ),
        success: action.payload.message,
        isSeoUpdate: true,
        isSeoUpdateFail: false
      };

    case UPDATE_SEO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSeoUpdate: false,
        isSeoUpdateFail: true,
      };

    case GET_SEO_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };

    case GET_SEO_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case RESET_SEO_FLAG:
      return {
        ...state,
        isSeoAdd: false,
        isSeoAddFail: false,
        isSuccess: false,
        isSeoDetails: false,
        isSeoUpdate: false,
        isSeoUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Seos;
