import {
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,

  ADD_NEW_COUPON,
  ADD_NEW_COUPON_SUCCESS,
  ADD_NEW_COUPON_FAIL,

  GET_COUPON_DETAILS,
  GET_COUPON_DETAILS_SUCCESS,
  GET_COUPON_DETAILS_FAIL,

  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,

  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,

  STATUS_CHANGE_COUPON,
  STATUS_CHANGE_COUPON_SUCCESS,
  STATUS_CHANGE_COUPON_FAIL,

  MODIFY_COUPON_FLAG,
  RESET_COUPON_FLAG,

} from "./actionTypes";

//LISTING
export const getCoupons = data => ({
  type: GET_COUPONS,
  payload: data,
});

export const getCouponsSuccess = data => ({
  type: GET_COUPONS_SUCCESS,
  payload: data,
});

export const getCouponsFail = error => ({
  type: GET_COUPONS_FAIL,
  payload: error,
});

//ADD NEW
export const addNewCoupon= data=> ({
  type: ADD_NEW_COUPON,
  payload: data,
});

export const addNewCouponSuccess = data => ({
  type: ADD_NEW_COUPON_SUCCESS,
  payload: data,
});

export const addNewCouponFail = error => ({
  type: ADD_NEW_COUPON_FAIL,
  payload: error,
});

//DETAILS
export const getCouponDetails = data => ({
  type: GET_COUPON_DETAILS,
  payload: data,
});

export const getCouponDetailsSuccess = data => ({
  type: GET_COUPON_DETAILS_SUCCESS,
  payload: data,
});

export const getCouponDetailsFail = error => ({
  type: GET_COUPON_DETAILS_FAIL,
  payload: error,
});

//UPDATE
export const updateCoupon = data => ({
  type: UPDATE_COUPON,
  payload: data,
});

export const updateCouponSuccess = data => ({
  type: UPDATE_COUPON_SUCCESS,
  payload: data,
});

export const updateCouponFail = error => ({
  type: UPDATE_COUPON_FAIL,
  payload: error,
});

//Delete
export const deleteCoupon = data => ({
  type: DELETE_COUPON,
  payload: data,
});

export const deleteCouponSuccess = data => ({
  type: DELETE_COUPON_SUCCESS,
  payload: data,
});

export const deleteCouponFail = error => ({
  type: DELETE_COUPON_FAIL,
  payload: error,
});

//Status Change
export const statusChangeCoupon = data => ({
  type: STATUS_CHANGE_COUPON,
  payload: data,
});

export const statusChangeCouponSuccess = data => ({
  type: STATUS_CHANGE_COUPON_SUCCESS,
  payload: data,
});

export const statusChangeCouponFail = error => ({
  type: STATUS_CHANGE_COUPON_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyCouponFlag = () => {
  return {
    type: MODIFY_COUPON_FLAG,
  }
}

//Reset Flag for toaster
export const resetCouponFlag = () => {
  return {
    type: RESET_COUPON_FLAG,
  }
}