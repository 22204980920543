import {
  GET_SEOS,
  GET_SEOS_SUCCESS,
  GET_SEOS_FAIL,
  ADD_NEW_SEO,
  ADD_NEW_SEO_SUCCESS,
  ADD_NEW_SEO_FAIL,
  GET_SEO_DETAILS,
  GET_SEO_DETAILS_SUCCESS,
  GET_SEO_DETAILS_FAIL,
  UPDATE_SEO,
  UPDATE_SEO_SUCCESS,
  UPDATE_SEO_FAIL,
  RESET_SEO_FLAG
} from "./actionTypes";

export const getSeos = data => ({
  type: GET_SEOS,
  payload: data,
});

export const getSeosSuccess = data => ({
  type: GET_SEOS_SUCCESS,
  payload: data,
});

export const getSeosFail = error => ({
  type: GET_SEOS_FAIL,
  payload: error,
});

export const addNewSeo = seo=> ({
  type: ADD_NEW_SEO,
  payload: seo,
});

export const addNewSeoSuccess = seo => ({
  type: ADD_NEW_SEO_SUCCESS,
  payload: seo,
});

export const addNewSeoFail = error => ({
  type: ADD_NEW_SEO_FAIL,
  payload: error,
});

export const updateSeo = seo => ({
  type: UPDATE_SEO,
  payload: seo,
});

export const updateSeoSuccess = seo => ({
  type: UPDATE_SEO_SUCCESS,
  payload: seo,
});

export const updateSeoFail = error => ({
  type: UPDATE_SEO_FAIL,
  payload: error,
});

export const getSeoDetails = seo => ({
  type: GET_SEO_DETAILS,
  payload: seo,
});

export const getSeoDetailsSuccess = seo => ({
  type: GET_SEO_DETAILS_SUCCESS,
  payload: seo,
});

export const getSeoDetailsFail = error => ({
  type: GET_SEO_DETAILS_FAIL,
  payload: error,
});

export const resetSeoFlag = () => {
  return {
    type: RESET_SEO_FLAG,
  }
}