import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import * as Yup from "yup";
import { useFormik } from "formik";
import Moment from "react-moment";
import "moment-timezone";
import {
  getTicketDetails,
  getSignatureTicketDetails,
} from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";


// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const TicketDetails = () => {
  document.title = "Ticket Details | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const { id } = useParams();
  const download = (file_name,original_name="") => {
    saveAs(
      file_name,
      original_name
    );
  };
  const {
    error,
    success,
    details,
    signatureDetails,
  } = useSelector((state) => ({
    error: state.Tickets.error,
    success: state.Tickets.success,
    details: state.Tickets.details,
    signatureDetails: state.Tickets.signatureDetails,
  }));


  useEffect(() => {
    dispatch(getTicketDetails({ id: id }));
    dispatch(getSignatureTicketDetails({ ticket_id: id }));
  }, [dispatch, id]);
  



  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Ticket Details"
          pageTitle="Tickets"
          pageUrl="/tickets"
        />
        <Row>
          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Ticket Info</h5>
              </CardHeader>
              <CardBody>
                {details?.summons_number ? 
                  <Row className="gy-4">
                    <Col xxl={3} md={6}>
                      Summons Number
                    </Col>
                    <Col xxl={3} md={6}>
                      : {details && details?.summons_number ? details.summons_number : ""}
                    </Col>
                  </Row>
                  :''
                }

                {details?.issue_date ?
                  <Row>
                    <Col xxl={6} md={6}>
                      Issue Date
                    </Col>
                    <Col xxl={6} md={6}>
                      <Moment format="D MMM YYYY">
                        : {details && details?.issue_date}
                      </Moment>
                    </Col>
                  </Row>
                  :''
                }
                {details?.ticket_type ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Ticket Type
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.ticket_type ? details.ticket_type : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.state ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      State
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.state ? details.state : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.fine_amount ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Fine Amount
                    </Col>
                    <Col xxl={6} md={6}>
                      : ${details && details?.fine_amount? parseInt(details && details?.fine_amount).toFixed(2): "0.00"}
                    </Col>
                  </Row>
                  :''
                }
                {details?.penalty_amount ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Penalty Amount
                    </Col>
                    <Col xxl={6} md={6}>
                      : ${details && details?.penalty_amount? parseInt(details && details?.penalty_amount).toFixed(2): "0.00"}
                    </Col>
                  </Row>
                  :''
                }
                {details?.interest_amount ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Interest Amount
                    </Col>
                    <Col xxl={6} md={6}>
                      : ${details && details?.interest_amount? parseInt(details && details?.interest_amount).toFixed(2): "0.00"}
                    </Col>
                  </Row>
                  :''
                }
                {details?.reduction_amount ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Reduction Amount
                    </Col>
                    <Col xxl={6} md={6}>
                      : ${details && details?.reduction_amount? parseInt(details && details?.reduction_amount).toFixed(2): "0.00"}
                    </Col>
                  </Row>
                  :''
                }
                {details?.payment_amount ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Payment Amount
                    </Col>
                    <Col xxl={6} md={6}>
                      : ${details && details?.payment_amount? parseInt(details && details?.payment_amount).toFixed(2): "0.00"}
                    </Col>
                  </Row>
                  :''
                }
                {details?.due_amount ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Due Amount
                    </Col>
                    <Col xxl={6} md={6}>
                    : ${details && details?.due_amount? parseInt(details && details?.due_amount).toFixed(2): "0.00"}
                    </Col>
                  </Row>
                  :''
                }
                {details?.violation_description ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Violation Description
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.violation_description ? details.violation_description : ""}
                    </Col>
                  </Row>
                  :''
                }
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">User Info</h5>
              </CardHeader>
              <CardBody>
                {details?.user_details?.name ?
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Name
                    </Col>
                    <Col xxl={8} md={8}>
                      : {details && details?.user_details?.name ? details.user_details.name : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.user_details?.email ?
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                       Email
                    </Col>
                    <Col xxl={8} md={8}>
                      : {details && details?.user_details?.email ? details.user_details.email : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.user_details?.phone ?
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                      Phone
                    </Col>
                    <Col xxl={8} md={8}>
                      : { details.user_details.phone_code+details.user_details.phone}
                    </Col>
                  </Row>
                  :''
                }
                {details?.user_details?.address ?
                  <Row className="gy-4">
                    <Col xxl={4} md={4}>
                     Address
                    </Col>
                    <Col xxl={8} md={8}>
                      : {details?.user_details.address+" , "+details?.user_details.city+" , "+details?.user_details.zip_code}
                    </Col>
                  </Row>
                  :''
                }
                
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Plate Info</h5>
              </CardHeader>
              <CardBody>
                {details?.plate_details?.vehicle_vin ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      VIN
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.plate_details?.vehicle_vin ? details.plate_details?.vehicle_vin : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.plate_details?.vehicle_make ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Vehicle Make
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.plate_details?.vehicle_make ? details.plate_details?.vehicle_make : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.plate_details?.vehicle_model ?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Vehicle Model
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.plate_details?.vehicle_model ? details.plate_details?.vehicle_model : ""}
                    </Col>
                  </Row>
                  :''
                }
                {details?.plate_details?.vehicle_color?
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      Vehicle Color
                    </Col>
                    <Col xxl={6} md={6}>
                      : {details && details?.plate_details?.vehicle_color ? details.plate_details?.vehicle_color : ""}
                    </Col>
                  </Row>
                  :''
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

       { signatureDetails?.summons?(
        <Row>
          <Col lg={9} className="m-auto">
            <Card>
              <CardBody>
                <div className="border p-3 mb-2">
                  <p>In order to have an attorney or representative settle a summons with TLC on your behalf, you and the attorney or representative 
                  must complete this form. Please fill in all fields and retain a copy of the completed form for your records. TLC does not endorse or 
                  qualify any attorneys or representatives.</p>
                </div>

                <h3 className="text-center">SETTLEMENT AUTHORIZATON FORM</h3>

                <div className="border p-3">
                  <p className="text-center text-decoration-underline">CERTIFICATION OF RESPONDENT</p>
                  <p>
                  I, {signatureDetails?.name} , certify that I am the respondent and/or owner or principal of the respondent 
                  entity named in the TLC summons(es) listed below. I hereby authorize {signatureDetails?.attorney_to}, an attorney or 
                  representative, to enter into a settlement agreement with TLC on my behalf in resolution of the summons(es) listed 
                  below. I further certify that the attorney or representative has informed me of all of the following:
                  </p>
                  <ul>
                    <li>The attorney or representative is not an employee of TLC.</li>
                    <li>I have a right to a hearing and am not required to settle my summons(es) with TLC.</li>
                    <li>I may appear at TLC to settle my summons(es) without an attorney or representative.</li>
                    <li>I may appear at TLC to settle my summons(es) without an attorney or representative.</li>
                    <li>All TLC settlement agreements include a guilty plea that will count toward the calculation of future penalties.</li>
                  </ul>


                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <h5>Summons #</h5>
                    </div>
                    <div className="col-md-4">
                      <h5>License #</h5>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {signatureDetails?.summons?.map((item1) => (
                      <div key={item1._id}>
                      <div className="col-md-4 mb-2">{item1?.summons}</div>
                      <div className="col-md-4 mb-2">{item1?.license}</div>
                      </div>
                    ))}
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-4">
                      <div style={{'width': '80px','margin': 'auto', 'margin-bottom': '10px'}}>
                      {signatureDetails?.respondent_signature ? (<>
                         <a  className=" btn  down-btn btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(signatureDetails?.respondent_signature)}>Download</a>
                        <img style={{
                          'display': 'block',
                          'width': '100%',
                          'height': '100%',
                          'object-fit': 'contain',
                          'object-position': 'center center',
                          'margin': 'auto',
                        }} src={signatureDetails?.respondent_signature} />
                        </>
                      ):('')}
                      </div>
                      <div className="border border-bottom-0 border-start-0 border-end-0 text-center">Respondent Signature</div>
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <div className="mt-auto mb-2 text-center">{signatureDetails?.respondent_print_name}</div>
                      <div className="border border-bottom-0 border-start-0 border-end-0 text-center">Print Name</div>
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <div className="mt-auto mb-2 text-center">
                      {signatureDetails && signatureDetails?.respondent_date ? (
                        <Moment format="D MMM YYYY">{signatureDetails && signatureDetails?.respondent_date}</Moment>
                      ):('')}
                      </div>
                      <div className="border border-bottom-0 border-start-0 border-end-0 text-center">Date</div>
                    </div>
                  </div>

                </div>

                <div className="border p-3 mt-2">
                  <p className="text-center text-decoration-underline">CERTIFICATION OF ATTORNEY/REPRESENTATIVE</p>
                  <p>
                    I, {signatureDetails?.attorney_name} , hereby certify that I have been authorized by {signatureDetails?.respondent_name}, 
                    the respondent and/or owner or principal of the respondent entity named in the above TLC summons(es), to enter into 
                    a settlement agreement with TLC in resolution of the above summons(es). I also acknowledge that I am aware of and 
                    have explained all of the terms of the settlement agreement(s) to the above-named respondent.
                  </p>
                  <p>
                    Furthermore, I have been warned that any willful false statements made on or in connection with this form are 
                    punishable by fine or imprisonment, or both, under New York Penal Law Section 175.30. I declare that all statements 
                    made of my knowledge are true, and all statements made based on information and belief are believed to be true.
                  </p>

                  <div className="row mt-5">
                    <div className="col-md-4">
                      <div style={{'width': '80px','margin': 'auto', 'margin-bottom': '10px'}}>
                        {signatureDetails?.attorney_signature ? (<>
                            <a  className=" btn  btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(signatureDetails?.attorney_signature)}>Download</a>
                        <img style={{
                          'display': 'block',
                          'width': '100%',
                          'height': '100%',
                          'object-fit': 'contain',
                          'object-position': 'center center',
                          'margin': 'auto',
                        }} src={signatureDetails?.attorney_signature} />
                        </>
                        ):('')}
                      </div>
                      <div className="border border-bottom-0 border-start-0 border-end-0 text-center">Attorney/Representative Signature </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <div className="mt-auto mb-2 text-center">{signatureDetails?.attorney_print_name}</div>
                      <div className="border border-bottom-0 border-start-0 border-end-0 text-center">Print Name</div>
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <div className="mt-auto mb-2 text-center">
                        {signatureDetails && signatureDetails?.attorney_date ? (
                        <Moment format="D MMM YYYY">{signatureDetails && signatureDetails?.attorney_date}</Moment>
                        ):('')}
                      </div>
                      <div className="border border-bottom-0 border-start-0 border-end-0 text-center">Date</div>
                    </div>
                  </div>
                </div>

                  

              </CardBody>
            </Card>
          </Col>
        </Row>):('')
      }
       { signatureDetails?.ticket_galleries?(
        <Row>
          <Col lg={9} className="m-auto">
            <Card>
              <CardBody>
              <h3 className="text-center">Front and back of license (personal and TLC)</h3>
                <div className="border p-3 mt-2">
                  <div className="row mt-5">
                    <div className="col-md-3 text-center">
                    <div className="documents-inner">
                    <img style={{
                          'display': 'block',
                          'width': '100%',
                          'height': '100%',
                          'object-fit': 'contain',
                          'object-position': 'center center',
                          'margin': 'auto',
                        }} src={signatureDetails?.back_dmv_license} />
                        <a  className=" btn down-btn btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(signatureDetails?.back_dmv_license)}>Download</a>
                    </div>
                        
                    </div>
                    <div className="col-md-3 text-center">
                    <div className="documents-inner">
                    <img style={{
                          'display': 'block',
                          'width': '100%',
                          'height': '100%',
                          'object-fit': 'contain',
                          'object-position': 'center center',
                          'margin': 'auto',
                        }} src={signatureDetails?.front_dmv_license} />
                        <a  className=" btn down-btn btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(signatureDetails?.front_dmv_license)}>Download</a>
                    </div>
                    </div>
                    <div className="col-md-3 text-center">
                    <div className="documents-inner">
                    <img style={{
                          'display': 'block',
                          'width': '100%',
                          'height': '100%',
                          'object-fit': 'contain',
                          'object-position': 'center center',
                          'margin': 'auto',
                        }} src={signatureDetails?.front_dmv_license} />
                        <a  className=" btn down-btn btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(signatureDetails?.front_dmv_license)}>Download</a>
                    </div>
                    </div>
                    <div className="col-md-3 text-center">
                    <div className="documents-inner">
                    <img style={{
                          'display': 'block',
                          'width': '100%',
                          'height': '100%',
                          'object-fit': 'contain',
                          'object-position': 'center center',
                          'margin': 'auto',
                        }} src={signatureDetails?.front_tlc_license} />
                        <a  className=" btn down-btn btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(signatureDetails?.front_tlc_license)}>Download</a>
                    </div>
                    </div>
                  </div>
                </div>
                <h3 className="text-center">Others Documents</h3>
                <div className="border p-3 mt-2">
                  

                  <div className="row mt-5">
                  {signatureDetails?.ticket_galleries.map((item) => (<>
                    <div className="col-md-3 text-center" key={item._id}>
                      <div className="documents-inner">
                      {item.extension && item.extension=='pdf' ? (
                              <iframe  style={{'width':"100%",'height':'100%'}} src={item.file_name}></iframe>

                          ):(<img style={{
                            'display': 'block',
                            'width': '100%',
                            'height': '100%',
                            'object-fit': 'contain',
                            'object-position': 'center center',
                            'margin': 'auto',
                          }} src={item.file_name} />)}
                      <a  className=" btn down-btn btn-sm btn-success" href="javascript:void(0)"  onClick={(e) => download(item.file_name,item.original_name)}>Download</a>
                      </div>
                    </div>
                     </>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>):('')
      }
      </Container>
    </div>
  );
};

export default TicketDetails;
