import {
  GET_BANNERS,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  ADD_NEW_BANNER,
  ADD_NEW_BANNER_SUCCESS,
  ADD_NEW_BANNER_FAIL,
  GET_BANNER_DETAILS,
  GET_BANNER_DETAILS_SUCCESS,
  GET_BANNER_DETAILS_FAIL,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  RESET_BANNER_FLAG,

  SAVE_ORDERING_BANNER,
  SAVE_ORDERING_BANNER_SUCCESS,
  SAVE_ORDERING_BANNER_FAIL,
  SAVE_ORDERING_BANNER_RESET_FLAG,
} from "./actionTypes";

export const getBanners = data => ({
  type: GET_BANNERS,
  payload: data,
});

export const getBannersSuccess = data => ({
  type: GET_BANNERS_SUCCESS,
  payload: data,
});

export const getBannersFail = error => ({
  type: GET_BANNERS_FAIL,
  payload: error,
});

export const addNewBanner = banner => ({
  type: ADD_NEW_BANNER,
  payload: banner,
});

export const addNewBannerSuccess = banner => ({
  type: ADD_NEW_BANNER_SUCCESS,
  payload: banner,
});

export const addNewBannerFail = error => ({
  type: ADD_NEW_BANNER_FAIL,
  payload: error,
});

export const updateBanner = banner => ({
  type: UPDATE_BANNER,
  payload: banner,
});

export const updateBannerSuccess = banner => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: banner,
});

export const updateBannerFail = error => ({
  type: UPDATE_BANNER_FAIL,
  payload: error,
});

export const getBannerDetails = banner => ({
  type: GET_BANNER_DETAILS,
  payload: banner,
});

export const getBannerDetailsSuccess = role => ({
  type: GET_BANNER_DETAILS_SUCCESS,
  payload: role,
});

export const getBannerDetailsFail = error => ({
  type: GET_BANNER_DETAILS_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingBanner = data => ({
  type: SAVE_ORDERING_BANNER,
  payload: data,
});

export const saveOrderingBannerSuccess = data => ({
  type: SAVE_ORDERING_BANNER_SUCCESS,
  payload: data,
});

export const saveOrderingBannerFail = error => ({
  type: SAVE_ORDERING_BANNER_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingBannerResetFlag = () => {
  return {
    type: SAVE_ORDERING_BANNER_RESET_FLAG,
  }
}


export const resetBannerFlag = () => {
  return {
    type: RESET_BANNER_FLAG,
  }
}
