import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Calender Redux States
import { GET_STATES, GET_STATE_DETAILS, UPDATE_STATE } from "./actionTypes";

import {
  getStateSuccess,
  getStateFail,
 
  getStateDetailsSuccess,
  getStateDetailsFail,
  updateStateSuccess,
  updateStateFail,

} from "./actions";

//Include Both Helper File with needed methods
import {
  getStateList,

  getStateDetails,
  updateState,

} from "../../helpers/fakebackend_helper";

function* fetchStates({ payload: state }) {
  try {
    const response = yield call(getStateList, state);
    yield put(getStateSuccess(response));
  } catch (error) {
    yield put(getStateFail(error));
  }
}

export function* watchFetchStates() {
  yield takeEvery(GET_STATES, fetchStates);
}



function* onStateDetails({ payload: state }) {
  try {
    const response = yield call(getStateDetails, state);
    if (response.status === 200) {
      yield put(getStateDetailsSuccess(response));
    } else {
      yield put(getStateDetailsFail(response));
    }
  } catch (error) {
    yield put(getStateDetailsFail(error));
  }
}

export function* watchStateDetails() {
  yield takeEvery(GET_STATE_DETAILS, onStateDetails);
}

function* onStateUpdate({ payload: state }) {
  try {
    const response = yield call(updateState, state);
    if (response.status === 200) {
      yield put(updateStateSuccess(response));
    } else {
      yield put(updateStateFail(response));
    }
  } catch (error) {
    yield put(updateStateFail(error));
  }
}

export function* watchUpdateState() {
  yield takeEvery(UPDATE_STATE, onStateUpdate);
}



function* stateSaga() {
  yield all([
    fork(watchFetchStates),
 
    fork(watchStateDetails),
    fork(watchUpdateState),
 
  ]);
}

export default stateSaga;
