/* CLIENT */
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_CLIENT_FAIL = "ADD_NEW_CLIENT_FAIL";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS";
export const GET_CLIENT_DETAILS_FAIL = "GET_CLIENT_DETAILS_FAIL";

export const RESET_CLIENT_FLAG = "RESET_CLIENT_FLAG";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";

export const STATUS_CHANGE_CLIENT = "STATUS_CHANGE_CLIENT";
export const STATUS_CHANGE_CLIENT_SUCCESS = "STATUS_CHANGE_CLIENT_SUCCESS";
export const STATUS_CHANGE_CLIENT_FAIL = "STATUS_CHANGE_CLIENT_FAIL";

export const SAVE_ORDERING_CLIENT = "SAVE_ORDERING_CLIENT";
export const SAVE_ORDERING_CLIENT_SUCCESS = "SAVE_ORDERING_CLIENT_SUCCESS";
export const SAVE_ORDERING_CLIENT_FAIL = "SAVE_ORDERING_CLIENT_FAIL";
export const SAVE_ORDERING_CLIENT_RESET_FLAG = "SAVE_ORDERING_CLIENT_RESET_FLAG";

export const MODIFY_CLIENT_FLAG = "MODIFY_CLIENT_FLAG";




