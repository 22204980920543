import {
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_STATE_DETAILS_SUCCESS,
  GET_STATE_DETAILS_FAIL,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  RESET_STATE_FLAG,
  MODIFY_STATE_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  states: [],
};

const States = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_STATES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case UPDATE_STATE_SUCCESS:
      return {
        ...state,
        states: state.states.map((state) =>
        state._id.toString() === action.payload.data._id.toString()
            ? { ...state, ...action.payload.data }
            : state
        ),
        success: action.payload.message,
        isStateUpdate: true,
        isStateUpdateFail: false,
      };

    case UPDATE_STATE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isStateUpdate: false,
        isStateUpdateFail: true,
      };



    case GET_STATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_STATE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };




    case RESET_STATE_FLAG:
      return {
        ...state,
        isStateAdd: false,
        isStateAddFail: false,
        isSuccess: false,
        isStateDetails: false,
        isStateUpdate: false,
        isStateUpdateFail: false,
        isStateDelete: false,
        isStateDeleteFail: false,
        error: false,
      };

    case MODIFY_STATE_FLAG:
      return {
        ...state,
        isStateUpdate: false,
        isStateUpdateFail: false,
        error: false,
      };



    default:
      return state;
  }
};

export default States;
