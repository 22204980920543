import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateTicket,
  getTicketDetails,
  resetTicketFlag,
  getStateList,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const TicketEdit = () => {
    document.title = "Edit Ticket | Defendit - Admin Panel";
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
  
   
  
    const {
      isTicketUpdate,
      isTicketUpdateFail,
      error,
      success,
      details,
    } = useSelector((state) => ({
      isTicketUpdate: state.Tickets.isTicketUpdate,
      isTicketUpdateFail: state.Tickets.isTicketUpdateFail,
      error: state.Tickets.error,
      success: state.Tickets.success,
      details: state.Tickets.details,
    }));
   
    useEffect(() => {
      if (isTicketUpdate || isTicketUpdateFail) {
        setIsCreating(false);
        setTimeout(() => {
          dispatch(resetTicketFlag());
          history.push('/tickets')
        }, 3000);
      }
    }, [dispatch, isTicketUpdate, isTicketUpdateFail]);
    useEffect(() => {
      dispatch(getStateList({ country_code: "US" }));
    }, [dispatch]);
    useEffect(() => {
      dispatch(getTicketDetails({ id: id }));
    }, [dispatch, id]);
  
    const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
        total_violation_amount: details && details.total_violation_amount ? details.total_violation_amount : "",
        penalty_amount: details && details.penalty_amount ? details.penalty_amount : "",
        additional_penalties_late_fees: details && details.additional_penalties_late_fees ? details.additional_penalties_late_fees : "",
        paid_amount:details && details.paid_amount ? details.paid_amount : "",
        due_amount:details && details.due_amount ? details.due_amount : "",
        violation_location_house: details && details.violation_location_house ? details.violation_location_house : "",
        violation_location_lot_no:details && details.violation_location_lot_no? details.violation_location_lot_no: "",


        violation_location_block_no: details && details.violation_location_block_no ? details.violation_location_block_no : "",
        violation_location_street_name: details && details.violation_location_street_name ? details.violation_location_street_name : "",
        violation_location_borough:details && details.violation_location_borough ? details.violation_location_borough : "",
        violation_location_city:details && details.violation_location_city ? details.violation_location_city : "",
        violation_location_state_name: details && details.violation_location_state_name ? details.violation_location_state_name : "",
        violation_location_zip_code:details && details.violation_location_zip_code? details.violation_location_zip_code: "",


        violation_details: details && details.violation_details ? details.violation_details : "",
        respondent_address_house: details && details.respondent_address_house ? details.respondent_address_house : "",
        respondent_address_street_name:details && details.respondent_address_street_name ? details.respondent_address_street_name : "",
        respondent_address_borough:details && details.respondent_address_borough ? details.respondent_address_borough : "",
        respondent_address_city: details && details.respondent_address_city ? details.respondent_address_city : "",
        respondent_address_state_name:details && details.respondent_address_state_name? details.respondent_address_state_name: "",
     
        respondent_address_zip_code: details && details.respondent_address_zip_code ? details.respondent_address_zip_code : "",
        hearing_status: details && details.hearing_status ? details.hearing_status : "",
        hearing_result:details && details.hearing_result ? details.hearing_result : "",
        compliance_status:details && details.compliance_status ? details.compliance_status : "",
       
        issuing_agency:details && details.issuing_agency? details.issuing_agency: "",
     
      },
      validationSchema: Yup.object({
      }),
  
      onSubmit: (values) => {
        setIsCreating(true);
        const data = {
          id: id,
          total_violation_amount:values.total_violation_amount,
        penalty_amount:values.penalty_amount,
        additional_penalties_late_fees:values.additional_penalties_late_fees,
        paid_amount:values.paid_amount,
        due_amount:values.due_amount,
        violation_location_house: values.violation_location_house,
        violation_location_lot_no:values.violation_location_lot_no,
        violation_location_block_no:  values.violation_location_block_no,
        violation_location_street_name: values.violation_location_street_name,
        violation_location_borough:values.violation_location_borough,
        violation_location_city:values.violation_location_city,
        violation_location_state_name: values.violation_location_state_name,
        violation_location_zip_code:values.violation_location_zip_code,
        violation_details:values.violation_details,
        respondent_address_house: values.respondent_address_house,
        respondent_address_street_name: values.respondent_address_street_name,
        respondent_address_borough:values.respondent_address_borough,
        respondent_address_city:values.respondent_address_city,
        respondent_address_state_name:values.respondent_address_state_name,
        respondent_address_zip_code:values.respondent_address_zip_code,
        hearing_status: values.hearing_status,
        hearing_result:values.hearing_result,
        compliance_status: values.compliance_status,
        issuing_agency:values.issuing_agency,
          type: "Admin",
  
        };
  
        dispatch(updateTicket(data));
      },
    });
  
  
    return (
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Edit Ticket" pageTitle="TIcket" pageUrl="/tickets" />
          <Row>
            <Col lg={8}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Ticket Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="gy-4">
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Total Violation Amount
                          </Label>
                          <Input
                            type="number"
                            name="total_violation_amount"
                            className="form-control"
                            placeholder="Enter Total Violation Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.total_violation_amount || ""}
                            invalid={
                              validation.touched.total_violation_amount &&
                              validation.errors.total_violation_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.total_violation_amount &&
                          validation.errors.total_violation_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.total_violation_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Penalty Amount
                          </Label>
                          <Input
                            type="number"
                            name="penalty_amount"
                            className="form-control"
                            placeholder="Enter Penalty Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.penalty_amount || ""}
                            invalid={
                              validation.touched.penalty_amount &&
                              validation.errors.penalty_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.penalty_amount &&
                          validation.errors.penalty_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.penalty_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Additional Penalties Late Fees
                          </Label>
                          <Input
                            type="number"
                            name="additional_penalties_late_fees"
                            className="form-control"
                            placeholder="Enter Additional Penalties Late Fees"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.additional_penalties_late_fees || ""}
                            invalid={
                              validation.touched.additional_penalties_late_fees &&
                              validation.errors.additional_penalties_late_fees
                                ? true
                                : false
                            }
                          />
                          {validation.touched.additional_penalties_late_fees &&
                          validation.errors.additional_penalties_late_fees ? (
                            <FormFeedback type="invalid">
                              {validation.errors.additional_penalties_late_fees}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Paid Amount
                          </Label>
                          <Input
                            type="number"
                            name="paid_amount"
                            className="form-control"
                            placeholder="Enter Paid Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.paid_amount || ""}
                            invalid={
                              validation.touched.paid_amount &&
                              validation.errors.paid_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.paid_amount &&
                          validation.errors.paid_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.paid_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Due Amount
                          </Label>
                          <Input
                            type="number"
                            name="due_amount"
                            className="form-control"
                            placeholder="Enter Due Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.due_amount || ""}
                            invalid={
                              validation.touched.due_amount &&
                              validation.errors.due_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.due_amount &&
                          validation.errors.due_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.due_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Violation Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="gy-4">
                    
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location House
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_house"
                            className="form-control"
                            placeholder="Enter Violation Location House"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_house || ""}
                            invalid={
                              validation.touched.violation_location_house &&
                              validation.errors.violation_location_house
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_house &&
                          validation.errors.violation_location_house ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_house}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location Lot No
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_lot_no"
                            className="form-control"
                            placeholder="Enter Violation Location Lot No"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_lot_no || ""}
                            invalid={
                              validation.touched.violation_location_lot_no &&
                              validation.errors.violation_location_lot_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_lot_no &&
                          validation.errors.violation_location_lot_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_lot_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location Block No
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_block_no"
                            className="form-control"
                            placeholder="Enter Violation Location Block No"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_block_no || ""}
                            invalid={
                              validation.touched.violation_location_block_no &&
                              validation.errors.violation_location_block_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_block_no &&
                          validation.errors.violation_location_block_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_block_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location Street Name
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_street_name"
                            className="form-control"
                            placeholder="Enter Violation Location Street Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_street_name || ""}
                            invalid={
                              validation.touched.violation_location_street_name &&
                              validation.errors.violation_location_street_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_street_name &&
                          validation.errors.violation_location_street_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_street_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location Borough
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_borough"
                            className="form-control"
                            placeholder="Enter Violation Location Borough"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_borough || ""}
                            invalid={
                              validation.touched.violation_location_borough &&
                              validation.errors.violation_location_borough
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_borough &&
                          validation.errors.violation_location_borough ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_borough}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location City
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_city"
                            className="form-control"
                            placeholder="Enter Violation Location City"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_city || ""}
                            invalid={
                              validation.touched.violation_location_city &&
                              validation.errors.violation_location_city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_city &&
                          validation.errors.violation_location_city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_city}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location State Name
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_state_name"
                            className="form-control"
                            placeholder="Enter Violation Location State Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_state_name || ""}
                            invalid={
                              validation.touched.violation_location_state_name &&
                              validation.errors.violation_location_state_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_state_name &&
                          validation.errors.violation_location_state_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_state_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Location Zip Code
                          </Label>
                          <Input
                            type="text"
                            name="violation_location_zip_code"
                            className="form-control"
                            placeholder="Enter Violation Location Zip Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.violation_location_zip_code || ""}
                            invalid={
                              validation.touched.violation_location_zip_code &&
                              validation.errors.violation_location_zip_code
                                ? true
                                : false
                            }
                          />
                          {validation.touched.violation_location_zip_code &&
                          validation.errors.violation_location_zip_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.violation_location_zip_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Violation Details
                          </Label>
                      <Input
                        type="textarea"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Violation Details"
                        name="violation_details"
                        value={validation.values.violation_details || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                       
                        />
                        {validation.touched.violation_details &&
                        validation.errors.violation_details ? (
                        <FormFeedback type="invalid">
                            {validation.errors.violation_details}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Respondent Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="gy-4">
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Respondent Address House
                          </Label>
                          <Input
                            type="text"
                            name="respondent_address_house"
                            className="form-control"
                            placeholder="Enter Respondent Address House"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.respondent_address_house || ""}
                            invalid={
                              validation.touched.respondent_address_house &&
                              validation.errors.respondent_address_house
                                ? true
                                : false
                            }
                          />
                          {validation.touched.respondent_address_house &&
                          validation.errors.respondent_address_house ? (
                            <FormFeedback type="invalid">
                              {validation.errors.respondent_address_house}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Respondent Address Street Name
                          </Label>
                          <Input
                            type="text"
                            name="respondent_address_street_name"
                            className="form-control"
                            placeholder="Enter Respondent Address Street Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.respondent_address_street_name || ""}
                            invalid={
                              validation.touched.respondent_address_street_name &&
                              validation.errors.respondent_address_street_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.respondent_address_street_name &&
                          validation.errors.respondent_address_street_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.respondent_address_street_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Respondent Address Borough
                          </Label>
                          <Input
                            type="text"
                            name="respondent_address_borough"
                            className="form-control"
                            placeholder="Enter Respondent Address Borough"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.respondent_address_borough || ""}
                            invalid={
                              validation.touched.respondent_address_borough &&
                              validation.errors.respondent_address_borough
                                ? true
                                : false
                            }
                          />
                          {validation.touched.respondent_address_borough &&
                          validation.errors.respondent_address_borough ? (
                            <FormFeedback type="invalid">
                              {validation.errors.respondent_address_borough}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Respondent Address City
                          </Label>
                          <Input
                            type="text"
                            name="respondent_address_city"
                            className="form-control"
                            placeholder="Enter Respondent Address City"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.respondent_address_city || ""}
                            invalid={
                              validation.touched.respondent_address_city &&
                              validation.errors.respondent_address_city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.respondent_address_city &&
                          validation.errors.respondent_address_city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.respondent_address_city}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Respondent Address State Name
                          </Label>
                          <Input
                            type="text"
                            name="respondent_address_state_name"
                            className="form-control"
                            placeholder="Enter Respondent Address State Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.respondent_address_state_name || ""}
                            invalid={
                              validation.touched.respondent_address_state_name &&
                              validation.errors.respondent_address_state_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.respondent_address_state_name &&
                          validation.errors.respondent_address_state_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.respondent_address_state_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       <Col xxl={3} md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Respondent Address Zip Code
                          </Label>
                          <Input
                            type="text"
                            name="respondent_address_zip_code"
                            className="form-control"
                            placeholder="Enter Respondent Address Zip Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.respondent_address_zip_code || ""}
                            invalid={
                              validation.touched.respondent_address_zip_code &&
                              validation.errors.respondent_address_zip_code
                                ? true
                                : false
                            }
                          />
                          {validation.touched.respondent_address_zip_code &&
                          validation.errors.respondent_address_zip_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.respondent_address_zip_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      
                    </Row>
                  </CardBody>
                </Card>
  
  
                <div className="text-end mb-3">
                  <Link to="/tickets" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Submit</span>
                    </span>
                  </Button>
                  {/* <button type="submit" className="btn btn-success w-sm">
                        Submit
                      </button> */}
                </div>
              </Form>
            </Col>
  
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Ticket Info</h5>
                </CardHeader>
                <CardBody>
                  <Row className="gy-4">
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Issuing Agency
                          </Label>
                      <Input
                        type="textarea"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Issuing Agency"
                        name="issuing_agency"
                        value={validation.values.issuing_agency || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                       
                        />
                        {validation.touched.issuing_agency &&
                        validation.errors.issuing_agency ? (
                        <FormFeedback type="invalid">
                            {validation.errors.issuing_agency}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Hearing Status
                          </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Hearing Status"
                        name="hearing_status"
                        value={validation.values.hearing_status || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                        invalid={
                            validation.touched.hearing_status &&
                            validation.errors.hearing_status
                            ? true
                            : false
                        }
                        />
                        {validation.touched.hearing_status &&
                        validation.errors.hearing_status ? (
                        <FormFeedback type="invalid">
                            {validation.errors.hearing_status}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Hearing Result
                          </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Hearing Result"
                        name="hearing_result"
                        value={validation.values.hearing_result || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                        invalid={
                            validation.touched.hearing_result &&
                            validation.errors.hearing_result
                            ? true
                            : false
                        }
                        />
                        {validation.touched.hearing_result &&
                        validation.errors.hearing_result ? (
                        <FormFeedback type="invalid">
                            {validation.errors.hearing_result}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                      <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Compliance Status
                          </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        rows="3"
                        className="form-control"
                        placeholder="Enter Compliance Status"
                        name="compliance_status"
                        value={validation.values.compliance_status || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                        invalid={
                            validation.touched.compliance_status &&
                            validation.errors.compliance_status
                            ? true
                            : false
                        }
                        />
                        {validation.touched.compliance_status &&
                        validation.errors.compliance_status ? (
                        <FormFeedback type="invalid">
                            {validation.errors.compliance_status}
                        </FormFeedback>
                        ) : null}
                       
                      </div>
                    </Col>
                   
                  </Row>
                </CardBody>
              </Card>
  
            </Col>
          </Row>
          {isTicketUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isTicketUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    );
  };
export default TicketEdit;
