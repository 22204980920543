/* JOBS */
export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAIL = "GET_JOBS_FAIL";

export const GET_JOB_APPLIES = "GET_JOB_APPLIES";
export const GET_JOB_APPLIES_SUCCESS = "GET_JOB_APPLIES_SUCCESS";
export const GET_JOB_APPLIES_FAIL = "GET_JOB_APPLIES_FAIL";

export const ADD_NEW_JOB = "ADD_NEW_JOB";
export const ADD_NEW_JOB_SUCCESS = "ADD_NEW_JOB_SUCCESS";
export const ADD_NEW_JOB_FAIL = "ADD_NEW_JOB_FAIL";

export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAIL = "UPDATE_JOB_FAIL";

export const REMOVE_JOB = "REMOVE_JOB";
export const REMOVE_JOB_SUCCESS = "REMOVE_JOB_SUCCESS";
export const REMOVE_JOB_FAIL = "REMOVE_JOB_FAIL";

export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAIL = "GET_JOB_DETAILS_FAIL";

// STATUS CHANGE
export const STATUS_CHANGE_JOB = "STATUS_CHANGE_JOB";
export const STATUS_CHANGE_JOB_SUCCESS = "STATUS_CHANGE_JOB_SUCCESS";
export const STATUS_CHANGE_JOB_FAIL = "STATUS_CHANGE_JOB_FAIL";

export const MODIFY_JOB_FLAG = "MODIFY_JOB_FLAG";

export const RESET_JOB_FLAG = "RESET_JOB_FLAG";
