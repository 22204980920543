import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getJobs,
  removeJob,
  resetJobFlag,
  modifyJobFlag,
  statusChangeJob,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import MsgToast from "../../../Components/Common/MsgToast";

const JobList = () => {
  document.title = "Job list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const {
    jobs,
    totalDataCount,
    success,
    isSuccess,
    error,
    statusSuccess,
    statusFailed,
  } = useSelector((state) => ({
    jobs: state.Jobs.jobs,
    totalDataCount: state.Jobs.totalDataCount,
    isSuccess: state.Jobs.isSuccess,
    success: state.Jobs.success,
    error: state.Jobs.error,
    statusSuccess: state.Jobs.statusSuccess,
    statusFailed: state.Jobs.statusFailed,
  }));
  console.log(jobs);
  useEffect(() => {
    dispatch(
      getJobs({
        keyword,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [dispatch, keyword, sortByField, sortByValue, limit, offset]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getJobs({ limit, dateRange, offset, keyword }));
    }
    // console.log(date);
  }, [dispatch, date, limit, offset, keyword]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyJobFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    dispatch(getJobs({ limit, offset, keyword }));
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  const deleteJob = (id) => {
    dispatch(removeJob({ id }));
    console.log(id)
  };

  function onClickStatusChange(id, status) {
    console.log(id, status);
    dispatch(statusChangeJob({ id, status }));
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Jobs" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Job List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/job/create"
                      className="btn btn-success add-btn"
                      id="createJobTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Job
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createJobTooltip"
                    >
                      Create Job
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={1} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {jobs.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="image"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Image
                                </th> */}
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="title"
                                  onClick={() => sortBy("title")}
                                >
                                  Name
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="degree"
                                  onClick={() => sortBy("degree")}
                                >
                                  Degree
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="salary"
                                  onClick={() => sortBy("salary")}
                                >
                                  Salary
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="vacancy"
                                  onClick={() => sortBy("vacancy")}
                                >
                                  Vacancy
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {jobs.map((item) => (
                                <tr key={item._id}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                      />
                                    </div>
                                  </th>

                                  <td>{item.title}</td>
                                  <td>{item.degree}</td>
                                  <td>{item.salary}</td>
                                  <td>{item.vacancy}</td>

                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {item.status && item.status === "active" ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        Inactive
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li
                                        className="list-inline-item edit"
                                        title="Edit"
                                      >
                                        <Link
                                          to={`/job/edit/${item._id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>
                                      <li
                                        className="list-inline-item"
                                        title="Remove"
                                        id={"deletePopover" + item._id}
                                      >
                                        <Button className="p-0 bg-transparent border-0 text-danger">
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Button>
                                      </li>
                                      <UncontrolledPopover
                                       trigger="focus"
                                       placement="left"
                                       target={"deletePopover" + item._id}
                                      >
                                        <PopoverHeader>
                                          Delete Job
                                        </PopoverHeader>
                                        <PopoverBody>
                                          Are you sure want to delete?
                                          <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Button className="btn btn-sm btn-light">
                                              No
                                            </Button>
                                            <Button
                                              onClick={() => deleteJob(item._id)}
                                              className="btn btn-sm btn-danger ms-2"
                                            >
                                              Yes
                                            </Button>
                                          </div>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                      <li
                                        className="list-inline-item"
                                        title="Status Change"
                                      >
                                        <Button
                                          onClick={() =>
                                            onClickStatusChange(
                                              item._id,
                                              item.status &&
                                                item.status === 'active'
                                                ? 'inactive'
                                                : 'active'
                                            )
                                          }
                                          className="p-0 bg-transparent border-0 text-warning"
                                        >
                                          <i className="ri-exchange-line fs-16"></i>
                                        </Button>
                                      </li>
                                      <li
                                        className="list-inline-item edit"
                                        title="Job-apply"
                                      >
                                        <Link
                                          to={`/jobapplies/${item._id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-file-list-2-fill fs-16"></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </>
  );
};

export default JobList;
