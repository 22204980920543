import React, { useState,useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { Card,CardBody,CardHeader,Col,Container,Button,Spinner,Row,Input,Label,FormFeedback,Form} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  convertToBase64 
} from "../../../helpers/image_helper"
import { 
  updateBanner, 
  resetBannerFlag,
  getBannerDetails,
  getPages,
} from "../../../store/actions";

import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";


import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const BannerEdit = () => {
  document.title ="Edit Banner | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [isHomePage, setisHomePage] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [image, setBannerImage1] = useState();
  const [image2, setBannerImage2] = useState();
  const [description, setData] = useState();
  const allowedExts = ['jpg', 'jpeg', 'png','svg','svg+xml'];
  const { isBannerUpdate, isBannerUpdateFail, error, success,details,pageList } = useSelector(state => ({
    isBannerUpdate: state.Banners.isBannerUpdate,
    isBannerUpdateFail: state.Banners.isBannerUpdateFail,
    error: state.Banners.error,
    success: state.Banners.success,
    details: state.Banners.details,
    pageList: state.Pages.pages,
  }));  

  const selectedPageOption = {
    value: details && details?.page_details?._id ? details?.page_details?._id : "",
    label: details && details?.page_details?.title ? details?.page_details?.title : "",
  };

  const handleBannerChange = (event)=>{
    if(event.target.value?.value == '62d7ffabed090b5890fd1d14'){
      setisHomePage(true);
    }else{
      setisHomePage(false);
    }
  }

  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById('image1');
          output.src = URL.createObjectURL(e.target.files[0]);
          output.onload = function() {
            URL.revokeObjectURL(output.src) // free memory
          }
        convertToBase64(e.target.files[0], setBannerImage1);
  
      }
    }
  };
 
  const onImg2Change = (e) => {
        
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById('image2');
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function() {
          URL.revokeObjectURL(output.src) // free memory
        }
        convertToBase64(e.target.files[0], setBannerImage2);
      }
    }
  };
  
  

  const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
          title: (details && details.title)?details.title:'',
          description: (details && details.description)?details.description:'',
          image: (details && details.image)?details.image:'',
          image2: (details && details.image2)?details.image2:'',
          type_id : selectedPageOption,
       
              
      },
      validationSchema: Yup.object({
          title: Yup.string().required("Please Enter Title"),
  
          
      }),
      onSubmit: (values) => {
          setIsCreating(true)
          const data = {
            id: id,
            title : values.title,
            description:description,
            image: image,
            image2:image2,
            type_id : values.type_id?.value,
            type:'page',
          }
          dispatch(updateBanner(data));
      }
  });

  useEffect(() => {
    if(isBannerUpdate || isBannerUpdateFail){
        setIsCreating(false)
        validation.resetForm();
        setTimeout(() => {
            dispatch(resetBannerFlag())
            history.push('/banners')
        }, 3000);
    }
  }, [dispatch, isBannerUpdate, isBannerUpdateFail, validation]);



  useEffect(() => {
    dispatch(getBannerDetails({id:id}))
  }, [dispatch, id]);


  useEffect(() => {
    dispatch(getPages());
    if(details?.type_id == '62d7ffabed090b5890fd1d14'){
      setisHomePage(true);
    }
  }, [dispatch, details]);

  
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title="Edit Banner" pageTitle="Banner" pageUrl="/banners" />
        <Row>
          <Col lg={8}>
          <Form onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
          }}>
               <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Banner </h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Name
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Name"
                      type="text"
                      name="title"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title || ""}
                      invalid={
                          validation.touched.title && validation.errors.title ? true : false
                      }
                    />
                    {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="hstack gap-3 align-items-start mb-3">
                    <div className="flex-grow-1">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Page
                      </Label>
                      <Select
                        type="text"
                        name="type_id"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "type_id",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                          handleBannerChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          pageList &&
                          pageList.map((item, index) => ({
                            label: item.title,
                            value: item._id,
                          }))
                        }
                        value={validation.values.type_id || ""}
                      />
                      {validation.touched.type_id &&
                      validation.errors.type_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Description
                  </Label>
                  <CKEditor
                        editor={ClassicEditor}
                        name="description"
                        data={validation.values.description || ""}
                        onChange={(event, editor) => {
                          setData(editor.getData())
                          }}
                        invalid={false}
                        />
                        {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                
              </CardBody>
            </Card>            

              <div className="text-end mb-3">
              <Link to="/banners" className="btn btn-danger w-sm me-1">Cancel</Link>
                <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                    <span className="d-flex align-items-center">
                        {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                        <span className="flex-grow-1">
                           Submit
                        </span>
                    </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button> */}
              </div>
              </Form>
          </Col>
          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Banner Image</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Image
                  </Label>
                  <Input type="file" onChange={onImg1Change}/>
                  <img src={validation.values.image || ""} alt=""  id="image1" width="100px" ></img>
                  </div>
                </div>
                {isHomePage === true ?
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Image
                  </Label>
                  <Input type="file" onChange={onImg2Change}/>
                  <img src={validation.values.image2 || ""} alt="" id="image2"  width="100px" ></img>
                  </div>
                </div>
                :''}              
              </CardBody>
            </Card>
          </Col>

         
        </Row>
        {isBannerUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
        {isBannerUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default BannerEdit;
