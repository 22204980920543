import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  
  GET_NOTIFICATIONS_HISTORY,
  GET_NOTIFICATIONS_HISTORY_SUCCESS,
  GET_NOTIFICATIONS_HISTORY_FAIL,


  RESET_NOTIFICATION_FLAG,

  RESET_NOTIFICATION_HISTORY_FLAG,
  MODIFY_NOTIFICATION_FLAG
  
} from "./actionTypes";

export const getNotifications = data => ({
  type: GET_NOTIFICATIONS,
  payload: data,
});

export const getNotificationsSuccess = data => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});


export const getNotificationsHistory = data => ({
  type: GET_NOTIFICATIONS_HISTORY,
  payload: data,
});

export const getNotificationsHistorySuccess = data => ({
  type: GET_NOTIFICATIONS_HISTORY_SUCCESS,
  payload: data,
});

export const getNotificationsHistoryFail = error => ({
  type: GET_NOTIFICATIONS_HISTORY_FAIL,
  payload: error,
});



export const resetNotificationFlag = () => {
  return {
    type: RESET_NOTIFICATION_FLAG,
  }
}


export const resetNotificationHistoryFlag = () => {
  return {
    type: RESET_NOTIFICATION_HISTORY_FLAG,
  }
}

export const modifyNotificationFlag = () => {
  return {
    type: MODIFY_NOTIFICATION_FLAG,
  }
}