import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,

  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,

  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,

  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,

  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,

  STATUS_CHANGE_USER_SUCCESS,
  STATUS_CHANGE_USER_FAIL,

  EMAIL_VERIFIED_CHANGE_USER_SUCCESS,
  EMAIL_VERIFIED_CHANGE_USER_FAIL,

  PHONE_VERIFIED_CHANGE_USER_SUCCESS,
  PHONE_VERIFIED_CHANGE_USER_FAIL,

  PASSWORD_CHANGE_USER_SUCCESS,
  PASSWORD_CHANGE_USER_FAIL,

  MODIFY_USER_FLAG,
  RESET_USER_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  users: []
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };


    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.data],
        success: action.payload.message,
        isUserAdd: true,
        isUserAddFail: false,
      };

    case ADD_NEW_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isUserAdd: false,
        isUserAddFail: true,
      };


    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user._id.toString() === action.payload.data._id.toString()
            ? { ...user, ...action.payload.data }
            : user
        ),
        success: action.payload.message,
        isUserUpdate: true,
        isUserUpdateFail: false
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isUserUpdate: false,
        isUserUpdateFail: true,
      };


    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    //Delete
    case DELETE_USER_SUCCESS:
      return { 
        ...state,
        users: state.users.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
     
    //Status Change
    case STATUS_CHANGE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
     
    //Status Change
    case EMAIL_VERIFIED_CHANGE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case EMAIL_VERIFIED_CHANGE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
     
    //Status Change
    case PHONE_VERIFIED_CHANGE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case PHONE_VERIFIED_CHANGE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    //Password Change
    case PASSWORD_CHANGE_USER_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        isPasswordChangeSuccess: true,
        isPasswordChangeFaild: false,
      };
        
    case PASSWORD_CHANGE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPasswordChangeSuccess: false,
        isPasswordChangeFaild: true,
      };

    case MODIFY_USER_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_USER_FLAG:
      return {
        ...state,
        isUserAdd: false,
        isUserAddFail: false,
        isSuccess: false,
        isUserDetails: false,
        isUserUpdate: false,
        isUserUpdateFail: false,
        isPasswordChangeSuccess : false,
        isPasswordChangeFaild : false,
        error: false
      };

    default:
      return state;
  }
};

export default Users;
