/* STEPS */
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";


export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const STATUS_CHANGE_USER = "STATUS_CHANGE_USER";
export const STATUS_CHANGE_USER_SUCCESS = "STATUS_CHANGE_USER_SUCCESS";
export const STATUS_CHANGE_USER_FAIL = "STATUS_CHANGE_USER_FAIL";

export const EMAIL_VERIFIED_CHANGE_USER = "EMAIL_VERIFIED_CHANGE_USER";
export const EMAIL_VERIFIED_CHANGE_USER_SUCCESS = "EMAIL_VERIFIED_CHANGE_USER_SUCCESS";
export const EMAIL_VERIFIED_CHANGE_USER_FAIL = "EMAIL_VERIFIED_CHANGE_USER_FAIL";

export const PHONE_VERIFIED_CHANGE_USER = "PHONE_VERIFIED_CHANGE_USER";
export const PHONE_VERIFIED_CHANGE_USER_SUCCESS = "PHONE_VERIFIED_CHANGE_USER_SUCCESS";
export const PHONE_VERIFIED_CHANGE_USER_FAIL = "PHONE_VERIFIED_CHANGE_USER_FAIL";


export const PASSWORD_CHANGE_USER = "PASSWORD_CHANGE_USER";
export const PASSWORD_CHANGE_USER_SUCCESS = "PASSWORD_CHANGE_USER_SUCCESS";
export const PASSWORD_CHANGE_USER_FAIL = "PASSWORD_CHANGE_USER_FAIL";

export const MODIFY_USER_FLAG = "MODIFY_USER_FLAG";
export const RESET_USER_FLAG = "RESET_USER_FLAG";