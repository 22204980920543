/* WHYUS */
export const GET_WHYUS = "GET_WHYUS";
export const GET_WHYUS_SUCCESS = "GET_WHYUS_SUCCESS";
export const GET_WHYUS_FAIL = "GET_WHYUS_FAIL";

export const ADD_NEW_WHYUS = "ADD_NEW_WHYUS";
export const ADD_NEW_WHYUS_SUCCESS = "ADD_NEW_WHYUS_SUCCESS";
export const ADD_NEW_WHYUS_FAIL = "ADD_NEW_WHYUS_FAIL";

export const UPDATE_WHYUS = "UPDATE_WHYUS";
export const UPDATE_WHYUS_SUCCESS = "UPDATE_WHYUS_SUCCESS";
export const UPDATE_WHYUS_FAIL = "UPDATE_WHYUS_FAIL";

export const GET_WHYUS_DETAILS = "GET_WHYUS_DETAILS";
export const GET_WHYUS_DETAILS_SUCCESS = "GET_WHYUS_DETAILS_SUCCESS";
export const GET_WHYUS_DETAILS_FAIL = "GET_WHYUS_DETAILS_FAIL";




export const DELETE_WHYUS = "DELETE_WHYUS";
export const DELETE_WHYUS_SUCCESS = "DELETE_WHYUS_SUCCESS";
export const DELETE_WHYUS_FAIL = "DELETE_WHYUS_FAIL";

export const STATUS_CHANGE_WHYUS = "STATUS_CHANGE_WHYUS";
export const STATUS_CHANGE_WHYUS_SUCCESS = "STATUS_CHANGE_WHYUS_SUCCESS";
export const STATUS_CHANGE_WHYUS_FAIL = "STATUS_CHANGE_WHYUS_FAIL";

export const SAVE_ORDERING_WHYUS = "SAVE_ORDERING_WHYUS";
export const SAVE_ORDERING_WHYUS_SUCCESS = "SAVE_ORDERING_WHYUS_SUCCESS";
export const SAVE_ORDERING_WHYUS_FAIL = "SAVE_ORDERING_WHYUS_FAIL";
export const SAVE_ORDERING_WHYUS_RESET_FLAG = "SAVE_ORDERING_WHYUS_RESET_FLAG";

export const RESET_WHYUS_FLAG = "RESET_WHYUS_FLAG";
export const MODIFY_WHYUS_FLAG = "MODIFY_WHYUS_FLAG";


