import React, { useState,useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Card,CardBody,CardHeader,Col,Container,Button,Spinner,Row,Input,Label,FormFeedback,Form} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link , useHistory} from 'react-router-dom';
import { 
  addNewWhyus, 
  resetWhyusFlag, 
} from "../../../store/actions";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";



// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const WhyusCreate = () => {
  document.title ="Create Whyus | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);

  const { isWhyusAdd, isWhyusAddFail, error, success, } = useSelector(state => ({
    isWhyusAdd: state.Whyus.isWhyusAdd,
    isWhyusAddFail: state.Whyus.isWhyusAddFail,
    error: state.Whyus.error,
    success: state.Whyus.success,
    
  }));  

  const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
        name:'',
        icon: '',
         
      },
      validationSchema: Yup.object({
          name: Yup.string().required("Please Choose Name"),
          icon: Yup.string().required("Please Enter Icon"),
      }),
      onSubmit: (values) => {
          setIsCreating(true)
          const data = {
            name : values.name,
            icon: values.icon,
          }
          dispatch(addNewWhyus(data));
      }
  });

  useEffect(() => {
    if(isWhyusAdd || isWhyusAddFail){
        setIsCreating(false)
        validation.resetForm();
        setTimeout(() => {
            dispatch(resetWhyusFlag())
            history.push('/whyus')
        }, 3000);
    }
  }, [dispatch, isWhyusAdd, isWhyusAddFail, validation]);



  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title="Create Whyus" pageTitle="Whyus" pageUrl="/whyus" />
        <Row>
          <Col lg={12}>
          <Form onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
          }}>

              <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Whyus </h5>
              </CardHeader>
              <CardBody>
               <Row className="gy-4">
              <Col xxl={3} md={6}>
                <div>
                    <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                    <Input
                        type="text"
                        id='placeholderInput'
                        className="form-control"
                        placeholder="Enter name"
                        name='name'
                        value={validation.values.name || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{
                            required: { value: true },
                        }}
                        invalid={
                            validation.touched.name && validation.errors.name ? true : false
                        }
                    />
                    {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                    ) : null}
                </div>
              </Col>
                <Col xxl={3} md={6}>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Icon
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Icon"
                      type="text"
                      name="icon"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.icon || ""}
                      invalid={
                          validation.touched.icon && validation.errors.icon ? true : false
                      }
                    />
                    {validation.touched.icon && validation.errors.icon ? (
                        <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                    ) : null}
                  </div>
                </div>
                </Col>
                </Row>
              </CardBody>
            </Card>            

              <div className="text-end mb-3">
              <Link to="/whyus" className="btn btn-danger w-sm me-1">Cancel</Link>
                <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                    <span className="d-flex align-items-center">
                        {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                        <span className="flex-grow-1">
                           Submit
                        </span>
                    </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button> */}
              </div>
              </Form>
          </Col>
        </Row>
        {isWhyusAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
        {isWhyusAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default WhyusCreate;
