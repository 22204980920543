import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from 'react-router-dom';
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewCoupon, resetCouponFlag, getUsers } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import Flatpickr from "react-flatpickr";

const StepCreate = () => {
    document.title = "Create Step | Defendit - Admin Panel";
    const history = useHistory();
    const dispatch = useDispatch();
    const [ValidFrom, setValidFrom] = useState(null);
    const [ValidThru, setValidThru] = useState(null);
    const fp = useRef(null);
    const [isCreating, setIsCreating] = useState(false);
    const [selectedMulti2, setselectedMulti2] = useState(null);
    const [userIds, setUserIds] = useState(null);
    const { isCouponAdd, isCouponAddFail, error, success, userList } = useSelector(state => ({
        isCouponAdd: state.Coupons.isCouponAdd,
        isCouponAddFail: state.Coupons.isCouponAddFail,
        error: state.Coupons.error,
        success: state.Coupons.success,
        userList: state.Users.users,
    }));


    useEffect(() => {
        dispatch(getUsers({role:'62de277397c8633a839c6450'}))
    }, [dispatch]);

    function handleMulti2(selectedMulti2) {
        setselectedMulti2(selectedMulti2);
        var users = [];
        for(var i=0; i<selectedMulti2.length; i++){
            users.push(selectedMulti2[i].value);
        }
        setUserIds(users);
    }


    const typeList = [
        { label: "Fixed Discount", value: "fixed" },
        { label: "Percentage Discount", value: "percentage" },
    ];

    const selectedDiscountTypeOption = {
        value: "",
        label: "",
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            ammount:'',
            comment:'',
            usage:'',
            description:'',
            type : selectedDiscountTypeOption,
            // start_date_alies: ValidFrom?.e[0].toISOString(),
            // end_date_date_alies: ValidThru?.e[0].toISOString(),
            
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Coupon name is required."),
            ammount: Yup.string().required("Coupon amount is required."),
            usage: Yup.string().required("Coupon usage is required."),
            // start_date_alies: Yup.string().required("Please select valid from date"),
            // end_date_date_alies: Yup.string().required("Please select valid thru date"),
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                name: values.name,
                ammount: values.ammount,
                comment: values.comment,
                usage: values.usage,
                fees_type : values.fees_type,
                description: values.description,
                type: validation.values.type.value,
                start_date:ValidFrom?.e[0].toISOString(),
                end_date:ValidThru?.e[0].toISOString(),
                user_ids : userIds,
            }
            //console.log(data);
            dispatch(addNewCoupon(data))
        },
    });
    useEffect(() => {
        if(isCouponAdd || isCouponAddFail){
            
            setIsCreating(false);
            setTimeout(() => {
                dispatch(resetCouponFlag());
            }, 3000);
        }
      }, [dispatch, isCouponAdd, isCouponAddFail, validation]);
    useEffect(() => {
        if(isCouponAdd){
         
            validation.resetForm();
            setTimeout(() => {
                history.push("/coupons");
            }, 3000);
        }
      }, [dispatch, isCouponAdd, isCouponAddFail, validation]);
  

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Coupon" pageTitle="Coupon" pageUrl="/coupons" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Create Coupon" />

                                    <CardBody className="card-body">
                                        <div className="live-preview">
                                            <p className="text-muted">Fees Type</p>
                                            <div className="mb-3">
                                               <div className="form-check form-check-inline">
                                                <Input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="fees_type" 
                                                id="inlineRadio1" 
                                                value={1}
                                                onChange={validation.handleChange}
                                                defaultChecked
                                                />
                                                <Label className="form-check-label" htmlFor="inlineRadio1">Platform fees</Label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                <Input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="fees_type" 
                                                id="inlineRadio2" 
                                                value={2} 
                                                onChange={validation.handleChange}
                                                defaultChecked={validation.values.fees_type === 2}
                                                />
                                                <Label className="form-check-label" htmlFor="inlineRadio2">Cc fees</Label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                <Input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="fees_type" 
                                                id="inlineRadio3" 
                                                value={3} 
                                                onChange={validation.handleChange}
                                                defaultChecked={validation.values.fees_type === 3}
                                                />
                                                <Label className="form-check-label" htmlFor="inlineRadio3">Total value</Label>
                                                </div>
                                            {validation.touched.fees_type && validation.errors.fees_type ? (
                                                <FormFeedback type="invalid">{validation.errors.fees_type}</FormFeedback>
                                            ) : null}
                                            </div>
                                            <Row className="gy-4">
                                                <Col xxl={4} md={4}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name*</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Coupon name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={4} md={4}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Amount*</Label>
                                                        <Input
                                                            type="number"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Enter Amount"
                                                            name='ammount'
                                                            value={validation.values.ammount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.ammount && validation.errors.ammount ? true : false
                                                            }
                                                        />
                                                        {validation.touched.ammount && validation.errors.ammount ? (
                                                            <FormFeedback type="invalid">{validation.errors.ammount}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={4} md={4}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Usage*</Label>
                                                        <Input
                                                            type="number"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Enter Usage"
                                                            name='usage'
                                                            value={validation.values.usage || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.usage && validation.errors.usage ? true : false
                                                            }
                                                        />
                                                        {validation.touched.usage && validation.errors.usage ? (
                                                            <FormFeedback type="invalid">{validation.errors.usage}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={4} md={4}>
                                                    <div className="mb-3">
                                                    <Label
                                                        className="form-label"
                                                        htmlFor="product-title-input"
                                                    >
                                                        Discount Types*
                                                    </Label>
                                                    <Select
                                                        type="text"
                                                        name="type"
                                                        onChange={(selectedOption) => {
                                                        let event = {
                                                            target: { name: "type", value: selectedOption },
                                                        };
                                                        validation.handleChange(event);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        options={
                                                        typeList &&
                                                        typeList.map((item, index) => ({
                                                            label: item.label,
                                                            value: item.value,
                                                        }))
                                                        }
                                                        value={validation.values.type || ""}
                                                    />
                                                    {validation.touched.type && validation.errors.type ? (
                                                        <FormFeedback type="invalid">
                                                        {validation.errors.type}
                                                        </FormFeedback>
                                                    ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={4} md={4}>
                                                    <Label
                                                    htmlFor="placeholderInput"
                                                    className="form-label"
                                                    >
                                                    Valid from*
                                                    </Label>
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            placeholder="Select valid from date"
                                                            options={{
                                                                mode: "single",
                                                                dateFormat: "d M, Y",
                                                                //defaultDate: ["01 Jan 2022", "31 Jan 2022"]
                                                            }}
                                                            onChange={(e) => {
                                                                setValidFrom({ e });
                                                            }}
                                                        />
                                                        <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>


                                                       {/*  <Input
                                                            type="hidden"
                                                            name='start_date_alies'
                                                            value={validation.values.start_date_alies || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.start_date_alies && validation.errors.start_date_alies ? true : false
                                                            }
                                                        />

                                                        {validation.touched.start_date_alies && validation.errors.start_date_alies ? (
                                                            <FormFeedback type="invalid">{validation.errors.start_date_alies}</FormFeedback>
                                                        ) : null} */}

                                                    </div>
                                                </Col>

                                                <Col xxl={4} md={4}>
                                                    <Label
                                                    htmlFor="placeholderInput"
                                                    className="form-label"
                                                    >
                                                    Valid thru*
                                                    </Label>
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            ref={fp}
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            placeholder="Select valid thru date"
                                                            options={{
                                                                mode: "single",
                                                                dateFormat: "d M, Y",
                                                                //defaultDate: ["01 Jan 2022", "31 Jan 2022"]
                                                            }}
                                                            onChange={(e) => {
                                                                setValidThru({ e });
                                                            }}
                                                        />
                                                        <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                                                    
                                                        {/* <Input
                                                            type="hidden"
                                                            name='end_date_date_alies'
                                                            value={validation.values.end_date_date_alies || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.end_date_date_alies && validation.errors.end_date_date_alies ? true : false
                                                            }
                                                        />
                                                        {validation.touched.end_date_date_alies && validation.errors.end_date_date_alies ? (
                                                            <FormFeedback type="invalid">{validation.errors.end_date_date_alies}</FormFeedback>
                                                        ) : null} */}
                                                    </div>
                                                </Col>

                                                <Col lg={12} md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="placeholderInput" className="form-label">
                                                        User
                                                        </Label>                                                       
                                                        <Select
                                                            name="user_ids"
                                                            value={selectedMulti2}
                                                            isMulti={true}
                                                            isClearable={true}
                                                            onChange={(e) => {
                                                                handleMulti2(e);
                                                            }}

                                                            options={
                                                                userList &&
                                                                userList.map((item, index) => ({
                                                                  label: item.name+' - '+item.email,
                                                                  value: item._id,
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                           

                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label
                                                        htmlFor="placeholderInput"
                                                        className="form-label"
                                                        >
                                                        Comment
                                                        </Label>
                                                        <Input
                                                        type="textarea"
                                                        id="placeholderInput"
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Enter comment"
                                                        name="comment"
                                                        value={validation.values.comment || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        invalid={
                                                            validation.touched.comment &&
                                                            validation.errors.comment
                                                            ? true
                                                            : false
                                                        }
                                                        />
                                                        {validation.touched.comment &&
                                                        validation.errors.comment ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.comment}
                                                        </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label
                                                        htmlFor="placeholderInput"
                                                        className="form-label"
                                                        >
                                                        Description
                                                        </Label>
                                                        <Input
                                                        type="textarea"
                                                        id="placeholderInput"
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Enter description"
                                                        name="description"
                                                        value={validation.values.description || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        invalid={
                                                            validation.touched.description &&
                                                            validation.errors.description
                                                            ? true
                                                            : false
                                                        }
                                                        />
                                                        {validation.touched.description &&
                                                        validation.errors.description ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.description}
                                                        </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/coupons" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isCouponAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isCouponAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default StepCreate;