
import {
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAIL,
  GET_JOB_APPLIES,
  GET_JOB_APPLIES_SUCCESS,
  GET_JOB_APPLIES_FAIL,
  ADD_NEW_JOB,
  ADD_NEW_JOB_SUCCESS,
  ADD_NEW_JOB_FAIL,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAIL,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAIL,
 
  REMOVE_JOB,
  REMOVE_JOB_SUCCESS,
  REMOVE_JOB_FAIL,

  MODIFY_JOB_FLAG,
  RESET_JOB_FLAG,
  STATUS_CHANGE_JOB,
  STATUS_CHANGE_JOB_SUCCESS,
  STATUS_CHANGE_JOB_FAIL
} from "./actionTypes";

export const getJobs = data => ({
  type: GET_JOBS,
  payload: data,
});

export const getJobsSuccess = data => ({
  type: GET_JOBS_SUCCESS,
  payload: data,
});

export const getJobsFail = error => ({
  type: GET_JOBS_FAIL,
  payload: error,
});
export const getJobApplies = data => ({
  type: GET_JOB_APPLIES,
  payload: data,
});

export const getJobAppliesSuccess = data => ({
  type: GET_JOB_APPLIES_SUCCESS,
  payload: data,
});

export const getJobAppliesFail = error => ({
  type: GET_JOB_APPLIES_FAIL,
  payload: error,
});

export const addNewJob = job=> ({
  type: ADD_NEW_JOB,
  payload: job,
});

export const addNewJobSuccess = job => ({
  type: ADD_NEW_JOB_SUCCESS,
  payload: job,
});

export const addNewJobFail = error => ({
  type: ADD_NEW_JOB_FAIL,
  payload: error,
});

export const updateJob = job => ({
  type: UPDATE_JOB,
  payload: job,
});

export const updateJobSuccess = job => ({
  type: UPDATE_JOB_SUCCESS,
  payload: job,
});

export const updateJobFail = error => ({
  type: UPDATE_JOB_FAIL,
  payload: error,
});

export const getJobDetails = job => ({
  type: GET_JOB_DETAILS,
  payload: job,
});

export const getJobDetailsSuccess = job => ({
  type: GET_JOB_DETAILS_SUCCESS,
  payload: job,
});

export const getJobDetailsFail = error => ({
  type: GET_JOB_DETAILS_FAIL,
  payload: error,
});

export const removeJob = job => ({
  type: REMOVE_JOB,
  payload: job,
});

export const removeJobSuccess = job => ({
  type: REMOVE_JOB_SUCCESS,
  payload: job,
});

export const removeJobFail = error => ({
  type: REMOVE_JOB_FAIL,
  payload: error,
});

export const statusChangeJob = job => ({
  type: STATUS_CHANGE_JOB,
  payload: job,
});

export const statusChangeJobSuccess = job => ({
  type: STATUS_CHANGE_JOB_SUCCESS,
  payload: job,
});

export const statusChangeJobFail = error => ({
  type: STATUS_CHANGE_JOB_FAIL,
  payload: error,
});

export const modifyJobFlag = () => {
  return {
    type: MODIFY_JOB_FLAG,
  }
}

export const resetJobFlag = () => {
  return {
    type: RESET_JOB_FLAG,
  }
}