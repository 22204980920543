/* BILLINGS */
export const GET_BILLINGS = "GET_BILLINGS";
export const GET_BILLINGS_SUCCESS = "GET_BILLINGS_SUCCESS";
export const GET_BILLINGS_FAIL = "GET_BILLINGS_FAIL";

export const ADD_NEW_BILLING = "ADD_NEW_BILLING";
export const ADD_NEW_BILLING_SUCCESS = "ADD_NEW_BILLING_SUCCESS";
export const ADD_NEW_BILLING_FAIL = "ADD_NEW_BILLING_FAIL";


export const RESEND_INVOICE = "RESEND_INVOICE";
export const RESEND_INVOICE_SUCCESS = "RESEND_INVOICE_SUCCESS";
export const RESEND_INVOICE_FAIL = "RESEND_INVOICE_FAIL";


export const INVOICE_STATUS_CHANGE = "INVOICE_STATUS_CHANGE";
export const INVOICE_STATUS_CHANGE_SUCCESS = "INVOICE_STATUS_CHANGE_SUCCESS";
export const INVOICE_STATUS_CHANGE_FAIL = "INVOICE_STATUS_CHANGE_FAIL";



export const RESET_BILLING_FLAG = "RESET_BILLING_FLAG";
export const MODIFY_BILLING_FLAG = "MODIFY_BILLING_FLAG";