import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,

  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,

  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,

  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,

  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,

  STATUS_CHANGE_USER,
  STATUS_CHANGE_USER_SUCCESS,
  STATUS_CHANGE_USER_FAIL,

  EMAIL_VERIFIED_CHANGE_USER,
  EMAIL_VERIFIED_CHANGE_USER_SUCCESS,
  EMAIL_VERIFIED_CHANGE_USER_FAIL,

  PHONE_VERIFIED_CHANGE_USER,
  PHONE_VERIFIED_CHANGE_USER_SUCCESS,
  PHONE_VERIFIED_CHANGE_USER_FAIL,

  PASSWORD_CHANGE_USER,
  PASSWORD_CHANGE_USER_SUCCESS,
  PASSWORD_CHANGE_USER_FAIL,

  MODIFY_USER_FLAG,
  RESET_USER_FLAG
  
} from "./actionTypes";

//List
export const getUsers = data => ({
  type: GET_USERS,
  payload: data,
});

export const getUsersSuccess = data => ({
  type: GET_USERS_SUCCESS,
  payload: data,
});

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
});


//Create
export const addNewUser = user=> ({
  type: ADD_NEW_USER,
  payload: user,
});

export const addNewUserSuccess = user => ({
  type: ADD_NEW_USER_SUCCESS,
  payload: user,
});

export const addNewUserFail = error => ({
  type: ADD_NEW_USER_FAIL,
  payload: error,
});


//Update
export const updateUser = step => ({
  type: UPDATE_USER,
  payload: step,
});

export const updateUserSuccess = step => ({
  type: UPDATE_USER_SUCCESS,
  payload: step,
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});


//Details
export const getUserDetails = step => ({
  type: GET_USER_DETAILS,
  payload: step,
});

export const getUserDetailsSuccess = step => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: step,
});

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
});


//Status Change
export const statusChangeUser = data => ({
  type: STATUS_CHANGE_USER,
  payload: data,
});

export const statusChangeUserSuccess = data => ({
  type: STATUS_CHANGE_USER_SUCCESS,
  payload: data,
});

export const statusChangeUserFail = error => ({
  type: STATUS_CHANGE_USER_FAIL,
  payload: error,
});

//Status Change
export const emailVerifiedChangeUser = data => ({
  type: EMAIL_VERIFIED_CHANGE_USER,
  payload: data,
});

export const emailVerifiedChangeUserSuccess = data => ({
  type: EMAIL_VERIFIED_CHANGE_USER_SUCCESS,
  payload: data,
});

export const emailVerifiedChangeUserFail = error => ({
  type: EMAIL_VERIFIED_CHANGE_USER_FAIL,
  payload: error,
});

//Status Change
export const phoneVerifiedChangeUser = data => ({
  type: PHONE_VERIFIED_CHANGE_USER,
  payload: data,
});

export const phoneVerifiedChangeUserSuccess = data => ({
  type: PHONE_VERIFIED_CHANGE_USER_SUCCESS,
  payload: data,
});

export const phoneVerifiedChangeUserFail = error => ({
  type: PHONE_VERIFIED_CHANGE_USER_FAIL,
  payload: error,
});


//Change Password
export const passwordChangeUser = data => ({
  type: PASSWORD_CHANGE_USER,
  payload: data,
});

export const passwordChangeUserSuccess = data => ({
  type: PASSWORD_CHANGE_USER_SUCCESS,
  payload: data,
});

export const passwordChangeUserFail = error => ({
  type: PASSWORD_CHANGE_USER_FAIL,
  payload: error,
});



//Delete
export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
});

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyUserFlag = () => {
  return {
    type: MODIFY_USER_FLAG,
  }
}

export const resetUserFlag = () => {
  return {
    type: RESET_USER_FLAG,
  }
}