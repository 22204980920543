import React, { useEffect, useState, useRef, Fragment } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,

  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getPaymentTicketsTLc, resetTransactionFlag ,resetStatusFlag,assignManager,getRoles,getUsers,
  getTlcTicketUserStatus,
  getTlcTicketAdminStatus,} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import MsgToast from "../../../Components/Common/MsgToast";
//import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ToastContainer, toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactPaginate from "react-paginate";
import {
  convertToBase64 
} from "../../../helpers/image_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
const PaymentTicketList = () => {
  const role=JSON.parse(sessionStorage.getItem("authUser"))?JSON.parse(sessionStorage.getItem("authUser")).data.role_details.code:"admin";
  var roleId=JSON.parse(sessionStorage.getItem("authUser"))?JSON.parse(sessionStorage.getItem("authUser")).data._id:"";
  var managerId=null;
  document.title =
    "Payment ticket list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  // const [managerId, setManagerId] = useState(null);
  const [setId, setSetId] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticket_type, setTicketType] = useState("tlc");
  const tableRef = useRef(null);
  const [activeTab, setActiveTab] = useState("2");
  const [file, setDmvStatePayFile] = useState(null);
  const [user_status, setUserStatus] = useState(null);
  const [admin_status, setAdminStatus] = useState(null);
  const [modal, setModal] = useState(false);
 
  const dispatch = useDispatch();

  const {
    roles,
    managerList,
    userStatus,
    adminStatus,
    ticketsTlc,
    totalDataCountTlc,
    success,
    isSuccessTlc,
    error,
    assignManagerSuccess,
    assignManagerFaild,
  } = useSelector((state) => ({
    roles: state.Roles.roles,
    managerList: state.Users.users,
    userStatus :state.Tickets.userStatus,
    adminStatus:state.Tickets.adminStatus,
    ticketsTlc: state.Transactions.ticketsTlc,
    totalDataCountTlc: state.Transactions.totalDataCountTlc,
    success: state.Transactions.success,
    isSuccessTlc: state.Transactions.isSuccessTlc,
    error: state.Transactions.error,
    assignManagerSuccess: state.Transactions.assignManagerSuccess,
    assignManagerFaild: state.Transactions.assignManagerFaild,
  }));
  if(role && role=="managers"){ managerId =roleId}


  useEffect(() => {
    dispatch(
      getPaymentTicketsTLc({
        keyword,
        sortKey:sortByField,
        sortValue:sortByValue,
        limit,
        offset,
        ticket_type,
        user_status,
        admin_status,
        managerId
      })
    );
  }, [dispatch, keyword, sortByField, sortByValue, limit, offset, ticket_type,user_status,admin_status,managerId,role]);
  useEffect(() => {
    dispatch(
      getRoles({
        code:"managers"
       
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getUsers({
        role:roles[0]?._id
       
      })
    );
  }, [dispatch,roles]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getPaymentTicketsTLc({ limit, dateRange, offset, keyword ,ticket_type, user_status, admin_status,managerId}));
    }
  }, [dispatch, date, limit, offset, keyword, ticket_type,user_status, admin_status,managerId]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCountTlc / limit));
  }, [limit, totalDataCountTlc]);
  useEffect(() => {
    dispatch(getTlcTicketAdminStatus({"status_for":"admin","sortKey":"ordering","sortValue":"ASC"}))
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTlcTicketUserStatus({"status_for":"user","sortKey":"ordering","sortValue":"ASC"}))
  }, [dispatch]);
  useEffect(() => {
    if (assignManagerSuccess || assignManagerFaild) {
      var inputs = document.getElementsByName('chk_child');
      for(var i = 0; i<inputs.length; i++){
          inputs[i].checked = false;

        }
      setModal(false)
      setIsCreating(false)
      dispatch(getPaymentTicketsTLc({ limit, offset, keyword ,ticket_type, user_status, admin_status}));
      setTimeout(() => {
        dispatch(resetStatusFlag());
        
      }, 3000);

    }
  }, [dispatch,keyword, sortByField, sortByValue, limit, offset, ticket_type,user_status,admin_status, assignManagerSuccess, assignManagerFaild]);
 
  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCountTlc;
   
    setOffset(newOffset);
  };
  const handleChange = (e, index,ids) => {
    var value  = e.target.checked;
    if(value){
      setSetId([...setId,{"ticket_id":ids}])
    }else{
      const updated= setId.filter((list ) => list.ticket_id !== ids);
   
      setSetId(updated)
      
    }
  };
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const resetFilter = () => {
    dispatch(resetTransactionFlag());
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    setUserStatus('');
    setAdminStatus('');

    dispatch(
      getPaymentTicketsTLc({ keyword, sortKey:sortByField, sortValue:sortByValue, limit, offset,ticket_type,user_status,admin_status,managerId })
    );
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue ==="ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      manager_id:'',
    
    },
    onSubmit: (values) => {
        setIsCreating(true)
        const data = {
          data: setId,
          manager_id :  values.manager_id?.value,
        }
        
        dispatch(assignManager(data));
    }
});

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Tickets" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Tlc Ticket List</h5>
                  
                       <div className=" flex-shrink-0 ms-auto">
                          
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                          { role && role!="managers"&&
                          <button
                      className="btn btn-success ms-sm-2"
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Assign Manager
                    </button>
                  }
                        </div>
                  
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={3} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={user_status}
                          onChange={(e) => setUserStatus(e.target.value || null)}>
                          <option value="">Select User Status</option>
                          {userStatus && userStatus.length && userStatus.map((data,index)=>
                          (
                            <option value={data.code} key={index}>{data.name}</option>
                          ))
                          }
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={admin_status}
                          onChange={(e) => setAdminStatus(e.target.value || null)}>
                          <option value="">Select Admin Status</option>
                          {adminStatus && adminStatus.length && adminStatus.map((data,index)=>
                          (
                            <option value={data.code} key={index}>{data.name}</option>
                          ))
                          }
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select Payment Date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                             
                            }}
                          />
                        </div>
                      </Col>
                     
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccessTlc  ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {ticketsTlc?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                               <th scope="col" style={{ width: "50px" }}>
                                  {/* <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div> */}
                                </th>
                                <th>Plate</th>
                                <th  className="sort cursor-pointer"
                                  data-sort="ticket_id"
                                  onClick={() => sortBy("ticket_id")}>Ticket-ID</th>
                                <th>Ticket Type</th>
                                <th>Fine</th>
                                <th>Due amount</th>
                                <th>Violation</th>
                                <th>User Status</th>
                                <th>Admin Status</th>
                                <th  className="sort cursor-pointer"
                                  data-sort="transaction_id"
                                  onClick={() => sortBy("transaction_id")}>Transaction Id</th>
                                <th>Payment Date</th>
                                <th>Manager</th>
                                <th>User Name</th>
                                <th>Action</th>

                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {ticketsTlc.map((item,i) => (
                                <tr key={item._id}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                     
                                        onChange={(e) => handleChange(e, i,item.ticket_id)}
                                      />
                                    </div>
                                  </th>
                                  <td>{item?.ticket_details?.plate_no}</td>
                                  <td>{item?.ticket_details?._id?<Link
                                          to={`/tickets/details/${item?.ticket_details?._id}`}
                                          className=""
                                        >{item?.ticket_id}
                                        </Link>:''
                                   
}
                                  </td>
                                  <td>{item?.ticket_details?.ticket_type}</td>
                                  <td>
                                    $
                                    {item?.ticket_details?.fine_amount
                                      ? parseInt(item?.ticket_details?.fine_amount).toFixed(2)
                                      : "0.00"}
                                  </td>
                                  <td>
                                    $
                                    {item?.ticket_details?.due_amount
                                      ? parseInt(item?.ticket_details?.due_amount).toFixed(2)
                                      : "0.00"}
                                  </td>
                                  <td>{item?.ticket_details?.violation_description}</td>
                                  <td>
                                  {item?.transaction_assignees_details_user ? (
                                      <div className="badge badge-soft-success text-uppercase">
                                        {item?.transaction_assignees_details_user?.status}
                                      </div>
                                    ) :""}
                                  </td>
                                  <td>
                                  {item?.transaction_assignees_details_admin ? (
                                      <div className="badge badge-soft-success text-uppercase">
                                        {item?.transaction_assignees_details_admin?.status}
                                      </div>
                                    ) :""}
                                  </td>
                                 
                                  <td>
                                  <Link
                                          to={`/transaction/${item?.transaction_id}`}
                                          className=""
                                        >{item?.payment_transaction?item?.payment_transaction:"N.A."}
                                        </Link>
                                  </td>
                                  <td>
                                   <Moment format="D MMM YYYY">
                                      {item?.created_at}
                                    </Moment>
                                  </td>
                                  <td>{item?.manager_details?.name?<>{item?.manager_details?.name}</>:"N.A."}</td>
                                  <td>{item?.transaction_details?.user_info?<>{item?.transaction_details?.user_info?.name},{item?.transaction_details?.user_info?.email},{item?.transaction_details?.user_info?.phone}</>:"N.A."}</td>
                                  <td>
                                            <UncontrolledDropdown>
                                              <DropdownToggle className="dropdown-menu-icon btn-transparent text-white">
                                                <i className="ri-more-2-fill"></i>
                                              </DropdownToggle>
                                              <DropdownMenu container="body">
                                               
                                               { role && role!="managers"&&
                                                
                                                <DropdownItem>
                                                  <Button
                                                    onClick={ (e) => {
                                                      setModal(true)
                                                      setSetId([{"ticket_id":item.ticket_id}])

                                                    }
                                                    }
                                                    className="p-0 bg-transparent border-0 text-success"
                                                  >
                                                    <i className="ri-add-fill fs-16"></i>{" "}
                                                    Assign Manager
                                                  </Button>
                                                </DropdownItem>
                                              }
                                                <DropdownItem>
                                          
                                              <Link
                                                to={`/tickets/assign-ticket/${item?.ticket_details?._id}`}
                                                className="text-primary d-inline-block edit-item-btn"
                                              >
                                                <i className="ri-external-link-line fs-16"></i>{" "}
                                                Change Status
                                              </Link>
                                        </DropdownItem>
                                               
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </td>
                                  
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {
                            noOfPage > 1 &&
                            <div className='pagination-inner'>
                                <nav className="px-3" aria-label="Page navigation example">
                                  <ReactPaginate
                                    activeClassName = "active"
                                    breakClassName = "page-item"
                                    breakLinkClassName = "page-link"
                                    nextClassName = "page-item"
                                    nextLinkClassName = "page-link"
                                    previousClassName = "page-item"
                                    previousLinkClassName = "page-link"
                                    containerClassName = "pagination"
                                    pageClassName = "page-item"
                                    pageLinkClassName = "page-link"
                                    breakLabel="..."
                                    nextLabel="Next &nbsp;→"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={noOfPage}
                                    previousLabel="←&nbsp;Prev"
                                    renderOnZeroPageCount={null}
                                  />
                                </nav>
                            </div>
                          }
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {assignManagerSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {assignManagerFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
        
      </div>
      <ToastContainer limit={1} closeButton={false} />
        <Modal
            className="zoomIn"
            id="addDocument"
            size="md"
            isOpen={modal}
            toggle={toggle}
            centered
            >
            <ModalHeader toggle={toggle}>Assign Manager</ModalHeader>
            <div className="modal-content border-0 mt-3">
             
            </div>
            <ModalBody>
              <TabContent activeTab={activeTab}>
                
                <TabPane tabId="2">
                  <Form onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                      }}>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label for="confirmationnumberInput" className="form-label">
                          Manager
                          </Label>
                          <Select
                            type="text"
                            name="manager_id"
                            onChange={(selectedOption) => {
                              let event = {
                                target: { name: "manager_id", value: selectedOption },
                              };
                              validation.handleChange(event);
                            }}
                            onBlur={validation.handleBlur}
                            options={
                              managerList &&
                              managerList.map((item, index) => ({
                                label: item.name,
                                value: item._id,
                              }))
                            }
                            value={validation.values.manager_id || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                     
                          <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                              <span className="d-flex align-items-center">
                                  {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                  <span className="flex-grow-1">
                                    Submit
                                  </span>
                              </span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              
              </TabContent>
            </ModalBody>
        </Modal>
       
    </>
  );
};

export default PaymentTicketList;
