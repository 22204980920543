/* SOLUTION */
export const GET_SOLUTIONS = "GET_SOLUTIONS";
export const GET_SOLUTIONS_SUCCESS = "GET_SOLUTIONS_SUCCESS";
export const GET_SOLUTIONS_FAIL = "GET_SOLUTIONS_FAIL";

export const ADD_NEW_SOLUTION = "ADD_NEW_SOLUTION";
export const ADD_NEW_SOLUTION_SUCCESS = "ADD_NEW_SOLUTION_SUCCESS";
export const ADD_NEW_SOLUTION_FAIL = "ADD_NEW_SOLUTION_FAIL";

export const GET_SOLUTION_DETAILS = "GET_SOLUTION_DETAILS";
export const GET_SOLUTION_DETAILS_SUCCESS = "GET_SOLUTION_DETAILS_SUCCESS";
export const GET_SOLUTION_DETAILS_FAIL = "GET_SOLUTION_DETAILS_FAIL";

export const UPDATE_SOLUTION = "UPDATE_SOLUTION";
export const UPDATE_SOLUTION_SUCCESS = "UPDATE_SOLUTION_SUCCESS";
export const UPDATE_SOLUTION_FAIL = "UPDATE_SOLUTION_FAIL";

export const DELETE_SOLUTION = "DELETE_SOLUTION";
export const DELETE_SOLUTION_SUCCESS = "DELETE_SOLUTION_SUCCESS";
export const DELETE_SOLUTION_FAIL = "DELETE_SOLUTION_FAIL";

export const STATUS_CHANGE_SOLUTION = "STATUS_CHANGE_SOLUTION";
export const STATUS_CHANGE_SOLUTION_SUCCESS = "STATUS_CHANGE_SOLUTION_SUCCESS";
export const STATUS_CHANGE_SOLUTION_FAIL = "STATUS_CHANGE_SOLUTION_FAIL";

export const SAVE_ORDERING_SOLUTION = "SAVE_ORDERING_SOLUTION";
export const SAVE_ORDERING_SOLUTION_SUCCESS = "SAVE_ORDERING_SOLUTION_SUCCESS";
export const SAVE_ORDERING_SOLUTION_FAIL = "SAVE_ORDERING_SOLUTION_FAIL";
export const SAVE_ORDERING_SOLUTION_RESET_FLAG = "SAVE_ORDERING_SOLUTION_RESET_FLAG";

export const RESET_SOLUTION_FLAG = "RESET_SOLUTION_FLAG";
export const MODIFY_SOLUTION_FLAG = "MODIFY_SOLUTION_FLAG";


