import {
  GET_JOBS_SUCCESS,
  GET_JOBS_FAIL,
  GET_JOB_APPLIES_SUCCESS,
  GET_JOB_APPLIES_FAIL,
  ADD_NEW_JOB_SUCCESS,
  ADD_NEW_JOB_FAIL,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAIL,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAIL,
  REMOVE_JOB_SUCCESS,
  REMOVE_JOB_FAIL,
  STATUS_CHANGE_JOB_SUCCESS,
  STATUS_CHANGE_JOB_FAIL,
  RESET_JOB_FLAG,
  MODIFY_JOB_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  jobs: [],
  jobapplies:[]
};

const Jobs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_JOBS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };
      case GET_JOB_APPLIES_SUCCESS:
        return {
          ...state,
          jobapplies: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true,
        };
  
      case GET_JOB_APPLIES_FAIL:
        return {
          ...state,
          error: action.payload.message,
          totalDataCount: 0,
          isSuccess: false,
        };
  
    case ADD_NEW_JOB_SUCCESS:
      return {
        ...state,
        jobs: [...state.jobs, action.payload.data],
        success: action.payload.message,
        isJobAdd: true,
        isJobAddFail: false,
      };

    case ADD_NEW_JOB_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobAdd: false,
        isJobAddFail: true,
      };

    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job._id.toString() === action.payload.data._id.toString()
            ? { ...job, ...action.payload.data }
            : job
        ),
        success: action.payload.message,
        isJobUpdate: true,
        isJobUpdateFail: false,
      };

    case UPDATE_JOB_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobUpdate: false,
        isJobUpdateFail: true,
      };

    case GET_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data ? action.payload.data : [],
        isSuccess: true,
      };

    case GET_JOB_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };

    case REMOVE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.filter((jobs) => jobs._id !== action.payload.data._id),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
        isJobRemove: true,
        isJobRemoveFail: true,
      };

    case REMOVE_JOB_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
        isJobRemove: false,
        isJobRemoveFail: true,
      };
    //Status Change
    case STATUS_CHANGE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job._id.toString() === action.payload.data._id.toString()
            ? { ...job, ...action.payload.data }
            : job
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_JOB_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };
    case RESET_JOB_FLAG:
      return {
        ...state,
        isJobAdd: false,
        isJobAddFail: false,
        isSuccess: false,
        isJobDetails: false,
        isJobUpdate: false,
        isJobUpdateFail: false,
        error: false,
      };

    case MODIFY_JOB_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    default:
      return state;
  }
};

export default Jobs;
