/* SITESETTING */


export const UPDATE_SITESETTING = "UPDATE_SITESETTING";
export const UPDATE_SITESETTING_SUCCESS = "UPDATE_SITESETTING_SUCCESS";
export const UPDATE_SITESETTING_FAIL = "UPDATE_SITESETTING_FAIL";

export const GET_SITESETTING_DETAILS = "GET_SITESETTING_DETAILS";
export const GET_SITESETTING_DETAILS_SUCCESS = "GET_SITESETTING_DETAILS_SUCCESS";
export const GET_SITESETTING_DETAILS_FAIL = "GET_SITESETTING_DETAILS_FAIL";

export const RESET_SITESETTING_FLAG = "RESET_SITESETTING_FLAG";


