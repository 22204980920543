import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

import logoLight from "../../assets/images/logo_alt.png";
//Import config
import { facebook, google } from "../../config";
//import images
import MsgToast from "../../Components/Common/MsgToast";
const Login = (props) => {
    const dispatch = useDispatch();
    const [isPassword, setisPassword] = useState('password');
    const { user,success,isSuccess,isLoginfailed} = useSelector(state => ({
        user: state.Account.user,
        success: state.Login.success,
        isSuccess: state.Login.isSuccess,
        isLoginfailed: state.Login.isLoginfailed,
    }));

    const [userLogin, setUserLogin] = useState([]);

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.user.email,
                password: user.user.confirm_password
            });
        }
    }, [user]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.history));
        }
    });

    const { error } = useSelector(state => ({
        error: state.Login.error,
    }));


    const signIn = (res, type) => {
        if (type === "google" && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.history, type));
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.history, type));
        }
    };

    //handleGoogleLoginResponse
    const googleResponse = response => {
        signIn(response, "google");
    };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //handleFacebookLoginResponse
    const facebookResponse = response => {
        signIn(response, "facebook");
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetLoginFlag());
        }, 3000);
    }, [dispatch, error,success]);


    const passwordSee = (event) => {
        //issetAutoPay((current) => !current);
        if(isPassword == 'password'){
            setisPassword('text');
        }else{
            setisPassword('password'); 
        }
    };

    document.title = "Basic SignIn | Defendit - Admin Panel";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="38" />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to Defendit.</p>
                                        </div>
                
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#"
                                             
                                                >

                                                <div className="mb-3">
                                                    <Label htmlFor="email"  
                                                    className="form-label">Email</Label>
                                                    <Input
                                                       autoComplete="new-email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                       
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }

                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                           autoComplete="new-password" 
                                                         
                                                            
                                                            name="password"
                                                        
                                                            value={validation.values.password || ""}
                                                            type={isPassword}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button onClick={passwordSee} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                {/* <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div> */}

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">Sign In</Button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <FacebookLogin
                                                            appId={facebook.APP_ID}
                                                            autoLoad={false}
                                                            callback={facebookResponse}
                                                            render={renderProps => (
                                                                <Button color="primary"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-facebook-fill fs-16" />
                                                                </Button>
                                                            )}
                                                        />
                                                        <GoogleLogin
                                                            clientId={
                                                                google.CLIENT_ID ? google.CLIENT_ID : ""
                                                            }
                                                            render={renderProps => (
                                                                <Button color="danger"
                                                                    to="#"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-google-fill fs-16" />
                                                                </Button>
                                                            )}
                                                            onSuccess={googleResponse}
                                                            onFailure={() => {

                                                            }}
                                                        />
                                                        <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                                                        <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                    </div>
                                                </div> */}

                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}

                            </Col>
                        </Row>
                        {isSuccess ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isLoginfailed ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);