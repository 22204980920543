//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/signin";
//export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_JWT_LOGIN = "/signin";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
//export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/forget-password";
export const SOCIAL_LOGIN = "/social-login";

//OTP Verification
export const FORGET_PASSWORD_OTP = "/forget-password-verify";

//Forget Password change
export const FORGET_PASSWORD_CHANGE = "/forget-update-password";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const GET_UPCOMMINGEVENT = "/upcommingevents";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_DIRECT_CONTACT = "/chat";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";
export const GET_CHANNELS = "/channels";

//Mailbox
export const GET_MAIL_DETAILS = "/mail";
export const DELETE_MAIL = "/delete/mail";

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product";
export const DELETE_PRODUCT = "/apps/product";

// Orders
export const GET_ORDERS = "/apps/order";
export const ADD_NEW_ORDER = "/apps/order";
export const UPDATE_ORDER = "/apps/order";
export const DELETE_ORDER = "/apps/order";

// Customers
export const GET_CUSTOMERS = "/apps/customer";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

// Sellers
export const GET_SELLERS = "/sellers";

// Project list
export const GET_PROJECT_LIST = "/project/list";

// Task
export const GET_TASK_LIST = "/apps/task";
export const ADD_NEW_TASK = "/apps/task";
export const UPDATE_TASK = "/apps/task";
export const DELETE_TASK = "/apps/task";

// CRM
// Conatct
export const GET_CONTACTS = "/apps/contact";
export const ADD_NEW_CONTACT = "/apps/contact";
export const UPDATE_CONTACT = "/apps/contact";
export const DELETE_CONTACT = "/apps/contact";

// Companies
export const GET_COMPANIES = "/apps/company";
export const ADD_NEW_COMPANIES = "/apps/company";
export const UPDATE_COMPANIES = "/apps/company";
export const DELETE_COMPANIES = "/apps/company";

// Lead
export const GET_LEADS = "/apps/lead";
export const ADD_NEW_LEAD = "/apps/lead";
export const UPDATE_LEAD = "/apps/lead";
export const DELETE_LEAD = "/apps/lead";

// Deals
export const GET_DEALS = "/deals";

// Crypto
export const GET_TRANSACTION_LIST = "/transaction-list";
export const GET_ORDRER_LIST = "/order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const ADD_NEW_INVOICE = "/apps/invoice";
export const UPDATE_INVOICE = "/apps/invoice";
export const DELETE_INVOICE = "/apps/invoice";

// TicketsList
export const GET_TICKETS_LIST = "ticket/list";
export const GET_TICKETS_DETAILS = "/ticket/details";
export const GET_TICKET_SIGNATURE_DETAILS = "/signature/details";
export const GET_TICKET_ASSIGN_DETAILS = "/ticket_assignee/details";
export const GET_TICKET_ASSIGN_STATUS_DOCUMENTS_LIST =
  "/ticket_assignee/status-documents";
export const GET_TICKET_STATUS_LIST = "/ticket-status/list";
export const UPDATE_ASSIGN_TICKET = "/ticket_assignee/create";

export const ADD_NEW_TICKET = "/ticket/new";
export const UPDATE_TICKET = "/ticket/edit";
export const DELETE_TICKET = "/apps/ticket";

// Roles
export const GET_ROLE_LIST = "/role/list";
export const GET_ROLE_DETAILS = "/role/details";
export const ADD_NEW_ROLE = "/role/create";
export const UPDATE_ROLE = "/role/edit";

// Vehicles
export const GET_VEHICLE_LIST = "/vehicle/list";
export const GET_VEHICLE_DETAILS = "/vehicle/details";
export const ADD_NEW_VEHICLE = "/vehicle/create";
export const UPDATE_VEHICLE = "/vehicle/edit";

//State
export const GET_STATE_LIST = "/state/list";

// Purchase type
export const GET_PURCHASE_TYPE_LIST = "/purchasetype/list";

// Driver
export const GET_DRIVERS = "/driver/list";

// Whyus
export const GET_WHYUS_LIST = "/whyus/list";
export const GET_WHYUS_DETAILS = "/whyus/details";
export const ADD_NEW_WHYUS = "/whyus/create";
export const UPDATE_WHYUS = "/whyus/edit";
export const DELETE_WHYUS = "/whyus/remove";
export const STATUS_CHANGE_WHYUS = "/whyus/status-change";

// Solution
export const GET_SOLUTION_LIST = "/solutions/list";
export const GET_SOLUTION_DETAILS = "/solutions/details";
export const ADD_NEW_SOLUTION = "/solutions/create";
export const UPDATE_SOLUTION = "/solutions/edit";
export const DELETE_SOLUTION = "/solutions/remove";
export const STATUS_CHANGE_SOLUTION = "/solutions/status-change";

// Banner
export const GET_BANNER_LIST = "/banner/list";
export const GET_BANNER_DETAILS = "/banner/details";
export const ADD_NEW_BANNER = "/banner/create";
export const UPDATE_BANNER = "/banner/edit";
// Testimonial
export const GET_TESTIMONIAL_LIST = "/testimonial/list";
export const GET_TESTIMONIAL_DETAILS = "/testimonial/details";
export const ADD_NEW_TESTIMONIAL = "/testimonial/create";
export const UPDATE_TESTIMONIAL = "/testimonial/edit";

// Steps
export const GET_STEP_LIST = "/how_it_work/list";
export const GET_STEP_DETAILS = "/how_it_work/details";
export const ADD_NEW_STEP = "/how_it_work/create";
export const UPDATE_STEP = "/how_it_work/edit";

// Client
export const GET_CLIENT_LIST = "/client/list";
export const GET_CLIENT_DETAILS = "/client/details";
export const ADD_NEW_CLIENT = "/client/create";
export const UPDATE_CLIENT = "/client/edit";
export const DELETE_CLIENT = "/client/remove";
export const STATUS_CHANGE_CLIENT = "/client/status-change";

// Pages
export const GET_PAGE_LIST = "/page/list";
export const GET_PAGE_DETAILS = "/page/details";
export const ADD_NEW_PAGE = "/page/add";
export const UPDATE_PAGE = "/page/edit";

// Sitesetting
export const GET_SITESETTING_DETAILS = "/site-setting/list";
export const UPDATE_SITESETTING = "/site-setting/edit";

// Driver
export const GET_DRIVER_LIST = "/driver/list";
export const GET_DRIVER_DETAILS = "/driver/details";
export const ADD_NEW_DRIVER = "/driver/create";
export const UPDATE_DRIVER = "/driver/edit";
export const DELETE_DRIVER = "/driver/remove";

//City
export const GET_CITY_LIST = "/city/list";
//Country
export const GET_COUNTRY_LIST = "/country/list";

// Mission Vision
export const GET_MISSION_VISION_LIST = "/how_it_work/list";
export const GET_MISSION_VISION_DETAILS = "/how_it_work/details";
export const ADD_NEW_MISSION_VISION = "/how_it_work/create";
export const UPDATE_MISSION_VISION = "/how_it_work/edit";
export const DELETE_MISSIONVISION = "how_it_work/remove";
export const STATUS_CHANGE_MISSIONVISION = "how_it_work/status-change";

export const DELETE_STEP = "/how_it_work/remove";
export const STATUS_CHANGE_STEP = "/how_it_work/status-change";

// SEO
export const GET_SEO_LIST = "/seo/list";
export const GET_SEO_DETAILS = "/seo/details";
export const ADD_NEW_SEO = "/seo/add";
export const UPDATE_SEO = "/seo/edit";

// Jobs
export const GET_JOB_LIST = "/job/list";
export const GET_JOB_APPLY_LIST = "/job/apply-list";
export const GET_JOB_DETAILS = "/job/details";
export const ADD_NEW_JOB = "/job/create";
export const UPDATE_JOB = "/job/edit";
export const REMOVE_JOB = "/job/remove";
export const STATUS_CHANGE_JOB = "/job/status-change";

// Payment-ticket
export const GET_PAYMENT_TICKET_LIST = "/transaction/payment-tickets";
export const STATUS_CHANGE_TRANSACTION_STATEPAY =
  "/transaction/change-statepay-status";
export const UPLOAD_STATEPAY_DOCUMENT = "/transaction/upload-statepay-document";
export const ASSIGN_MANAGER = "/ticket/assign-manager";
export const ASSIGN_USER = "/transaction/user-assign-tlc-by-admin";

// Transaction
export const GET_BILLING_LIST = "/transaction/list";
export const GET_BILLING_DETAILS = "/transaction/details";

// Billing
export const GET_BILLING_INVOICE_LIST = "/transaction/list";
export const ADD_NEW_BILLING_INVOICE = "/transaction/create-invoice";
export const INVOICE_STATUS_CHANGE = "/transaction/change-invoice-status";
export const RESEND_INVOICE = "/transaction/create-invoice";

//State
export const GET_STATE_DETAILS = "/state/details";
export const UPDATE_STATE = "/state/edit";

// User
export const GET_USER_LIST = "/user/list";
export const GET_USER_DETAILS = "/user/get-details";
export const ADD_NEW_USER = "/user/create";
export const UPDATE_USER = "/user/edit";
export const STATUS_CHANGE_USER = "/user/status-change";
export const VERIFIED_EMAIL_PHONE = "/user/verified-email-phone";
export const PASSWORD_CHANGE_USER = "/user/change-password";
export const DELETE_USER = "/user/remove";

// Referral
export const GET_REFERRAL_LIST = "/transaction/user-referral/list";
export const GET_REFERRAL_TRANSACTION = "/transaction/total-referral";

export const MODIFY_REFERRAL_DOCUMENT = "/transaction/referral-document-modify";
export const GET_REFERRAL_TRANSACTION_DETAILS =
  "/transaction/user-referral/details";

// Referral
export const GET_REFERRAL_CODE_LIST = "/user/list";
export const REFERRAL_CODE_STATUS_CHANGE = "/user/referral-status-change";

// Dashboard
export const GET_DASHBOARD = "/admin/dashboard";

// Coupons
export const GET_COUPONS = "/coupon/list";
export const ADD_NEW_COUPON = "/coupon/create";
export const GET_COUPON_DETAILS = "/coupon/details";
export const UPDATE_COUPON = "/coupon/edit";
export const DELETE_COUPON = "/coupon/remove";
export const STATUS_CHANGE_COUPON = "/coupon/status-change";

// Notification
export const GET_NOTIFICATION_LIST = "/notification/admin-list";
export const GET_NOTIFICATION_HISTORY_LIST = "/notification-history/list";

//Save Ordering
export const SAVE_ORDERING = "/common/save-ordering";
