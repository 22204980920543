import React, { useState,useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Card,CardBody,CardHeader,Col,Container,Button,Spinner,Row,Input,Label,FormFeedback,Form} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { 
  addNewVehicle, 
  resetVehicleFlag,
  getStateList,
  getPurchaseTypeList,
  getDriver
} from "../../../store/actions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";


import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const VehicleCreate = () => {
  document.title ="Create Vehicle | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [state,setState] = useState([]);
  const [allType,setAllType] = useState([]);
  const [allDriver,setAllDriver] = useState([]);
  const { isVehicleAdd, isVehicleAddFail, error, success,vehicleState,vehiclePurchaseType,drivers } = useSelector(state => ({
    isVehicleAdd: state.Vehicles.isVehicleAdd,
    isVehicleAddFail: state.Vehicles.isVehicleAddFail,
    error: state.Vehicles.error,
    success: state.Vehicles.success,
    vehicleState:state.Vehicles.vehicleState,
    vehiclePurchaseType:state.Vehicles.vehiclePurchaseType,
    drivers:state.Vehicles.drivers
  }));  

  const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
          vehicle_type:'personal',
          plate_no: '',
          plate_type: '',
          vehicle_state: '',
          vehicle_year:'',
          vehicle_make:'',
          vehicle_color:'',
          vehicle_vin:'',          
          vendor_name:'',
          vendor_insurance_expiratione:'',
          vendor_agent_name:'',
          vendor_insurance_note:'',
          registration_number:'',
          registration_expiration:'',
          inspection_expiration_date:'',
          registration_note:'',
          vehicle_purchase_type:'',
          purchase_date:'',
          purchase_price:'',
          warranty_expires_on:'',
          purchase_note:'',          
          driver_id:'',
      },
      validationSchema: Yup.object({
          vehicle_type: Yup.string().required("Please Choose Vehicle Type"),
          plate_no: Yup.string().required("Please Enter Plate No"),
          plate_type: Yup.string().required("Please Enter Plate Type"),
          vehicle_state : Yup.object().required("Please Choose Vehicle State"),
          vehicle_year: Yup.string().required("Please Enter Vehicle Year"),
          vehicle_make: Yup.string().required("Please Enter Vehicle Make"),
          vehicle_color : Yup.string().required("Please Enter Vehicle Color"),
          vehicle_vin : Yup.string().required("Please Enter Vehicle VIN"),          
          vendor_name: Yup.string().required("Please Enter Vendor Name"),
          //vendor_insurance_expiratione: Yup.object().required("Please Choose Insurance Expiratione"),
          vendor_agent_name: Yup.string().required("Please Enter Agent Name"),
          vendor_insurance_note : Yup.string().required("Please Enter Insurance Note"),
          registration_number : Yup.string().required("Please Enter Registration No"),
          //registration_expiration : Yup.string().required("Please Enter Registration Expiration"),
          //inspection_expiration_date : Yup.object().required("Please Enter Inspection Expiration Date"),
          registration_note: Yup.string().required("Please Enter Registration Note"),
          vehicle_purchase_type : Yup.object().required("Please Enter Vehicle Purchase Type"),
          //purchase_date: Yup.object().required("Please Enter Vehicle Purchase Date"),
          purchase_price : Yup.string().required("Please Enter Vehicle Purchase Price"),
          //warranty_expires_on : Yup.object().required("Please Enter Warranty Expires On"),
          purchase_note : Yup.string().required("Please Enter Purchase Note"),          
          driver_id: Yup.object().required("Please Choose Driver Name"),
      }),
      onSubmit: (values) => {
        console.log(values);
          setIsCreating(true)
          const data = {
            vehicle_type : values.vehicle_type,
            plate_no: values.plate_no,
            plate_type: values.plate_type,
            vehicle_state: (values.vehicle_state)?.value,
            vehicle_year:values.vehicle_year,
            vehicle_make:values.vehicle_make,
            vehicle_color:values.vehicle_color,
            vehicle_vin:values.vehicle_vin,            
            vendor_name:values.vendor_name,
            vendor_insurance_expiratione:values.vendor_insurance_expiratione,
            vendor_agent_name:values.vendor_agent_name,
            vendor_insurance_note:values.vendor_insurance_note,
            registration_number:values.registration_number,
            registration_expiration:values.registration_expiration,
            inspection_expiration_date:values.inspection_expiration_date,
            registration_note:values.registration_note,
            vehicle_purchase_type: (values.vehicle_purchase_type)?.value,
            purchase_date:values.purchase_date,
            purchase_price:values.purchase_price,
            warranty_expires_on:values.warranty_expires_on,
            purchase_note:values.purchase_note,            
            driver_id:(values.driver_id)?.value
          }
          dispatch(addNewVehicle(data));
      }
  });

  useEffect(() => {
    if(isVehicleAdd || isVehicleAddFail){
        setIsCreating(false)
        validation.resetForm();
        setTimeout(() => {
            dispatch(resetVehicleFlag())
        }, 3000);
    }
  }, [dispatch, isVehicleAdd, isVehicleAddFail, validation]);

  useEffect(() => {
    dispatch(getStateList())
    dispatch(getPurchaseTypeList())
    dispatch(getDriver())
    

  }, []);

  useEffect(() => {
    let states = [];
    if(vehicleState){
      for (let i = 0; i < vehicleState?.length; i++) {
        states[i] = { label: vehicleState[i].name, value: vehicleState[i]._id }
      }
    }
    setState(states)

    let types = [];
    if(vehiclePurchaseType){
      for (let i = 0; i < vehiclePurchaseType?.length; i++) {
        types[i] = { label: vehiclePurchaseType[i].name, value: vehiclePurchaseType[i]._id }
      }
    }
    setAllType(types)

    let driver = [];
    if(drivers){
      for (let i = 0; i < drivers?.length; i++) {
        driver[i] = { label: drivers[i].first_name+' '+drivers[i].last_name, value: drivers[i]._id }
      }
    }
    setAllDriver(driver)
  }, [vehicleState?.length,vehiclePurchaseType?.length,drivers?.lenght]);  

  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title="Create Vehicle" pageTitle="Vehicle" pageUrl="/vehicles" />
        <Row>
          <Col lg={8}>
          <Form onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
          }}>
              <Card>
                <CardBody>
                <p className="text-muted">Vehicle Type</p>
                <div className="mb-3">
                      <div className="form-check form-check-inline">
                          <Input 
                          className="form-check-input" 
                          type="radio" 
                          name="vehicle_type" 
                          id="inlineRadio1" 
                          value="personal"
                          onChange={validation.handleChange}
                          defaultChecked
                          />
                          <Label className="form-check-label" htmlFor="inlineRadio1">Personal</Label>
                      </div>
                      <div className="form-check form-check-inline">
                          <Input 
                          className="form-check-input" 
                          type="radio" 
                          name="vehicle_type" 
                          id="inlineRadio2" 
                          value="commercial" 
                          onChange={validation.handleChange}
                          defaultChecked={validation.values.vehicle_type === "commercial"}
                          />
                          <Label className="form-check-label" htmlFor="inlineRadio2">Commercial</Label>
                      </div>
                      {validation.touched.vehicle_type && validation.errors.vehicle_type ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_type}</FormFeedback>
                    ) : null}
                  </div>
                  <p className="text-muted">Vehicle Information</p>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Plate No
                    </Label>
                    <Input
                      type="text"
                      name="plate_no"
                      className="form-control"
                      placeholder="Enter Plate no."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.plate_no || ""}
                      invalid={
                          validation.touched.plate_no && validation.errors.plate_no ? true : false
                      }
                    />
                    {validation.touched.plate_no && validation.errors.plate_no ? (
                        <FormFeedback type="invalid">{validation.errors.plate_no}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Plate Type
                    </Label>
                    <Input
                      type="text"
                      name="plate_type"
                      className="form-control"
                      placeholder="Enter Plate no."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.plate_type || ""}
                      invalid={
                          validation.touched.plate_type && validation.errors.plate_type ? true : false
                      }
                    />
                    {validation.touched.plate_type && validation.errors.plate_type ? (
                        <FormFeedback type="invalid">{validation.errors.plate_type}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Vehicle State
                    </Label>
                    <Select
                      type="text"
                      name="vehicle_state"
                      className="form-control"
                      placeholder="Enter Vehicle State"
                      //onChange={validation.handleChange}
                      onChange={selectedOption => {
                        let event = { target : { name:'vehicle_state',value: selectedOption}}
                        validation.handleChange(event)
                      }}
                      options={state}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicle_state || ""}
                      invalid={
                        validation.touched.vehicle_state && validation.errors.vehicle_state ? true : false
                      }
                    />
                    {validation.touched.vehicle_state && validation.errors.vehicle_state ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_state}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Vehicle Year
                    </Label>
                    <Input
                      type="text"
                      name="vehicle_year"
                      className="form-control"
                      placeholder="Enter Vehicle Year"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicle_year || ""}
                      invalid={
                          validation.touched.vehicle_year && validation.errors.vehicle_year ? true : false
                      }
                    />
                    {validation.touched.vehicle_year && validation.errors.vehicle_year ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_year}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Vehicle Make
                    </Label>
                    <Input
                      type="text"
                      name="vehicle_make"
                      className="form-control"
                      placeholder="Enter Vehicle Make"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicle_make || ""}
                      invalid={
                          validation.touched.vehicle_make && validation.errors.vehicle_make ? true : false
                      }
                    />
                    {validation.touched.vehicle_make && validation.errors.vehicle_make ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_make}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Vehicle Color
                    </Label>
                    <Input
                      type="text"
                      name="vehicle_color"
                      className="form-control"
                      placeholder="Enter Vehicle Color"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicle_color || ""}
                      invalid={
                          validation.touched.vehicle_color && validation.errors.vehicle_color ? true : false
                      }
                    />
                    {validation.touched.vehicle_color && validation.errors.vehicle_color ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_color}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                    Vehicle VIN
                    </Label>
                    <Input
                      type="text"
                      name="vehicle_vin"
                      className="form-control"
                      placeholder="Enter Vehicle VIN"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicle_vin || ""}
                      invalid={
                          validation.touched.vehicle_vin && validation.errors.vehicle_vin ? true : false
                      }
                    />
                    {validation.touched.vehicle_vin && validation.errors.vehicle_vin ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_vin}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>  

              <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Vehicle Insurance</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Vendor
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Vendor"
                      type="text"
                      name="vendor_name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vendor_name || ""}
                      invalid={
                          validation.touched.vendor_name && validation.errors.vendor_name ? true : false
                      }
                    />
                    {validation.touched.vendor_name && validation.errors.vendor_name ? (
                        <FormFeedback type="invalid">{validation.errors.vendor_name}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Insurance Expiratione
                  </Label>
                    <Flatpickr
                      className="form-control"
                      type="text"
                      placeholder="DD-MM-YYYY"
                      name="vendor_insurance_expiratione"
                      onChange={(selectedDates, dateStr, instance) => {
                        let event = { target : { name:'vendor_insurance_expiratione',value: dateStr}}
                        validation.handleChange(event)
                      }}
                      options={{
                        dateFormat: "d-m-Y"
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.vendor_insurance_expiratione || ""}
                      invalid={
                          validation.touched.vendor_insurance_expiratione && validation.errors.vendor_insurance_expiratione ? 'true' : 'false'
                      }
                    />
                    {validation.touched.vendor_insurance_expiratione && validation.errors.vendor_insurance_expiratione ? (
                        <FormFeedback type="invalid">{validation.errors.vendor_insurance_expiratione}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                   Agent
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Registration No"
                      type="text"
                      name="vendor_agent_name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vendor_agent_name || ""}
                      invalid={
                          validation.touched.vendor_agent_name && validation.errors.vendor_agent_name ? true : false
                      }
                    />
                    {validation.touched.registration_number && validation.errors.vendor_agent_name ? (
                        <FormFeedback type="invalid">{validation.errors.vendor_agent_name}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Insurance Note
                  </Label>
                  <textarea
                      className="form-control"
                      placeholder="Enter Registration Note"
                      rows="5"
                      name="vendor_insurance_note"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vendor_insurance_note || ""}
                      invalid={
                          validation.touched.vendor_insurance_note && validation.errors.vendor_insurance_note ? 'true' : 'false'
                      }
                    ></textarea>
                    {validation.touched.vendor_insurance_note && validation.errors.vendor_insurance_note ? (
                            <FormFeedback type="invalid">{validation.errors.vendor_insurance_note}</FormFeedback>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>            

              <div className="text-end mb-3">
                <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                    <span className="d-flex align-items-center">
                        {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                        <span className="flex-grow-1">
                           Submit
                        </span>
                    </span>
                </Button>
                {/* <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button> */}
              </div>
              </Form>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Vehicle Registration</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Registration Number
                  </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Registration No"
                      type="text"
                      name="registration_number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.registration_number || ""}
                      invalid={
                          validation.touched.registration_number && validation.errors.registration_number ? true : false
                      }
                    />
                    {validation.touched.registration_number && validation.errors.registration_number ? (
                        <FormFeedback type="invalid">{validation.errors.registration_number}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Registration Expiration
                  </Label>
                    <Flatpickr
                      className="form-control"
                      placeholder="DD-MM-YYYY"
                      name="registration_expiration"
                      onChange={(selectedDates, dateStr, instance) => {
                        let event = { target : { name:'registration_expiration',value: dateStr}}
                        validation.handleChange(event)
                      }}
                      options={{
                        dateFormat: "d-m-Y"
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.registration_expiration || ""}
                      invalid={ 
                        validation.touched.registration_expiration && validation.errors.registration_expiration ? 'true' : 'false'
                      }
                    />
                    {validation.touched.registration_expiration && validation.errors.registration_expiration ? (
                        <FormFeedback type="invalid">{validation.errors.registration_expiration}</FormFeedback>
                    ) : null}
                  </div>
                </div>                

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Inspection Expiration Date
                  </Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d-m-Y"
                      }}
                      placeholder="DD-MM-YYYY"
                      type="text"
                      name="inspection_expiration_date"
                      onChange={(selectedDates, dateStr, instance) => {
                        const firstDate = selectedDates[0];
                        let event = { target : { name:'inspection_expiration_date',value: dateStr}}
                        validation.handleChange(event)
                        //validation.handleChange(firstDate);
                        //console.log({ firstDate, dateStr });
                      }}
                     // onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.inspection_expiration_date || ""}
                      invalid={
                          validation.touched.inspection_expiration_date && validation.errors.inspection_expiration_date ? 'true' : 'false'
                      }
                    />
                    {validation.touched.inspection_expiration_date && validation.errors.inspection_expiration_date ? (
                        <FormFeedback type="invalid">{validation.errors.inspection_expiration_date}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Registration Note
                  </Label>
                  <textarea
                      className="form-control"
                      placeholder="Enter Registration Note"
                      rows="5"
                      name="registration_note"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.registration_note || ""}
                      invalid={
                          validation.touched.registration_note && validation.errors.registration_note ? 'true' : 'false'
                      }
                    ></textarea>
                    {validation.touched.registration_note && validation.errors.registration_note ? (
                            <FormFeedback type="invalid">{validation.errors.registration_note}</FormFeedback>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Purchase/Leasing Information</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                    Vehicle Purchase Type
                  </Label>
                  <Select
                      type="text"
                      name="vehicle_purchase_type"
                      className="form-control"
                      placeholder="Enter Vehicle Purchase Type"
                      //onChange={validation.handleChange}
                      onChange={selectedOption => {
                        let event = { target : { name:'vehicle_purchase_type',value: selectedOption}}
                        validation.handleChange(event)
                      }}
                      options= {allType}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicle_purchase_type || ""}
                      invalid={
                        validation.touched.vehicle_purchase_type && validation.errors.vehicle_purchase_type ? 'true' : 'false'
                      }
                    />
                      {/* {vehiclePurchaseType && vehiclePurchaseType.map(item =>
                      <option key={item._id} value={item._id}>{item.name}</option>
                      )}; */}
                    {/* </select> */}
                    {validation.touched.vehicle_purchase_type && validation.errors.vehicle_purchase_type ? (
                        <FormFeedback type="invalid">{validation.errors.vehicle_purchase_type}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Purchase date
                  </Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d-m-Y"
                      }}
                      placeholder="DD-MM-YYYY"
                      type="text"
                      name="purchase_date"
                      onChange={(selectedDates, dateStr, instance) => {
                        const firstDate = selectedDates[0];
                        let event = { target : { name:'purchase_date',value: firstDate}}
                        validation.handleChange(event)
                        //validation.handleChange(firstDate);
                        //console.log({ firstDate, dateStr });
                      }}
                      //onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.purchase_date || ""}
                      invalid={
                          validation.touched.purchase_date && validation.errors.purchase_date ? 'true' : 'false'
                      }
                    />
                    {validation.touched.purchase_date && validation.errors.purchase_date ? (
                        <FormFeedback type="invalid">{validation.errors.purchase_date}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Purchase Price
                  </Label>
                    <Input
                      className="form-control"                      
                      placeholder="Enter Purchase Price"
                      type="number"
                      name="purchase_price"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.purchase_price || ""}
                      invalid={
                          validation.touched.purchase_price && validation.errors.purchase_price ? true : false
                      }
                    />
                    {validation.touched.purchase_price && validation.errors.purchase_price ? (
                        <FormFeedback type="invalid">{validation.errors.purchase_price}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Warranty expires on
                  </Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d-m-Y"
                      }}
                      placeholder="DD-MM-YYYY"
                      type="text"
                      name="warranty_expires_on"
                      onChange={(selectedDates, dateStr, instance) => {
                        const firstDate = selectedDates[0];
                        let event = { target : { name:'warranty_expires_on',value: firstDate}}
                        validation.handleChange(event)
                        //validation.handleChange(firstDate);
                        //console.log({ firstDate, dateStr });
                      }}
                      //onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.warranty_expires_on || ""}
                      invalid={
                          validation.touched.warranty_expires_on && validation.errors.warranty_expires_on ? 'true' : 'false'
                      }
                    />
                    {validation.touched.warranty_expires_on && validation.errors.warranty_expires_on ? (
                        <FormFeedback type="invalid">{validation.errors.warranty_expires_on}</FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="hstack gap-3 align-items-start mb-3">
                  <div className="flex-grow-1">
                  <Label className="form-label" htmlFor="product-title-input">
                  Purchase Note
                  </Label>
                  <textarea
                      className="form-control"
                      placeholder="Enter Registration Note"
                      rows="5"
                      name="purchase_note"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.purchase_note || ""}
                      invalid={
                          validation.touched.purchase_note && validation.errors.purchase_note ? 'true' : 'false'
                      }
                    ></textarea>
                    {validation.touched.purchase_note && validation.errors.purchase_note ? (
                            <FormFeedback type="invalid">{validation.errors.purchase_note}</FormFeedback>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Driver</h5>
              </CardHeader>
              <CardBody>
              <Select
                type="text"
                name="driver_id"
                className="form-control"
                placeholder="Choose Driver"
                //onChange={validation.handleChange}
                onChange={selectedOption => {
                  let event = { target : { name:'driver_id',value: selectedOption}}
                  validation.handleChange(event)
                }}
                options={allDriver}
                onBlur={validation.handleBlur}
                value={validation.values.driver_id || ""}
                invalid={
                  validation.touched.driver_id && validation.errors.driver_id ? 'true' : 'false'
                }
              />
                {/* {drivers && drivers.map(driver =>
                <option key={driver._id} value={driver._id}>{driver.first_name} {driver.last_name}</option>
                )};
              </select> */}
              {validation.touched.driver_id && validation.errors.driver_id ? (
                  <FormFeedback type="invalid">{validation.errors.driver_id}</FormFeedback>
              ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isVehicleAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
        {isVehicleAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default VehicleCreate;
