import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updatePage,
  resetPageFlag,
  getPageDetails,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { call } from "redux-saga/effects";
import { convertToBase64 } from "../../../helpers/image_helper";
const PageEdit = () => {
  document.title = "Edit Page | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [description, setData] = useState();
  const [image, setImage] = useState();
  const { isPageUpdate, isPageUpdateFail, error, success, details } =
    useSelector((state) => ({
      isPageUpdate: state.Pages.isPageUpdate,
      isPageUpdateFail: state.Pages.isPageUpdateFail,
      error: state.Pages.error,
      success: state.Pages.success,
      details: state.Pages.details,
    }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      description: details && details.description ? details.description : "",
      slug: details && details.slug ? details.slug : "",
      image: details && details.image ? details.image : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      slug: Yup.string().required("Please Enter Slug"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: details && details._id ? details._id : "",
        title: values.title,
        description: description,
        slug: values.slug,
        image: image,
      };
      dispatch(updatePage(data));
    },
  });

  useEffect(() => {
    if (isPageUpdate || isPageUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetPageFlag());
      }, 3000);
    }
  }, [dispatch, isPageUpdate, isPageUpdateFail]);

  useEffect(() => {
    dispatch(getPageDetails({ slug: id }));
  }, [dispatch, id]);

  const allowedExts = ['jpg', 'jpeg', 'png','svg','svg+xml'];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Page" pageTitle="Pages" pageUrl="/pages" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Page" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Page title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Slug *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Slug name"
                              name="slug"
                              value={validation.values.slug || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.slug &&
                                validation.errors.slug
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.slug &&
                            validation.errors.slug ? (
                              <FormFeedback type="invalid">
                                {validation.errors.slug}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description *
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              
                              onChange={(event, editor) => {
                                setData(editor.getData())
                                }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              // value={validation.values.image || ""}
                              onChange={onImg1Change}
                              // onBlur={validation.handleBlur}
                              // validate={{
                              //   required: { value: true },
                              // }}
                              // invalid={
                              //   validation.touched.image &&
                              //   validation.errors.image
                              //     ? true
                              //     : false
                              // }
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/pages" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isPageUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isPageUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default PageEdit;
