/* ROLES */
export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL";

export const ADD_NEW_VEHICLE = "ADD_NEW_VEHICLE";
export const ADD_NEW_VEHICLE_SUCCESS = "ADD_NEW_VEHICLE_SUCCESS";
export const ADD_NEW_VEHICLE_FAIL = "ADD_NEW_VEHICLE_FAIL";

export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAIL = "UPDATE_VEHICLE_FAIL";

export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS";
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS";
export const GET_VEHICLE_DETAILS_FAIL = "GET_VEHICLE_DETAILS_FAIL";

export const RESET_VEHICLE_FLAG = "RESET_VEHICLE_FLAG";

// State 
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS";
export const GET_STATE_LIST_FAIL = "GET_STATE_LIST_FAIL";

// Purchase Type 
export const GET_PURCHASE_TYPE_LIST = "GET_PURCHASE_TYPE_LIST";
export const GET_PURCHASE_TYPE_LIST_SUCCESS = "GET_PURCHASE_TYPE_LIST_SUCCESS";
export const GET_PURCHASE_TYPE_LIST_FAIL = "GET_PURCHASE_TYPE_LIST_FAIL";

// Driver
// export const GET_DRIVERS = "GET_DRIVERS";
// export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
// export const GET_DRIVERS_FAIL = "GET_DRIVERS_FAIL";
