import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import Loader from "../../../Components/Common/Loader";
const Widgets = ({dashboard,isSuccess}) => {
    return (
        <React.Fragment>
            {isSuccess?(<>
       {dashboard.map((item, key) => (
       
                <Col xl={3} md={6} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total {item.name}</p>
                                </div>
                                
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix=""
                                            suffix=""
                                            separator=""
                                            end={item.count}
                                            decimals=""
                                            duration={2}
                                        />
                                    </span></h4>
                                    <Link to={item.link} className="text-decoration-underline">{item.name}</Link>
                                </div>

                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title rounded fs-3 bg-soft-success ">
                                        <i className={`text- ` + item.class}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>))} </>)
                : (
                    <Loader  />
                  )}
        </React.Fragment>
    );
};

export default Widgets;