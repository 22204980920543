import {
  GET_MISSIONVISIONS_SUCCESS,
  GET_MISSIONVISIONS_FAIL,

  ADD_NEW_MISSIONVISION_SUCCESS,
  ADD_NEW_MISSIONVISION_FAIL,

  UPDATE_MISSIONVISION_SUCCESS,
  UPDATE_MISSIONVISION_FAIL,

  GET_MISSIONVISION_DETAILS_SUCCESS,
  GET_MISSIONVISION_DETAILS_FAIL,

  DELETE_MISSIONVISION_SUCCESS,
  DELETE_MISSIONVISION_FAIL,

  STATUS_CHANGE_MISSIONVISION_SUCCESS,
  STATUS_CHANGE_MISSIONVISION_FAIL,

  SAVE_ORDERING_MISSIONVISION_SUCCESS,
  SAVE_ORDERING_MISSIONVISION_FAIL,
  SAVE_ORDERING_MISSIONVISION_RESET_FLAG,

  RESET_MISSIONVISION_FLAG,
  MODIFY_MISSIONVISION_FLAG
} from "./actionTypes";

const INIT_STATE = {
  missionvisions: []
};

const Missionvisions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MISSIONVISIONS_SUCCESS:
      return {
        ...state,
        missionvisions: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_MISSIONVISIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_MISSIONVISION_SUCCESS:
      return {
        ...state,
        missionvisions: [...state.missionvisions, action.payload.data],
        success: action.payload.message,
        isMissionvisionAdd: true,
        isMissionvisionAddFail: false,
      };

    case ADD_NEW_MISSIONVISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isMissionvisionAdd: false,
        isMissionvisionAddFail: true,
      };

    case UPDATE_MISSIONVISION_SUCCESS:
      return {
        ...state,
        missionvisions: state.missionvisions.map(missionvision =>
          missionvision._id.toString() === action.payload.data._id.toString()
            ? { ...missionvision, ...action.payload.data }
            : missionvision
        ),
        success: action.payload.message,
        isMissionvisionUpdate: true,
        isMissionvisionUpdateFail: false
      };

    case UPDATE_MISSIONVISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isMissionvisionUpdate: false,
        isMissionvisionUpdateFail: true,
      };

    case GET_MISSIONVISION_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_MISSIONVISION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    

    case DELETE_MISSIONVISION_SUCCESS:
      return { 
      ...state,
      missionvisions: state.missionvisions.filter(getData =>
        getData._id !== action.payload.data._id
      ),
      success: action.payload.message,
      statusSuccess: true,
      statusFaild: false,
    };

    case DELETE_MISSIONVISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    
    //Status Change
    case STATUS_CHANGE_MISSIONVISION_SUCCESS:
      return {
        ...state,
        missionvisions: state.missionvisions.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
      
    case STATUS_CHANGE_MISSIONVISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_MISSIONVISION_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_MISSIONVISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_MISSIONVISION_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };



      
      case RESET_MISSIONVISION_FLAG:
      return {
        ...state,
        isMissionvisionAdd: false,
        isMissionvisionAddFail: false,
        isSuccess: false,
        isMissionvisionDetails: false,
        isMissionvisionUpdate: false,
        isMissionvisionUpdateFail: false,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

      case MODIFY_MISSIONVISION_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };
    default:
      return state;
  }
};

export default Missionvisions;
