import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_MISSIONVISIONS,
  ADD_NEW_MISSIONVISION,
  GET_MISSIONVISION_DETAILS,
  UPDATE_MISSIONVISION,
  DELETE_MISSIONVISION,
  STATUS_CHANGE_MISSIONVISION,
  SAVE_ORDERING_MISSIONVISION,
} from "./actionTypes"

import {
  getMissionvisionsSuccess,
  getMissionvisionsFail,

  addNewMissionvisionSuccess,
  addNewMissionvisionFail,

  getMissionvisionDetailsSuccess,
  getMissionvisionDetailsFail,

  updateMissionvisionSuccess,
  updateMissionvisionFail,

  deleteMissionvisionSuccess,
  deleteMissionvisionFail,

  statusChangeMissionvisionSuccess,
  statusChangeMissionvisionFail,

  saveOrderingMissionvisionSuccess,
  saveOrderingMissionvisionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMissionvisionList,
  addNewMissionvision,
  getMissionvisionDetails,
  updateMissionvision,
  deleteMissionvision,
  statusChangeMissionvision,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchMissionvisions({ payload: missionvision }) {
  try {
    const response = yield call(getMissionvisionList, missionvision)
    yield put(getMissionvisionsSuccess(response))
  } catch (error) {
    yield put(getMissionvisionsFail(error))
  }
}

export function* watchFetchMissionvisions() {
  yield takeEvery(GET_MISSIONVISIONS, fetchMissionvisions);
}

function* onaddNewMissionvision({ payload: missionvision }) {
  try {
      const response = yield call(addNewMissionvision, missionvision);
      if(response.status === 200){
        yield put(addNewMissionvisionSuccess(response));
      } else {
        yield put(addNewMissionvisionFail(response));
      }
  } catch (error) {
      yield put(addNewMissionvisionFail(error));
  }
}

export function* watchAddNewMissionvision() {
  yield takeEvery(ADD_NEW_MISSIONVISION, onaddNewMissionvision);
}

function* onMissionvisionDetails({ payload: missionvision }) {
  try {
      const response = yield call(getMissionvisionDetails, missionvision);
      if(response.status === 200){
        yield put(getMissionvisionDetailsSuccess(response));
      } else {
        yield put(getMissionvisionDetailsFail(response));
      }
  } catch (error) {
      yield put(getMissionvisionDetailsFail(error));
  }
}

export function* watchMissionvisionDetails() {
  yield takeEvery(GET_MISSIONVISION_DETAILS, onMissionvisionDetails);
}

function* onMissionvisionUpdate({ payload: missionvision }) {
  try {
      const response = yield call(updateMissionvision, missionvision);
      if(response.status === 200){
        yield put(updateMissionvisionSuccess(response));
      } else {
        yield put(updateMissionvisionFail(response));
      }
  } catch (error) {
      yield put(updateMissionvisionFail(error));
  }
}

export function* watchUpdateMissionvision() {
  yield takeEvery(UPDATE_MISSIONVISION, onMissionvisionUpdate);
}



function* onDeleteMissionvision({ payload: missionvision }) {
  try {
    const response = yield call(deleteMissionvision, missionvision);
    yield put(deleteMissionvisionSuccess({ missionvision, ...response }));
  } catch (error) {
    yield put(deleteMissionvisionFail(error));
  }
}

export function* watchDeleteMissionvision() {
  yield takeEvery(DELETE_MISSIONVISION, onDeleteMissionvision);
}



//Status Change
function* onStatusChangeMissionvision({ payload: missionvision }) {
  try {
    const response = yield call(statusChangeMissionvision, missionvision);
    yield put(statusChangeMissionvisionSuccess({ missionvision, ...response }));
  } catch (error) {
    yield put(statusChangeMissionvisionFail(error));
  }
}

export function* watchStatusChangeMissionvision() {
  yield takeEvery(STATUS_CHANGE_MISSIONVISION, onStatusChangeMissionvision);
}


//Save Ordering
function* onSaveOrderingMissionvision({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingMissionvisionSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingMissionvisionFail(error));
  }
}

export function* watchSaveOrderingMissionvision() {
  yield takeEvery(SAVE_ORDERING_MISSIONVISION, onSaveOrderingMissionvision);
}






function* missionvisionsSaga() {
  yield all(
    [
      fork(watchFetchMissionvisions),
      fork(watchAddNewMissionvision),
      fork(watchMissionvisionDetails),
      fork(watchUpdateMissionvision),
      fork(watchDeleteMissionvision),
      fork(watchStatusChangeMissionvision),
      fork(watchSaveOrderingMissionvision),
    ]
  );

}

export default missionvisionsSaga
