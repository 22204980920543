import {
  GET_TESTIMONIALS,
  GET_TESTIMONIALS_SUCCESS,
  GET_TESTIMONIALS_FAIL,
  ADD_NEW_TESTIMONIAL,
  ADD_NEW_TESTIMONIAL_SUCCESS,
  ADD_NEW_TESTIMONIAL_FAIL,
  GET_TESTIMONIAL_DETAILS,
  GET_TESTIMONIAL_DETAILS_SUCCESS,
  GET_TESTIMONIAL_DETAILS_FAIL,
  UPDATE_TESTIMONIAL,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_FAIL,
  RESET_TESTIMONIAL_FLAG,
} from "./actionTypes";

export const getTestimonials = data => ({
  type: GET_TESTIMONIALS,
  payload: data,
});

export const getTestimonialsSuccess = data => ({
  type: GET_TESTIMONIALS_SUCCESS,
  payload: data,
});

export const getTestimonialsFail = error => ({
  type: GET_TESTIMONIALS_FAIL,
  payload: error,
});

export const addNewTestimonial = testimonial => ({
  type: ADD_NEW_TESTIMONIAL,
  payload: testimonial,
});

export const addNewTestimonialSuccess = testimonial => ({
  type: ADD_NEW_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const addNewTestimonialFail = error => ({
  type: ADD_NEW_TESTIMONIAL_FAIL,
  payload: error,
});

export const updateTestimonial = testimonial => ({
  type: UPDATE_TESTIMONIAL,
  payload: testimonial,
});

export const updateTestimonialSuccess = testimonial => ({
  type: UPDATE_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const updateTestimonialFail = error => ({
  type: UPDATE_TESTIMONIAL_FAIL,
  payload: error,
});

export const getTestimonialDetails = testimonial => ({
  type: GET_TESTIMONIAL_DETAILS,
  payload: testimonial,
});

export const getTestimonialDetailsSuccess = role => ({
  type: GET_TESTIMONIAL_DETAILS_SUCCESS,
  payload: role,
});

export const getTestimonialDetailsFail = error => ({
  type: GET_TESTIMONIAL_DETAILS_FAIL,
  payload: error,
});

export const resetTestimonialFlag = () => {
  return {
    type: RESET_TESTIMONIAL_FLAG,
  }
}
