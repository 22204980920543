import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card,CardHeader, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewSolution, resetSolutionFlag } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer ,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import {
    convertToBase64 
  } from "../../../helpers/image_helper"
  
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const SolutionCreate = () => {
    document.title = "Create Solution | Defendit - Admin Panel";
    const dispatch = useDispatch();
    const allowedExts = ['jpg', 'jpeg', 'png','svg','svg+xml'];
    const [isCreating, setIsCreating] = useState(false);
    const [image, setImage] = useState();
    const [description, setData] = useState();
    const { isSolutionAdd, isSolutionAddFail, error, success } = useSelector(state => ({
        isSolutionAdd: state.Solutions.isSolutionAdd,
        isSolutionAddFail: state.Solutions.isSolutionAddFail,
        error: state.Solutions.error,
        success: state.Solutions.success,
    }));
    const onImg1Change = (e) => {
        if (e.target.files[0]) {
          const ext = e.target.files[0].type.split('/')[1];
    
          if (![...allowedExts].includes(ext)) {
            console.log('Please upload a valid image');
            toast("Please upload a valid image.");
          } else {
            // getBase64(e.target.files[0]);
            var output = document.getElementById('image1');
              output.src = URL.createObjectURL(e.target.files[0]);
              output.onload = function() {
                URL.revokeObjectURL(output.src) // free memory
              }
            convertToBase64(e.target.files[0], setImage);
      
          }
        }
      };
     
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            icon: '',
            description: '',
            image: '',
          
           
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Solution Name"),
            icon: Yup.string().required("Please Enter Solution Icon")
        }),
        onSubmit: (values) => {
           
            setIsCreating(true)
            const data = {
                name: values.name,
                icon: values.icon,
                description: description,
                image: image,
            }
            dispatch(addNewSolution(data))
        },
    });

    useEffect(() => {
        if(isSolutionAdd || isSolutionAddFail){
            setIsCreating(false)
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetSolutionFlag())
            }, 3000);
        }
      }, [dispatch, isSolutionAdd, isSolutionAddFail, validation]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Solution" pageTitle="Solutions" pageUrl="/solutions" />
                    <Row>
                        
                            <Col lg={8}>
                            <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                                <Card>
                                    <PreviewCardHeader title="Create Solution" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Solution name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Icon</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Solution icon"
                                                            name='icon'
                                                            value={validation.values.icon || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.icon && validation.errors.icon ? true : false
                                                            }
                                                        />
                                                        {validation.touched.icon && validation.errors.icon ? (
                                                            <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="gy-4">

                                                <Col xxl={12} md={12}>
                                                <div className="hstack gap-3 align-items-start mb-3">
                                                    <div className="flex-grow-1">
                                                    <Label className="form-label" htmlFor="product-title-input">
                                                    Description
                                                    </Label>
                                                    <CKEditor
                                                            editor={ClassicEditor}
                                                            name="description"
                                                            data={validation.values.description || ""}
                                                            onChange={(event, editor) => {
                                                            setData(editor.getData())
                                                            }}
                                                            invalid={false}
                                                            />
                                                            {validation.touched.description && validation.errors.description ? (
                                                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    </div>
                                                </Col>
                                                
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="text-end mb-4">
                                    <Link to="/solutions" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                                </Form>
                            </Col>
                           
                      
                        <Col lg={4}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Client Image</h5>
                                </CardHeader>
                                <CardBody>
                                    <div className="hstack gap-3 align-items-start mb-3">
                                    <div className="flex-grow-1">
                                    <Label className="form-label" htmlFor="product-title-input">
                                        Image
                                    </Label>
                                    <Input type="file" onChange={onImg1Change}/>
                                    <img src={validation.values.image || ""} alt=""  id="image1" width="100px" ></img>
                                    </div>
                                    </div>
                                                

                                    
                                </CardBody>
                            </Card>

                                
                            </Col>
                    </Row>
                    {isSolutionAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isSolutionAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default SolutionCreate;