import React, { useEffect, useState } from "react";
import {
  Container,
  CardHeader,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateUser,
  resetUserFlag,
  getUserDetails,
  getStateList,
  getCountryList,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { call } from "redux-saga/effects";
import { convertToBase64 } from "../../../helpers/image_helper";
import { ToastContainer, toast } from "react-toastify";

const UserEdit = () => {
  document.title = "Edit Manager | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [image, setBannerImage1] = useState();
  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const [isCreating, setIsCreating] = useState(false);
  const {
    isUserUpdate,
    isUserUpdateFail,
    error,
    success,
    details,
    countryList,
    stateList,
  } = useSelector((state) => ({
    isUserUpdate: state.Users.isUserUpdate,
    isUserUpdateFail: state.Users.isUserUpdateFail,
    error: state.Users.error,
    success: state.Users.success,
    details: state.Users.details,
    countryList: state.Drivers.country,
    stateList: state.Vehicles.vehicleState,
  }));

  const selectedStateOption = {
    value: details && details.states_details ? details.states_details?._id : "",
    label:
      details && details.states_details ? details.states_details?.name : "",
  };
  const selectedCountryOption = {
    value:
      details && details.country_details ? details.country_details?._id : "",
    label:
      details && details.country_details ? details.country_details?.name : "",
  };

  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setBannerImage1);
      }
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: details && details.name ? details.name : "",
      phone: details && details.phone ? details.phone : "",
      email: details && details.email ? details.email : "",
      address: details && details.address ? details.address : "",
      city: details && details.city ? details.city : "",
      state: selectedStateOption,
      country: selectedCountryOption,
      zip_code: details && details.zip_code ? details.zip_code : "",
      image: details && details.image ? details.image : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name field is required."),
      phone: Yup.string().required("Phone Nember field is required."),
      email: Yup.string().required("Email Id field is required."),
      address: Yup.string().required("Address field is required."),
      city: Yup.string().required("City field is required."),
      zip_code: Yup.string().required("Zipcode field is required."),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        name: values.name,
        phone: values.phone,
        email: values.email,
        address: values.address,
        city: values.city,
        country: values.country?.value,
        state: values.state?.value,
        zip_code: values.zip_code,
        image: image,
        user_type : 2,
      };
      dispatch(updateUser(data));
    },
  });

  useEffect(() => {
    if (isUserUpdate || isUserUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetUserFlag());
        history.push("/managers");
      }, 3000);
    }
  }, [dispatch, isUserUpdate, isUserUpdateFail]);

  useEffect(() => {
    dispatch(getUserDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getCountryList());
  }, [dispatch]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Manager" pageTitle="Manager" pageUrl="/managers" />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>

              <Col lg={8}>
                <Card>
                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Phone
                            </Label>
                            <Input
                              type="number"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phone"
                              value={validation.values.phone || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.phone &&
                                validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Email
                            </Label>
                            <Input
                              type="email"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={validation.values.email || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Address
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              value={validation.values.address || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.address &&
                                validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address &&
                            validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              Country
                            </Label>
                            <Select
                              type="text"
                              name="country"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "country",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                countryList &&
                                countryList.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              value={validation.values.country || ""}
                            />
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <FormFeedback type="invalid">
                                {validation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              State
                            </Label>
                            <Select
                              type="text"
                              name="state"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "state",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                stateList &&
                                stateList.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              value={validation.values.state || ""}
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              City
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              value={validation.values.city || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.city &&
                                validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Zipcode
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Zipcode"
                              name="zip_code"
                              value={validation.values.zip_code || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.zip_code &&
                                validation.errors.zip_code
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.zip_code &&
                            validation.errors.zip_code ? (
                              <FormFeedback type="invalid">
                                {validation.errors.zip_code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Image</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="hstack gap-3 align-items-start mb-3">
                      <div className="flex-grow-1">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          Image
                        </Label>
                        <Input type="file" onChange={onImg1Change} />
                        <img
                          src={validation.values.image || ""}
                          alt=""
                          id="image1"
                          width="100px"
                        ></img>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12}>
                <div className="text-end mb-4">
                  <Link to="/managers" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>

            </Row>
          </Form>

          {isUserUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isUserUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default UserEdit;
