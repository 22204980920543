import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_VEHICLES,
  ADD_NEW_VEHICLE,
  GET_VEHICLE_DETAILS,
  UPDATE_VEHICLE,

  GET_STATE_LIST,

  GET_PURCHASE_TYPE_LIST,
  // GET_DRIVERS
} from "./actionTypes"

import {
  getVehiclesSuccess,
  getVehiclesFail,
  addNewVehicleSuccess,
  addNewVehicleFail,
  getVehicleDetailsSuccess,
  getVehicleDetailsFail,
  updateVehicleSuccess,
  updateVehicleFail,

  getStateListSuccess,
  getStateListFail,

  getPurchaseTypeListSuccess,
  getPurchaseTypeListFail,

  // getDriversSuccess,
  // getDriversFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVehicleList,
  addNewVehicle,
  getVehicleDetails,
  updateVehicle,

  getStateList,
  getPurchaseTypeList,

  // getDriverList,
} from "../../helpers/fakebackend_helper"

function* fetchVehicles({ payload: vehicle }) {
  try {
    const response = yield call(getVehicleList, vehicle)
    yield put(getVehiclesSuccess(response))
  } catch (error) {
    yield put(getVehiclesFail(error))
  }
}

export function* watchFetchVehicles() {
  yield takeEvery(GET_VEHICLES, fetchVehicles);
}

function* onAddNewVehicle({ payload: vehicle }) {
  try {
      const response = yield call(addNewVehicle, vehicle);
      if(response.status === 200){
        yield put(addNewVehicleSuccess(response));
      } else {
        yield put(addNewVehicleFail(response));
      }
  } catch (error) {
      yield put(addNewVehicleFail(error));
  }
}

export function* watchAddNewVehicle() {
  yield takeEvery(ADD_NEW_VEHICLE, onAddNewVehicle);
}

function* onVehicleDetails({ payload: vehicle }) {
  try {
      const response = yield call(getVehicleDetails, vehicle);
      if(response.status === 200){
        yield put(getVehicleDetailsSuccess(response));
      } else {
        yield put(getVehicleDetailsFail(response));
      }
  } catch (error) {
      yield put(getVehicleDetailsFail(error));
  }
}

export function* watchVehicleDetails() {
  yield takeEvery(GET_VEHICLE_DETAILS, onVehicleDetails);
}

function* onVehicleUpdate({ payload: vehicle }) {
  try {
      const response = yield call(updateVehicle, vehicle);
      if(response.status === 200){
        yield put(updateVehicleSuccess(response));
      } else {
        yield put(updateVehicleFail(response));
      }
  } catch (error) {
      yield put(updateVehicleFail(error));
  }
}

export function* watchUpdateVehicle() {
  yield takeEvery(UPDATE_VEHICLE, onVehicleUpdate);
}

// State
function* onStateList({ payload: data }) {
  try {
      const response = yield call(getStateList,data);
      if(response.status === 200){
        yield put(getStateListSuccess(response));
      } else {
        yield put(getStateListFail(response));
      }
  } catch (error) {
      yield put(getStateListFail(error));
  }
}

export function* watchStateList() {
  yield takeEvery(GET_STATE_LIST, onStateList);
}

// Purchase type
function* onPurchaseTypeList() {
  try {
      const response = yield call(getPurchaseTypeList);
      if(response.status === 200){
        yield put(getPurchaseTypeListSuccess(response));
      } else {
        yield put(getPurchaseTypeListFail(response));
      }
  } catch (error) {
      yield put(getStateListFail(error));
  }
}

export function* watchPurchaseTypeList() {
  yield takeEvery(GET_PURCHASE_TYPE_LIST, onPurchaseTypeList);
}

// Drivers
// function* onDriverList() {
//   try {
//       const response = yield call(getDriverList);
//       if(response.status === 200){
//         yield put(getDriversSuccess(response));
//       } else {
//         yield put(getDriversFail(response));
//       }
//   } catch (error) {
//       yield put(getDriversFail(error));
//   }
// }

// export function* watchDriverList() {
//   yield takeEvery(GET_DRIVERS, onDriverList);
// }


function* vehicleSaga() {
  yield all(
    [
      fork(watchFetchVehicles),
      fork(watchAddNewVehicle),
      fork(watchVehicleDetails),
      fork(watchUpdateVehicle),
      fork(watchStateList),
      fork(watchPurchaseTypeList),
      // fork(watchDriverList),
    ]
  );

}

export default vehicleSaga
