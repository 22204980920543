let base64String = "";

export const convertToBase64 = (file, setImage) => {
  if(file instanceof File && file.name) {
    const reader = new FileReader();
    
    reader.onload = () => {
      base64String = reader.result;
      const imageBase64Stringsep = base64String;
      setImage(imageBase64Stringsep);
    }

    reader.readAsDataURL(file);
  }
}
export const convertToBase64MultipleFile = file => new Promise((res, rej) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => res(reader.result);
  reader.onerror = error => rej(error);
})
export const convertToBase64MultipleFiles =  async(files, setFile,file) => {
  let images=files;

  if(files.length>0){    
    for (let i = 0; i < files.length; i++) {
      if(files[i] instanceof File && files[i].name) {
        const reader = new FileReader();        
        reader.onload = () => {
          base64String = reader.result;
          const imageBase64Stringsep = base64String;
        
          images.push(imageBase64Stringsep);
          setFile([...images])
        }
    
        reader.readAsDataURL(files[i]);
      }
   
    }
    // console.log(images," ---------------",file)
    // setFile([...file,images]) ;
    
  }
  
}