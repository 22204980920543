import {
  GET_MISSIONVISIONS,
  GET_MISSIONVISIONS_SUCCESS,
  GET_MISSIONVISIONS_FAIL,
  ADD_NEW_MISSIONVISION,
  ADD_NEW_MISSIONVISION_SUCCESS,
  ADD_NEW_MISSIONVISION_FAIL,
  UPDATE_MISSIONVISION,
  UPDATE_MISSIONVISION_SUCCESS,
  UPDATE_MISSIONVISION_FAIL,
  GET_MISSIONVISION_DETAILS,
  GET_MISSIONVISION_DETAILS_SUCCESS,
  GET_MISSIONVISION_DETAILS_FAIL,
  DELETE_MISSIONVISION,
  DELETE_MISSIONVISION_SUCCESS,
  DELETE_MISSIONVISION_FAIL,
  STATUS_CHANGE_MISSIONVISION,
  STATUS_CHANGE_MISSIONVISION_SUCCESS,
  STATUS_CHANGE_MISSIONVISION_FAIL,

  SAVE_ORDERING_MISSIONVISION,
  SAVE_ORDERING_MISSIONVISION_SUCCESS,
  SAVE_ORDERING_MISSIONVISION_FAIL,
  SAVE_ORDERING_MISSIONVISION_RESET_FLAG,

  RESET_MISSIONVISION_FLAG,
  MODIFY_MISSIONVISION_FLAG
  
} from "./actionTypes";

export const getMissionvisions = data => ({
  type: GET_MISSIONVISIONS,
  payload: data,
});

export const getMissionvisionsSuccess = data => ({
  type: GET_MISSIONVISIONS_SUCCESS,
  payload: data,
});

export const getMissionvisionsFail = error => ({
  type: GET_MISSIONVISIONS_FAIL,
  payload: error,
});

export const addNewMissionvision = missionvision => ({
  type: ADD_NEW_MISSIONVISION,
  payload: missionvision,
});

export const addNewMissionvisionSuccess = missionvision => ({
  type: ADD_NEW_MISSIONVISION_SUCCESS,
  payload: missionvision,
});

export const addNewMissionvisionFail = error => ({
  type: ADD_NEW_MISSIONVISION_FAIL,
  payload: error,
});

export const updateMissionvision = missionvision => ({
  type: UPDATE_MISSIONVISION,
  payload: missionvision,
});

export const updateMissionvisionSuccess = missionvision => ({
  type: UPDATE_MISSIONVISION_SUCCESS,
  payload: missionvision,
});

export const updateMissionvisionFail = error => ({
  type: UPDATE_MISSIONVISION_FAIL,
  payload: error,
});

export const getMissionvisionDetails = missionvision => ({
  type: GET_MISSIONVISION_DETAILS,
  payload: missionvision,
});

export const getMissionvisionDetailsSuccess = missionvision => ({
  type: GET_MISSIONVISION_DETAILS_SUCCESS,
  payload: missionvision,
});

export const getMissionvisionDetailsFail = error => ({
  type: GET_MISSIONVISION_DETAILS_FAIL,
  payload: error,
});

//Delete
export const deleteMissionvision = missionvision => ({
  type: DELETE_MISSIONVISION,
  payload: missionvision,
});

export const deleteMissionvisionSuccess = missionvision => ({
  type: DELETE_MISSIONVISION_SUCCESS,
  payload: missionvision,
});

export const deleteMissionvisionFail = error => ({
  type: DELETE_MISSIONVISION_FAIL,
  payload: error,
});


//Status Change
export const statusChangeMissionvision = missionvision => ({
  type: STATUS_CHANGE_MISSIONVISION,
  payload: missionvision,
});

export const statusChangeMissionvisionSuccess = missionvision => ({
  type: STATUS_CHANGE_MISSIONVISION_SUCCESS,
  payload: missionvision,
});

export const statusChangeMissionvisionFail = error => ({
  type: STATUS_CHANGE_MISSIONVISION_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingMissionvision = data => ({
  type: SAVE_ORDERING_MISSIONVISION,
  payload: data,
});

export const saveOrderingMissionvisionSuccess = data => ({
  type: SAVE_ORDERING_MISSIONVISION_SUCCESS,
  payload: data,
});

export const saveOrderingMissionvisionFail = error => ({
  type: SAVE_ORDERING_MISSIONVISION_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingMissionvisionResetFlag = () => {
  return {
    type: SAVE_ORDERING_MISSIONVISION_RESET_FLAG,
  }
}



export const resetMissionvisionFlag = () => {
  return {
    type: RESET_MISSIONVISION_FLAG,
  }
}

export const modifyMissionvisionFlag = () => {
  return {
    type: MODIFY_MISSIONVISION_FLAG,
  }
}