/* STEPS */
export const GET_STEPS = "GET_STEPS";
export const GET_STEPS_SUCCESS = "GET_STEPS_SUCCESS";
export const GET_STEPS_FAIL = "GET_STEPS_FAIL";

export const ADD_NEW_STEP = "ADD_NEW_STEP";
export const ADD_NEW_STEP_SUCCESS = "ADD_NEW_STEP_SUCCESS";
export const ADD_NEW_STEP_FAIL = "ADD_NEW_STEP_FAIL";

export const GET_STEP_DETAILS = "GET_STEP_DETAILS";
export const GET_STEP_DETAILS_SUCCESS = "GET_STEP_DETAILS_SUCCESS";
export const GET_STEP_DETAILS_FAIL = "GET_STEP_DETAILS_FAIL";

export const UPDATE_STEP = "UPDATE_STEP";
export const UPDATE_STEP_SUCCESS = "UPDATE_STEP_SUCCESS";
export const UPDATE_STEP_FAIL = "UPDATE_STEP_FAIL";

export const DELETE_STEP = "DELETE_STEP";
export const DELETE_STEP_SUCCESS = "DELETE_STEP_SUCCESS";
export const DELETE_STEP_FAIL = "DELETE_STEP_FAIL";

export const STATUS_CHANGE_STEP = "STATUS_CHANGE_STEP";
export const STATUS_CHANGE_STEP_SUCCESS = "STATUS_CHANGE_STEP_SUCCESS";
export const STATUS_CHANGE_STEP_FAIL = "STATUS_CHANGE_STEP_FAIL";

export const SAVE_ORDERING_STEP = "SAVE_ORDERING_STEP";
export const SAVE_ORDERING_STEP_SUCCESS = "SAVE_ORDERING_STEP_SUCCESS";
export const SAVE_ORDERING_STEP_FAIL = "SAVE_ORDERING_STEP_FAIL";
export const SAVE_ORDERING_STEP_RESET_FLAG = "SAVE_ORDERING_STEP_RESET_FLAG";

export const RESET_STEP_FLAG = "RESET_STEP_FLAG";
export const MODIFY_STEP_FLAG = "MODIFY_STEP_FLAG";