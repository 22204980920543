import React, { useState, useEffect, useRef } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getSitesettingDetails } from '../../store/actions';

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = ({ socket }) => {

  socket.on('notificationListResponse', data => {
    setNotifications(data.list);
    setUnreadNotifications(data.unreadCount)
  });

  socket.on('notificationAddedResponse', data => {
    for (let index = 0; index < data.list.length; index++) {
      const element = data.list[index];
      // eslint-disable-next-line eqeqeq
      if (userDetails.data?._id && element.reciver_id == userDetails.data?._id) {
        setNotifications([element, ...notifications]);
        // eslint-disable-next-line eqeqeq
        if(notificationButtonEl.current.context.isOpen == true){
          socket.emit('notificationRead', {
            notification_id: element._id,
            user_id: userDetails.data?._id
          })
        } else {
          if (location.pathname !== '/notifications') {
            setUnreadNotifications(data.unreadCount);
          }
        }
      }
    }
  });

  socket.on('notificationReadResponse', data => {
    // eslint-disable-next-line eqeqeq
    if (userDetails.data?._id && data.data.user_id == userDetails.data?._id) {
      setUnreadNotifications(data.unreadCount);
    }
  });
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };
  const location = useLocation()
  const dispatch = useDispatch();
  const notificationButtonEl = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('authUser')));
  const { settings } = useSelector(state => ({
    settings: state.Sitesettings.details,
  }));

  useEffect(() => {
    dispatch(getSitesettingDetails({}))
  }, [dispatch]);

  useEffect(() => {
    if (isNotificationDropdown) {
      socket.emit('notificationRead', {
        notification_id: '',
        user_id: userDetails.data?._id
      });
      setUnreadNotifications(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationDropdown])

  useEffect(() => {
    if (userDetails.data?._id) {
      socket.emit('notificationList', {
        user_id: userDetails.data?._id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  const dateFormat = issue_date => {
    return new Intl.DateTimeFormat('en', {
      //weekday: 'long',
      month: 'short',//long
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
      hour12: true
    }
    ).format(new Date(issue_date));
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
        { userDetails && userDetails.data?.role_details?.code!=='managers' &&
        <DropdownToggle ref={notificationButtonEl} type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
          <i className='bx bx-bell fs-22'></i>
          <span
            className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{(unreadNotifications > 99) ? '99+' : unreadNotifications}<span
              className="visually-hidden">unread messages</span></span>
        </DropdownToggle>}
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge badge-soft-light fs-13"> {(unreadNotifications > 99) ? '99+' : unreadNotifications} New</span>
                </div>
              </Row>
            </div>
          </div>

          <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
            {
              (notifications && notifications.length > 0) &&
              notifications.map((item, index) => (
                <div className="text-reset notification-item d-block dropdown-item position-relative" key={index} onClick={(e)=>setIsNotificationDropdown(false)
                }>
                  <div className="d-flex">
                    <img src={(item.sender_details)?item.sender_details.image:settings?.logo}
                      className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                    <div className="flex-1">
                      <Link to="#" className="stretched-link"><h6 className="mt-0 mb-1 fs-13 fw-semibold">{(item.sender_details)?item.sender_details.name:settings?.title}</h6></Link>
                      <div className="fs-13 text-muted">
                        {item.message}
                      </div>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span><i className="mdi mdi-clock-outline"></i> {item?.created_at ? dateFormat(item?.created_at) : ''}</span>
                      </p>
                    </div>
                    <div className="px-2 fs-15">
                      {/* <div className="form-check notification-check">
                        <input className="form-check-input" type="checkbox" value="" id="all-notification-check02" />
                        <label className="form-check-label" htmlFor="all-notification-check02"></label>
                      </div> */}
                      {/* <input className="form-check-input" type="checkbox" /> */}
                    </div>
                  </div>
                </div>
              ))
            }

            <div className="my-3 text-center">
             <button type="button" className="btn btn-soft-success waves-effect waves-light" onClick={(e)=>setIsNotificationDropdown(false)
             }><Link
                      to="/notifications">View
                    All Notifications <i className="ri-arrow-right-line align-middle"></i></Link></button>
             
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;